import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { licenseActions } from '../../../_actions';
import { SideBar } from '../../../_components/Admin/More/License';
import { Header } from '../../../_components/Admin/Header';
import { isEmpty } from 'lodash';


function HistoryLicense() {

    const dispatch = useDispatch();
    const licenses = useSelector(state => state.licenses);
    const authentication = useSelector(state => state.authentication);

    useEffect(() => {
        dispatch(licenseActions.getHistoryLicense(authentication.id));
    }, []);
    return (
        <div className="sunE-right-container">
            <Header title={'Nâng cấp tài khoản'} isBack/>
            <div className="flex-m sunE-content">
                <SideBar />
                <div className="flex-1 sunE-content-box  list-buy-history">

                    {!isEmpty(licenses.listHistoryLicenses) ? licenses.listHistoryLicenses.map((license, i) => {
                        return (
                            <div className="box-shadow box-white-content box-buy-history" key={i}>
                                <h2 className="title-bg">{license.order_detail[0]['package_name']}</h2>
                                <div className="flex-m box-buy-history-price">
                                    <div className="flex-1">
                                        <p className="price">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(license.order_detail[0]['price'])}</p>
                                    </div>
                                    <div className="flex-1 box-buy-history-time">
                                        <p className="text-light">Ngày mua: {license.created_at}</p>
                                        <p className="text-light">Thời hạn: {license.order_detail[0]['expired_datetime']}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }): <div className="empty-payment-custom">
                        <img src="/assets/images/no_payment.png" alt="ico_remove_blue"/>
                        <span>Bạn chưa mua khóa học nào.</span>
                        <span>Nhập mã hoặc ấn vào "Mua gói mới" để trải nghiệm.</span>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export { HistoryLicense };