import React, { useEffect, useState } from "react";
import { HeaderCloud } from "../../_components/Header";
import { stepAuthConstants } from "../../_constants/auth";
import { apiCaller, history } from "../../_helpers";
import AccountType from "./components/AccountType";
import CreateAccount from "./components/CreateAccount/index";
import RegisterSuccess from "./components/RegisterSuccess";
import UpdateInformation from "./components/UpdateInformation";
import { userConstants } from "../../_constants";
import API from "../../_apis/APIConstants";
import APIBase from "../../_base/APIBase";
import { useDispatch } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";

function RegisterPage() {
    const [step, setStep] = useState(stepAuthConstants.STEP_CREATE_ACCOUNT);
    const dispatch = useDispatch()
    const [dataCreateAccount, setDataCreateAccount] = useState({
        email: "",
        password: "",
        rePassword: "",
    });
    const [dataStepRole, setDataStepRole] = useState({
        role: "",
        grade: "",
        schoolType: "public",
    });
    const [dataUpdateInfo, setDataUpdateInfo] = useState({
        fullname: "",
        birthday: "",
        gender: "",
        phone: "",
        province: "",
        district: "",
        school: "",
        onRegister: false,
    });
    const [statusRegister, setStatusRegister] = useState({
        type: "",
        message: "",
    });
    const [messageTeacher, setMessageTeacher] = useState("");
    const [popupMessageRegisterTeacher, setPopupMessageRegisterTeacher] = useState(false);
    const [saveDataUser, setSaveDataUser] = useState({})
    const [statusSuccessRegisterTeacher, setStatusSuccessRegisterTeacher] = useState();

    useEffect(() => {
        if (dataUpdateInfo.onRegister) {
            onRegister();
        }
    }, [dataUpdateInfo]);

    const onClickYes = () => {
        const data = {
            email: dataCreateAccount.email,
            password: dataCreateAccount.password,
            re_password: dataCreateAccount.rePassword,
            role: dataStepRole.role,
            class: dataStepRole.grade,
            school_type: dataStepRole.schoolType,
            school: dataUpdateInfo.school,
            birthday: dataUpdateInfo.birthday,
            grade_id: dataStepRole.grade,
            fullname: dataUpdateInfo.fullname,
            gender: dataUpdateInfo.gender,
            province_alias: dataUpdateInfo.province,
            district_alias: dataUpdateInfo.district,
            phone: dataUpdateInfo.phone,
            year_of_birth: dataUpdateInfo.birthday?.getFullYear(),
        };
        data.birthday = moment(dataUpdateInfo.birthday).format('YYYY-MM-DD')
        setPopupMessageRegisterTeacher(false)
        history.push(`/register/authentication`, {data: data})
    }

    const onRegister = async() => {
        const data = {
            email: dataCreateAccount.email,
            password: dataCreateAccount.password,
            re_password: dataCreateAccount.rePassword,
            role: dataStepRole.role,
            class: dataStepRole.grade,
            school_type: dataStepRole.schoolType,
            school: dataUpdateInfo.school,
            birthday: dataUpdateInfo.birthday,
            grade_id: dataStepRole.grade,
            fullname: dataUpdateInfo.fullname,
            gender: dataUpdateInfo.gender,
            province_alias: dataUpdateInfo.province,
            district_alias: dataUpdateInfo.district,
            phone: dataUpdateInfo.phone,
            year_of_birth: dataUpdateInfo.birthday?.getFullYear(),
        };
        if(userConstants.ROLE_TEACHER == dataStepRole.role) {
            data.birthday = moment(dataUpdateInfo.birthday).format('YYYY-MM-DD')
            try {
                let uriApi = `${API.baseURL}${API.register_teacher}`;
                const result = await APIBase.apiBaseCaller("POST", uriApi, data);
                if(result.status) {
                    setMessageTeacher("Tạo tài khoản thành công. Vui lòng kiểm tra tin nhắn zalo để nhận mã kích hoạt.")            
                    setPopupMessageRegisterTeacher(true)
                    setStatusSuccessRegisterTeacher(true)
                }
                
            } catch (error) {
                if(!isEmpty(error?.message?.errors?.phone)) {
                    setMessageTeacher("Số điện thoại đã dùng để đăng ký tài khoản khác. Vui lòng nhập số điện thoại mới.")            
                    setPopupMessageRegisterTeacher(true)
                    setStatusSuccessRegisterTeacher(false)
                }
            }
        } else {
            apiCaller("/api_register/register", "POST", data).then((res) => {
                if (res.status) {
                    setStatusRegister({
                        type: "success",
                        message: res.msg,
                    });
                }
            });
        }
    };

    return (
        <div className="bg-register rel">
            <HeaderCloud />
            {statusRegister.type !== "success" ? (
                <>
                    <div style={{ display: step === stepAuthConstants.STEP_CREATE_ACCOUNT ? "block" : "none" }}>
                        <CreateAccount setStep={setStep} data={dataCreateAccount} setData={setDataCreateAccount}></CreateAccount>
                    </div>
                    <div style={{ display: step === stepAuthConstants.STEP_ACCOUNT_TYPE ? "block" : "none" }}>
                        <AccountType setStep={setStep} data={dataStepRole} setData={setDataStepRole} step={step}></AccountType>
                    </div>
                    <div style={{ display: step === stepAuthConstants.STEP_UPDATE_ACCOUNT ? "block" : "none" }}>
                        <UpdateInformation
                            setStep={setStep}
                            data={dataUpdateInfo}
                            setData={setDataUpdateInfo}
                            onRegister={onRegister}
                            step={step}
                            role={dataStepRole.role}
                            schoolType={dataStepRole.schoolType}
                            messageTeacher={messageTeacher}
                            popupMessageRegisterTeacher={popupMessageRegisterTeacher}
                            setPopupMessageRegisterTeacher={setPopupMessageRegisterTeacher}
                            dataUser={saveDataUser}
                            statusSuccessRegisterTeacher={statusSuccessRegisterTeacher}
                            onClickYes={onClickYes}
                        ></UpdateInformation>
                    </div>
                </>
            ) : (
                <RegisterSuccess></RegisterSuccess>
            )}
        </div>
    );
}

export { RegisterPage };
