import { isEmpty } from 'lodash';
import React from 'react';
import { ChartDoughnut } from './../../../_components/Chart';

const OverviewChartReport = (props) => {
    const {report_data, exercises} = props;
    const { exercise_info } = exercises;
    
    let reportNote = []

    if (!isEmpty(exercises) && !isEmpty(exercises.exercise_info)) {
        let total_overview_score = exercises.exercise_info.poor + exercises.exercise_info.average + exercises.exercise_info.good + exercises.
        exercise_info.excellent;
        total_overview_score = total_overview_score === 0 ? 1 : total_overview_score;
        reportNote = [
            {
                color: '#00AEEF',
                label: 'Giỏi: ' + exercises.exercise_info.excellent + ' (' + Number.parseFloat(exercises.exercise_info.excellent / total_overview_score * 100).toFixed(1) + '%)'
            },
            {
                color: '#00CC7E',
                label: 'Khá: ' + exercises.exercise_info.good + ' (' + Number.parseFloat(exercises.exercise_info.good / total_overview_score * 100).toFixed(1) + '%)'
            },
            {
                color: '#FBB040',
                label: 'Trung Bình: ' + exercises.exercise_info.average + ' (' + Number.parseFloat(exercises.exercise_info.average / total_overview_score * 100).toFixed(1) + '%)'
            },
            {
                color: '#BE1E2D',
                label: 'Yếu: ' + exercises.exercise_info.poor + ' (' + Number.parseFloat(exercises.exercise_info.poor / total_overview_score * 100).toFixed(1) + '%)'
            },
        ]
    }

    return (
        <div className="__overview_report">
            {/* <div className="__overview_chart">
                <div className="__chart">
                    <ChartDoughnut report_data={report_data} position='bottom' display_legend={false} />
                </div>
            </div> */}
            
            <ChartDoughnut report_data={report_data} position='bottom' display_legend={false} />
            <div className="__overview_info">
                <div className="__count_std_completed" style={{color: '#404041'}}>Số học sinh hoàn thành: <h5>{exercise_info.total_complete + '/' + exercise_info.total_user}</h5></div>
                {reportNote.map((item, index) => (
                    <div className="__item_note_chart" key={index}>
                        <div className="__color_chart" style={{background: item.color}}></div>
                        <span className="__text_note">{item.label}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OverviewChartReport;