import React, { useEffect, useState } from "react";
import { teacherActions } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { ContentSkill } from "./";
import { useHistory } from "react-router-dom";
import { teacherConstants } from "../../../_constants";

function ViewDetailCurriculumListExercise() {
  const { id, unit_id, skill } = useParams();

  const history = useHistory();

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const typeAssign = params?.get("assign");
  const userID = params?.get("user_id");

  useEffect(() => {
    dispatch(teacherActions.getDetailCurriculumById(id));
  }, []);
  const dispatch = useDispatch();
  const curriculums = useSelector(
    (state) => state.curriculums.detail.data_lesson.unit_name
  );
  let currentCurriculum = curriculums.find((item) => item.unit_id === unit_id);
  let currentListSkill;
  if (currentCurriculum) {
    currentListSkill = currentCurriculum.list_skill.find(
      (item) => item.skill === skill
    ).lesson_in_skill;
  }
  const exercises = useSelector((state) => state.classes.exercises);
  const [saveListExercise, setSaveListExercise] = useState(exercises.data_exercise)
  
  useEffect(() => {
    exercises.data_exercise = saveListExercise
    dispatch({type: teacherConstants.ADD_DATA_EXERCISE, data: exercises})
  },[saveListExercise])

  return (
    <div className="sunE-right-container">
      <Header title={skill.charAt(0).toUpperCase() + skill.slice(1)} isBack />
      <div className="sunE-container-box giaotrinh filter flex-column">
        {currentListSkill && (
          <ContentSkill
            data={currentListSkill}
            textEmpty="Bạn chưa có bài tập yêu thích nào."
            typeAssign={typeAssign}
            userID={userID}
            setSaveListExercise={setSaveListExercise}
          />
        )}
      </div>
    </div>
  );
}

export { ViewDetailCurriculumListExercise };
