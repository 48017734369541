import React from "react";
import "./styles.scss";

const InstructTestExam = (props) => {
  const { examInfo, handleStartExam, detailMockTest } = props;

  // console.log(examInfo);

  return (
    <div className="flex-1 instruction_container scrollbar-custom">
      <div
        className="instruction_wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="title_exam">
          <h2
            style={{
              fontSize: 26,
              color: "#00b9b7",
              paddingBottom: 10,
            }}
          >
            {detailMockTest?.exam_name}
          </h2>
          <div>
            <div style={{ fontSize: "1.2rem", color: "#00b9b7" }}>
              Trường: {detailMockTest?.school}
            </div>
            <div style={{ fontSize: "1.2rem", color: "#00b9b7" }}>
              Năm học: {detailMockTest?.school_year}
            </div>
          </div>
        </div>

        <div className="infor_exam_container">
          <div className="infor_exam_header">
            <div>{detailMockTest?.number_question} câu hỏi</div>
            <div>{detailMockTest?.duration} phút</div>
            <div>{detailMockTest?.number_attempt} lượt thi</div>
          </div>

          <div className="exam_instruction_container">
            <h2>HƯỚNG DẪN LÀM BÀI</h2>

            <div className="instruction_content">
              <ul className="instruction_list">
                <li className="instruction_item">
                  <div className="isntruction_item_img">
                    <img
                      className="img_icon_instruction"
                      src="/assets/images/luyenthi/ico-radioanh.png"
                      alt="Radioanh"
                    />
                  </div>
                  <p>Chọn câu trả lời đúng</p>
                </li>

                <li className="instruction_item">
                  <div className="isntruction_item_img">
                    <img
                      className="img_icon_instruction"
                      src="/assets/images/luyenthi/ico-textboxthi.png"
                      alt="Text box"
                    />
                  </div>
                  <p>Viết câu trả lời đúng</p>
                </li>

                <li className="instruction_item">
                  <div className="isntruction_item_img">
                    <img
                      className="img_icon_instruction"
                      src="/assets/images/luyenthi/ico-checkboxanh.png"
                      alt="Check box"
                    />
                  </div>
                  <p>
                    Đánh dấu câu hỏi khi muốn <b>Xem lại sau</b>
                  </p>
                </li>

                <li className="instruction_item">
                  <div className="isntruction_item_img">
                    <img
                      className="img_icon_instruction"
                      src="/assets/images/luyenthi/ico-nextthi.png"
                      alt="Next"
                    />
                  </div>
                  <p>
                    Chuyển qua <b>Câu kế tiếp</b>
                  </p>
                </li>

                <li className="instruction_item">
                  <div className="isntruction_item_img">
                    <img
                      className="img_icon_instruction"
                      src="/assets/images/luyenthi/ico-backthi.png"
                      alt="Back"
                    />
                  </div>
                  <p>
                    Chuyển qua <b>Câu trước</b>
                  </p>
                </li>
              </ul>

              <div className="instruction_content_right">
                Lưu ý:
                <ul>
                  <li>
                    <p>- Những câu chưa chọn đáp án được tính là câu sai.</p>
                  </li>

                  <li>
                    <p>
                      - Nếu bạn thoát ra trong lúc chưa hết thời gian làm bài
                      thì kết quả sẽ không được tính.
                    </p>
                  </li>

                  <li>
                    <p>
                      - Nếu bạn làm chưa xong bài mà hết thời gian thì bài sẽ tự
                      động thu.
                    </p>
                  </li>
                </ul>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "3rem",
                  }}
                >
                  <button className="btn btn-primary" onClick={handleStartExam}>
                    Bắt đầu
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructTestExam;
