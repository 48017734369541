import "./PolicyPage.style.scss";
import { policyPageLogic } from "./PolicyPage.logic";
import { renderListPage1 } from "../../_base/DetailListNews";
import LazyLoad from "react-lazyload";

const Content1 = () => {
  let { dataRule1 } = policyPageLogic();

  return (
    <div id="content1" className="content_1">
      {/* <div
        id="about"
        className=" bg-white content_about flex-jus-between pd-top-1 flex-align-end-mobile flex-column-mobile"
      >
        <div className="pd_4per flex-column mg-right-2 mg-right-0-mobile">
          <div className=" pd-top-15-pc pd-bottom-0-5 color-primary-green font_news_bold fz-14-mobile fz-22">
            Về Sunday English
          </div>
          <ul className="list_about text-align-justify">
            <li>
              <span className="line_height_18-6-6 fz-15 fz-1-mobile">
                Sunday English là ứng dụng học tiếng Anh hàng đầu dành cho học
                sinh THCS, được thiết kế theo chương trình SGK của Bộ GD&ĐT.
              </span>
            </li>
            <li>
              <span className="line_height_18-6-6 fz-15 fz-1-mobile">
                Sunday English là công cụ giảng dạy đắc lực có tích hợp công
                nghệ AI, giúp Thầy Cô chủ động, sáng tạo, đổi mới phương pháp
                giảng dạy, tiết kiệm thời gian soạn, chấm, chữa bài. Thuật toán
                thông minh cho phép Thầy Cô giao bài theo trình độ riêng biệt
                cho từng học sinh mà vẫn đảm bảo lượng kiến thức SGK. Hơn nữa,
                Sunday English còn cung cấp công cụ tạo TKB và lịch làm việc
                giúp Thầy Cô quản lý công việc giảng dạy hiệu quả.
              </span>
            </li>
          </ul>
        </div>

        <img
          src="/assets/images/introNews/banner_1.png"
          alt="Banner About"
          className="contain_image img_about"
        />
      </div> */}
      <div className="content_rules mg-top-25">
        <div
          id="rules"
          className="pd-top-15-pc fz-12-5-mobile pd_8per bg-white pd-bottom-2 color-primary-green fz-14-mobile fz-16 flex-column-mobile"
        >
          <span className="fz-14-mobile color-primary-green font_news_bold fz-20 col-80-mobile">
            ĐIỀU KHOẢN VÀ CHÍNH SÁCH DỊCH VỤ
          </span>{" "}
          {/* (Terms and conditions of service) */}
        </div>

        <div className="flex-column">
          <div className="pd_8per flex-align bg_white">
            <img
              src="/assets/images/introNews/icon/ico_rule_1.png"
              alt="Icon Rule 1"
              className="contain_image img_intro_item"
            />
            <span
              id="rule1"
              className="font_news_bold pd-top-1-pc pd-bottom-1 fz-12-5-mobile fz-18-75 mg-left-1"
            >
              {dataRule1?.nameRule}
            </span>
          </div>

          <LazyLoad once className="text-align-justify ">
            {dataRule1?.dataPage1?.map((data) => renderListPage1(data))}
            <div className="transparent_side"></div>
            {dataRule1?.dataPage1_1?.map((data) => renderListPage1(data))}
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default Content1;
