import classNames from "classnames";
import React, { useRef, useState } from "react";
// import { SelectDate } from "../../Calendar";
import "./index.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
registerLocale("vi", vi);

const InputDate = (props) => {
    const $inputRef = useRef(null);
    const [isFocus, setIsFocus] = useState(false);

    /**
     * changeValue: input change value
     * @param e event
     */
    const changeValue = (date) => {
        if (props.isWarning) {
            props.setIsWarning && props.setIsWarning("");
        }
        props.setValue(date);
    };

    return (
        <div className="input_date_base_container">
            {props.errorText ? (
                <div className={props.errorAbsolute ? "error_text_absolute error_input" : "error_text error_input"}>
                    <span>{props.errorText}</span>
                </div>
            ) : null}
            <div
                ref={$inputRef}
                className={classNames(
                    {
                        focus: isFocus,
                        warning: props.isWarning,
                    },
                    `${props.className ? props.className : ""} input_date_base`
                )}
                style={props.style}
            >
                <div className="icon_label">{props.renderLabelIcon ? props.renderLabelIcon() : null}</div>
                <DatePicker
                    className="date_picker"
                    selected={props.value}
                    onChange={(date) => changeValue(date)}
                    showMonthDropdown
                    showYearDropdown
                    fixedHeight
                    dropdownMode="select"
                    dateFormat="P"
                    locale="vi"
                    placeholderText="DD/MM/YYYY"
                    name={props.name}
                    popperPlacement="bottom"
                    popperModifiers={[
                        {
                            name: "flip",
                            options: {
                                fallbackPlacements: ["bottom"],
                            },
                        },
                    ]}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                />
            </div>
        </div>
    );
};

export default InputDate;
