import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { teacherActions, studentActions } from './../../_actions';
import { useParams } from "react-router-dom";
import { popupConstants } from './../../_constants';
import { userConstants } from '../../_constants';

import './style.css';

function PopUpAddStudent(props) {
    let { width } = props;
    const { id } = useParams();
    const dispatch = useDispatch();
    const student = useSelector(state => state.classes.addStudent);
    const authentication = useSelector(state => state.authentication);

    function handleSubmit() {
        if(authentication.role === userConstants.ROLE_TEACHER){
            dispatch(teacherActions.addStudent({ class_id: id, user_code: student.user_data.user_code }));
        }else{
            dispatch(studentActions.addParent({user_code: student.user_data.user_code }));
        }
    }
    function close() {
        dispatch({
            type: popupConstants.CLEAR_ALL_POPUP,
        });
    }
    return (
        <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" style={{ width: width }}>
                <p className="text-center popup-title bold">{student.avatar}</p>
                <div className="avt-pop">
                    <img src={student.base_url + student.user_data.avatar} alt={student.user_data.fullname} />
                </div>
                <h2 className="text-center popup-title bold title-student-pop cutom-mg">{student.user_data.fullname}</h2>
                <p className="text-center popup-title semibold pad0 break-all">{student.user_data.email}</p>
                <div className="flex-m btn-popup-gr ">
                    <button className="btn-default mr-10 width-100-percent" onClick={close}>Huỷ</button>
                    <button className="btn-bg-blue btn-default width-100-percent" onClick={handleSubmit}>Thêm</button>
                </div>
            </div>
        </div>

    );
}

export { PopUpAddStudent };