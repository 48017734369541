import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentActions } from "../../_actions";
import { studentService, teacherService } from "../../_services";
import {
  homeType,
  studentConstants,
  configConstants,
  userConstants,
} from "../../_constants";
import _, { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import $ from "jquery";
import DoTestExamModal from "../StudentPage/exam-test/DoTestExam";
import LessonBase from "../../_base/LessonBase";
import { PopUpYesNo } from "../../_components/Popup";
import { history } from "../../_helpers";
import { PlacementTestPage } from "./FirstStep/PlacementTestPage/PlacementTestPage";
import LazyLoad from "react-lazyload";

function HomePageStudent() {
  const dispatch = useDispatch();
  const learns = useSelector((state) => state.learns);
  const authentication = useSelector((state) => state.authentication);
  const { itemActiveHomeStudent } = useSelector(
    (state) => state.home_page_reducer
  );
  const isRootlessness = useSelector(
    (state) => state.rootlessness.isRootlessness
  );
  const scrollRef = useRef();
  const [showPopup, setShowPopup] = useState();

  const [numberMsg, setNumberMsg] = useState({
    number_msg_new: "0",
    number_system_new: "0",
  });

  const [active, setActive] = useState({
    type: "",
    id: "",
  });
  const [baseUrl, setBaseUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [examSelected, selectExam] = useState(null);
  const [exeData, setexeData] = useState(null);
  const proposedCurriculum = useSelector(
    (state) => state.rootlessness.proposedCurriculum
  );

  const chooseListCurriculum = useSelector(
    (state) => state.rootlessness.chooseListCurriculum
  );
  const startLearning = useSelector((state) => state.learns.startLearning);

  useEffect(() => {
    if (
      window.location.pathname === `/${userConstants.ROLE_STUDENT}` &&
      authentication.last_login == null &&
      chooseListCurriculum
    ) {
      history.push(`/${userConstants.ROLE_STUDENT}/curriculum`);
    }
  }, []);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [learns.exercises]);

  useEffect(() => {
    teacherService.getInboxInfo().then((data) => {
      setNumberMsg({
        number_msg_new: data.number_msg_new,
        number_system_new: data.number_system_new,
      });
    });
  }, []);

  useEffect(() => {
    getDataLearn();
  }, []);

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTop = itemActiveHomeStudent?.scrollTop || 0;
    }
  }, [scrollRef, learns.data.list_teacher, learns.data.list_curriculum]);

  const getDataLearn = async () => {
    try {
      setLoading(true);
      const learns = await studentService.getListDataLearnV2();
      setBaseUrl(learns.base_url);
      dispatch({
        type: studentConstants.GET_LIST_DATA_LEARN,
        learns,
      });

      if (itemActiveHomeStudent && itemActiveHomeStudent.type) {
        getExercises(itemActiveHomeStudent.type, itemActiveHomeStudent.id);
      } else {
        if (!isEmpty(learns.data.list_teacher)) {
          getExercises("teacher", learns.data.list_teacher[0].id);
        } else if (!isEmpty(learns.data.list_curriculum)) {
          getExercises(
            "curriculum",
            learns.data.list_curriculum[0].curriculum_id
          );
        }
      }
    } catch (e) {}
  };

  function getExercises(type, id) {
    setActive({ type, id });
    dispatch({
      type: homeType.SELECT_TEACHER_OR_CURRICULUM,
      payload: { type, id, scrollTop: scrollRef.current?.scrollTop || 0 },
    });
    if (type === "teacher") {
      dispatch(studentActions.getExerciseByTeacherIdV2(id));
    } else {
      authentication.last_login == null && isRootlessness
        ? ""
        : dispatch(studentActions.getExerciseByCurriculumId(id));
    }
  }

  let listDataExcercise = [];
  if (active.type === "teacher") {
    listDataExcercise = [
      ...(learns.exercises.list_home_work_new || []),
      ...(learns.exercises.list_home_work_complete || []),
    ];
  } else {
    listDataExcercise = learns.exercises.list_home_work || [];
  }

  const renderStatusExcercise = (status) => {
    if (_.isEqual(status, 1)) {
      return (
        <div className="__status_excercise_home_std">
          <img src={`/assets/images/student/home/ico_check.png`} />
        </div>
      );
    }
    // TODO with status lock
    // else if (_.isEqual(status, 0)) {
    //     return (
    //         <div className="__status_excercise_home_std">
    //             <img src={`/assets/images/student/home/ico_lock.png`}/>
    //         </div>
    //     )
    // }
  };
  let curriculumSelected = learns.data.list_curriculum.find(
    (item) => item.curriculum_id === active.id
  );
  var countNotiMess =
    parseInt(numberMsg.number_msg_new) + parseInt(numberMsg.number_system_new);

  const goToExercise = (data) => {
    // console.log(data, "===> 1");
    setShowPopup(null);
    let typeUri = "";
    if (["exam", "mini_test"].includes(data?.exercise_type)) {
      selectExam(data);
      return;
    } else if (
      [
        "grammar",
        "listening",
        "pronunciation",
        "reading",
        "speaking",
        "writing",
        "vocabulary",
      ].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "lesson";
    } else if (["project"].includes(data?.exercise_type || data?.lesson_type)) {
      typeUri = "project";
    } else if (
      ["mini_test", "exam"].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "exam";
    } else if (
      ["homework"].includes(data?.exercise_type || data?.lesson_type)
    ) {
      typeUri = "homework";
    } else {
      typeUri = "lesson";
    }
    if (
      (data?.exercise_type === "project" ||
        (data?.exercise_type === "writing" && data?.question_type === "7") ||
        (data?.exercise_type === "speaking" && data?.question_type === "3")) &&
      parseInt(data?.status) !== -1
    ) {
      setexeData(data);
      setShowPopup(data);
    } else {
      LessonBase._moveLesson(
        active.type === "teacher"
          ? LessonBase.Type.homework
          : LessonBase.Type.curriculum,
        authentication,
        data,
        null,
        1
      );
    }
    // const url = `${configConstants.EXCERCISE_URL}index.php/example/lesson_demo?type=${typeUri}&id=${data?.lesson_id || data?.exercise_id}&token=${authentication?.jwt_token}`
    // console.log(url, '===> 1')
    // window.open(url, "_blank")
  };

  const handleGoToHomework = () => {
    setShowPopup(null);
    LessonBase._moveLesson(
      active.type === "teacher"
        ? LessonBase.Type.homework
        : LessonBase.Type.curriculum,
      authentication,
      exeData,
      null,
      1
    );
  };

  const goToHistoryExer = () => {
    setShowPopup(null);
    history.push(
      `/${authentication.role}/curriculum/${showPopup.class_id}/skill/${showPopup.id}/lesson/${showPopup.exercise_type}/${showPopup.exercise_id}/exercise/${showPopup.id}/${showPopup.exercise_type}?from_screen=home_page&page=homepage&topic=${showPopup.exercise_topic}`
    );
    // history.push(
    //   `/${authentication.role}/curriculum/${showPopup.class_id}/skill/${showPopup.id}/lesson/${showPopup.exercise_type}/${showPopup.exercise_id}/history?from_screen=home_page&page=homepage`
    // );
  };

  return (
    <>
      {authentication.last_login == null && isRootlessness ? (
        <PlacementTestPage />
      ) : (
        <div
          className="sunE-right-container"
          style={{
            paddingBottom: 0,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          {authentication.role === "student" && (
            <div className="text-right d-flex align-items-center justify-content-end">
              <Link
                to={
                  "/" +
                  authentication.role +
                  "/more/message?active=personal&page=homepage"
                }
              >
                <div className="noti-message-custom d-flex mr-20">
                  <img
                    className="icon-noti-mess"
                    src="/assets/images/icon/ico_notification.png"
                    alt="teacher_detail"
                  />
                  {countNotiMess > 0 && (
                    <label className="count-noti-custom">
                      {countNotiMess > 100 ? "99+" : countNotiMess}
                    </label>
                  )}
                </div>
              </Link>
              <div className="position-relative __diamond_total d-flex align-items-center">
                <div className="d-flex __ico_diamond">
                  <img
                    className="icon-noti-mess"
                    src="/assets/images/icon/ico_diamond.png"
                    alt="teacher_detail"
                  />
                </div>
                <div className="__diamond_number">
                  {authentication?.total_diamond || 0}
                </div>
              </div>
            </div>
          )}
          <div
            className="student-container student-index"
            style={{ paddingBottom: 0, flex: 1 }}
          >
            <div className="flex-m">
              <div
                className="list-teacher-sunE scrollbar-custom"
                ref={scrollRef}
              >
                {learns.data.list_teacher.map((data, i) => {
                  let AVATAR = _.isEmpty(data.avatar)
                    ? "/assets/images/student/teacher_detail.png"
                    : baseUrl + data.avatar;
                  return (
                    <div
                      title={data.fullname}
                      className={
                        "box-item-wh box-shadow " +
                        (active.type === "teacher" && active.id === data.id
                          ? " active"
                          : "")
                      }
                      key={"_teacher_" + i}
                      onClick={() => getExercises("teacher", data.id)}
                    >
                      <div className="img-thumb">
                        <img src={AVATAR} alt="teacher_detail" />
                      </div>
                      <div className="name-box">{data.fullname}</div>
                    </div>
                  );
                })}
                {learns.data.list_curriculum.map((data, i) => {
                  let AVATAR = _.isEmpty(data.avatar)
                    ? "/assets/images/student/sunE_detail.png"
                    : baseUrl + data.class_avatar;
                  return (
                    <div
                      title={data.curriculum_name}
                      className={
                        "box-item-wh box-shadow" +
                        (active.type === "curriculum" &&
                        active.id === data.curriculum_id
                          ? " active"
                          : "")
                      }
                      key={"_curriculum_" + i}
                      onClick={() =>
                        getExercises("curriculum", data.curriculum_id)
                      }
                    >
                      <div className="img-thumb">
                        <img src={AVATAR} alt="sunE_detail" />
                      </div>
                      <div className="name-box">{data.class_name}</div>
                    </div>
                  );
                })}
              </div>
              <div className="flex-1 ml-20 shedu-item-container">
                {!isLoading &&
                  (isEmpty(listDataExcercise) ? (
                    <div className="box-shadow shedu-mo-item center-flex">
                      <div>
                        <img
                          src="/assets/images/student/bg_no_homework.png"
                          alt="bg_no_homework"
                        />
                        {active.type === "teacher" ? (
                          <p>Hiện tại bạn chưa được giao bài nào</p>
                        ) : (
                          <p>Giáo trình lớp đang được cập nhật</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      <div className="shedu-first-item box-shadow">
                        <div
                          className="img-shedu center-flex"
                          style={{ position: "relative" }}
                        >
                          <img
                            src={listDataExcercise[0].status == 1 
                              ?
                              `/assets/images/student/homeworkdone/${
                                active.type === "teacher"
                                  ? listDataExcercise[0].exercise_type
                                  : listDataExcercise[0].lesson_type
                              }.png`
                              :
                              `/assets/images/student/home/${
                              active.type === "teacher"
                                ? listDataExcercise[0].exercise_type
                                : listDataExcercise[0].lesson_type
                            }.png`}
                            alt="ico_listening_large"
                            onClick={() => goToExercise(listDataExcercise[0])}
                          />
                          {/* {renderStatusExcercise(listDataExcercise[0].status)} */}
                        </div>
                        <h2>
                          {active.type === "teacher"
                            ? listDataExcercise[0].exercise_name
                            : listDataExcercise[0].lesson_name}
                        </h2>
                        {/* <h3>{listDataExcercise[0].topic}</h3> */}
                        <p>
                          {active.type === "teacher"
                            ? listDataExcercise[0].topic
                            : listDataExcercise[0].lesson_topic}
                        </p>
                      </div>
                      <div className="shedu-list-more-gr box-shadow">
                        <LazyLoad className="flex-m">
                          {listDataExcercise.map((data, i) => {
                            if (i > 0 && i < 4) {
                              let avatar =
                                active.type === "teacher"
                                  ? data.exercise_type
                                  : data.lesson_type;
                              return (
                                <div className="flex-1" key={"curriculum_" + i}>
                                  <div className="shedu-more-item">
                                    <div
                                      className="img center-flex rel"
                                      style={{ position: "relative" }}
                                    >
                                      <img
                                        src={data.status == 1 ? `/assets/images/student/homeworkdone/${avatar}.png` :`/assets/images/student/home/${avatar}.png`}
                                        alt={avatar}
                                        onClick={() => goToExercise(data)}
                                      />
                                      {/* {renderStatusExcercise(data.status)} */}
                                    </div>
                                    <p>
                                      {active.type === "teacher"
                                        ? data.exercise_name
                                        : data.lesson_name}
                                    </p>
                                  </div>
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                          {!isEmpty(listDataExcercise) && (
                            <Link
                              to={
                                "/" +
                                authentication.role +
                                (active?.type === "teacher"
                                  ? "/homework_by_teacher/" +
                                    (curriculumSelected
                                      ? curriculumSelected?.id
                                      : active?.id)
                                  : learns &&
                                    learns?.exercises?.require_learn_master_unit
                                  ? "/curriculum/" +
                                    (curriculumSelected
                                      ? curriculumSelected?.id
                                      : active?.id) +
                                    "?from=home_screen"
                                  : `/curriculum/${curriculumSelected?.id}/skill/${learns?.exercises?.list_home_work[0]?.unit_id}?page=homepage`)
                              }
                              onClick={() => dispatch({type: studentConstants.CLEAR_STATUS_HOMEWORK})}
                            >
                              <div className="flex-1">
                                <div className="shedu-more-item">
                                  <div className="img center-flex rel">
                                    <img
                                      src="/assets/images/student/ico_add_blue.png"
                                      alt="ico_add_blue"
                                    />
                                  </div>
                                  <p>Xem chi tiết</p>
                                </div>
                              </div>
                            </Link>
                          )}
                        </LazyLoad>
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
          {examSelected && (
            <DoTestExamModal
              isShow={examSelected !== null}
              classId={examSelected.class_id}
              unitId={examSelected.unit}
              onClose={() => {selectExam(null)
                getDataLearn();
              }}
              isHomeWork={true}
              mockTestId={examSelected?.id}
              type={examSelected.lesson_type || examSelected.exercise_type}
            />
          )}
          {showPopup && (
            <PopUpYesNo
              onClickYes={() =>
                parseInt(showPopup?.status) === 0
                  ? handleGoToHomework()
                  : goToHistoryExer()
              }
              onClickNo={() => setShowPopup(null)}
              labelNo={"Không"}
              message={
                parseInt(showPopup?.status) === 0
                  ? "Bạn đã nộp bài trước đó, bạn có chắc chắn muốn nộp lại?"
                  : parseInt(showPopup?.status) === 1 &&
                    "Bạn đã nộp bài và giáo viên đã chấm. Bạn có muốn xem bài chữa không?"
              }
            />
          )}
        </div>
      )}
    </>
  );
}

export { HomePageStudent };
