import React, { useState, useMemo, useCallback } from "react";
import _, { update } from "lodash";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { scheduleActions } from "./../../../_actions";
import { userConstants } from "./../../../_constants";
import { Header } from "./../../../_components/Admin/Header";
import { Alert } from "./../../../_components/Alert";
import { teacherConstants, scheduleConstants } from "./../../../_constants";
import { useHistory } from "react-router-dom";
import { SelectAsDiv } from "./../../../_components/Select";
import { SelectDate } from "./../../../_components/Calendar";
import $ from "jquery";
import { useParams } from "react-router-dom";
import { parse } from "query-string";
import { alertActions } from "./../../../_actions";
import { alertConstants } from "./../../../_constants";
import { FastfoodOutlined } from "@material-ui/icons";

function AddSchedule() {
  const { page } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const schedules = useSelector((state) => state.schedules);
  const [isShowSwitchEdit, showSwitchEdit] = useState(false);
  const [typeUpdateWithLoop, setTypeUpdateWithLoop] = useState(0);

  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const pageParams = useMemo(() => {
    return history?.location?.search ? parse(history.location.search) : null;
  }, [history.location.search]);

  const setEndTimeSchedules = useMemo(() => {
    if (schedules.detail_today.action === "add") {
      return {
        ...schedules.detail_today,
        start_time: pageParams?.date
          ? new Date(
              new Date(pageParams.date).getFullYear(),
              new Date(pageParams.date).getMonth(),
              new Date(pageParams.date).getDate(),
              new Date().getHours(),
              new Date().getMinutes()
            )
          : schedules.detail_today.start_time,
        end_time: new Date(
          moment(
            pageParams?.date
              ? new Date(
                  new Date(pageParams.date).getFullYear(),
                  new Date(pageParams.date).getMonth(),
                  new Date(pageParams.date).getDate(),
                  new Date().getHours(),
                  new Date().getMinutes()
                )
              : schedules.detail_today.start_time
          ).add(7, "days")
        ),
      };
    } else {
      return {
        ...schedules.detail_today,
        start_time: new Date(moment(schedules?.detail_today?.start_time)),
        end_time: new Date(moment(schedules?.detail_today?.end_time)),
        valid_to: new Date(moment(schedules?.detail_today?.valid_to)),
      };
    }
  }, [schedules.detail_today]);

  const authentication = useSelector((state) => state.authentication);
  const [inputs, setInputs] = useState(setEndTimeSchedules);
  const [submitted, setSubmitted] = useState(false);
  let {
    title,
    start_time,
    end_time,
    type,
    content,
    remind_time,
    repeat_type,
    action,
    valid_to,
  } = inputs;

  // console.log(inputs);

  const [checkRemind, setcheckRemind] = useState(
    parseInt(inputs.remind || 1) === 1 ? true : false
  );

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "remind_time") {
      let val = e.target.value === "" ? "" : e.target.value.replace(/\D/, "");
      if (val > 60 && val < 70) {
        val = 60;
        setInputs((inputs) => ({ ...inputs, [name]: val }));
      } else if (val <= 60) {
        setInputs((inputs) => ({ ...inputs, [name]: val }));
      }
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }

    if (name == "title") {
      if (e.target.value.length <= 50) {
        setIsDisabledBtn(false);
        dispatch(
          alertActions.error({
            message: "",
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE,
          })
        );
      } else {
        setIsDisabledBtn(true);
        dispatch(
          alertActions.error({
            message: alertConstants.MSG_ERROR,
            screen: scheduleConstants.SCREEN_ADD_SCHEDULE,
          })
        );
      }
    }
  }

  function handleBlurInputText(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value.trim() }));
  }

  function handleBlur() {
    if (inputs["remind_time"] === "") {
      setInputs((inputs) => ({ ...inputs, ["remind_time"]: 0 }));
    }
    if (Number(inputs["remind_time"]) > 60) {
      setInputs((inputs) => ({ ...inputs, ["remind_time"]: 60 }));
    }
  }

  function changeRepeatType(repeat_type) {
    if (inputs) {
      const startTime = pageParams?.date
        ? new Date(
            new Date(pageParams.date).getFullYear(),
            new Date(pageParams.date).getMonth(),
            new Date(pageParams.date).getDate(),
            new Date().getHours(),
            new Date().getMinutes()
          )
        : schedules.detail_today.start_time;
      switch (repeat_type) {
        case "no_repeat":
          setInputs({
            ...inputs,
            repeat_type,
          });
          break;
        case "day":
          setInputs({
            ...inputs,
            end_time: new Date(moment(startTime).add(7, "days")),
            repeat_type,
          });
          break;
        case "week":
          setInputs({
            ...inputs,
            end_time: new Date(moment(startTime).add(1, "month")),
            repeat_type,
          });
          break;
        case "month":
          setInputs({
            ...inputs,
            end_time: new Date(moment(startTime).add(1, "year")),
            repeat_type,
          });
          break;
        default:
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (validateParam()) {
      if (!checkRemind) {
        inputs.remind_time = null;
        inputs.remind = 0;
        inputs.is_remind = 0;
        setInputs({
          ...inputs,
          remind_time: "",
        });
      } else {
        inputs.remind = 1;
        inputs.is_remind = 1;
        if (!inputs.remind_time) {
          return false;
        }
      }

      if (action === "add") {
        dispatch(
          scheduleActions.addSchedule({
            title: inputs.title.trim(),
            content: inputs.content.trim(),
            remind: checkRemind ? 1 : 0,
            remind_time: inputs.remind_time || remind_time,
            start_time: moment(inputs.start_time).format("HH:mm"),
            end_time: moment(inputs.end_time).format("HH:mm"),
            valid_from: moment(inputs.start_time).format("yyyy-MM-DD"),
            valid_to: moment(inputs.end_time).format("yyyy-MM-DD"),
            type: inputs.type,
            repeat_type: inputs.repeat_type,
          })
        );

        localStorage.setItem(
          "date_selected",
          JSON.stringify(moment(inputs.start_time).format("yyyy-MM-DD"))
        );
      } else {
        if (setEndTimeSchedules.repeat_type === "") {
          handleUpdateSchedule(0);
        } else {
          if (inputs.repeat_type == "no_repeat") {
            showSwitchEdit(false);
            let dateNow = new Date();
            let status =
              moment(start_time).toDate() > dateNow
                ? "no_status"
                : inputs.status;
            dispatch(
              scheduleActions.editSchedule(
                _.pickBy({
                  id: inputs.id,
                  title: inputs.title.trim(),
                  content: inputs.content.trim(),
                  remind_time: inputs.remind_time || remind_time,
                  remind: checkRemind ? "1" : "0",
                  start_time: moment(inputs.start_time).format(
                    "yyyy-MM-DD HH:mm:ss"
                  ),
                  end_time: moment(inputs.end_time).format("HH:mm"),
                  valid_from: moment(inputs.start_time).format("yyyy-MM-DD"),
                  valid_to: moment(inputs.end_time).format("yyyy-MM-DD"),
                  update_all: 0,
                  status,
                  type: inputs.type,
                  repeat_type: inputs.repeat_type,
                })
              )
            );
            localStorage.setItem(
              "date_selected",
              JSON.stringify(moment(inputs.start_time).format("yyyy-MM-DD"))
            );
          } else {
            showSwitchEdit(true);
          }
        }
      }
    }
  }

  const handleUpdateSchedule = (updateAll) => {
    showSwitchEdit(false);
    let dateNow = new Date();
    let status =
      moment(start_time).toDate() > dateNow ? "no_status" : inputs.status;
    dispatch(
      scheduleActions.editSchedule(
        _.pickBy({
          id: inputs.id,
          title: inputs.title.trim(),
          content: inputs.content.trim(),
          remind_time: inputs.remind_time || remind_time,
          remind: checkRemind ? "1" : "0",
          start_time: moment(inputs.start_time).format("yyyy-MM-DD HH:mm:ss"),
          end_time: moment(inputs.end_time).format("HH:mm"),
          valid_from: moment(inputs.start_time).format("yyyy-MM-DD"),
          valid_to: moment(inputs.end_time).format("yyyy-MM-DD"),
          update_all: `${updateAll}`,
          status,
          type: inputs.type,
          repeat_type: inputs.repeat_type,
        })
      )
    );
  };

  function validateParam() {
    return start_time && end_time && type && title.trim()
      ? // && tart_time <= end_time
        true
      : false;
  }

  function onCompleteAddSchedule(page) {
    if (authentication.role === userConstants.ROLE_TEACHER) {
      page === "homepage"
        ? history.push(
            "/" +
              authentication.role +
              `?date=${moment(start_time).format("YYYY-MM-DD")}`
          )
        : history.push("/" + authentication.role + "/more/schedule/day");
    } else {
      history.push("/" + authentication.role + "/more/schedule");
    }
  }

  const onChangeDateTimeSelect = (start_time) => {
    let dateNow = new Date();
    if (repeat_type === "no_repeat") {
      setInputs({ ...inputs, start_time, end_time: start_time });
    } else {
      setInputs({ ...inputs, start_time });
    }
    // if (start_time.getTime() > dateNow.getTime() - 24 * 60 * 60 * 1000) {
    //   if (repeat_type === "no_repeat") {
    //     setInputs({ ...inputs, start_time, end_time: start_time });
    //   } else {
    //     setInputs({ ...inputs, start_time });
    //   }
    // } else {
    //   console.log("Date is not selected!");
    // }
  };

  const onChangeDateEndTime = (end_time) => {
    let dateNow = new Date();
    end_time = new Date(
      end_time.getFullYear(),
      end_time.getMonth(),
      end_time.getDate(),
      23,
      59,
      59
    );
    if (end_time.getTime() > dateNow.getTime() - 24 * 60 * 60 * 1000) {
      // console.log("end_time", end_time);
      setInputs({ ...inputs, end_time: end_time, valid_to: end_time });
    } else {
      console.log("Date is not selected!");
    }
  };

  const gotoBackPage = useCallback(() => {
    if (pageParams?.redirectUrl) {
      history.push(
        `${pageParams?.redirectUrl}?date=${
          pageParams?.date ? moment(pageParams?.date).format("YYYY-MM-DD") : ""
        }`
      );
    } else {
      history.go(-1);
    }
  }, [pageParams, history, authentication, inputs]);

  // console.log(alert);

  return (
    <div className="sunE-right-container flex-column scrollbar-custom">
      <Header
        title={action === "add" ? "Tạo kế hoạch" : "Sửa kế hoạch"}
        isBack
        clickBack={gotoBackPage}
      />
      {alert.message &&
        alert.screen === teacherConstants.SCREEN_ADD_SCHEDULE && (
          <Alert alert={alert} onComplete={() => onCompleteAddSchedule(page)} />
        )}
      <div className="flex-m sunE-content taokehoach flex-1">
        <div className="sunE-box-tkh">
          <div
            className={
              "input-gr" +
              (submitted && !title ? " err" : "") +
              (alert.type == alertConstants.ERROR &&
              alert.message &&
              !alert.message.includes("thời gian")
                ? " err"
                : "")
            }
          >
            <label>Tiêu đề</label>
            <input
              type="text"
              placeholder="Nhập tiêu đề "
              name="title"
              onChange={handleChange}
              value={title}
              onBlur={handleBlurInputText}
            />
          </div>
          <div className="flex-m sunE-time-lcv">
            <div
              className={
                "input-gr time" +
                (submitted && (!start_time || start_time > end_time)
                  ? " err"
                  : "")
              }
            >
              <label>Thời gian</label>
              <SelectDate
                // disableMouseFocus={true}
                showTimeSelect={true}
                timeIntervals={15}
                dateFormat="HH:mm dd-MM-yyyy"
                name="start_time"
                selected={start_time}
                onChange={(start_time) => onChangeDateTimeSelect(start_time)}
              />
            </div>
            {authentication.role === userConstants.ROLE_TEACHER && (
              <div
                className={
                  "input-gr lcv select-custom-bg text-center " +
                  (submitted && !type ? " err" : "")
                }
              >
                <label>Loại công việc</label>
                <SelectAsDiv
                  keySelected={type}
                  textDefault={"Loại công việc"}
                  data={scheduleConstants.TEXT_TYPE_SCHEDULE}
                  onChangeSelect={(type) => setInputs({ ...inputs, type })}
                  list_style
                />
              </div>
            )}
          </div>
          <div className="input-gr message">
            <label>Nội dung</label>
            <textarea
              placeholder="Nhập nội dung"
              value={content}
              name="content"
              onChange={handleChange}
              onBlur={handleBlurInputText}
            />
          </div>
          <div
            className={
              "datlichnhac ct-hs" +
              (submitted && !inputs.remind_time && checkRemind ? " err" : "")
            }
          >
            <label className="ht">
              Đặt lịch nhắc
              {/* {checkRemind && <span className="__text_remind_max">(Thời gian nhắc trước tối đa 60 phút)</span>} */}
            </label>
            <div className="datlichnhac-input-gr" style={{ marginLeft: 15 }}>
              <span className="wh20 chk-custom-gr">
                <input
                  type="checkbox"
                  name="datlichnhac"
                  className="dln-check"
                  onChange={() => {
                    setcheckRemind(!checkRemind);
                  }}
                  checked={checkRemind}
                  id="datlichnhac"
                />
                <label htmlFor="datlichnhac"></label>
              </span>
              <span className="left">Nhắc trước</span>
              <input
                type="text"
                className="dln-mins"
                name="remind_time"
                value={inputs.remind_time}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="right">phút</span>
            </div>
            <div className="__max_add_schedule">
              Thời gian nhắc trước tối đa 60 phút
            </div>
          </div>

          <div className="laplai">
            <label className="title-b">Lặp lại</label>
            <p
              className={
                submitted && !inputs.repeat_type
                  ? "radio-item err"
                  : "radio-item"
              }
              onChange={() => changeRepeatType("no_repeat")}
            >
              <input
                type="radio"
                name="radio-group"
                id="nn0"
                checked={inputs.repeat_type === "no_repeat"}
                onChange={() => {}}
              />
              <label htmlFor="nn0">Không lặp lại</label>
            </p>
            <p
              className={
                submitted && !inputs.repeat_type
                  ? "radio-item err"
                  : "radio-item"
              }
              onChange={() => changeRepeatType("day")}
            >
              <input
                type="radio"
                name="radio-group"
                id="nn1"
                checked={inputs.repeat_type === "day"}
                onChange={() => {}}
              />
              <label htmlFor="nn1">Lặp lại hàng ngày</label>
            </p>
            <p
              className={
                submitted && !inputs.repeat_type
                  ? "radio-item err"
                  : "radio-item"
              }
              onChange={() => changeRepeatType("week")}
            >
              <input
                type="radio"
                name="radio-group"
                id="nn2"
                checked={inputs.repeat_type === "week"}
                onChange={() => {}}
              />
              <label htmlFor="nn2">Lặp lại hàng tuần</label>
            </p>
            <p
              className={
                submitted && !inputs.repeat_type
                  ? "radio-item err"
                  : "radio-item"
              }
              onChange={() => changeRepeatType("month")}
            >
              <input
                type="radio"
                name="radio-group"
                id="nn3"
                checked={inputs.repeat_type === "month"}
                onChange={() => {}}
              />
              <label htmlFor="nn3">Lặp lại hàng tháng</label>
            </p>
            {inputs.repeat_type !== "no_repeat" && (
              <div
                className={
                  "flex-m apdung tp-k input-gr" +
                  (submitted && (!end_time || start_time > end_time)
                    ? " err"
                    : "")
                }
              >
                <label>Áp dụng đến:</label>
                <div className="flex-1 select-time rel time-to">
                  <SelectDate
                    // disableMouseFocus={true}
                    id="changeTime"
                    showTimeSelect={false}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat={"dd/MM/yyyy"}
                    name="end_time"
                    selected={inputs.valid_to}
                    onChange={(dateTime) => onChangeDateEndTime(dateTime)}
                  />
                  <img
                    src="/assets/images/teacher/lichlamviec/ico_edit.png"
                    alt="ico_edit"
                    className="ico_edit_time_to"
                    onClick={() => $("#changeTime").trigger("click")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="form-sunE-button text-right btn-mr-custom">
        <button
          disabled={isDisabledBtn}
          className={
            "btn-line-blue" +
            (!validateParam() ? " btn-disable" : "") +
            (isDisabledBtn ? " btn-disable" : "")
          }
          onClick={handleSubmit}
        >
          Xong
        </button>
      </div>

      {isShowSwitchEdit && (
        <div
          id="modal-center"
          className="uk-flex-top uk-modal uk-flex uk-open"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <div className="radio-body">
              <div>
                <p className="radio-gr">
                  <input
                    type="radio"
                    name="radioName"
                    id="One"
                    checked={typeUpdateWithLoop === 0}
                    onChange={() => setTypeUpdateWithLoop(0)}
                  />
                  <label htmlFor="One">Chỉ thay đổi cho sự kiện này</label>
                </p>
                <p className="radio-gr">
                  <input
                    type="radio"
                    name="radioName"
                    id="Two"
                    checked={typeUpdateWithLoop === 1}
                    onChange={() => setTypeUpdateWithLoop(1)}
                  />
                  <label htmlFor="Two">
                    Thay đổi toàn bộ sự kiện trong chuỗi
                  </label>
                </p>
              </div>
            </div>
            <div className="flex-m btn-popup-gr">
              <button
                className="btn-default mr-10 width-100-percent"
                onClick={() => showSwitchEdit(false)}
              >
                Đóng
              </button>
              <button
                className="btn-bg-blue btn-default width-100-percent"
                onClick={() => handleUpdateSchedule(typeUpdateWithLoop)}
              >
                Xong
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { AddSchedule };
