import InputText from "../../../_components/Auth/InputText";
import { registerNewsLogic } from "./RegisterNews.logic";
import InputSelect from "../../../_components/Auth/InputSelect";
import GradeSelection from "../../../_components/GradeSelection/GradeSelection";
import InputDate from "../../../_components/Auth/InputDate";
import ButtonNews from "../../../_components/Button/ButtonNews";

const TeacherRegister = (props) => {
  let {
    handleGoBack,
    handleRegister,
    disabledBtnRegist,
    provinceList,
    provinceSelected,
    changeProvince,
    errProvince,
    setErrProvince,
    districtList,
    districtSelected,
    errDistrict,
    setErrDistrict,
    changeDistrict,
    schoolList,
    schoolSelected,
    changeSchool,
    dataTypeSchool,
    typeSchoolSelected,
    handleClickTypeSchool,
    birthday,
    birthdayWarning,
    setBirthdayWarning,
    changeBirthday,
    genderSelected,
    genderList,
    changeGender,
    changePhone,
    phone,
    phoneWarning,
    setPhoneWarning,
    onBlurField,
    email,
    password,
    emailError,
    setEmailError,
    passwordError,
    setPasswordError,
    changeEmail,
    changePassword,
    setPassword,
    cfPassword,
    cfPasswordError,
    setCfPasswordError,
    changeCfPassword,
    setCfPassword,
    name,
    setName,
    changeName,
    handleCheckRegister,
  } = registerNewsLogic(props);

  const renderAuthIcon = (type) => {
    return (
      <div
        className={`flex-center ${
          type == "district" && " hide display_flex_mobile"
        }`}
      >
        <img
          src={`/assets/images/authNews/ico_${type}.png`}
          alt="Icon Input"
          className="contain_image img_label_input"
        />
      </div>
    );
  };

  return (
    <div className="col-100 register_news mg-top-1">
      {props.typeLogin != "google" && (
        <>
          <InputText
            className="mg-bottom-2"
            value={email}
            setValue={changeEmail}
            type="text"
            name="email_student"
            placeholder="Địa chỉ Email xác thực tài khoản"
            required={true}
            renderLabelIcon={() => renderAuthIcon("email")}
            errorText={emailError}
            onBlur={() => {
              onBlurField("email");
            }}
            setErrorText={setEmailError}
            // autoComplete={savePassword ? "on" : "new-password"}
          ></InputText>
          <InputText
            className="mg-bottom-2"
            value={password}
            setValue={changePassword}
            type="password"
            name="password"
            placeholder="Nhập mật khẩu"
            required={true}
            renderLabelIcon={() => renderAuthIcon("password")}
            errorText={passwordError}
            setErrorText={setPasswordError}
            onBlur={() => {
              onBlurField("password");
            }}
            onFocus={() => setPassword(password?.trim())}
            // autoComplete={savePassword ? "on" : "new-password"}
            errorAbsolute={true}
          ></InputText>
          <InputText
            className="mg-bottom-2"
            value={cfPassword}
            setValue={changeCfPassword}
            type="password"
            name="confirm_password"
            placeholder="Nhập lại mật khẩu"
            required={true}
            renderLabelIcon={() => renderAuthIcon("cf_password")}
            errorText={cfPasswordError}
            setErrorText={setCfPasswordError}
            onBlur={() => {
              onBlurField("password");
            }}
            onFocus={() => setCfPassword(cfPassword?.trim())}
            errorAbsolute={true}
          ></InputText>
        </>
      )}

      <InputText
        className="mg-bottom-2"
        value={name}
        setValue={changeName}
        type="text"
        name="name_student"
        placeholder="Họ và tên"
        required={true}
        renderLabelIcon={() => renderAuthIcon("name")}
        onFocus={() => setName(name?.trim())}
      ></InputText>

      <div className="flex-align col-100 flex-jus-between birthday_gender_container">
        <InputDate
          className="field_birthday col-100 mg-bottom-15"
          value={birthday}
          setValue={changeBirthday}
          name="birthday"
          renderLabelIcon={() => renderAuthIcon("birthday")}
          errorText={birthdayWarning}
          setErrorText={setBirthdayWarning}
          errorAbsolute={true}
        ></InputDate>

        <InputSelect
          colorInput="color-text"
          fillColor="#4D4D4D"
          className="field_item field_gender col-fix-35"
          value={genderSelected}
          setValue={changeGender}
          options={genderList.map((item) => {
            return {
              value: item.value,
              title: item.title,
            };
          })}
          placeholder="Giới tính"
          renderLabelIcon={() => renderAuthIcon("gender")}
          errorAbsolute={true}
          mgBottom={"1.5rem"}
        ></InputSelect>
      </div>
      <InputText
        className="mg-bottom-2"
        value={phone}
        setValue={changePhone}
        type="text"
        name="phone_teacher"
        placeholder="Số điện thoại"
        required={true}
        renderLabelIcon={() => renderAuthIcon("phone")}
        errorText={phoneWarning}
        setErrorText={setPhoneWarning}
        onBlur={() => {
          onBlurField("phone");
        }}
        errorAbsolute={true}
      ></InputText>

      <div className="flex-align col-100 flex-jus-between inputs_address_mobile">
        <InputSelect
          colorInput="color-text"
          fillColor="#4D4D4D"
          className="field_item field_province col-60"
          value={provinceSelected}
          setValue={changeProvince}
          options={provinceList.map((item) => {
            return {
              value: item.province_alias,
              title: item.province,
            };
          })}
          placeholder="Chọn Tỉnh/ Thành phố"
          renderLabelIcon={() => renderAuthIcon("city")}
          errorText={errProvince}
          setErrorText={setErrProvince}
          errorAbsolute={true}
          mgBottom={"1.5rem"}
        ></InputSelect>

        <InputSelect
          colorInput="color-text"
          fillColor="#4D4D4D"
          className="field_item field_district col-fix-35"
          value={districtSelected}
          setValue={changeDistrict}
          options={districtList.map((item) => {
            return {
              value: item.district_alias,
              title: item.district,
            };
          })}
          placeholder="Quận/ Huyện"
          renderLabelIcon={() => renderAuthIcon("district")}
          disabledClick={!provinceSelected.value}
          onClickDisable={() => {
            setErrProvince("Vui lòng chọn tỉnh/ thành phố");
          }}
          errorText={errDistrict}
          setErrorText={setErrDistrict}
          errorAbsolute={true}
          mgBottom={"1.5rem"}
        ></InputSelect>
      </div>
      <div className="flex-align grade_container col-80">
        <div className="flex-align title_grade">
          <img
            src="/assets/images/authNews/ico_grade.png"
            alt="Icon Grade"
            className="contain_image"
          />
          <span className="fz-11 fz-08-mobile name_input_grade">
            Bạn là giáo viên
          </span>
        </div>
        <GradeSelection
          dataGrades={dataTypeSchool}
          gradeSelected={typeSchoolSelected}
          handleClickGrade={handleClickTypeSchool}
          type="teacher"
        />
      </div>
      <InputSelect
        colorInput="color-text"
        fillColor="#4D4D4D"
        className="field_item field_school"
        value={schoolSelected}
        setValue={changeSchool}
        options={schoolList.map((item) => {
          return {
            value: item.school_name,
            title: item.school_name,
          };
        })}
        placeholder="Trường"
        renderLabelIcon={() => renderAuthIcon("school")}
        disabledClick={!districtSelected.value}
        onClickDisable={() => {
          setErrDistrict("Chọn quận/ huyện");
          if (!provinceSelected.value) {
            setErrProvince("Vui lòng chọn tỉnh/ thành phố");
          }
        }}
        errorAbsolute={true}
      ></InputSelect>

      <div className="flex-jus-between btn_auth_container">
        <ButtonNews
          width="15rem"
          height="3.5rem"
          onClick={() => handleCheckRegister()}
          border={"1px solid #00cc83"}
          backgroundColor={"#00cc83"}
          boxShadow={"2px 2px 5px white, 0 0 1px black"}
          maxWidth={"45%"}
          maxHeight={"7vw"}
          disabled={disabledBtnRegist}
        >
          <div className="fz-12 fz-09-mobile  color-white font_news_bold">
            ĐĂNG KÝ
          </div>
        </ButtonNews>

        <ButtonNews
          width="15rem"
          height="3.5rem"
          onClick={() => handleGoBack()}
          border={"1px solid #00cc83"}
          backgroundColor={"#fff"}
          maxWidth={"45%"}
          maxHeight={"7vw"}
        >
          <div className="fz-12 fz-09-mobile  color-primary-green font_news_bold">
            QUAY LẠI
          </div>
        </ButtonNews>
      </div>
    </div>
  );
};

export default TeacherRegister;
