import { configConstants } from '../_constants';

class LessonBase{

    static Type = {
        teacher : 'teacher',
        curriculum : 'curriculum',
        homework : 'homework',
        masterUnit : 'masterUnit'
    };

    static _moveLesson = (type, auth, data, navigation, from) => {
        if (['exam', 'mini_test'].includes(data?.exercise_type)) {
            //navigate to the minitest/exam screen
            return
        }
        var id = ""
        switch (type) {
            case LessonBase.Type.homework:
                id = data?.id
                break;
            case LessonBase.Type.masterUnit:
                id = data?.id
                break;
            default:
                id = (data?.lesson_id || data?.exercise_id)
                break;
        }
        // console.log("=====Go to type", type)
        // console.log("=====Go to id", id)
        // console.log("=====Go to with data", data)
        // console.log("=====Go to from", from)
        // var id = type == (LessonBase.Type.homework || LessonBase.Type.masterUnit) ? data?.id : (data?.lesson_id || data?.exercise_id)
        const url = `${configConstants.EXCERCISE_URL}index.php/example/lesson_demo?type=${type}&dataL=${JSON.stringify(data)}&id=${id}&token=${auth?.jwt_token}`
        window.location.href = url
    };
}

export default LessonBase;