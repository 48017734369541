import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "../../../_components/Alert";
import { teacherActions, userActions } from "../../../_actions";
import { userConstants } from "./../../../_constants";
import { SelectDate } from "../../../_components/Calendar";
import { Header } from "./../../../_components/Admin/Header";
import $ from "jquery";
import { SelectAsDiv } from "./../../../_components/Select";
import styled from "styled-components";
import moment from "moment";
import { isEmpty } from "lodash";
import InputDate from "../../../_components/Auth/InputDate";
import dayjs from "dayjs";

const ProfileImage = styled.img`
  object-fit: cover;
`;

const CustomSelect = styled(SelectAsDiv)`
  background-image: unset;
  .select-styled {
    height: 44px;
    line-height: 36px;
    font-size: 14px;
    padding-left: 20px;
    border-radius: 18px;
    outline: none;
    border: 1px solid #fff;
    box-shadow: 0 1px 8px 0 rgb(21 27 38 / 15%);
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      top: 7px;
    }
  }
  img {
    display: none;
  }
  ul li {
    height: 40px;
  }
`;

const GRADE_OPTIONS = [
  // { id: "1", name: "1" },
  // { id: "2", name: "2" },
  // { id: "3", name: "3" },
  // { id: "4", name: "4" },
  // { id: "5", name: "5" },
  { id: "Khối 6", name: "Khối 6" },
  { id: "Khối 7", name: "Khối 7" },
  { id: "Khối 8", name: "Khối 8" },
  { id: "Khối 9", name: "Khối 9" },
  // { id: "10", name: "10" },
  // { id: "11", name: "11" },
  // { id: "12", name: "12" },
];

function EditProfile() {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const dataProfile = useSelector((state) => state.profile.user_info.data);
  const alert = useSelector((state) => state.alert);

  // console.log(dataProfile);

  const [inputs, setInputs] = useState({
    fullname: dataProfile.fullname,
    gender: dataProfile.gender,
    birthday:
      dataProfile?.birthday && moment(dataProfile.birthday).isValid()
        ? new Date(dataProfile.birthday.replaceAll("-", "/"))
        : new Date(),
    email: dataProfile.email,
    phone: dataProfile.phone,
    school: dataProfile.school,
  });

  const history = useHistory();

  const [submitted, setSubmitted] = useState(false);
  const [changeAvatar, setChangeAvatar] = useState(true);
  const [availableEdit, setAilableEdit] = useState(false);
  const [feildInputInValid, setFeildInputInvalid] = useState("");

  const { fullname, gender, birthday, email, phone, school } = inputs;

  useEffect(() => {
    setInputs({
      fullname: dataProfile.fullname,
      gender: dataProfile.gender,
      birthday:
        dataProfile?.birthday && moment(dataProfile.birthday).isValid()
          ? new Date(dataProfile.birthday.replaceAll("-", "/"))
          : new Date(),
      email: dataProfile.email,
      phone: dataProfile.phone,
      school: dataProfile.school,
    });
  }, [dataProfile]);

  useEffect(() => {
    dispatch(teacherActions.getProfile(authentication.id));
    $(document).ready(function ($) {
      $("#avatar").change(function () {
        readURL(this, "#preview-avatar");
      });

      function readURL(input, element) {
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = function (e) {
            $(element).attr("src", e.target.result);
          };
          reader.readAsDataURL(input.files[0]);
        }
      }
    });
  }, []);

  function handleChange(e) {
    setAilableEdit(true);
    const { name, value } = e.target;
    if (name === "phone") {
      let numberPhone = e.target.value.replace(/\D/, "");
      setInputs((inputs) => ({ ...inputs, [name]: numberPhone }));
      setFeildInputInvalid("");
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  }

  const changeBirthday = (birthday) => {
    const today = new Date();
    setAilableEdit(true);
    if (dayjs(birthday).isBefore(dayjs(today))) {
      setInputs((inputs) => ({ ...inputs, ["birthday"]: birthday }));
    } else {
      setInputs((inputs) => ({ ...inputs, ["birthday"]: today }));
    }
  };

  function handleOnBlur(e) {
    const { name } = e.target;
    let value = e.target.value.trim();
    if (name === "phone") {
      let numberPhone = e.target.value.replace(/\D/, "").trim();
      if ([10, 11].includes(numberPhone.length)) {
        setInputs((inputs) => ({ ...inputs, [name]: numberPhone }));
        setFeildInputInvalid("");
      } else {
        setFeildInputInvalid(name);
      }
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  }

  function handleChangeFile(event) {
    setInputs((inputs) => ({
      ...inputs,
      avatar: event.target.files[0],
    }));
    setChangeAvatar(true);
    setAilableEdit(true);
  }

  function openSelectFile(e) {
    setChangeAvatar(false);
    e.preventDefault();
    $("#avatar").trigger("click");
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (changeAvatar && inputs["avatar"]) {
      var uploadAvatar = new FormData();
      uploadAvatar.append("file", inputs["avatar"]);
      dispatch(userActions.uploadAvatar(uploadAvatar));
    }
    if (validateParam()) {
      const params = {
        ...inputs,
        class: inputs.grade_name,
        grade_id:
          inputs.grade_name == "Khối 6"
            ? 2
            : inputs.grade_name == "Khối 7"
            ? 4
            : inputs.grade_name == "Khối 8"
            ? 3
            : inputs.grade_name == "Khối 9"
            ? 6
            : "",
        birthday:
          inputs.birthday.getFullYear() +
          "-" +
          (inputs.birthday.getMonth() + 1) +
          "-" +
          inputs.birthday.getDate(),
      };
      // delete params.grade_name;

      dispatch(teacherActions.updateProfile(params));
    }
  }

  function changeGender(gender) {
    setAilableEdit(true);
    setInputs({
      ...inputs,
      gender: gender,
    });
  }

  function validateParam() {
    if (
      fullname?.trim() &&
      gender &&
      birthday &&
      email &&
      phone &&
      school?.trim()
    ) {
      if (changeAvatar && availableEdit) {
        return true;
      } else if (availableEdit) {
        return true;
      } else {
        return false;
      }
    }
    // return (changeAvatar && availableEdit && fullname && gender && birthday && email && phone && school) ? true : false;
  }

  return (
    <div className="sunE-container flex bg-main-color">
      <div className="sunE-right-container flex-column">
        <Header
          title={
            authentication.role === userConstants.ROLE_TEACHER
              ? "Chỉnh sửa thông tin"
              : "Hồ sơ"
          }
          isBack
        />
        <form
          className="sunE-container-box hoso flex-1"
          onSubmit={handleSubmit}
          noValidate={true}
        >
          <div
            className={
              "avatar-edit rel" +
              (authentication.role === userConstants.ROLE_STUDENT
                ? " student"
                : "")
            }
          >
            {authentication.role === userConstants.ROLE_STUDENT && (
              <img
                style={{ width: "100%" }}
                src="/assets/images/student/bg_edit_student_profile.png"
                alt="bg_edit_student_profile"
                className="bg_edit_student_profile"
              />
            )}
            <div
              className={
                "avatar-edit-box box-shadow rel" +
                (authentication.role === userConstants.ROLE_STUDENT
                  ? " student"
                  : "")
              }
            >
              <ProfileImage
                id="preview-avatar"
                src={dataProfile.avatar}
                alt="avatar"
              />
              <input
                type="file"
                name="avatar"
                accept="image/*"
                className="hide"
                onChange={handleChangeFile}
                id="avatar"
              />
              <div className="img-edit-avt" onClick={openSelectFile}>
                <img
                  src="/assets/images/hoso/ico_edit_avt.png"
                  alt="ico_edit_avt"
                />
              </div>
            </div>
            <div className="flex-m">
              <div className="flex-1 mr-20">
                <div className="sunE-input-gr">
                  <label>Họ và tên</label>
                  <input
                    className="capitalize"
                    type="text"
                    name="fullname"
                    value={inputs.fullname}
                    onChange={handleChange}
                    placeholder="Họ và tên"
                    onBlur={handleOnBlur}
                  />
                </div>
                <div className="sunE-input-gr select-date-profile">
                  <label>Ngày sinh</label>
                  {/* <SelectDate
                    dateFormat="dd/MM/yyyy"
                    name="birthday"
                    selected={birthday}
                    disableMouseFocus={true}
                    onChange={(birthday) =>
                      console.log(birthday) +
                      setInputs({ ...inputs, birthday }) + setAilableEdit(true)
                    }
                  /> */}
                  <InputDate
                    className="field_birthday"
                    value={birthday}
                    setValue={changeBirthday}
                    name="birthday"
                    // renderLabelIcon={renderBirthdayIcon}
                    errorText={""}
                    // setErrorText={setBirthdayWarning}
                    errorAbsolute={true}
                  ></InputDate>
                </div>
                <div className="sunE-input-gr">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    defaultValue={dataProfile.email}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                {authentication.role === userConstants.ROLE_STUDENT && (
                  <div className="sunE-input-gr">
                    <label>Trường</label>
                    <input
                      type="text"
                      name="school"
                      defaultValue={dataProfile.school}
                      onChange={handleChange}
                      placeholder="Trường"
                    />
                  </div>
                )}
              </div>
              <div className="flex-1 ml-20">
                <div className="sunE-input-gr">
                  <label>Giới tính</label>
                  <div
                    className={
                      "flex-in" +
                      (submitted && inputs.gender === "" ? " err" : "")
                    }
                  >
                    <div className="male-box">
                      <div
                        className={
                          "sunE-male-female flex-m male" +
                          (inputs.gender === "male" ? " active" : "")
                        }
                        onClick={() => changeGender("male")}
                      >
                        <img
                          src="/assets/images/icon/ico_male.png"
                          alt={"ico_male"}
                          className="ico_male_active"
                        />
                        <img
                          src="/assets/images/icon/ico_male_2.png"
                          alt={"ico_male_2"}
                          className="ico_male"
                        />
                        <span>Nam</span>
                      </div>
                    </div>
                    <div className="female-box">
                      <div
                        className={
                          "sunE-male-female flex-m female" +
                          (inputs.gender === "female" ? " active" : "")
                        }
                        onClick={() => changeGender("female")}
                      >
                        <img
                          src="/assets/images/icon/ico_female.png"
                          alt={"ico_female"}
                          className="ico_female"
                        />
                        <img
                          src="/assets/images/icon/ico_female_2.png"
                          alt={"ico_female_2"}
                          className="ico_female_active"
                        />
                        <span>Nữ</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sunE-input-gr">
                  <label>
                    Số điện thoại{" "}
                    {feildInputInValid === "phone" ? (
                      <span className="text-error-input">
                        (Số điện thoại hợp lệ gồm 10-11 số!)
                      </span>
                    ) : null}
                  </label>
                  <input
                    className={
                      feildInputInValid === "phone" ? "input-error" : ""
                    }
                    type="text"
                    name="phone"
                    defaultValue={dataProfile.phone}
                    value={inputs.phone}
                    onChange={handleChange}
                    placeholder="Số điện thoại"
                    onBlur={handleOnBlur}
                  />
                </div>
                {authentication.role === userConstants.ROLE_TEACHER && (
                  <div className="sunE-input-gr">
                    <label>Nơi công tác</label>
                    <input
                      type="text"
                      name="school"
                      value={inputs.school}
                      onChange={handleChange}
                      placeholder="Nơi công tác"
                      onBlur={handleOnBlur}
                    />
                  </div>
                )}
                {authentication.role === userConstants.ROLE_STUDENT && (
                  <div className="sunE-input-gr">
                    <label>Khối</label>
                    {/* <input
                      type="text"
                      name="classname"
                      defaultValue={dataProfile.class}
                      onChange={handleChange}
                      placeholder="Lớp"
                    /> */}
                    <CustomSelect
                      keySelected={inputs.grade_name}
                      textDefault={dataProfile.grade_name}
                      data={GRADE_OPTIONS}
                      onChangeSelect={(grade_name) => {
                        setAilableEdit(true);
                        setInputs({ ...inputs, grade_name });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {alert.message &&
            alert.screen === userConstants.SCREEN_UPDATE_PROFILE && (
              <Alert
                alert={alert}
                onComplete={() =>
                  history.push("/" + authentication.role + "/more/profile")
                }
              />
            )}

          <div className="form-sunE-button btn-save text-right">
            <button
              className={
                "btn-line-blue" +
                (!validateParam() || !isEmpty(feildInputInValid)
                  ? " btn-disable"
                  : "")
              }
            >
              Lưu
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export { EditProfile };
