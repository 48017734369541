import "./RegisterNews.style.scss";
import { HeaderNews } from "../../../_components/Header";
import { TypeHeaderNewsItem } from "../../../_constants/headerNews";
import FooterAuth from "../../../_components/FooterNews/FooterAuth";
import ModalAuth from "../../../_components/Modal/ModalAuth/ModalAuth";
import InputText from "../../../_components/Auth/InputText";
import ButtonNews from "../../../_components/Button/ButtonNews";
import { registerNewsLogic } from "./RegisterNews.logic";
import StudentRegister from "./StudentRegister";
import ParentRegister from "./ParentRegister";
import TeacherRegister from "./TeacherRegister";
import ModalSuccessRegister from "../../../_components/Modal/ModalSuccessRegister/ModalSuccessRegister";
import ModalConfirmOTP from "../../../_components/Modal/ModalConfirmOTP/ModalConfirmOTP";
import { useParams } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { configConstants } from "../../../_constants";

const RegisterNews = (props) => {
  const { typeLogin } = useParams();

  let {
    dataAgents,
    typeAgentSelected,
    handleSelectAgent,
    isSuccessRegister,
    handleCheckRegister,
    verificationCode,
    changeVerificationCode,
    handleConfirmOTP,
    onLoginGoogleSuccess,
  } = registerNewsLogic();

  const renderContentAgent = () => {
    switch (typeAgentSelected) {
      case "student":
        return <StudentRegister typeLogin={typeLogin} typeAgent="student" />;
      case "teacher":
        return <TeacherRegister typeLogin={typeLogin} typeAgent="teacher" />;
      case "parent":
        return <ParentRegister typeLogin={typeLogin} typeAgent="parent" />;
      default:
    }
  };

  const renderAuthIcon = (type) => {
    return (
      <div className="flex-center">
        <img
          src={`/assets/images/authNews/ico_${type}.png`}
          alt="Icon Email"
          className="contain_image"
        />
      </div>
    );
  };

  const renderSelectionAgent = (item) => {
    return (
      <div
        onClick={() => handleSelectAgent(item)}
        className="flex-center-column agent_item pos_rel pointer_cursor"
        key={item.id}
        style={{
          opacity: typeAgentSelected == item.type ? "1" : "0.6",
        }}
      >
        <img
          // loading="lazy"
          src={`/assets/images/authNews/${item.type}.png`}
          alt="Image Agent"
          className="pos_rel contain_image col-100 box-shadow border_radius_05"
        />
        <div className="font_news_bold mg-top-0-5 fz-11 fz-09-mobile">
          {item.name}
        </div>

        {typeAgentSelected == item.type && (
          <div className="tick_select">
            <img
              src="/assets/images/authNews/ico_tick_select.png"
              alt="Icon Tick Select"
              className="contain_image tick_select_img"
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="loginNew registerNew commondPage">
      <HeaderNews type={TypeHeaderNewsItem.LOGIN} />

      <div
        className="content flex-column"
        style={{
          background: `url(
              /assets/images/authNews/bg_login.png
            ) no-repeat top center /cover`,
        }}
      >
        <div className="content_1 flex-center-column">
          <ModalSuccessRegister
            // onClickClose={handleCloseModal}
            titleMsg="Bạn đã đăng ký tài khoản thành công!"
            roleRegister={typeAgentSelected}
          />
          <ModalConfirmOTP
            verificationCode={verificationCode}
            changeVerificationCode={changeVerificationCode}
            handleConfirmOTP={handleConfirmOTP}
          />
          <ModalAuth
            title="Đăng ký tài khoản"
            desc1="Đã có tài khoản"
            desc2="Đăng nhập ngay"
            linkAuth={`/${TypeHeaderNewsItem.LOGIN}`}
          >
            <div className="list_agents flex-center">
              {dataAgents.map((item) => renderSelectionAgent(item))}
            </div>
            <div className="form_input_login form_input_mobile">
              {renderContentAgent()}

              {typeLogin != "google" && (
                <div className="flex-center-column">
                  <div>Hoặc kết nối với</div>

                  <GoogleLogin
                    clientId={configConstants.APP_ID_GOOGLE}
                    buttonText="Login"
                    cookiePolicy={"single_host_origin"}
                    onSuccess={onLoginGoogleSuccess}
                    render={(renderProps) => (
                      <div
                        onClick={() => {
                          if (!renderProps.disabled) {
                            renderProps.onClick();
                          }
                        }}
                        className="pointer_cursor box_auth_social flex-center"
                      >
                        <img
                          src="/assets/images/authNews/ico_gg.png"
                          alt="Icon Google"
                          className="contain_image icon_auth_social"
                        />
                      </div>
                    )}
                  ></GoogleLogin>
                </div>
              )}
            </div>
          </ModalAuth>
        </div>
        <FooterAuth />
      </div>
    </div>
  );
};

export default RegisterNews;
