import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { studentService } from "../../../_services";
import DoTestExamModal from "./DoTestExam";
import "./styles.scss";

const DetailHistoryTest = (props) => {
  const { id } = useParams();
  const [examDetail, setExamDetail] = useState({});
  const [isDoAgain, showDoAgain] = useState(false);
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);

  useEffect(() => {
    getDetailExamTest();
  }, [id]);

  const getDetailExamTest = async () => {
    try {
      const res = await studentService.getDetailExamTestHistoryService(id);
      if (res?.status) {
        setExamDetail(res);
      }
    } catch (e) {}
  };

  // console.log(examDetail);

  const handleCloseDoTest = () => {
    showDoAgain(false);
    history.push("/" + authentication.role + "/exam-test/history");
  };

  return (
    <div id="c-detail-history-test">
      <div className="header-view">
        <div
          className="title-header"
          onClick={() =>
            history.push("/" + authentication.role + "/exam-test/history")
          }
        >
          <img src="/assets/images/icon/ico_left.png" />
          <span>Kết quả làm bài</span>
        </div>
        <div className="flex-1"></div>
      </div>
      <div className="body-view scrollbar-custom">
        <div className="score-info-history">
          <div className="item-score">
            <label>Điểm số:</label>
            <span>{examDetail?.data_history?.score}</span>
          </div>
          <div className="item-score">
            <label>Câu đúng:</label>
            <span>{examDetail?.data_history?.number_true}</span>
          </div>
          <div className="item-score">
            <label>Câu sai:</label>
            <span>{examDetail?.data_history?.number_false}</span>
          </div>
        </div>
        <div className="detail-history">
          {examDetail?.list_question?.map((item, index) => (
            <div className="col-2" key={item.id}>
              <div
                className={`item-answer ${
                  item.score === "1" ? "true" : "false"
                }`}
              >
                {index + 1}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-view">
        <button className="btn-line-blue" onClick={() => showDoAgain(true)}>
          Làm lại
        </button>
      </div>
      {isDoAgain && (
        <DoTestExamModal
          isShow={isDoAgain}
          onClose={() => handleCloseDoTest()}
          mockTestId={examDetail?.data_history?.exam_id}
          type={"mock_test"}
        />
      )}
    </div>
  );
};

export default React.memo(DetailHistoryTest);
