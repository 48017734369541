import React from "react";
import _ from "lodash";
import { Header } from "../../../../../_components/Admin/Header";
import { assignSelectionLogic } from "./AssignExerciseSelection.logic";
import "./AssignExerciseSelection.style.scss";

const AssignExerciseSelection = () => {
  const {
    typeSelected,
    dataSelection,
    navigateToSourcePage,
    handleSubmit,
    handleChecked,
  } = assignSelectionLogic();

  const renderListAssignSelection = (data) => {
    return (
      <div key={data.id} className="item_assign_selection ">
        <div className="item_selection_header flex-jus-between">
          <div className="item_selection_header_left flex-align">
            <img
              src={`/assets/images/teacher/giaobai/ico_${data.type}_assign.png`}
              alt="Icon Item Selection"
              loading="lazy"
              className="contain_image icon_assign"
            />
            <div className="item_header_title mg-left-0-5 color-title-assign fz-15 font_myriad_bold">
              {data.title}
            </div>
          </div>

          <div className="item_selection_header_right">
            <span className="chk-custom-gr wh20 check-select-student">
              <input
                type="checkbox"
                name="selection_assign"
                defaultValue="selection_assign"
                id={data.id}
                onChange={() => handleChecked(data)}
                checked={typeSelected == data.type}
              />
              <label htmlFor={data.id}></label>
            </span>
          </div>
        </div>

        <div className="item_selection_content text-align-justify fz-12-5 color-text">
          {data.content}
        </div>
      </div>
    );
  };

  return (
    <div className="sunE-right-container flex-column selection_assign">
      <Header
        title={"Chọn hình thức giao"}
        isBack
        clickBack={() => navigateToSourcePage()}
      />
      {/* {alert.message &&
        alert.screen === teacherConstants.SCREEN_ADD_STUDENT_EXERCISE && (
          <Alert
            alert={alert}
            onComplete={() =>
              history.push(
                "/" +
                  authentication.role +
                  "/class/view/" +
                  id +
                  "/exercise/" +
                  exercise_id
              )
            }
          />
        )} */}
      <div className="content_selection pos_rel flex-column-start  scrollbar-custom-2 ">
        <div className="list_assign_selection flex-wrap flex-align-end">
          {dataSelection.map((data) => renderListAssignSelection(data))}
        </div>
      </div>

      <div className="form-sunE-button text-right mb0 btn_continue_selection">
        <button
          className={
            "btn-bg-blue btn-default " + (!typeSelected ? " btn-disable" : "")
          }
          onClick={handleSubmit}
        >
          Tiếp tục
        </button>
      </div>
    </div>
  );
};

export default AssignExerciseSelection;
