import "./PolicyPage.style.scss";
import { HeaderNews } from "../../_components/Header";
import {
  TypeHeaderNewsItem,
  NameTitleNewsItem,
} from "../../_constants/headerNews";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import FooterNews from "../../_components/FooterNews/FooterNews";
import { policyPageLogic } from "./PolicyPage.logic";
import Pagination from "../../_components/Pagination/Pagination";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import LazyLoad from "react-lazyload";

const PolicyPage = (props) => {
  let { pageSelected, dataPagination, handleClickPage } = policyPageLogic();
  // console.log(pageSelected);

  const renderPage = () => {
    switch (pageSelected) {
      case 1:
        return <Content1 />;
      case 2:
        return <Content2 />;
      case 3:
        return <Content3 />;
      default:
    }
  };

  return (
    <div className="policyPage commondPage">
      <HeaderNews type={TypeHeaderNewsItem.INTRODUCTION} />
      {/* <TitleNews title={NameTitleNewsItem.INTRODUCTION} /> */}
      <LazyLoad className="bg_white">
        <img
          src="/assets/images/introNews/intro_banner.png"
          alt="Slider Bg"
          className="slider_bg contain_image display_none_mobile zIndex5"
        />
      </LazyLoad>
      <LazyLoad className="bg_white">
        <img
          src="/assets/images/introNews/intro_banner_mobile.png"
          alt="Slider Bg"
          className="slider_bg contain_image hide display_flex_mobile zIndex5"
        />
      </LazyLoad>
      <div className="content zIndex5 pos_rel">
        {renderPage()}
        <Pagination
          pageSelected={pageSelected}
          dataPagination={dataPagination}
          handleClickPage={handleClickPage}
        />
      </div>
      <LazyLoad
        className="bg_side display_none_mobile"
        style={{
          background: `url(
              /assets/images/banner_contact.png
            ) no-repeat top center /contain`,
          // paddingTop: "50%",
          backgroundAttachment: "scroll",
        }}
      ></LazyLoad>
      <LazyLoad
        className="bg_side hide display_flex_mobile"
        style={{
          background: `url(
              /assets/images/banner_contact_mobile.png
            ) no-repeat top center /contain`,
          // paddingTop: "50%",
          backgroundAttachment: "scroll",
        }}
      ></LazyLoad>
      <FooterNews />
      <ScrollFixed />
    </div>
  );
};

export default PolicyPage;
