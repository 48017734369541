import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DropDownCustom } from "../../../_components/dropdown";
import { studentService } from "../../../_services/students";
import {
  getListClassService,
  getLocationService,
} from "../../../_services/user";
import "./styles.scss";

const HistoryTest = (props) => {
  const [listClass, setListClass] = useState([]);
  const [classSelected, selectClass] = useState();
  const [listLocation, setListLocation] = useState([]);
  const [locationSelected, setLocation] = useState();
  const [listDataView, setListDataView] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);
  const grades = useSelector((state) => state.grades);

  useEffect(() => {
    if (grades.listAddress) {
      let list = grades.listAddress.map((item) => {
        item.value = item.id;
        item.title = item.address;
        return item;
      });
      list = [{ value: null, title: "Chọn tỉnh/TP", id: null }, ...list];
      setLocation(list[0]);
      setListLocation(list);
    }
    if (grades.listGrade) {
      let list = grades.listGrade.map((item) => {
        item.value = item.id;
        item.title = item.name;
        return item;
      });
      list = [{ value: null, title: "Chọn khối", id: null }, ...list];
      selectClass(list[0]);
      setListClass(list);
    }
  }, [grades.listAddress, grades.listGrade]);

  useEffect(() => {
    getListDataView();
  }, [classSelected?.value, locationSelected?.value]);

  const getListDataView = async () => {
    setLoading(true);
    try {
      const res = await studentService.getExamTestHistoryService(
        classSelected.id,
        locationSelected.id
      );
      if (res?.status) {
        setListDataView(res?.list_mock_history || []);
      }
    } catch (e) {}
    setLoading(false);
  };

  // console.log(user_exam_result_id);

  return (
    <div id="c-history-test">
      <div className="header-view">
        <div
          className="title-header"
          onClick={() => history.push("/" + authentication.role + "/exam-test")}
        >
          <img src="/assets/images/icon/ico_left.png" />
          <span>Lịch sử làm bài</span>
        </div>
        <div className="filter">
          <DropDownCustom
            listOptions={listClass}
            itemSelected={classSelected}
            onChangeValue={(item) => selectClass(item)}
          />
          <DropDownCustom
            classItem="with-200"
            listOptions={listLocation}
            itemSelected={locationSelected}
            onChangeValue={(item) => setLocation(item)}
          />
        </div>
      </div>
      <div className="body-view scrollbar-custom">
        <div className="">
          <div className="row">
            {listDataView.map((item, index) => (
              <div
                style={{ cursor: "pointer" }}
                className="col-md-6"
                key={index}
                onClick={() => {
                  history.push(
                    "/" +
                      authentication.role +
                      "/exam-test/history/" +
                      item.user_exam_result_id
                  );
                }}
              >
                <div className="unit-test">
                  <img src="/assets/images/luyenthi/ico-unit-test.png" />
                  <div className="content-view">
                    <div className="title">{item.exam_name}</div>
                    <div className="description">
                      {item.start_time.replace(" ", " | ")}
                    </div>
                  </div>
                  <div className="content-info flex-column-between">
                    <div className="title">
                      {item.number_true}/{item.total_question} Câu
                    </div>
                    <div
                      style={{
                        lineHeight: "normal",
                      }}
                      className="title"
                    >
                      {item.duration}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {!isLoading && (!listDataView || listDataView.length === 0) && (
              <div
                className="sunE-no-class text-center flex-1"
                style={{ marginTop: 0 }}
              >
                <img
                  style={{ width: "60%" }}
                  src="/assets/images/exam_history_empty.png"
                  alt="img_no_class_big"
                />
                <p className="text-light" style={{ marginTop: -60 }}>
                  {(classSelected?.value || locationSelected?.value) &&
                  listDataView.length == 0
                    ? "Không tìm thấy kết quả tương ứng."
                    : "Bạn chưa hoàn thành bài thi nào."}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HistoryTest);
