import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import LazyLoad from "react-lazyload";

function EmptyScheduleToday(props) {
  const { selectedDate } = props;
  const authentication = useSelector((state) => state.authentication);
  const schedules = useSelector((state) => state.schedules);
  let isButtonTKH = props.isButtonTKH ? true : false;
  let isBCHT = props.isBCHT ? true : false;
  let ViewScheduleStudentDetail = props.ViewScheduleStudentDetail;
  return (
    <div className="flex-1 sunE-plan">
      <LazyLoad
        once
        className="no-plan text-center responsive_screen_small_no_plan"
      >
        <img src="/assets/images/no_plan.png" alt={"no_plan"} />
        <h2>
          {isBCHT ? "CHƯA CÓ HOẠT ĐỘNG" : "BẠN CHƯA TẠO KẾ HOẠCH NÀO"}
          <br />
          CHO{" "}
          {moment(
            ViewScheduleStudentDetail
              ? schedules.dateSelectedCalendar
              : schedules.selectDate
          ).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
            ? isBCHT
              ? "NGÀY HÔM NAY"
              : "HÔM NAY"
            : "NGÀY NÀY"}
        </h2>
        {isButtonTKH && (
          <div className="form-sunE-button">
            <Link
              to={
                "/" +
                authentication.role +
                `/more/schedule/add/homepage?date=${
                  selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : ""
                }&redirectUrl=/teacher`
              }
            >
              <button className="btn-line-blue">Tạo kế hoạch</button>
            </Link>
          </div>
        )}
      </LazyLoad>
    </div>
  );
}

export { EmptyScheduleToday };
