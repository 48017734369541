import { useEffect, useState } from "react";

export const feePolicyPageLogic = (props) => {
  //
  const [dataRule4, setDataRule4] = useState({
    nameRule: "CHÍNH SÁCH ĐỔI, TRẢ HÀNG VÀ HOÀN TIỀN",
    rule_id: 4,
    data: [
      {
        id: 1,
        content:
          "- Khi khách hàng đăng ký mua một khóa học tiếng Anh bất kỳ trên hệ thống Sunday English, có nghĩa rằng khách hàng đã tìm hiểu kỹ lưỡng về toàn bộ sản phẩm trước khi mua và sử dụng. Sunday English chịu trách nhiệm đảm bảo các cam kết dịch vụ đã nêu ra và sẽ làm hết khả năng để khắc phục trong những trường hợp bất khả kháng.",
      },
      {
        id: 2,
        content:
          "- Sunday English không có trách nhiệm hoàn trả học phí mà khách hàng đã đăng ký.",
      },
    ],
  });

  // useEffect(() => {
  //   window.scrollTo({ top: 0 });
  // }, []);

  // Data policy Payment
  const dataPolicyPayment = {
    nameRule: "HƯỚNG DẪN THANH TOÁN",

    data1: {
      title:
        "Sau khi chọn gói học muốn mua tại mục “Học phí”, khách hàng tiến hành thanh toán bằng cách chọn một trong các hình thức thanh toán sau:",
      data: [
        {
          id: 1,
          title: "1. Thanh toán trực tuyến (OnePay) ",
          list_1: [
            {
              id: 1,
              content:
                "Khách hàng có thể sử dụng hình thức thanh toán trực tuyến với hầu hết các loại thẻ tín dụng và thẻ ATM do các ngân hàng nội địa phát hành. Trước khi tiến hành lựa chọn hình thức thanh toán trực tuyến, khách hàng phải kiểm tra kĩ lại các thông tin về đơn hàng, thông tin liên lạc, số tiền cần thanh toán. Sau đó tiến hành thanh toán trực tuyến. Khách hàng sẽ nhận mã kích hoạt (qua email) khi giao dịch thành công.",
            },
            {
              id: 2,
              content_note: "LƯU Ý:",
              content_note_1:
                "Sau khi hoàn tất các bước thanh toán theo hướng dẫn, nếu sau quá 30 phút khách hàng không nhận được bất cứ thông báo nào, khách hàng vui lòng liên hệ với GK Corp để nhận được kết quả giao dịch. Khách hàng không nên thực hiện tiếp giao dịch để tránh trường hợp bị trừ tiền thêm một lần.",
            },
          ],
        },
        {
          id: 2,
          title:
            "2. Thanh toán trong ứng dụng bằng thẻ tín dụng đăng ký với Apple/ Google (In-App Purchase)",
          list_1: [
            {
              id: 1,
              content:
                "Với hình thức giao dịch này, khách hàng thực hiện được qua app Sunday English, Sunday Teacher hoặc Sunday Parent. Khách hàng sẽ nhận mã kích hoạt (qua email) khi giao dịch thành công. ",
            },
          ],
        },
      ],
    },
  };
  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      var ids = hash.split("#");
      for (var i = 1; i < ids.length; i++) {
        var element = document.getElementById(ids[i]);
        // console.log(element);
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }
    } else {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [window.location]);

  return { dataRule4, dataPolicyPayment };
};
