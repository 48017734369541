import { useState, useEffect, useRef } from "react";
import { apiCaller } from "../../_helpers";
import { configConstants } from "../../_constants";
import { studentService } from "../../_services";
import { useSelector } from "react-redux";
import { history } from "../../_helpers";
import { userConstants } from "../../_constants";
import { getLocationService } from "../../_services/user";
import { LinkApp } from "../../_constants/linkDownloadApp";
import { getMobileOperatingSystem } from "../../_base/Validate";

export const mockTestLogic = (props) => {
  const authentication = useSelector((state) => state.authentication);

  // Variable Status Filter
  const [isFiltered, setIsFiltered] = useState(false);

  // Grade
  const [dataGrades, setDataGrades] = useState([]);
  const [dataGradesMobile, setDataGradesMobile] = useState([]);

  // Variable Province
  const [provinceList, setProvinceList] = useState([]);
  const [provinceListMobile, setProvinceListMobile] = useState([]);
  const getProvinceList = async () => {
    try {
      const res = await getLocationService();
      if (res?.data?.status) {
        setProvinceList([
          { id: null, address: "Chọn Tỉnh/Thành phố" },
          ...res.data.list_address,
        ]);
        setProvinceListMobile([
          { id: null, address: "Tất cả" },
          ...res.data.list_address,
        ]);
      }
    } catch (e) {}
  };

  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  const [listMockTest, setListMockTest] = useState([]);

  const getListMockTestScroll = async (
    offset,
    purpose,
    grade_id,
    address_id,
    key_search,
    isLoading
  ) => {
    try {
      //   setLoading(true);
      let params = {
        // purpose: purpose || "",
        limit: 10,
        offset: offset,
      };
      if (grade_id) {
        params.gradeId = grade_id;
      }
      if (address_id) {
        params.addressId = address_id;
      }
      if (key_search) {
        params.key_search = key_search;
      }

      if (purpose) {
        params.purpose = purpose;
      }
      // console.log(currentPage + "_" + offset);
      if ((currentPage != offset || offset == 0) && !isLoading) {
        setLoading(true);
        const res = await studentService.getExamTestServiceNews(params);
        //   setLoading(false);
        if (res?.status) {
          if (!hasDuplicates(listMockTest, res?.list_mock_test)) {
            setLoading(false);
            setListMockTest((prev) => [...prev, ...res?.list_mock_test]);
            setLoadMore(
              res.list_mock_test?.length == configConstants.DEFAULT_LIMIT
            );
            setCurrentPage(offset);
          }
        }
      }
    } catch (e) {}
  };

  const getListMockTest = async (
    offset,
    purpose,
    grade_id,
    address_id,
    key_search
  ) => {
    try {
      //   setLoading(true);
      let params = {
        // purpose: purpose || "",
        limit: configConstants.DEFAULT_LIMIT,
        offset: offset,
      };
      if (grade_id) {
        params.gradeId = grade_id;
      }
      if (address_id) {
        params.addressId = address_id;
      }
      if (key_search) {
        params.key_search = key_search;
      }

      if (purpose) {
        params.purpose = purpose;
      }
      const res = await studentService.getExamTestServiceNews(params);
      if (res?.status) {
        setListMockTest(res?.list_mock_test);
        setCurrentPage(offset);
      }
    } catch (e) {}
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadMore, setLoadMore] = useState(true);
  const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (refScroll && refScroll.current) {
  //     refScroll.current.addEventListener("scroll", handleScroll);
  //     return () =>
  //       refScroll.current?.removeEventListener("scroll", handleScroll);
  //   }
  // }, [refScroll, currentPage, isLoadMore]);

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
      isLoadMore &&
      !isLoading
    ) {
      getListMockTestScroll(
        currentPage + configConstants.DEFAULT_LIMIT,
        topicSelected?.id,
        gradeSlelected?.id,
        provinceSelected?.id,
        valueSearch,
        false
      );
    }
  };

  useEffect(() => {
    if (!dataGrades.length) {
      apiCaller(
        "/api_login/grade",
        "GET",
        {},
        null,
        true,
        configConstants.API_URL_SETEST,
        false
      ).then((res) => {
        if (res.status) {
          const newListGrade = [
            {
              id: "",
              name: "Chọn Khối Đề Thi",
              value: "",
            },
            ...res.list_grade,
          ];
          setDataGrades(newListGrade);
          setDataGradesMobile([
            {
              id: "",
              name: "Tất cả",
              value: "",
            },
            ...res.list_grade,
          ]);
        }
      });
    }

    getProvinceList();
    getListMockTest(currentPage);
    // window.scrollTo({ top: 0 });
  }, []);

  // Variable Filter
  const [gradeSlelected, setGradeSelected] = useState();
  const [provinceSelected, setProvinceSelected] = useState();
  const [topicSelected, setTopicSelected] = useState();

  const handleClickOption = (data, type) => {
    setValueSearch("");
    switch (type) {
      case "grade":
        setGradeSelected(data);
        getListMockTest(0, topicSelected?.id, data.id, provinceSelected?.id);
        return;
      case "province":
        setProvinceSelected(data);
        getListMockTest(0, topicSelected?.id, gradeSlelected?.id, data?.id);
        return;
      case "topic":
        setTopicSelected(data);
        getListMockTest(0, data?.id, gradeSlelected?.id, provinceSelected?.id);
        return;
      default:
        return;
    }
  };

  // Type MockTest

  const dataTypeTopic = [
    {
      id: null,
      title: "Chọn loại đề",
    },
    {
      id: "mock_15",
      title: "Kiểm tra 15 phút",
    },
    {
      id: "mock_45",
      title: "Kiểm tra 45 phút",
    },
    {
      id: "mock_60",
      title: "Kiểm tra học kỳ",
    },
    {
      id: "mock_class_10",
      title: "Luyện thi vào lớp 10",
    },
  ];

  // Data type topic mobile
  const dataTypeTopicMobile = [
    {
      id: null,
      title: "Tất cả",
    },
    {
      id: "mock_15",
      title: "Kiểm tra 15 phút",
    },
    {
      id: "mock_45",
      title: "Kiểm tra 45 phút",
    },
    {
      id: "mock_60",
      title: "Kiểm tra học kỳ",
    },
    {
      id: "mock_class_10",
      title: "Luyện thi vào lớp 10",
    },
  ];
  // Input Search
  const [valueSearch, setValueSearch] = useState();

  const changeValueSearch = (value) => {
    setValueSearch(value);
  };

  const handleClickMockTest = (item) => {
    const osMobile = getMobileOperatingSystem();
    if (osMobile == "Android") {
      window.open(LinkApp.GG_PLAY, "_blank");
    } else if (osMobile == "iOS") {
      window.open(LinkApp.APP_STORE, "_blank");
    }

    // else {
    //   if (authentication.isLogin) {
    //     if (authentication.role == userConstants.ROLE_STUDENT) {
    //       history.push(
    //         `/${authentication?.role}/exam-test/${item.id}/experience`
    //       );
    //     } else {
    //       history.push(`/exam-test/${item.id}/experience`);
    //     }
    //   } else {
    //     localStorage.setItem(
    //       "purposeLogin",
    //       JSON.stringify({
    //         purpose: "mock_test",
    //         exam_id: item.id,
    //       })
    //     );
    //     history.push("/login");
    //   }
    // }
    else {
      history.push(`/download_page/student`);
    }
  };

  // Handle Search Mock Test
  const handleSearchMockTest = (e) => {
    e?.preventDefault();
    if (valueSearch) {
      setGradeSelected("Chọn Khối Đề Thi");
      setProvinceSelected("Chọn Tỉnh/Thành phố");
      setTopicSelected("Chọn loại đề");
      getListMockTest(0, null, null, null, valueSearch);
    } else {
      setGradeSelected("Chọn Khối Đề Thi");
      setProvinceSelected("Chọn Tỉnh/Thành phố");
      setTopicSelected("Chọn loại đề");
      getListMockTest(0, null, null, null);
    }
  };

  // MODAL FILTER
  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);

  const handleOpenModal = () => {
    setIsOpenModalFilter(true);
  };

  const handleCloseModal = () => {
    setIsOpenModalFilter(false);
  };

  const handleClickOptionMobile = (data, type) => {
    setValueSearch("");
    switch (type) {
      case "grade":
        setGradeSelected(data);
        return;
      case "province":
        setProvinceSelected(data);
        return;
      case "topic":
        setTopicSelected(data);
        return;
      default:
        return;
    }
  };

  // Handle Filter
  const handleFilter = () => {
    setIsOpenModalFilter(false);
    setValueSearch("");
    getListMockTest(
      0,
      topicSelected?.id,
      gradeSlelected?.id,
      provinceSelected?.id
    );
    if (!topicSelected?.id && !gradeSlelected?.id && !provinceSelected?.id) {
      setIsFiltered(false);
    } else {
      setIsFiltered(true);
    }
  };

  // Handle Delete Value Search
  const handleResetValueSearch = () => {
    console.log("123");
    setValueSearch("");
    setGradeSelected("Chọn Khối Đề Thi");
    setProvinceSelected("Chọn Tỉnh/Thành phố");
    setTopicSelected("Chọn loại đề");
    getListMockTest(0, null, null, null, null);
  };

  return {
    listMockTest,
    dataGrades,
    provinceList,
    dataTypeTopic,
    valueSearch,
    gradeSlelected,
    provinceSelected,
    topicSelected,
    isOpenModalFilter,
    changeValueSearch,
    setValueSearch,
    handleClickOption,
    handleClickMockTest,
    handleSearchMockTest,
    handleScroll,
    handleOpenModal,
    handleCloseModal,
    handleClickOptionMobile,
    handleFilter,
    dataTypeTopicMobile,
    provinceListMobile,
    dataGradesMobile,
    isFiltered,
    handleResetValueSearch,
  };
};
