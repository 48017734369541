import "./FeePage.style.scss";
import { feePolicyPageLogic } from "./FeePolicyPage.logic";
import FooterNews from "../../_components/FooterNews/FooterNews";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { HeaderNews } from "../../_components/Header";
import {
  TypeHeaderNewsItem,
  NameTitleNewsItem,
} from "../../_constants/headerNews";
import TitleNews from "../../_components/Header/HeaderNews/TitleNews";
import {
  renderListContent1,
  renderContentPolicyPayment,
  renderContentPolicyPayment2,
} from "../../_base/DetailListNews";
import LazyLoad from "react-lazyload";

const FeePolicyPage = () => {
  let { dataRule4, dataPolicyPayment } = feePolicyPageLogic();

  return (
    <div className="feePage commondPage">
      <HeaderNews type={TypeHeaderNewsItem.FEE} />
      {/* <TitleNews title={NameTitleNewsItem.FEE} /> */}

      <LazyLoad className="slider">
        <img
          src="/assets/images/feeNews/slider_bg.png"
          alt="Slider Fee"
          className="slider_bg contain_image display_none_mobile"
        />
        <img
          src="/assets/images/feeNews/slider_bg_mobile.png"
          alt="Slider Fee"
          className="slider_bg contain_image hide display_block_mobile"
        />
      </LazyLoad>

      <div className="content flex-center flex-wrap col-100">
        <div className="pd_8per bg_white col-100">
          <LazyLoad className="flex-align pd-top-2-pc col-100">
            <img
              src="/assets/images/feeNews/ico_payment.png"
              alt="Icon Payment"
              className="contain_image icon_payment"
            />
            <span
              id="policy_payment"
              className="font_news_bold color-primary-green fz-12-5-mobile fz-20 mg-left-1"
            >
              {dataPolicyPayment?.nameRule}
            </span>
          </LazyLoad>

          <LazyLoad className="mg-top-15 mg-top-1-mobile  pd-bottom-2 pd-bottom-15-mobile col-100">
            {renderContentPolicyPayment(dataPolicyPayment.data1)}
          </LazyLoad>

          {/* <div className=" flex-center col-100 mg-top-2">
            <div className="border_bottom col-80"></div>
          </div> */}
          {/* <div className=" flex-center col-100 mg-top-2 mg-top-15-mobile  bg_white hide display_flex_mobile">
            <div className="border_bottom col-80 bg_white"></div>
          </div> */}
        </div>
        {/* <div id="rule4" className="pd_8per bg_white">
          <div className="flex-align pd-top-2">
            <img
              src="/assets/images/introNews/icon/ico_rule_4.png"
              alt="Icon Rule 4"
              className="contain_image img_intro_item"
            />
            <span className="font_news_bold color-primary-green fz-12-5-mobile fz-20 mg-left-1">
              {dataRule4?.nameRule}
            </span>
          </div>

          <div className="mg-top-15 pd-bottom-2 ">
            {dataRule4?.data?.map((data) => renderListContent1(data))}
          </div>
        </div> */}
      </div>

      <FooterNews />
      <ScrollFixed />
    </div>
  );
};

export default FeePolicyPage;
