import React, { useEffect, useState } from "react";
import { teacherActions } from "../../../_actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SelectAsDiv } from "../../../_components/Select";
import { ShowMapGrid, ChangView } from "../../../_components/Admin/Curriculum";
import { teacherService } from "../../../_services";
import { curriculumConstants } from "../../../_constants";
import { isEmpty } from "lodash";

function ViewDetailCurriculum() {
  const { id, type } = useParams();
  const authentication = useSelector((state) => state.authentication);
  const [listCuriculum, setListCurriculum] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const fromPage = params.get("page");

  useEffect(() => {
    if (type === "personal") {
      teacherActions.getCurriculumV2("personal").then((res) => {
        setListCurriculum(res || []);
      });
    } else {
      teacherActions.getCurriculumV2("sunday").then((res) => {
        setListCurriculum(res || []);
      });
    }

    setLoading(true);
    teacherService.getDetailCurriculumById(id).then((res) => {
      if (res) {
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum: res,
        });
      }
      setLoading(false);
    });
    // dispatch(teacherActions.getDetailCurriculumById(id));
    return () => {
      setListCurriculum([]);
    };
  }, []);
  const dispatch = useDispatch();
  const curriculums = useSelector((state) => state.curriculums);

  function onChangeSelect(key) {
    history.push("/" + authentication.role + "/curriculum/view/detail/" + key + (!isEmpty(fromPage) ? `?page=${fromPage}` : ""));
    setLoading(true);
    teacherService.getDetailCurriculumById(key).then((res) => {
      if (res) {
        dispatch({
          type: curriculumConstants.GET_DETAIL_CURRICULUM,
          curriculum: res,
        });
      }
      setLoading(false);
    });
  }
  let curriculumSelected = listCuriculum.find((item) => item.id === id) || {};

  return (
    <div className="sunE-right-container">
      <div className="sunE-main-title flex-m">
        <div className="flex-1 input-gr bar-select custom-select-no-bg">
          <SelectAsDiv
            textDefault={curriculumSelected.name}
            data={listCuriculum}
            onChangeSelect={(key) => onChangeSelect(key)}
            className="home_curriculum"
          />
        </div>
        <ChangView />
        <div
          className="form-sunE-button rel"
          onClick={() =>
            history.push(
              "/" +
                authentication.role +
                "/curriculum" +
                (type ? "/view/" + type : "/view/sunday") + (!isEmpty(fromPage)  ? `?page=${fromPage}` : "")
            )
          }
        >
          <button className="btn-line-blue">Quay lại</button>
        </div>
      </div>
      {!isLoading && (
        <ShowMapGrid view={curriculums.view} curriculums={curriculums} />
      )}
    </div>
  );
}

export { ViewDetailCurriculum };
