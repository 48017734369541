import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { scheduleConstants, userConstants } from "./../../_constants";
import { PopUpYesNo } from "../../_components/Popup";
import { persistor } from "../../index";
import LazyLoad from "react-lazyload";
import { apiRequestLogout } from "../../_helpers";
import API from "../../_apis/APIConstants";

function HomePageMore() {
  const authentication = useSelector((state) => state.authentication);
  const [visibleModalLogout, setVisibleModalLogout] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: scheduleConstants.SET_SELECT_DATE,
      time: null,
    });
  }, []);

   function logout() {
    apiRequestLogout(`${API.logout_account_service}`)
    apiRequestLogout(`${API.logout_account_exercise}`)
    window.location.href = "/login";
    dispatch({
      type: userConstants.RESET_ALL_STATE,
    });
    dispatch({
      type: userConstants.LOGOUT,
    });
    persistor.purge();
    // localStorage.clear();
    localStorage.removeItem("authentication");
    localStorage.removeItem("access_token");
    localStorage.removeItem("info_header_user");
    localStorage.removeItem("purposeLogin");
    localStorage.removeItem("date_selected");
    // history.push("/login");
  }
  return (
    <div className="sunE-right-container rel scrollbar-custom">
      <div className="sunE-main-title flex-m">
        <span className="line-h" />
        <h1 className="flex-1">Thêm</h1>
      </div>
      <div className="sunE-content setting-content">
        <div className="setting-list">
          {authentication.role === userConstants.ROLE_STUDENT && (
            <Link to={"/" + authentication.role + "/more/class"}>
              <LazyLoad once className="setting-box box-shadow text-center">
                <img
                  alt="ico_class"
                  src="/assets/images/student/more/ico_class.png"
                  className="ico_default"
                />
                <p className="text-light top-8">Lớp học</p>
              </LazyLoad>
            </Link>
          )}
          <Link to={"/" + authentication.role + "/more/profile"}>
            <LazyLoad once className="setting-box box-shadow text-center">
              <img
                alt="ico_hoso"
                src="/assets/images/teacher/lichlamviec/ico_hoso.png"
              />
              <p className="text-light top-8">Hồ sơ</p>
            </LazyLoad>
          </Link>
          {authentication.role === userConstants.ROLE_STUDENT && (
            <Link
              to={"/" + authentication.role + "/more/message?active=personal"}
            >
              <LazyLoad once className="setting-box box-shadow text-center">
                <img
                  alt="ico_tinnhan"
                  src="/assets/images/student/more/ico_tinnhan.png"
                  className="ico_default"
                />
                <p className="text-light top-8">Tin nhắn</p>
              </LazyLoad>
            </Link>
          )}
          {authentication.role === userConstants.ROLE_TEACHER && (
            <Link to={"/" + authentication.role + "/more/schedule"}>
              <LazyLoad once className="setting-box box-shadow text-center">
                <img
                  alt="ico_lichlamviec"
                  src="/assets/images/teacher/lichlamviec/ico_lichlamviec.png"
                />
                <p className="text-light top-8">Lịch làm việc</p>
              </LazyLoad>
            </Link>
          )}
          {authentication.role === userConstants.ROLE_TEACHER && (
            <Link to={"/" + authentication.role + "/more/resource"}>
              <LazyLoad once className="setting-box box-shadow text-center">
                <img
                  alt="ico_huongdan"
                  src="/assets/images/teacher/lichlamviec/ico_huongdan.png"
                />
                <p className="text-light">Hướng dẫn học tập</p>
              </LazyLoad>
            </Link>
          )}
          {authentication.role === userConstants.ROLE_TEACHER && (
            <Link to={"/" + authentication.role + "/more/exercise?page=morepage"}>
              <LazyLoad once className="setting-box box-shadow text-center">
                <img
                  alt="ico_quanly"
                  src="/assets/images/teacher/lichlamviec/ico_quanly.png"
                />
                <p className="text-light">Quản lý bài đã giao</p>
              </LazyLoad>
            </Link>
          )}
          {authentication.role === userConstants.ROLE_STUDENT && (
            <Link to={"/" + authentication.role + "/more/schedule"}>
              <LazyLoad once className="setting-box box-shadow text-center">
                <img
                  alt="ico_kehoachhoctap"
                  src="/assets/images/student/more/ico_kehoachhoctap.png"
                  className="ico_default"
                />
                <p className="text-light">
                  Kế hoạch
                  <br />
                  học tập
                </p>
              </LazyLoad>
            </Link>
          )}
          <Link to={"/" + authentication.role + "/more/setting"}>
            <LazyLoad once className="setting-box box-shadow text-center">
              <img
                alt="ico_caidat"
                src="/assets/images/teacher/lichlamviec/ico_caidat.png"
              />
              <p className="text-light top-8">Cài đặt</p>
            </LazyLoad>
          </Link>
          {/* {authentication.role === userConstants.ROLE_TEACHER && (
                        <Link to={"/" + authentication.role + "/license"}>
                            <div className="setting-box box-shadow text-center">
                                <img alt="ico_nangcap" src="/assets/images/teacher/lichlamviec/ico_nangcap.png" />
                                <p className="text-light">Nâng cấp tài khoản</p>
                            </div>
                        </Link>
                    )} */}
        </div>
      </div>
      <div
        className="form-sunE-button btn-sign-out"
        onClick={() => setVisibleModalLogout(true)}
      >
        <button className="btn-line-blue">Đăng xuất</button>
      </div>
      {visibleModalLogout && (
        <PopUpYesNo
          onClickYes={() => logout()}
          onClickNo={() => setVisibleModalLogout(false)}
          labelNo={"Không"}
          message={["Bạn có muốn đăng xuất tài khoản không?"]}
          customWidthBtn={"customWidthBtn"}
        />
      )}
    </div>
  );
}

export { HomePageMore };
