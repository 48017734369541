import "./FeePage.style.scss";
import { feePageLogic } from "./FeePage.logic";
import ButtonNews from "../../_components/Button/ButtonNews";
import FooterNews from "../../_components/FooterNews/FooterNews";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { HeaderNews } from "../../_components/Header";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import { convertTimePackage, validatePrice } from "../../_base/Validate";
import { HashLink } from "react-router-hash-link";
import { hasDomainStore } from "../../_base/Validate";
import LazyLoad from "react-lazyload";

const FeePage = () => {
  let { listPackages, handleNavigatePayment } = feePageLogic();

  const renderListPackageContent = (packageItems, item, index) => {
    const packages = packageItems?.info?.split("\n");

    let isLastItem =
      item.startsWith("-") && !packages[index + 1].startsWith("-");
    return (
      <div
        key={index}
        className="pos_rel flex-all-start col-100 text-align-justify"
        style={{
          // marginBottom: item.startsWith("-") ? "0.5rem" : "1rem",
          paddingBottom: !item.startsWith("-")
            ? item.trim().endsWith(":")
              ? "0.3rem"
              : "0.6rem"
            : isLastItem
            ? "0.6rem"
            : "0.1rem",
          paddingTop: item.startsWith("-") ? "0.3rem" : "0.6rem",
          borderTop: !item.startsWith("-")
            ? index == 0
              ? "none"
              : "1px solid rgba(0 , 0, 0,  0.1)"
            : "none",
          paddingLeft: item.startsWith("-") && "1.5rem",
        }}
      >
        <div
          style={{
            fontSize: "1rem",
            marginLeft: !item.startsWith("-") && "0.85rem",
          }}
          className={`pos_rel fz-1-mobile item_detail_fee col-87-5 col-80-mobile ${
            !item.startsWith("-") && "item_dot_lozenge_black"
          }`}
        >
          {item}
        </div>
        {!item.startsWith("-") && (
          <img
            src={`/assets/images/icon/${
              packageItems.package_type_id == 1
                ? "ico_tick_detail_orange"
                : "ico_tick_detail"
            }.png`}
            alt="Icon Tick"
            style={{}}
            className=" ico_tick_detail"
          />
        )}
      </div>
    );
  };

  const renderListPackages = (item) => {
    return (
      <div
        key={item.id}
        className="flex-center-column box-shadow package_item border_radius_1"
        style={{
          border: item.package_type_id == 1 && "2px solid #F3BB44",
          paddingBottom: item.package_type_id == 1 && "calc(1.5rem - 2px)",
        }}
      >
        <div
          className="flex-center-column package_item_header"
          style={{
            backgroundColor:
              item?.package_type_id == 1
                ? "#F3BB44"
                : item?.package_type_id == 2
                ? "#77badc"
                : "#ACD578",
            paddingTop: item?.package_type_id == 1 && "calc(1rem - 2px)",
            paddingTop: item?.package_type_id == 1 && "calc(1rem - 2px)",
          }}
        >
          <div
            className={`color-white fz-12-5-mobile fz-13-75 font_news_bold font_news_bold`}
          >
            {item.package_name.toUpperCase()}{" "}
            {item.package_type_id == 1 && (
              <img
                src="/assets/images/icon/ico_white_star.png"
                alt="Icon White Star"
                className="contain_image ico_white_star"
              />
            )}
          </div>

          {/* <div className={`color-white fz-1-mobile fz-12-5 font_news_italic`}>
            {item.duration ? convertTimePackage(item?.duration) : "(Trọn đời)"}
          </div> */}
        </div>
        <div
          className="flex-column package_item_content"
          style={{
            paddingLeft: item.package_type_id == 1 && "calc(5% - 2px)",
            paddingRight: item.package_type_id == 1 && "calc(5% - 2px)",
          }}
        >
          {item?.info
            ?.split("\n")
            .map((info, index) => renderListPackageContent(item, info, index))}
        </div>

        <div className="flex-center-column mg-bottom-1 pd-top-15-pc pd-top-1 ">
          <div className=" text_through fz-1-mobile fz-12-5 pos_rel font_news_italic">
            {item.origin_price == item.cur_price
              ? ""
              : validatePrice(item.origin_price)}
            {item.origin_price != item.cur_price ? (
              <span className="original_price_item_unit  text_underline font_news_italic">
                đ
              </span>
            ) : (
              <br />
            )}
          </div>

          <div className="color-red font_news_bold fz-20-mobile fz-25 pos_rel current_price_item">
            {validatePrice(item.cur_price)}
            <span className="fz-15-mobile fz-18-75 color-red  current_price_item_unit text_underline font_news_bold">
              đ
            </span>
          </div>
        </div>

        {!hasDomainStore() && (
          <ButtonNews
            width="15rem"
            height="3.5rem"
            border={"none"}
            backgroundColor={"#EB5757"}
            // boxShadow={"2px 2px 5px white, 0 0 2px black"}
            onClick={() => handleNavigatePayment(item)}
            maxWidth={"55%"}
            maxHeight={"7vw"}
          >
            <div className="color-white fz-15 font_news_bold">MUA NGAY</div>
          </ButtonNews>
        )}
      </div>
    );
  };

  return (
    <div className="feePage commondPage">
      <HeaderNews type={TypeHeaderNewsItem.FEE} />
      {/* <TitleNews title={NameTitleNewsItem.FEE} /> */}

      <LazyLoad once className="slider">
        <img
          src="/assets/images/feeNews/slider_bg.png"
          alt="Slider Fee"
          className="slider_bg contain_image display_none_mobile"
        />
        <img
          src="/assets/images/feeNews/slider_bg_mobile.png"
          alt="Slider Fee"
          className="slider_bg contain_image hide display_block_mobile"
        />
      </LazyLoad>

      <div className="content flex-center flex-wrap">
        <LazyLoad
          once
          className="content_bg flex-center-column"
          style={{
            background: `url(
              /assets/images/feeNews/bg_content.png
            ) no-repeat top center /cover`,
          }}
        >
          <div
            id="list_packages"
            className="list_packages flex-jus-evenly flex-wrap"
          >
            {listPackages.map((item) => renderListPackages(item))}
          </div>
        </LazyLoad>

        {/* <div className=" text_footer_content text-align-center ">
          <HashLink
            className="pointer_cursor display_none_mobile  color-primary-green text_underline fz-16 font_news_bold"
            to={`/${TypeHeaderNewsItem.FEE}/policy`}
          >
            Hình thức thanh toán - Chính sách đổi trả và hoàn tiền
          </HashLink>
          <span className="hide display_block_mobile">
            <HashLink
              className=" pointer_cursor  color-primary-green text_underline fz-11-5-mobile font_news_bold"
              to={`/${TypeHeaderNewsItem.FEE}/policy`}
            >
              Hình thức thanh toán
              <br />
              Chính sách đổi trả và hoàn tiền
            </HashLink>
          </span>
        </div> */}
      </div>

      <FooterNews />
      <ScrollFixed />
    </div>
  );
};

export default FeePage;
