import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CalendaSchedule } from "../../_components/Calendar";
import { teacherActions, scheduleActions } from "../../_actions";
import { SliderClass, EmptyClassSlider } from "../../_components/Admin/Teacher";
import {
  EmptyScheduleToday,
  ScheduleToday,
} from "../../_components/Admin/More/Schedule";
import { isEmpty } from "lodash";
import { parse } from "query-string";
import { useHistory } from "react-router-dom";
import $ from "jquery";

function HomePageTeacher() {
  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useSelector((state) => state.classes);
  const schedules = useSelector((state) => state.schedules);
  const authentication = useSelector((state) => state.authentication);
  const [isLoadingClasses, setLoadingClasses] = useState(false);
  const [isLoadingSchedules, setLoadingSchedules] = useState(false);
  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoadingClasses(isLoading);
  }, [classes]);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoadingSchedules(isLoading);
  }, [schedules]);

  const pageParams = useMemo(() => {
    return history?.location?.search ? parse(history.location.search) : null;
  }, [history.location.search]);

  const [selectedDate, setSelectedDate] = useState(null);
  useEffect(() => {
    setLoadingSchedules(true);
    setLoadingClasses(true);
    dispatch(teacherActions.getListClass());

    if (pageParams?.date) {
      dispatch(
        scheduleActions.getScheduleToday(authentication.id, pageParams?.date)
      );
    } else if (schedules.dateSelectedCalendar) {
      dispatch(
        scheduleActions.getScheduleToday(
          authentication.id,
          schedules.dateSelectedCalendar
        )
      );
    } else {
      dispatch(scheduleActions.getScheduleToday(authentication.id));
    }
  }, []);

  function changeDateCalendaSchedule(date) {
    // console.log(date);
    setSelectedDate(
      date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day
    );
    dispatch(
      scheduleActions.getScheduleToday(
        authentication.id,
        date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day
      )
    );
  }

  let componentClass = isEmpty(classes.data) ? (
    !isLoadingClasses && <EmptyClassSlider />
  ) : (
    <SliderClass role={authentication.role} classes={classes} />
  );
  let componentScheduleToday = isEmpty(schedules.today) ? (
    <EmptyScheduleToday isButtonTKH selectedDate={selectedDate} />
  ) : (
    <ScheduleToday
      page="homepage"
      schedules={schedules.today}
      selectedDate={selectedDate}
    />
  );

  return (
    <div className="sunE-right-container scrollbar-custom">
      <div className="sunE-container-box homepage-teacher p_responsive_home_page_teacher">
        <div className="flex-m">
          <div className="flex-1 sunE-calendar height_calendar_responsive">
            <div className="calendar box-shadow">
              <CalendaSchedule
                changeDateCalendaSchedule={(date) =>
                  changeDateCalendaSchedule(date)
                }
                showAll
                homePageTeacherCalendaSchedule={true}
              />
            </div>
          </div>
          {componentScheduleToday}
        </div>
        <div className="sunE-box-slider mb_mt_5px">{componentClass}</div>
      </div>
    </div>
  );
}

export { HomePageTeacher };
