import React from "react";
import { useState } from "react";
import { ReviewExam } from "./ReviewExam";
import "./styles.scss";

const formatTwoNumber = (num) => {
  return num >= 10 ? num : "0" + num;
};

const ResultExam = (props) => {
  const {
    dataResult,
    onClose,
    listQuestions,
    examId,
    resultExam,
    typeMockTest,
    handleGoBackNews,
    examInfo,
  } = props;

  const [isShowReview, showReview] = useState(false);

  return (
    <div id="c-result-exam">
      <div className="header-result mg-bottom-1">
        <h1>Kiểm tra</h1>
        <div className="mg-top-1 fz-17 text-align-center">
          {examInfo?.name || examInfo?.exam_name}
        </div>
      </div>

      <div className="result-score">
        <div className="score">
          <div className="title">Điểm</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span className="number-score">{dataResult?.score}</span>
            <img
              src="/assets/images/underline-score.png"
              alt="img_no_class_big"
            />
          </div>
        </div>
        <div className="note">
          <div className="title">Lời phê giáo viên</div>
          <span className="text-note">{dataResult.comment}</span>
        </div>
      </div>
      <div className="result-info-exam">
        <div className="d-flex item-info">
          <span className="text">Thời gian hoàn thành:</span>
          <span className="info">
            {formatTwoNumber(parseInt(dataResult?.duration / 60))}:
            {formatTwoNumber(dataResult?.duration % 60)}
          </span>
        </div>
        <div className="d-flex item-info">
          <span className="text">Số câu trả lời đúng:</span>
          <span className="info">
            {dataResult?.number_true}/
            {dataResult?.number_true + dataResult?.number_false}
          </span>
        </div>
      </div>
      <div className="result-action">
        <button
          style={{ width: 150 }}
          className="btn-action"
          onClick={() => showReview(true)}
        >
          Xem bài chữa
        </button>
        <button
          style={{ width: 150 }}
          className="btn-action"
          onClick={typeMockTest == "experience" ? handleGoBackNews : onClose}
        >
          Trở về
        </button>
      </div>
      <ReviewExam
        examInfo={examInfo}
        isShow={isShowReview}
        onClose={() => showReview(false)}
        examId={examId}
        listQuestions={listQuestions}
        resultExam={resultExam}
      />
    </div>
  );
};

export default React.memo(ResultExam);
