const API = {
  baseURL: "https://oauth2.sundayenglish.com/",
  get_packages: "api/package",
  get_packages_teacher: "api/package/package_teacher",
  payment_history: "api/web_advertise/history_payment",
  register_teacher: "api/teacher/register",
  verify_resgiter_teacher: "api/teacher/verify_auth",
  verify_register_teacher_by_zalo: "api/teacher/verify_auth",
  logout_account_service: "https://service.sundayenglish.com/login/logout?logout_app_id=VK7JG-NPHTM-C97JM-9MPGT-3V66T",
  logout_account_exercise: "https://exercise.sundayenglish.com/account/logout?logout_app_id=VK7JG-NPHTM-C97JM-9MPGT-3V66T"
};

export default API;
