import { useState, useEffect } from "react";

export const benefitTeacherLogic = () => {
  const [dataContentTeacher, setDataContentTeacher] = useState([
    {
      id: 1,
      outstanding: "HÀNG NGHÌN",
      content: "bài tập bám sát chương trình Sách Giáo Khoa",
      items: [
        {
          id: 1,
          name: "Bài tập được xây dựng với các cấp độ từ dễ đến khó phù hợp với học lực của từng học sinh",
        },
        {
          id: 2,
          name: "Trên 50 dạng bài kèm lời giải chi tiết",
        },
        {
          id: 3,
          name: "Chức năng gợi ý làm bài giúp học sinh hiểu bài nhanh hơn",
        },
        {
          id: 4,
          name: "Có giáo trình riêng cho học sinh mất gốc",
        },
      ],
    },
    {
      id: 2,
      outstanding: "CÔNG CỤ",
      content: "giao bài tập về nhà",
      items: [
        {
          id: 1,
          name: "Giao bài theo học lực của từng học sinh",
        },
        {
          id: 2,
          name: "Giao bài ôn tập, luyện thi theo chương trình SGK",
        },
        {
          id: 3,
          name: "Giao bài tập bổ trợ cho học sinh giỏi",
        },
        {
          id: 4,
          name: "Giao bài, kèm cặp học sinh yếu",
        },
        {
          id: 5,
          name: "Công cụ trộn kiến thức giúp học sinh khám phá kiến thức mới bên cạnh việc ôn luyện hiệu quả kiến thức cũ",
        },
      ],
    },
    {
      id: 3,
      outstanding: "ĐỔI MỚI",
      content: "phương pháp giảng dạy, tăng tính hấp dẫn cho bài giảng",
      items: [
        {
          id: 1,
          name: "Công cụ tổ chức các hoạt động trên lớp: Warm up, Group work, Pair work, Lead-in, Practice",
        },
        {
          id: 2,
          name: "Công cụ thiết kế bài kiểm tra: Miệng, 15 phút, 45 phút",
        },
      ],
    },
    {
      id: 4,
      outstanding: "CÁ NHÂN HÓA",
      content: "giáo trình giảng dạy",
      items: [
        {
          id: 1,
          name: "Biên soạn, đăng tải giáo trình, bài tập, tài liệu lên hệ thống",
        },
        {
          id: 2,
          name: "Tùy chỉnh bộ giáo trình có sẵn của Sunday English để sử dụng cho từng lớp học",
        },
      ],
    },
    // {
    //   id: 4,
    //   outstanding: "GIẢM TẢI",
    //   content: "công sức chấm chữa bài nhờ công cụ chấm chữa tự động",
    // },
    // {
    //   id: 5,
    //   outstanding: "CÔNG CỤ",
    //   content: "điểm danh, tạo phiếu điểm, xem báo cáo học tập của học sinh",
    // },
    // {
    //   id: 6,
    //   outstanding: "QUẢN LÝ",
    //   content:
    //     "công việc giảng dạy bằng công cụ tạo thời khóa biểu và lịch làm việc",
    // },
  ]);

  // Data assistant
  const dataDetailAssistant = [
    {
      id: 0,
      name: "Giúp Thầy cô đào tạo không giới hạn số lớp, số học sinh",
    },
    {
      id: 1,
      name: "Công cụ gợi ý làm bài giúp học sinh hiểu bài nhanh hơn",
    },
    {
      id: 2,
      name: "Công cụ Tự động chấm bài, chữa bài với lời giải chi tiết",
    },
    {
      id: 3,
      name: "Công cụ tự động giao bài với thuật toán tiên tiến giúp xác định chính xác điểm yếu, điểm quên của học sinh qua đó giúp học sinh tiến bộ nhanh hơn",
    },
    {
      id: 4,
      name: "Không còn học sinh mất gốc, có nhiều học sinh Giỏi hơn",
    },
    {
      id: 5,
      name: "Giúp học sinh đánh bay nỗi sợ học Tiếng Anh",
    },
    {
      id: 6,
      name: "Với Sunday English, Thầy cô có thể biết được chính xác học lực, điểm mạnh, điểm yếu của từng học sinh tới từng kỹ năng riêng biệt, qua đó có thể giao bài tập hoặc lên giáo án giảng dạy hiệu quả nhằm đạt được kết quả cao trong đào tạo",
    },
  ];

  // Handle Reverse Mobile
  const handleReverseMobile = (number) => {
    if (number == 1) {
      return false;
    } else {
      return number % 2 != 0;
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return {
    dataContentTeacher,
    dataDetailAssistant,
    handleReverseMobile,
  };
};
