import { useEffect } from "react";

export const deleteAccountLogic = (props) => {
  const dataStepsDelete = [
    {
      id: 1,
      title: "Truy cập App Sunday English",
      detail:
        "Trước tiên, bạn cần truy cập và đăng nhập vào App Sunday English",
      srcImg: "step_1",
    },
    {
      id: 2,
      title: "Mở mục Thêm",
      detail:
        "Bước tiếp theo, nhấn mở mục Thêm bên dưới phần Bottom Tab của App",
      srcImg: "step_2",
    },
    {
      id: 3,
      title: "Truy cập phần Cài đặt",
      detail: "Sau đó, truy cập phần Cài đặt bên trong mục Thêm",
      srcImg: "step_3",
    },
    {
      id: 4,
      title: "Nhấn và xác nhận chắc chắn xóa tài khoản",
      detail:
        "Cuối cùng, người dùng thấy mục Xóa tài khoản bên dưới phần Cài đặt. Nhấn xóa sẽ hiện popup xác nhận xóa tài khoản. Bạn đồng ý chắc chắn xóa tài khoản thì mọi thông tin và dữ liệu sẽ được xóa bỏ hoàn toàn!",
      srcImg: "step_4",
    },
  ];

  // Scroll To View
  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      var ids = hash.split("#");
      for (var i = 1; i < ids.length; i++) {
        var element = document.getElementById(ids[i]);
        if (element) {
          element.scrollIntoView();
        }
      }
    } else {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [window.location]);

  return {
    dataStepsDelete,
  };
};
