import React, { useState } from 'react';


function SliderItem() {
    const [showDesc, setShowDesc] = useState(false);

    function showDescFunc() {
        setShowDesc(true);
    }

    function hideDescFunc() {
        setShowDesc(false);
    }

    return (
        <li>
            <div className="box-shadow box-white-content text-center slider-item-content">
                <div className={showDesc ? ' hide' : ''}>
                    <div className="item-top-title bg-yellow">
                        <p className="text-light">Tiếng Anh lớp 8 - PRO 1</p>
                    </div>
                    <img alt="details" src="/assets/images/details.png" className="details-ig"/>
                    <div className="item-price">
                        <p>1.200.000đ</p>
                    </div>
                    <div className="item-time">
                        <div className=" flex-m">
                            <div className="flex-1">
                                <p>Thời hạn:</p>
                            </div>
                            <div className="flex-1 text-right">
                                <span>3 tháng</span>
                            </div>
                        </div>
                        <div className=" flex-m">
                            <div className="flex-1">
                                <p>Tặng thêm:</p>
                            </div>
                            <div className="flex-1 text-right">
                                <span className="text-red">+ 2 tháng</span>
                            </div>
                        </div>
                    </div>
                    <div className="btn-show-hide rel">
                        <span className="btn-desc" onClick={showDescFunc}><img alt="dropdown" src="/assets/images/icon/ico_dropdown_blue.png" /></span>
                    </div>
                </div>
                <div className={'info '+ (showDesc ? '' : ' hide')}>
                    <div className="item-top-title">
                        <p className="text-light">Tiếng Anh lớp 8 - PRO 1</p>
                    </div>
                    <div className="list-info">
                        <p className="info-list-title">Mua gói VIP 1 để sở hữu gói học tập 5  tháng bao gồm:</p>
                        <div className="flex-m item-list">
                            <div className="item-list-img">
                                <img alt="checked" src="/assets/images/icon/ico_checked.png" />
                            </div>
                            <div className="flex-1">
                                <p>Toàn bộ 60 video bài giảng và hơn 300 bài tập thuộc tất cả các kỹ năng.</p>
                            </div>
                        </div>
                        <div className="flex-m item-list">
                            <div className="item-list-img">
                                <img alt="checked" src="/assets/images/icon/ico_checked.png" />
                            </div>
                            <div className="flex-1">
                                <p>Thi thử online với ngân hàng đề thi lên tới 5.500 câu.</p>
                            </div>
                        </div>
                        <div className="flex-m item-list">
                            <div className="item-list-img">
                                <img alt="checked" src="/assets/images/icon/ico_checked.png" />
                            </div>
                            <div className="flex-1">
                                <p>Chế độ học tập “IMaster” – cải thiện điểm yếu và ôn tập những kiến thức đã học</p>
                            </div>
                        </div>
                        <div className="flex-m item-list">
                            <div className="item-list-img">
                                <img alt="checked" src="/assets/images/icon/ico_checked.png" />
                            </div>
                            <div className="flex-1">
                                <p>Học theo lộ trình học tập cá nhân</p>
                            </div>
                        </div>
                    </div>
                    <div className="btn-show-hide rel">
                        <span className="btn-desc" onClick={hideDescFunc}><img alt="dropdown" src="/assets/images/icon/ico_dropdown_blue.png" /></span>
                    </div>
                </div>
            </div>
        </li>
    );
}

export { SliderItem };