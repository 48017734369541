import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import moment from "moment";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { teacherService } from "../../../_services";
import { popupConstants, teacherConstants } from "../../../_constants";
import { roundNumber } from "../../../_base/Validate";

function ViewHomeWork() {
  const { id, exercise_id, library, status } = useParams();
  const dispatch = useDispatch();
  const homeworks = useSelector((state) => state.classes.homeworks);
  const authentication = useSelector((state) => state.authentication);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    teacherService
      .getDetailHomeWork(id, exercise_id, library, status)
      .then((homeworks) => {
        if (homeworks) {
          dispatch({
            type: teacherConstants.GET_DETAIL_HOMEWORK,
            homeworks: homeworks,
          });
          dispatch({
            type: popupConstants.CLEAR_ALL_POPUP,
          });
        }
        setLoading(false);
      });

    return () => {
      dispatch({
        type: teacherConstants.GET_DETAIL_HOMEWORK,
        homeworks: {},
      });
    };
  }, []);
  const [type, setType] = useState(status);

  function changeType(type) {
    setType(type);
    setLoading(true);
    teacherService
      .getDetailHomeWork(id, exercise_id, library, type)
      .then((homeworks) => {
        if (homeworks) {
          dispatch({
            type: teacherConstants.GET_DETAIL_HOMEWORK,
            homeworks: homeworks,
          });
          dispatch({
            type: popupConstants.CLEAR_ALL_POPUP,
          });
        }
        setLoading(false);
      });
    // dispatch(teacherActions.getDetailHomeWork(id, exercise_id, library, type));
  }

  const history = useHistory();

  const totalHomeWork =
  parseInt(homeworks.detail.total_mark) +
  parseInt(homeworks.detail.total_not_mark);
  return (
    <div className="sunE-right-container chambai flex-column pos_rel">
      <Header
        title={"Chấm bài"}
        isBack
        clickBack={() =>
          history.push(
            "/" + authentication.role + "/class/view/" + id + "/homework"
          )
        }
      />
      {totalHomeWork > 0 ? (
        <div className="flex-1 chambai writing pos_rel">
          <div className="topic box-shadow">
            <h2>
              <span className="bold">Tên bài:</span>{" "}
              {!isLoading && homeworks.detail.exercise_name}
            </h2>
          </div>
          <div className="gtcn-menu-gr cb">
            <span
              className={"" + (parseInt(type) === 1 ? " active" : "")}
              onClick={() => changeType(1)}
            >
              Đã chấm ({homeworks.detail.total_mark})
            </span>
            <span
              className={"" + (parseInt(type) === 0 ? " active" : "")}
              onClick={() => changeType(0)}
            >
              Chưa chấm ({homeworks.detail.total_not_mark})
            </span>
          </div>
          {!isLoading && (
            <div className="chambai-list-detail flex-m">
              <div className="flex-3">
                <div className="list-detail scrollbar-custom">
                  {homeworks.detail.data.map((data, i) => {
                    return (
                      <Link
                        to={
                          "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/homework/" +
                          data.exercise_id +
                          "/" +
                          library +
                          "/" +
                          type +
                          "/edit/" +
                          data.user_exercise_id +
                          "/" +
                          data.exercise_type
                        }
                        key={i}
                      >
                        <div className="item-student flex-m box-shadow rel">
                          <div className="item-student-img">
                            <img
                              src={homeworks.detail.base_url + data.to_avatar}
                              alt="ico_avt_detail"
                            />
                          </div>
                          <div className="flex-1 item-student-name pl-15">
                            <h2 className="semibold">{data.to_fullname}</h2>
                            <div className="flex-m">
                              <div>
                                <img
                                  src="/assets/images/chambai/ico_clock_orange.png"
                                  alt="ico_clock_orange"
                                />
                              </div>
                              <span className="flex-1">
                                Nộp bài:{" "}
                                {!isEmpty(data.create_submit_time) &&
                                  moment(data.create_submit_time).format(
                                    "HH:mm, DD/MM/YYYY"
                                  )}
                              </span>
                            </div>
                          </div>
                          {parseInt(type) === 1 && (
                            <div className="score">
                              <span>
                                <span className="f24 bold">
                                  {roundNumber(data.score, 1)}
                                </span>{" "}
                                Điểm
                              </span>
                            </div>
                          )}
                        </div>
                      </Link>
                    );
                  })}
                  {homeworks.detail.data.length === 0 && (
                    <div className="__horizontal_center __horizontal_center_over">
                      <img
                        src="/assets/images/empty_confirm_excercise.png"
                        alt=""
                      />
                      <div className="__text_empty __semibold">
                        {parseInt(type) === 1
                          ? "Chưa có bài nào được chấm"
                          : "Tuyệt vời! Đã chấm hết bài cần chấm."}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-2 center-flex">
                {!(type === 0) &&
                  parseInt(type) === 1 &&
                  homeworks.detail.total_mark > 0 && (
                    <div className="cb-score-gr flex-m">
                      <div className="flex-1 text-center bd-r-blue">
                        <p>Điểm cao nhất:</p>
                        <span>
                          {roundNumber(homeworks.detail.max_score, 1)}
                        </span>
                      </div>
                      <div className="flex-1 text-center">
                        <p>Điểm thấp nhất:</p>
                        <span>
                          {roundNumber(homeworks.detail.min_score, 1)}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="__horizontal_center __horizontal_center_over">
          <img src="/assets/images/empty_confirm_excercise.png" alt="" />
          <div className="__text_empty">Chưa có bài nào cần chấm</div>
        </div>
      )}
    </div>
  );
}

export { ViewHomeWork };
