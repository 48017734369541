import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { userConstants } from "../../../_constants";
import { useHistory } from "react-router-dom";
import "./SideBar.styles.scss";

function SideBar() {
  const history = useHistory();
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const page = params.get("page");
  const authentication = useSelector((state) => state.authentication);
  const chooseListCurriculum = useSelector((state) => state.rootlessness.chooseListCurriculum)
  const assign = params.get("assign");

  var isActiveHome =
    window.location.pathname.indexOf("student/homework_by_teacher") !== -1 ||
    window.location.pathname.indexOf("teacher/more/schedule/edit/homepage") !==
      -1 ||
    window.location.pathname.indexOf("teacher/more/schedule/add/homepage") !==
      -1 ||
    page === "homepage";

  return (
    <div className="sunE-sidebar">
      <div className="sunE-logo text-center">
        <NavLink
          to={`/${authentication.role}`}
          className={
            chooseListCurriculum && authentication.last_login == null
              ? "not_allowed_first_choose_curriculum"
              : ""
          }
          onClick={
            chooseListCurriculum && authentication.last_login == null
              ? (e) => e.preventDefault()
              : () => {}
          }
        >
          <img alt="Logo" src="/assets/images/logo.png" />
        </NavLink>
      </div>
      <div className="list-menu-i">
        <NavLink
          exact
          activeClassName="active"
          to={"/" + authentication.role}
          className={
            chooseListCurriculum && authentication.last_login == null
              ? "not_allowed_first_choose_curriculum flex menu-item"
              : "flex menu-item" + (isActiveHome ? " active" : "")
          }
          onClick={
            chooseListCurriculum && authentication.last_login == null
              ? (e) => e.preventDefault()
              : () => {}
          }
        >
          <div className="menu-img">
            <img
              alt="ico_home"
              src="/assets/images/icon/ico_home.png"
              className="ico_default"
            />
            <img
              alt="ico_home_active"
              src="/assets/images/icon/ico_home_active.png"
              className="ico_active"
            />
          </div>
          <span className="flex-1">Trang chủ</span>
        </NavLink>
        {authentication.role === userConstants.ROLE_TEACHER && (
          <NavLink
            activeClassName={(!isActiveHome && page != "morepage" && page!="curriculum") ? "active" : ""}
            to={"/" + authentication.role + "/class"}
            className={`flex menu-item ${page == "classmanager" ? "active" : "" || assign == "capacity" ? "active" : ""}`}
          >
            <div className="menu-img">
              <img
                alt="ico_lop"
                src="/assets/images/icon/ico_lop.png"
                className="ico_default"
              />
              <img
                alt="ico_lop"
                src="/assets/images/icon/ico_lop_active.png"
                className="ico_active"
              />
            </div>
            <span className="flex-1">Quản lý lớp</span>
          </NavLink>
        )}
        <NavLink
          activeClassName={!isActiveHome && page != "morepage" && page != "classmanager"  && assign != "capacity" ? "active" : ""}
          to={"/" + authentication.role + "/curriculum"}
          className={
            chooseListCurriculum && authentication.last_login == null
              ? "not_allowed_curriculum flex menu-item"
              : `flex menu-item ${page == "curriculum" ? "active" : ""}`
          }
          onClick={
            chooseListCurriculum && authentication.last_login == null
              ? (e) => e.preventDefault()
              : () => {}
          }
        >
          <div className="menu-img">
            <img
              alt="ico_book"
              src="/assets/images/icon/ico_book.png"
              className="ico_default"
            />
            <img
              alt="ico_book"
              src="/assets/images/icon/ico_book_active.png"
              className="ico_active"
            />
          </div>
          <span className="flex-1">Giáo trình</span>
        </NavLink>
        {authentication.role === userConstants.ROLE_STUDENT && (
          <NavLink
            activeClassName="active"
            to={"/" + authentication.role + "/exam-test"}
            className={
              chooseListCurriculum && authentication.last_login == null
                ? "not_allowed_first_choose_curriculum flex menu-item"
                : "flex menu-item"
            }
            onClick={
              chooseListCurriculum && authentication.last_login == null
                ? (e) => e.preventDefault()
                : () => {}
            }
          >
            <div className="menu-img">
              <img
                alt="ico_luyenthi"
                src="/assets/images/icon/ico_luyenthi.png"
                className="ico_default"
              />
              <img
                alt="ico_luyenthi"
                src="/assets/images/icon/ico_luyenthi_active.png"
                className="ico_active"
              />
            </div>
            <span className="flex-1">Luyện thi</span>
          </NavLink>
        )}
        {authentication.role === userConstants.ROLE_STUDENT && (
          <NavLink
            activeClassName="active"
            to={"/" + authentication.role + "/assessment"}
            className={
              chooseListCurriculum && authentication.last_login == null
                ? "not_allowed_first_choose_curriculum flex menu-item"
                : "flex menu-item"
            }
            onClick={
              chooseListCurriculum && authentication.last_login == null
                ? (e) => e.preventDefault()
                : () => {}
            }
          >
            <div className="menu-img">
              <img
                alt="ico_thanhtich"
                src="/assets/images/icon/ico_thanhtich.png"
                className="ico_default"
              />
              <img
                alt="ico_thanhtich"
                src="/assets/images/icon/ico_thanhtich_active.png"
                className="ico_active"
              />
            </div>
            <span className="flex-1">Thành tích</span>
          </NavLink>
        )}
        {authentication.role === userConstants.ROLE_TEACHER && (
          <NavLink
            activeClassName="active"
            to={"/" + authentication.role + "/message"}
            className="flex menu-item"
          >
            <div className="menu-img">
              <img
                alt="ico_message"
                src="/assets/images/icon/ico_message.png"
                className="ico_default"
              />
              <img
                alt="ico_message"
                src="/assets/images/icon/ico_message_active.png"
                className="ico_active"
              />
            </div>
            <span className="flex-1">Tin nhắn</span>
          </NavLink>
        )}
        <NavLink
          activeClassName={!isActiveHome && page != "curriculum" ? "active" : ""}
          to={"/" + authentication.role + "/more"}
          className={
            chooseListCurriculum && authentication.last_login == null
              ? "not_allowed_first_choose_curriculum flex menu-item"
              : `flex menu-item ${page == "morepage" ? "active" : ""}`
          }
          onClick={
            chooseListCurriculum && authentication.last_login == null
              ? (e) => e.preventDefault()
              : () => {}
          }
        >
          <div className="menu-img">
            <img
              alt="ico_xemthem"
              src="/assets/images/icon/ico_xemthem.png"
              className="ico_default"
            />
            <img
              alt="ico_xemthem"
              src="/assets/images/icon/ico_xemthem_active.png"
              className="ico_active"
            />
          </div>
          <span className="flex-1">Thêm</span>
        </NavLink>
      </div>
    </div>
  );
}

export { SideBar };
