import "./PolicyPage.style.scss";
import { policyPageLogic } from "./PolicyPage.logic";
import {
  renderListPage1,
  renderListPage2,
  renderListContent1,
} from "../../_base/DetailListNews";
import LazyLoad from "react-lazyload";

const Content3 = () => {
  let { dataRule2, dataRule3, dataRule4 } = policyPageLogic();

  return (
    <div id="content3" className="content_3">
      <div className="pd-top-2-pc bg_white">
        <LazyLoad className="pd_8per">
          {dataRule2?.dataPage3.map((data) => renderListPage2(data))}
        </LazyLoad>
        <div className=" flex-center col-100 mg-top-2">
          <div className="border_bottom col-80"></div>
        </div>
      </div>

      <div className="transparent_side"></div>

      {/* RULE 3 */}
      <div className=" pd-top-2-pc bg_white">
        <div className="pd_8per flex-align bg_white">
          <img
            src="/assets/images/introNews/icon/ico_rule_3.png"
            alt="Icon Rule 3"
            className="contain_image img_intro_item"
          />
          <span
            id="rule3"
            className="font_news_bold fz-12-5-mobile fz-18-75 mg-left-1"
          >
            {dataRule3?.nameRule}
          </span>
        </div>

        <LazyLoad className="mg-top-15 bg_white mg-top-1-mobile">
          {dataRule3?.data?.map((data) => renderListPage1(data))}
        </LazyLoad>

        <div className=" flex-center col-100 mg-top-2 bg_white mg-top-15-mobile">
          <div className="border_bottom col-80 bg_white"></div>
        </div>
      </div>
      {/* RULE 4 */}
      <div className=" pd-top-2-pc bg_white">
        <div id="rule4" className="flex-align pd_8per">
          <img
            src="/assets/images/introNews/icon/ico_rule_4.png"
            alt="Icon Rule 4"
            className="contain_image img_intro_item"
          />
          <span className="font_news_bold  fz-12-5-mobile fz-20 mg-left-1">
            {dataRule4?.nameRule}
          </span>
        </div>

        <LazyLoad className="mg-top-15 pd-bottom-2 ">
          {dataRule4?.data?.map((data) => renderListPage1(data))}
        </LazyLoad>
      </div>
    </div>
  );
};

export default Content3;
