import "./PolicyPage.style.scss";
import { policyPageLogic } from "./PolicyPage.logic";
import { renderListPage1, renderListPage2 } from "../../_base/DetailListNews";
import LazyLoad from "react-lazyload";

const Content2 = () => {
  let { dataRule1, dataRule2 } = policyPageLogic();

  return (
    <div id="content2" className="content_2">
      <div className="text-align-justify  bg_white pd-top-2-pc pd-top-1">
        {dataRule1?.dataPage2?.map((data) => renderListPage1(data))}
        <div className=" flex-center col-100 mg-top-2">
          <div className="border_bottom col-80 bg_white"></div>
        </div>
      </div>
      <div className="transparent_side"></div>
      <div className="flex-column bg_white pd_8per">
        <div className=" pd-top-2-pc pd-top-1 flex-align bg_white">
          <img
            src="/assets/images/introNews/icon/ico_rule_2.png"
            alt="Icon Rule 2"
            className="contain_image img_intro_item"
          />
          <span
            id="rule2"
            className="font_news_bold fz-12-5-mobile fz-18-75 mg-left-1"
          >
            {dataRule2?.nameRule}
          </span>
        </div>

        <div className="text-align-justify fz-1-mobile mg-top-15 fz-12-5 mg-top-0-5-mobile">
          {dataRule2?.title}{" "}
          <span className="font_news_bold fz-12-5 fz-1-mobile">
            {dataRule2?.sideTitle}
          </span>
        </div>

        <div className="text-align-justify  mg-top-15 mg-top-0-5-mobile">
          {dataRule2?.dataPage2?.map((data) => renderListPage2(data))}
        </div>
      </div>
    </div>
  );
};

export default Content2;
