import React, { useState, Fragment, useEffect } from "react";
import "./style.css";
import { isEmpty, forEach, result } from "lodash";
import { validateNumber } from "../../_base/Validate";

function PopUpSetCriteria(props) {
  let {
    onClickYes,
    onClickNo,
    criteria,
    exercise_id,
    width,
    exercise_selected,
  } = props;

  let [dataCriteria, setDataCriteria] = useState([]);

  useEffect(() => {
    let list = [];
    criteria.forEach((item) => {
      list.push({
        proportion: item.proportion,
        text: item.text,
      });
    });
    setDataCriteria(list);
  }, [criteria]);

  function remove(key) {
    let list = dataCriteria.filter((item, index) => index !== key);
    setDataCriteria(list);
  }

  function add() {
    let item = {
      proportion: 0,
      text: "",
    };
    setDataCriteria([...dataCriteria, item]);
  }

  function validateParam() {
    let count = countTotalPercent();
    forEach(dataCriteria, function (value) {
      if (isEmpty(value.text)) {
        return false;
      }
    });
    if (count !== 100) {
      return false;
    }
    return true;
  }

  function countTotalPercent() {
    let count = 0;
    forEach(dataCriteria, function (value) {
      count += parseInt(value.proportion);
    });
    return count;
  }

  function handleChange(e, key) {
    const { name, value } = e.target;
    let criterias = [];
    dataCriteria.forEach((item, index) => {
      if (index === key) {
        let data = {
          proportion: item.proportion,
          text: item.text,
          score: item.score,
        };
        if (name === "text") {
          data.text = value;
        } else {
          data.proportion = validateNumber(value);
        }
        criterias.push(data);
      } else {
        criterias.push(item);
      }
    });
    // dataCriteria[key][name] = value;
    setDataCriteria(criterias);
  }

  const handleChangeProportion = (e, key) => {
    const { value } = e.target;
    let criterias = [];
    dataCriteria.forEach((item, index) => {
      if (index === key) {
        let data = {
          proportion: item.proportion,
          text: item.text,
          score: item.score,
        };
        const regex = /^[0-9]+$/;
        if (regex.test(value)) {
          data.proportion = value == "%" ? 0 : validateNumber(value);
          data.proportion = value == "" ? 0 : validateNumber(value);
          criterias.push(data);
        } else {
          data.proportion = 0;
          criterias.push(data);
        }
      } else {
        criterias.push(item);
      }
    });
    // dataCriteria[key][name] = value;
    setDataCriteria(criterias);
  };

  let [DataExerciseId, setDataExerciseId] = useState(exercise_selected);

  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
        style={{ width: width }}
      >
        <div className="flex-m">
          <div className="flex-1 flex-m t-title-w">
            <div className="flex-1">
              <p>Tiêu chí</p>
            </div>
            <div className="text-center r-80">
              <p>Tỷ trọng</p>
            </div>
          </div>
          <div className="rm-20"></div>
        </div>
        <div className="tc-list">
          <div className="tc-content">
            {dataCriteria.map((item, i) => {
              return (
                <div className="flex-m tc-item rel" key={i}>
                  <div className="flex-1">
                    <input
                      style={{ color: "#000" }}
                      type="text"
                      placeholder="Tên tiêu chí"
                      name="text"
                      value={item.text}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </div>
                  <div className="text-center r-100">
                    <input
                      style={{ color: "#000" }}
                      type="text"
                      name="proportion"
                      value={
                        item.text != "" &&
                        item.proportion != 0 &&
                        item.proportion > 0
                          ? `${item.proportion}%`
                          : undefined
                      }
                      placeholder={!item.text && `${item.proportion}%`}
                      onChange={(e) => handleChangeProportion(e, i)}
                    />
                  </div>
                  <div className="rm-btn">
                    <img
                      src="/assets/images/chambai/ico_remove_small.png"
                      alt="ico_remove_small"
                      onClick={() => remove(i)}
                    />
                  </div>
                </div>
              );
            })}
            <div className="flex-m center-flex btn-add-tc">
              <img
                src="/assets/images/chambai/ico_add_small.png"
                alt="ico_add_small"
                onClick={() => add()}
              />
            </div>
          </div>
        </div>
        <div className="tc-content tc-sum flex-m">
          <div className="flex-1">
            <p>Tổng tỷ trọng</p>
          </div>
          <div className="text-center r-80">
            <p>{countTotalPercent()}%</p>
          </div>
        </div>
        <p className="title-regular-black">
          * Lưu ý: Tổng tỷ trọng cần bằng 100%
        </p>
        <p className="title-regular-black">
          <input
            type="radio"
            name="exercise_id"
            defaultChecked={DataExerciseId ? true : false}
            onChange={() => setDataExerciseId(exercise_id)}
          />{" "}
          Chỉ áp dụng vào bài viết này
        </p>
        <p className="title-regular-black">
          <input
            type="radio"
            name="exercise_id"
            defaultChecked={!DataExerciseId ? true : false}
            onChange={() => setDataExerciseId(0)}
          />{" "}
          Áp dụng cho tất cả các bài viết khác
        </p>
        <div className="flex-m btn-popup-gr">
          <div className="flex-1"></div>
          <button className="btn-default mr-10 wd-c" onClick={onClickNo}>
            Hủy
          </button>
          <button
            className={
              "btn-line-blue wd-c" + (!validateParam() ? " btn-disable" : "")
            }
            onClick={() => onClickYes(dataCriteria, DataExerciseId)}
          >
            Lưu
          </button>
          <div className="flex-1"></div>
        </div>
      </div>
    </div>
  );
}

export { PopUpSetCriteria };
