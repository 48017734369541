import { useState } from "react";
import { useHistory } from "react-router-dom";
import { apiCaller } from "../../../_helpers";
import { useEffect } from "react";
import dayjs from "dayjs";
import {
  validateNumber,
  validatePhoneNumber,
  validateEmail,
} from "../../../_base/Validate";
import { configConstants, userConstants } from "../../../_constants";
import { useSelector } from "react-redux";
import $ from "jquery";
import API from "../../../_apis/APIConstants";
import APIBase from "../../../_base/APIBase";
import { userActions } from "../../../_actions";
import { useDispatch } from "react-redux";

export const registerNewsLogic = (props) => {
  const _dispatch = useDispatch();
  const _history = useHistory();
  const alert = useSelector((state) => state.alert);
  const [disabledBtnRegist, setDisabledBtnRegist] = useState(true);
  const [dataAgents, setDataAgents] = useState([
    {
      id: 1,
      type: "student",
      name: "Học sinh",
    },
    {
      id: 2,
      type: "teacher",
      name: "Giáo viên",
    },
    {
      id: 3,
      type: "parent",
      name: "Phụ huynh",
    },
  ]);
  const [typeAgentSelected, setTypeAgentSelected] = useState("student");

  const dataLoginGoogle = JSON.parse(localStorage.getItem("data_google"));

  // Email
  const [email, setEmail] = useState(dataLoginGoogle?.email || "");
  const [emailParent, setEmailParent] = useState();
  const [password, setPassword] = useState();
  const [cfPassword, setCfPassword] = useState();
  const [emailError, setEmailError] = useState();
  const [emailParentError, setEmailParentError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [cfPasswordError, setCfPasswordError] = useState();
  const [name, setName] = useState(dataLoginGoogle?.fullname || "");
  // Register
  const [isSuccessRegister, setIsSuccessRegister] = useState(false);

  // Phone Number
  const [phone, setPhone] = useState();
  const [phoneWarning, setPhoneWarning] = useState("");
  const changePhone = (value) => {
    setPhone(validateNumber(value));
    if (phoneWarning) {
      if ([10, 11].includes(value?.length) || phone == "") {
        setPhoneWarning("");
      }
    }
  };

  // Validate
  const onBlurField = (type) => {
    switch (type) {
      case "email": {
        if (email && !emailError && !validateEmail(email)) {
          setEmailError("Định dạng email không đúng");
        } else if (validateEmail(email) || email == "") {
          setEmailError("");
        }

        // if (emailParent && !emailParentError && !validateEmail(emailParent)) {
        //   setEmailParentError("Định dạng email không đúng");
        // } else if (validateEmail(emailParent) || emailParent == "") {
        //   setEmailParentError("");
        // }
        return;
      }
      case "phone": {
        if (phone == "") {
          setPhoneWarning("");
        } else if (phone && phone?.length != 10 && phone?.length != 11) {
          setPhoneWarning("Số điện thoại hợp lệ gồm 10-11 số!");
          // console.log("123");
        } else if (phone && phone?.length && phone[0] !== "0") {
          setPhoneWarning("Định dạng số điện thoại không đúng");
        }
        return;
      }
      case "password": {
        if (password?.trim() === "" && !passwordError) {
          setPasswordError(
            "Mật khẩu cần tối thiểu 6 kí tự và không gồm toàn dấu cách"
          );
        }
        if (password && !passwordError && password.length < 6) {
          setPasswordError("Mật khẩu phải có từ 6 ký tự trở lên");
        }
        if (password == "" || password == undefined) {
          setPasswordError("");
        }

        if (cfPassword?.trim() === "" && !cfPasswordError) {
          setCfPasswordError(
            "Mật khẩu cần tối thiểu 6 kí tự và không gồm toàn dấu cách"
          );
        } else if (cfPassword && !cfPasswordError && cfPassword.length < 6) {
          setCfPasswordError("Mật khẩu phải có từ 6 ký tự trở lên");
        } else if (cfPassword == "" || cfPassword == undefined) {
          setCfPasswordError("");
        } else if (
          cfPassword != "" &&
          cfPassword != undefined &&
          cfPassword?.trim() !== password?.trim()
        ) {
          // console.log(cfPassword);
          setCfPasswordError("Mật khẩu không khớp");
        }
        return;
      }
      default:
    }
  };

  const changeEmail = (value) => {
    if (alert.message && alert.screen === userConstants.SCREEN_LOGIN) {
      _dispatch(alertActions.clear());
    }
    if (emailError) {
      if (validateEmail(value) || value == "") {
        setEmailError("");
      }
    }
    setEmail(value?.trim());
  };

  const changePassword = (value) => {
    setPasswordError("");
    if (alert.message && alert.screen === userConstants.SCREEN_LOGIN) {
      _dispatch(alertActions.clear());
    }
    if (passwordError) {
      if (value.length >= 6 || value == "") {
        setPasswordError("");
      }
    }

    if (value === cfPassword) {
      setCfPasswordError("");
    }
    setPassword(value);
  };

  const changeCfPassword = (value) => {
    setCfPasswordError("");
    if (alert.message && alert.screen === userConstants.SCREEN_LOGIN) {
      _dispatch(alertActions.clear());
    }
    if (cfPasswordError) {
      if (value.length >= 6 || value == "" || value === password) {
        setCfPasswordError("");
      }
    }
    setCfPassword(value);
  };

  const changeEmailParent = (value) => {
    if (alert.message && alert.screen === userConstants.SCREEN_LOGIN) {
      _dispatch(alertActions.clear());
    }
    if (emailParentError) {
      if (validateEmail(value) || value == "") {
        setEmailParentError("");
      }
    }
    setEmailParent(value?.trim());
  };

  // Variable Province
  const [provinceList, setProvinceList] = useState([]);
  const [provinceSelected, setProvinceSelected] = useState({
    title: "",
    value: "",
  });

  // Variable District
  const [districtList, setDistrictList] = useState([]);
  const [districtSelected, setDistrictSelected] = useState({
    title: "",
    value: "",
  });

  const [schoolList, setSchoolList] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState({
    title: "",
    value: "",
  });

  // Text error
  const [errProvince, setErrProvince] = useState("");
  const [errDistrict, setErrDistrict] = useState("");

  const getProvinceList = () => {
    apiCaller(
      "/api_category/provinces?country_code=vn",
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      false
    ).then((res) => {
      if (res.status) {
        setProvinceList(res.data);
      }
    });
  };

  useEffect(() => {
    getProvinceList();
  }, []);

  const handleSelectAgent = (item) => {
    setTypeAgentSelected(item.type);
  };

  // Handle Register
  const handleRegister = async () => {
    // console.log(props?.typeAgent);
    const data = {
      email,
      password,
      re_password: cfPassword,
      role: props?.typeAgent,
      class: gradeSelected?.value,
      school_type: typeSchoolSelected?.type,
      school: schoolSelected.value,
      birthday: props.typeAgent != userConstants.ROLE_TEACHER ? birthday : null,
      year_of_birth: birthday?.getFullYear(),
      grade_id: gradeSelected?.id,
      fullname: name,
      gender: genderSelected.value,
      province_alias: provinceSelected.value,
      district_alias: districtSelected.value,
      phone: phone,
      email_parent: emailParent,
    };

    if (props?.typeAgent == userConstants.ROLE_TEACHER) {
      let uriApi = `${API.baseURL}${API.register_teacher}`;
      // console.log(uriApi);
      await APIBase.apiBaseCallerMsg("POST", uriApi, data)
        .then((res) => {
          if (res?.status) {
            localStorage.setItem("phone_register", JSON.stringify(phone));
            $(".modal_success_register").addClass("hide");
            $(".modal_confirm_otp").removeClass("hide");
            $(".modalAuth").addClass("hide");
          }
        })
        .catch((e) => {
          console.log(e);
          if (e?.includes("422")) {
            setPhoneWarning("Số điện thoại đã được sử dụng.");
          }
        });
    } else {
      apiCaller("/api_register/register", "POST", data).then((res) => {
        if (res.status) {
          $(".modal_success_register").removeClass("hide");
          $(".modal_confirm_otp").addClass("hide");
          $(".modalAuth").addClass("hide");
        }
      });
    }
  };
  const [cacheEmailFail, setCacheEmailFail] = useState([]);
  const handleCheckRegister = async (e) => {
    // setSubmitted(true);
    // if (validateParam()) {
    //     dispatch(userActions.udpateInformation(inputs));
    // }
    e?.preventDefault();
    if (props?.typeLogin == "google") {
      const data = {
        email: dataLoginGoogle?.email,
        user_role: props?.typeAgent,
        class: gradeSelected?.value,
        school_type: typeSchoolSelected?.type,
        school: schoolSelected.value,
        birthday:
          props.typeAgent != userConstants.ROLE_TEACHER ? birthday : null,
        year_of_birth: birthday?.getFullYear(),
        grade_id: gradeSelected?.id,
        fullname: dataLoginGoogle?.fullname,
        gender: genderSelected.value,
        province_alias: provinceSelected.value,
        district_alias: districtSelected.value,
        phone: phone,
        email_parent: emailParent,
        id_token: dataLoginGoogle?.id_token,
        access_token: dataLoginGoogle?.access_token,
        organization_id: dataLoginGoogle?.organization_id,
      };
      _dispatch(userActions.login(data, configConstants.LOGIN_VIA_GOOLE));
    } else {
      if (
        validateEmail(email) &&
        password.length >= 6 &&
        password === cfPassword
      ) {
        // console.log("123");
        if (cacheEmailFail?.includes(email)) {
          // console.log("123");
          setDisabledBtnRegist(true);
          setEmailError(
            "Email đã tồn tại trên hệ thống. Vui lòng quay lại đăng nhập!"
          );
          window.scrollTo({ top: 0 });
          return;
        } else {
          await apiCaller("/api_register/check_email", "POST", {
            email,
          })
            .then((res) => {
              if (res.status) {
                // setCacheEmailPass([...cacheEmailPass, email]);
                handleRegister();
              }
            })
            .catch((e) => {
              setCacheEmailFail([...cacheEmailFail, email]);
              if (e === "Email đã tồn tại trên hệ thống.") {
                setDisabledBtnRegist(true);
                setEmailError(
                  "Email đã tồn tại trên hệ thống. Vui lòng quay lại đăng nhập!"
                );
              } else {
                setEmailError(e);
              }
              window.scrollTo({ top: 0 });
              return;
            });
        }
        return;
      }
    }
  };

  const handleGoBack = () => {
    _history.goBack();
  };

  const getDistrictList = (provinceAlias) => {
    apiCaller(
      `/api_category/districts?province_alias=${provinceAlias}`,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      false
    ).then((res) => {
      if (res.status) {
        setDistrictList(res.data);
      }
    });
  };

  const getSchoolList = (districtAlias) => {
    apiCaller(
      `/api_category/schools?district_alias=${districtAlias}`,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      false
    ).then((res) => {
      if (res.status) {
        setSchoolList(res.data);
      }
    });
  };

  const changeProvince = (newProvince) => {
    setIsSuccessRegister(true);
    if (newProvince.value !== provinceSelected.value) {
      setDistrictSelected({
        title: "",
        value: "",
      });
      setSchoolSelected({
        title: "",
        value: "",
      });
      setProvinceSelected(newProvince);
      getDistrictList(newProvince.value);
    }
  };

  const changeDistrict = (newDistrict) => {
    if (newDistrict.value !== districtSelected.value) {
      setSchoolSelected({
        title: "",
        value: "",
      });
      setDistrictSelected(newDistrict);
      getSchoolList(newDistrict.value);
    }
  };

  const changeSchool = (newSchool) => {
    setSchoolSelected(newSchool);
  };

  // Grade
  const [gradeSelected, setGradeSelected] = useState();

  const [dataGrades, setDataGrades] = useState([]);
  useEffect(() => {
    if (!dataGrades.length) {
      apiCaller(
        "/api_login/grade",
        "GET",
        {},
        null,
        true,
        configConstants.API_URL_SETEST,
        false
      ).then((res) => {
        if (res.status) {
          const newListGrade = res.list_grade;
          setDataGrades(newListGrade);
        }
      });
    }

    window.scrollTo({ top: 0 });
  }, []);

  const [typeSchoolSelected, setTypeShoolSelected] = useState("");
  const dataTypeSchool = [
    {
      id: 1,
      value: "Công lập",
      type: userConstants.SCHOOL_TYPE_PUBLIC,
    },
    {
      id: 2,
      value: "Dân lập",
      type: userConstants.SCHOOL_TYPE_PRIVATE,
    },
    {
      id: 3,
      value: "Tự do",
      type: userConstants.SCHOOL_TYPE_FREE,
    },
  ];

  const handleClickGrade = (item) => {
    setGradeSelected(item);
  };

  const handleClickTypeSchool = (item) => {
    setTypeShoolSelected(item);
  };

  //Date
  const [birthday, setBirthday] = useState();
  const [birthdayWarning, setBirthdayWarning] = useState();

  const changeBirthday = (birthday) => {
    const today = new Date();
    if (dayjs(birthday).isBefore(dayjs(today))) {
      setBirthday(birthday);
    } else {
      setBirthday(today);
    }
  };

  // Gender
  const [genderSelected, setGenderSelected] = useState({
    title: "",
    value: "",
  });
  const [genderList, setGenderList] = useState([
    {
      id: 1,
      title: "Nam",
      value: "male",
    },
    {
      id: 2,
      title: "Nữ",
      value: "female",
    },
  ]);

  const changeGender = (newGender) => {
    setGenderSelected(newGender);
  };

  // Name
  const changeName = (value) => {
    setName(value.capitalize());
  };

  useEffect(() => {
    if (
      props?.typeAgent &&
      password == cfPassword &&
      name &&
      phone &&
      [10, 11].includes(phone?.length) &&
      provinceSelected.value &&
      districtSelected.value &&
      genderSelected &&
      birthday
    ) {
      if (props?.typeLogin == "google") {
        // console.log(provinceSelected);
        switch (props?.typeAgent) {
          case "student":
            if (schoolSelected.value && gradeSelected) {
              setDisabledBtnRegist(false);
            } else {
              setDisabledBtnRegist(true);
            }
            return;
          case "teacher":
            if (schoolSelected.value && typeSchoolSelected) {
              setDisabledBtnRegist(false);
            } else {
              setDisabledBtnRegist(true);
            }
            return;
          default:
            // console.log(disabledBtnRegist);
            if (gradeSelected) {
              setDisabledBtnRegist(false);
            } else {
              setDisabledBtnRegist(true);
            }
            return;
        }
      } else {
        if (email && validateEmail(email) && password && cfPassword) {
          // console.log(provinceSelected);
          switch (props?.typeAgent) {
            case "student":
              if (schoolSelected.value && gradeSelected) {
                setDisabledBtnRegist(false);
              } else {
                setDisabledBtnRegist(true);
              }
              return;
            case "teacher":
              if (schoolSelected.value && typeSchoolSelected) {
                setDisabledBtnRegist(false);
              } else {
                setDisabledBtnRegist(true);
              }
              return;
            default:
              if (gradeSelected) {
                setDisabledBtnRegist(false);
              } else {
                setDisabledBtnRegist(true);
              }
              return;
          }
        }
      }
    } else {
      setDisabledBtnRegist(true);
    }
  }, [
    props?.typeAgent,
    email,
    password,
    cfPassword,
    name,
    phone,
    provinceSelected,
    districtSelected,
    schoolSelected,
    gradeSelected,
    typeSchoolSelected,
    genderSelected,
    birthday,
  ]);

  // Confirm Input
  const [verificationCode, setVerificationCode] = useState();

  const changeVerificationCode = (value) => {
    setVerificationCode(value);
  };

  const handleConfirmOTP = async () => {
    const phone_teacher = JSON.parse(localStorage.getItem("phone_register"));

    let data = {
      phone: phone_teacher,
      code: verificationCode,
    };

    let uriApi = `${API.baseURL}${API.verify_resgiter_teacher}`;
    const result = await APIBase.apiBaseCaller("POST", uriApi, data);
    if (result?.status) {
      // $(".modal_success_register").removeClass("hide");
      // $(".modal_confirm_otp").removeClass("hide");
      // $(".modalAuth").addClass("hide");
      $(".modal_success_register").removeClass("hide");
      $(".modal_confirm_otp").addClass("hide");
      $(".modalAuth").addClass("hide");
      localStorage.removeItem("phone_register");
    } else {
      console.log(result.message);
    }
  };

  // Login Google
  const onLoginGoogleSuccess = (user) => {
    // alert("Waiting Api");
    // setDataStepEmail({
    //   email: user.profileObj?.email ?? "",
    // });
    const dataLoginGoogle = {
      email: user.profileObj?.email ?? "",
      fullname: user.profileObj?.name ?? "",
      id_token: user.tokenId,
      access_token: user.accessToken,
      organization_id: 1,
    };
    localStorage.setItem("data_google", JSON.stringify(dataLoginGoogle));
    // setDataUpdateInfo({
    //   ...dataUpdateInfo,
    //   fullname: user.profileObj?.name ?? "",
    // });
    const data = {
      id_token: user.tokenId,
      access_token: user.accessToken,
      organization_id: 1,
    };
    // setCacheTokenSocial(data);
    _dispatch(userActions.login(data, configConstants.LOGIN_VIA_GOOLE));
  };

  return {
    disabledBtnRegist,
    dataAgents,
    typeAgentSelected,
    handleRegister,
    handleCheckRegister,
    handleSelectAgent,
    handleGoBack,
    provinceList,
    provinceSelected,
    changeProvince,
    errProvince,
    setErrProvince,
    districtList,
    districtSelected,
    errDistrict,
    setErrDistrict,
    changeDistrict,
    schoolList,
    schoolSelected,
    changeSchool,
    gradeSelected,
    dataGrades,
    handleClickGrade,
    dataTypeSchool,
    typeSchoolSelected,
    handleClickTypeSchool,
    birthday,
    birthdayWarning,
    setBirthdayWarning,
    changeBirthday,
    genderSelected,
    genderList,
    changeGender,
    changePhone,
    phone,
    phoneWarning,
    setPhoneWarning,
    onBlurField,
    email,
    password,
    emailError,
    setEmailError,
    passwordError,
    setPasswordError,
    changeEmail,
    changePassword,
    setPassword,
    cfPassword,
    cfPasswordError,
    setCfPasswordError,
    changeCfPassword,
    setCfPassword,
    name,
    setName,
    changeName,
    emailParent,
    emailParentError,
    setEmailParentError,
    setEmailParent,
    changeEmailParent,
    setEmail,
    setPhone,
    isSuccessRegister,
    verificationCode,
    changeVerificationCode,
    handleConfirmOTP,
    onLoginGoogleSuccess,
  };
};
