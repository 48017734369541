import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { configConstants, curriculumConstants } from "../../../_constants";
import { ContentSkill } from "../Curriculum";
import { Header } from "../../../_components/Admin/Header";
import LazyLoad from "react-lazyload";
import { isEmpty } from "lodash";
import { apiRequestLogout } from "../../../_helpers";
import API from "../../../_apis/APIConstants";
import { useRef } from "react";

function ViewCurriculumPersonal() {
  const { type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const curriculums = useSelector((state) => state.curriculums);
  const authentication = useSelector((state) => state.authentication);
  const [typeCurriculum, setTypeCurriculum] = useState(type);
  const [listCuriculum, setListCurriculum] = useState([]);
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");
  const dataServiceTeacher = useSelector(
    (state) => state.classes.dataServiceTeacher
  );
  const scrollCurriculumFavorite = useRef(null);
  const [isLoadMore, setLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (scrollCurriculumFavorite && scrollCurriculumFavorite.current) {
      scrollCurriculumFavorite.current.addEventListener(
        "scroll",
        handleScrollFavorite
      );
      return () =>
        scrollCurriculumFavorite.current?.removeEventListener(
          "scroll",
          handleScrollFavorite
        );
    }
  }, [scrollCurriculumFavorite, offset, isLoadMore]);

  const [isLoading, setLoading] = useState(false);

  const handleScrollFavorite = (e) => {
    if (
      scrollCurriculumFavorite &&
      scrollCurriculumFavorite.current?.scrollTop +
        scrollCurriculumFavorite.current?.clientHeight +
        5 >=
        scrollCurriculumFavorite.current?.scrollHeight &&
      !isLoading &&
      isLoadMore
    ) {
      fetchDataCurriculumFavorite(offset + configConstants.DEFAULT_LIMIT);
    }
    setScrollPosition(scrollCurriculumFavorite.current?.scrollTop);
  };

  const fetchDataCurriculumFavorite = (offset_Data) => {
    if ((!isLoading && offset != offset_Data) || offset_Data == 0) {
      setLoading(true);
      teacherActions
        .getCurriculumFavoriteV2(
          authentication.id,
          configConstants.DEFAULT_LIMIT,
          offset_Data
        )
        .then((curriculum) => {
          setLoading(false);
          if (!hasDuplicates(curriculum?.data, listCuriculum)) {
            setListCurriculum((prev) => [...prev, ...curriculum?.data]);
            setOffset(offset_Data);
            setLoadMore(
              curriculum?.data?.length === configConstants.DEFAULT_LIMIT
            );
          }
          if (scrollPosition > 0 && !isLoading) {
            scrollCurriculumFavorite.current.scrollTop = scrollPosition;
          }
        });
    }
  };

  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  useEffect(() => {
    changeType(typeCurriculum);
  }, []);

  function changeType(typeCurriculum) {
    setTypeCurriculum(typeCurriculum);
    setLoading(true);
    setListCurriculum([]);
    if (typeCurriculum === "personal") {
      // dispatch({
      //     type: curriculumConstants.GET_LIST_CURRICULUM,
      //     curriculums: []
      // })
      teacherActions.getCurriculumPersonal(typeCurriculum).then((res) => {
        setListCurriculum(res || []);
        setLoading(false);
      });
    } else {
      // dispatch({
      //     type: curriculumConstants.GET_LIST_CURRICULUM,
      //     curriculums: []
      // })
      teacherActions
        .getCurriculumFavoriteV2(
          authentication.id,
          configConstants.DEFAULT_LIMIT,
          offset
        )
        .then((curriculum) => {
          setListCurriculum(curriculum?.data || []);
          setLoading(false);
        });
      // dispatch(teacherActions.getCurriculumFavorite(authentication.id));
    }
  }

  const handleGotoService = async () => {
    await apiRequestLogout(`${API.logout_account_service}`);
    await apiRequestLogout(`${API.logout_account_exercise}`);
    if (
      !isEmpty(dataServiceTeacher.login_id) &&
      !isEmpty(dataServiceTeacher.teacher_redirect_url)
    ) {
      window.location.href = `${dataServiceTeacher.teacher_redirect_url}?signal=${dataServiceTeacher.login_id}`;
    } else {
      window.location.href = "https://service.sundayenglish.com/curriculum";
    }
  };

  function addComponentCreate() {
    return (
      <div
        className="form-sunE-button btn-create-taomoi rel"
        onClick={handleGotoService}
      >
        <img
          src="/assets/images/teacher/lichlamviec/ico_add.png"
          alt="ico_add"
        />
        <button className="btn-line-blue">Giáo trình mới</button>
      </div>
    );
  }

  return (
    <div className="sunE-right-container">
      <Header
        title={"Giáo trình Cá nhân"}
        component={addComponentCreate()}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/curriculum")
        }
      />
      <div className="sunE-container-box giaotrinh canhan pad">
        <div className="gtcn-menu-gr">
          <span
            className={typeCurriculum === "personal" ? " active" : ""}
            onClick={() => changeType("personal")}
          >
            {" "}
            Cá nhân
          </span>
          <span
            className={typeCurriculum === "favorite" ? " active" : ""}
            onClick={() => changeType("favorite")}
          >
            Yêu thích
          </span>
        </div>
        {!isLoading &&
          (typeCurriculum !== "favorite" ? (
            <div className="uk-container">
              <div className="uk-grid">
                {listCuriculum.length > 0 &&
                  listCuriculum.map((data, i) => {
                    return (
                      <div className=" uk-width-1-2" key={i}>
                        <Link
                          to={
                            "/" +
                            authentication.role +
                            "/curriculum/view/detail/personal/" +
                            data.id +
                            (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
                          }
                          key={i}
                        >
                          <div className="box-giaotrinh-gr rel box-shadow">
                            <div className="thumb">
                              <img
                                src="/assets/images/giaotrinh/ico_gt.png"
                                alt="ico_gt_sun"
                              />
                            </div>
                            <div className="box-select-info">
                              <p>{data.name || data.unit_name}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                {listCuriculum.length === 0 && (
                  <div className="__text_empty_curiclum_personal">
                    Bạn chưa có giáo trình cá nhân nào. Hãy ấn{" "}
                    <strong className="__semibold">"Giáo trình mới"</strong> để
                    tạo giáo trình cho mình.
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ContentSkill
              screen="CURRICULUM_PERSONAL_FAVORITE"
              data={listCuriculum}
              btn={false}
              fullWish={true}
              textEmpty="Bạn chưa có bài tập yêu thích nào."
              typeCurriculum={typeCurriculum}
              fromViewCurriculumPersonal
              scrollCurriculumFavorite={scrollCurriculumFavorite}
              handleScrollFavorite={handleScrollFavorite}
            />
          ))}
      </div>
    </div>
  );
}

export { ViewCurriculumPersonal };
