import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { isEmpty } from "lodash";
import { Link, useParams } from "react-router-dom";
import { PopUpFilter, PopUpYesNo } from "../../../_components/Popup";
import { Header } from "../../../_components/Admin/Header";
import {
  popupConstants,
  curriculumConstants,
  teacherConstants,
  userConstants,
} from "../../../_constants";
import { ContentSkill } from "../Curriculum";
import { useHistory } from "react-router-dom";
import { teacherService } from "../../../_services";
import { TypeAssignExercise } from "../../../_constants/typeAssignExercise";
import LazyLoad from "react-lazyload";

function AddCurriculumExercise() {
  const { id } = useParams();
  const history = useHistory();

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const typeAssign = params?.get("assign");
  const userID = params?.get("user_id");
  const fromPage = params?.get("page");
  const dispatch = useDispatch();
  const [type, setType] = useState(
    localStorage.getItem("typeCurrExercise") || "sunday"
  );
  const curriculums = useSelector((state) => state.curriculums);
  const authentication = useSelector((state) => state.authentication);
  const Popup = useSelector((state) => state.Popup);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDetail, setLoadingDetail] = useState(false);
  const paramFilter = useSelector((state) => state.curriculums.filters.param);

  const exercises = useSelector((state) => state.classes.exercises);
  const dataAssignProposal = useSelector(
    (state) => state.classes.data_assign_proposal
  );

  const dataExercise = dataAssignProposal.reduce(function (accumulator, obj) {
    return accumulator.concat(obj.exercise_suggest);
  }, []);

  useEffect(() => {
    // dispatch(teacherActions.getCurriculum('sunday', true));
    setLoading(true);
    teacherService
      .getCurriculum(type == "personal" ? "personal" : "sunday")
      .then((curriculums) => {
        dispatch({
          type: curriculumConstants.GET_LIST_CURRICULUM_EXERCISE,
          curriculums: curriculums.courses,
        });
        setLoading(false);
      });
    dispatch({
      type: curriculumConstants.RESET_DETAIL,
    });
    setnitCurriculumId(0);

    // return () => {
    //     dispatch({
    //         type: curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM
    //     });
    // }
  }, []);

  function changeType(type) {
    setType(type);
    localStorage.setItem("typeCurrExercise", type);
    setLoading(true);
    if (type === "favorite") {
      dispatch({
        type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE_START,
      });
      // dispatch(teacherActions.getCurriculumFavorite(authentication.id));
      teacherService
        .getCurriculumFavorite(authentication.id)
        .then((curriculums) => {
          dispatch({
            type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE,
            curriculums,
          });
          setLoading(false);
        });
    } else {
      // dispatch(teacherActions.getCurriculum(type, true));
      teacherService.getCurriculum(type).then((curriculums) => {
        dispatch({
          type: curriculumConstants.GET_LIST_CURRICULUM_EXERCISE,
          curriculums: curriculums.courses,
        });
        setLoading(false);
      });
    }
  }

  const [openPopUpBack, setOpenPopUpBack] = useState(false);
  const [unitIdSelected, setUnitIdSelected] = useState(false);
  const [curriculumId, setnitCurriculumId] = useState(
    curriculums.detail.course ? curriculums.detail.course.id : 0
  );
  const [curriculumName, setnitCurriculumName] = useState(
    curriculums.detail.course ? curriculums.detail.course.name : ""
  );

  function getDetailCurriculum(id, name) {
    // dispatch(teacherActions.getDetailCurriculumById(id));
    setLoadingDetail(true);
    teacherService.getDetailCurriculumById(id).then((curriculum) => {
      dispatch({
        type: curriculumConstants.GET_DETAIL_CURRICULUM,
        curriculum,
      });
      setLoadingDetail(false);
    });
    setnitCurriculumId(id);
    setnitCurriculumName(name);
  }

  function setshowFilter() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFilterCurriculum: true,
      },
    });
  }

  function setcloseFilter() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  function componentFilter() {
    return (
      <div
        className="form-sunE-button btn-filter rel"
        onClick={() => setshowFilter()}
      >
        <img
          src={
            _.isEmpty(paramFilter.skill) &&
            _.isEmpty(paramFilter.type) &&
            _.isEmpty(paramFilter.grade_id)
              ? `/assets/images/teacher/icon_not_yet_filter.png`
              : `/assets/images/teacher/ico_filter.png`
          }
          className="ml--5"
          alt="ico_add"
        />
        <button className="btn-line-blue">Lọc</button>
      </div>
    );
  }

  // Auto Get First Currciculum
  useEffect(() => {
    if (type != "favorite") {
      getDetailCurriculum(
        curriculums?.data[0]?.id,
        curriculums?.data[0]?.name || curriculums?.data[0]?.cat_name
      );
    }
  }, [curriculums?.data[0]]);

  useEffect(() => {
    if (type == "favorite") {
      dispatch({
        type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE_START,
      });
      // dispatch(teacherActions.getCurriculumFavorite(authentication.id));
      teacherService
        .getCurriculumFavorite(authentication.id)
        .then((curriculums) => {
          dispatch({
            type: curriculumConstants.GET_LIST_CURRICULUM_FAVORITE,
            curriculums,
          });
          setLoading(false);
        });
    }
  }, []);

  // Handle Check Duplicate

  var keySet = new Set(dataExercise.map((item) => item["lesson_id"]));

  const handleCheckDuplicateExercise = () => {
    for (var i = 0; i < exercises.data_exercise.length; i++) {
      if (
        keySet.has(exercises.data_exercise[i]["lesson_id"]) ||
        keySet.has(exercises.data_exercise[i]["exercise_id"])
      ) {
        return true; // Duplicates found
      }
    }
    return false; // No duplicates found
  };

  // Handle Go Back
  const handleGoBack = () => {
    if (
      [TypeAssignExercise.CAPACITY, TypeAssignExercise.DEMAND].includes(
        typeAssign
      )
    ) {
      localStorage.setItem("typeCurrExercise", "sunday");
      switch (typeAssign) {
        case "assign_freedom":
          dispatch({
            type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
          });
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/selection_assign"
          );
          break;
        case TypeAssignExercise.DEMAND:
        case TypeAssignExercise.CAPACITY:
          // const dataExercise = dataAssignProposal.reduce(function (
          //   accumulator,
          //   obj
          // ) {
          //   return accumulator.concat(obj.exercise_suggest);
          // },
          // []);

          // dispatch({
          //   type: teacherConstants.ADD_DATA_EXERCISE,
          //   data: {
          //     ...exercises,
          //     data_exercise: dataExercise,
          //   },
          // });

          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/" +
              typeAssign +
              "/list_proposals",

            { userIDSelected: userID }
          );
          break;
        default:
          dispatch({
            type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
          });
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student"
          );
      }
    } else {
      localStorage.setItem("typeCurrExercise", "sunday");
      typeAssign == "assign_freedom"
        ? history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/selection_assign"
          )
        : [TypeAssignExercise.DEMAND, TypeAssignExercise.CAPACITY].includes(
            typeAssign
          )
        ? history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/" +
              typeAssign +
              "/list_proposals"
          )
        : id &&
          fromPage == "morepage" &&
          authentication.role == userConstants.ROLE_TEACHER
        ? history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              `/exercise/review?${!isEmpty(fromPage) ? `page=${fromPage}` : ""}`
          )
        : history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              `/exercise/student?${
                !isEmpty(fromPage) ? `page=${fromPage}` : ""
              }`
          );
    }
  };

  // Handle Confirm Back
  const handleConfirmBack = () => {
    setOpenPopUpBack(false);
    localStorage.setItem("typeCurrExercise", "sunday");
    switch (typeAssign) {
      case "assign_freedom":
        dispatch({
          type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
        });
        history.push(
          "/" +
            authentication.role +
            "/class/view/" +
            id +
            "/exercise/student/selection_assign"
        );
        break;
      case TypeAssignExercise.DEMAND:
      case TypeAssignExercise.CAPACITY:
        const dataExercise = dataAssignProposal.reduce(function (
          accumulator,
          obj
        ) {
          return accumulator.concat(obj.exercise_suggest);
        },
        []);

        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: {
            ...exercises,
            data_exercise: dataExercise,
          },
        });

        history.push(
          "/" +
            authentication.role +
            "/class/view/" +
            id +
            "/exercise/student/" +
            typeAssign +
            "/list_proposals"
        );
        break;
      default:
        dispatch({
          type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
        });
        history.push(
          "/" + authentication.role + "/class/view/" + id + "/exercise/student"
        );
    }
  };

  return (
    <div className="sunE-right-container">
      {Popup.showFilterCurriculum && (
        <PopUpFilter
          filter_curriculum={true}
          onClickNo={() => setcloseFilter()}
          width={420}
          class_id={id}
          fromPage={fromPage}
        />
      )}

      <Header
        title={"Chọn giáo trình"}
        component={type == "sunday" && componentFilter()}
        isBack
        clickBack={() => handleGoBack()}
      />
      <div className="sunE-container-box giaotrinh unit teacher-giaobai">
        <div className="gtcn-menu-gr">
          <span
            className={type === "sunday" ? " active" : ""}
            onClick={() => changeType("sunday")}
          >
            Sunday English
          </span>
          <span
            className={type === "personal" ? " active" : ""}
            onClick={() => changeType("personal")}
          >
            Cá nhân
          </span>
          <span
            className={type === "favorite" ? " active" : ""}
            onClick={() => changeType("favorite")}
          >
            Yêu thích
          </span>
        </div>
        {!isLoading &&
          (type !== "favorite" ? (
            <div className="flex-m">
              <div
                className="flex-1 sunE-giaotrinh-list sunE-giaotrinh-list-teacher curriculum pr-25 scrollbar-custom"
                style={{ overflowX: "hidden" }}
              >
                {curriculums.data.map((data, i) => {
                  return (
                    <div
                      className={
                        "box-giaotrinh-gr rel box-shadow" +
                        (curriculumId === data.id ? " active" : "")
                      }
                      onClick={() =>
                        getDetailCurriculum(
                          data.id,
                          data.name ? data.name : data.cat_name
                        )
                      }
                      key={i}
                    >
                      <div className="thumb">
                        <img
                          src="/assets/images/giaotrinh/ico_gt.png"
                          alt="ico_gt_sun"
                        />
                      </div>
                      <div className="box-select-info">
                        <p className="two-line">{data.name}</p>
                      </div>
                    </div>
                  );
                })}
                {curriculums.data.length === 0 && (
                  <div className="__text_empty_curiclum_personal">
                    Bạn chưa có giáo trình cá nhân nào. Hãy Tạo giáo trình cá
                    nhân của mình!
                  </div>
                )}
              </div>
              {!isEmpty(curriculums.data) && (
                <div className="flex-1 pl-25 scrollbar-custom unit-list uk-container">
                  {!isEmpty(curriculums.detail.data_lesson.unit_name) && (
                    <div className="uk-grid">
                      {!isLoadingDetail &&
                        curriculums.detail.data_lesson.unit_name.map(
                          (data, i) => {
                            return (
                              <div className=" uk-width-1-1" key={i}>
                                <div
                                  className={
                                    "unit-giaotrinh-content" +
                                    (unitIdSelected === data.unit_id
                                      ? " active"
                                      : "")
                                  }
                                  key={i}
                                >
                                  <div
                                    className="box-giaotrinh-gr rel box-shadow"
                                    onClick={() =>
                                      unitIdSelected === data.unit_id
                                        ? setUnitIdSelected("")
                                        : setUnitIdSelected(data.unit_id)
                                    }
                                  >
                                    <div className="box-unit-info">
                                      <p>
                                        <span className="bold one-line">
                                          {data.stt ? `${data.stt}: ` : null}{" "}
                                          {data.unit_name}
                                        </span>
                                      </p>
                                    </div>
                                    {unitIdSelected === data.unit_id ? (
                                      <div
                                        className="btn-more-info center-flex"
                                        onClick={() => setUnitIdSelected("")}
                                      >
                                        <img
                                          src="/assets/images/giaotrinh/ico_show_.png"
                                          alt="-"
                                          className="show_img"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="btn-more-info center-flex"
                                        onClick={() =>
                                          setUnitIdSelected(data.unit_id)
                                        }
                                      >
                                        <img
                                          src="/assets/images/giaotrinh/ico_add_black.png"
                                          alt="+"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {data.list_skill.map((dataSkill, i) => {
                                    return (
                                      <Link
                                        to={
                                          "/" +
                                          authentication.role +
                                          "/class/curriculum/view/detail/" +
                                          curriculumId +
                                          "/list_skill/" +
                                          data.unit_id +
                                          "/list_exercise/" +
                                          dataSkill.skill +
                                          "?assign=" +
                                          typeAssign +
                                          "&user_id=" +
                                          userID +
                                          (!isEmpty(fromPage)
                                            ? `&page=${fromPage}`
                                            : "")
                                        }
                                        key={i}
                                      >
                                        <div className="unit-more-info">
                                          <div className="flex-m unit-more-info-item">
                                            <div className="flex-1 info-item-desc">
                                              <h2 className="text-center">
                                                {dataSkill.skill.capitalize()}
                                              </h2>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <ContentSkill
              typeAssign={typeAssign}
              userID={userID}
              fullWish={true}
              disableGetDetailCurriculumById={true}
              data={curriculums.data}
              textEmpty="Bạn chưa có bài tập yêu thích nào."
            />
          ))}
      </div>

      {openPopUpBack && (
        <PopUpYesNo
          onClickYes={() => handleConfirmBack()}
          onClickNo={() => setOpenPopUpBack(false)}
          labelYes={"Đồng ý"}
          labelNo={"Hủy"}
          message={[
            "Bạn có chắc chắn tiếp tục quay lại?",
            "Danh sách bài đã chọn sẽ không được lưu!",
          ]}
        />
      )}
    </div>
  );
}

export { AddCurriculumExercise };
