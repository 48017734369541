import { isEmpty } from "lodash";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Header } from "../../_components/Admin/Header";
import { studentService, teacherService } from "../../_services";
import { PopUpYesNo } from "../../_components/Popup";
import { configConstants, studentConstants } from "../../_constants";
import DoTestExamModal from "../StudentPage/exam-test/DoTestExam";
import LessonBase from "../../_base/LessonBase";
import LazyLoad from "react-lazyload";

function HomeWorkByTeacher() {
  const authentication = useSelector((state) => state.authentication);
  let { teacher_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const learns = useSelector((state) => state.learns);
  const statusHomeWork = learns.statusHomeWork
  console.log("🚀 ~ file: HomeWorkByTeacher.js:23 ~ HomeWorkByTeacher ~ statusHomeWork:", statusHomeWork)

  const [homeworkByTeacher, setHomeworkByTeacher] = useState(
    learns.exercises[statusHomeWork]
    );
  const [isLoading, setLoading] = useState(false);

  const [dataHomeWorkAll, setDataHomeWorkAll] = useState({});
  const [noteRemind, setNoteRemind] = useState("");
  const [teacher_info, setTeacherInfo] = useState({});

  const [type, setType] = useState(statusHomeWork);
  const [showPopup, setShowPopup] = useState(false);
  const [status, setStatus] = useState("0");
  const [item, setItem] = useState({});
  const [examSelected, selectExam] = useState(null);

  useEffect(() => {
    setLoading(true);
       studentService.getHomeWorkByTeacher(teacher_id).then((homeworks) => {
        setHomeworkByTeacher(homeworks[statusHomeWork]);
        setDataHomeWorkAll(homeworks);
        setTeacherInfo(homeworks.teacher_info);
        setLoading(false);
      });
  }, []);

  const fetchDataGetHomeWorkByTeacher = async () => {
    setLoading(true);
    const homeworks = await studentService.getHomeWorkByTeacher(teacher_id)
    if(homeworks) {
      setHomeworkByTeacher(homeworks[statusHomeWork]);
        setDataHomeWorkAll(homeworks);
        setTeacherInfo(homeworks.teacher_info);
        setLoading(false);
    }
  }

  const handleVisibilityChange = async () => {
    if (
      document.visibilityState === "visible"
    ) {
     fetchDataGetHomeWorkByTeacher(type)
    }
  };
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [statusHomeWork]);
  

   function changeType(typeSelected) {
    dispatch({type: studentConstants.STATUS_HOMEWORK, data: typeSelected})
    setType(typeSelected);
    setHomeworkByTeacher(dataHomeWorkAll[typeSelected]);
  }
  // to do
  const routeToDoHomeWork = (item) => {
    setStatus(item.status);
    // if (type === 'list_home_work_complete' &&
    //   ['speaking', 'writing', 'project'].includes(item.exercise_type) &&
    //   parseInt(item.status) !== 0) {
    //   setItem(item)
    //   setShowPopup(true)
    // } else {
    //   goToHomeWork(item)
    // }
    if (
      type === "list_home_work_complete" &&
      (item.exercise_type === "project" ||
        (item.exercise_type === "writing" && item.question_type === "7") ||
        (item.exercise_type === "speaking" && item.question_type === "3"))
    ) {
      setItem(item);
      setShowPopup(true);
    } else {
      goToHomeWork(item);
    }
  };

  const handleGoToHomework = () => {
    setShowPopup(false);
    goToHomeWork(item);
  };

  const goToHistoryExer = () => {
    setShowPopup(false);
    history.push(
      `/${authentication.role}/curriculum/${item.class_id}/skill/${item.id}/lesson/${item.exercise_type}/${item.exercise_id}/exercise/${item.id}/${item.exercise_type}?from_screen=home_work_teacher_page&teacher_id=${teacher_id}&topic=${item.exercise_topic}&page=homepage`
    );
  };

  // const goToHomeWork = (exer) => {
  //   if (exer.exercise_type === 'exam') {
  //     studentService.learn_exam(exer.id, exer.exercise_type, exer.class_id).then((learns) => {
  //       if (learns.url) {
  //         window.open(learns.url, "_blank");
  //       }
  //     });
  //   } else {
  //     studentService.getStudentLesson(exer.exercise_id).then((learns) => {
  //       if (learns.url) {
  //         window.open(learns.url, "_blank");
  //       }
  //     });
  //   }
  // }

  const goToHomeWork = (data) => {
    let typeUri = "";
    if (
      ["exam", "mini_test"].includes(
        data?.exercise_type || itemExam.lesson_type
      )
    ) {
      selectExam(data);
      return;
    } else {
      LessonBase._moveLesson(
        LessonBase.Type.homework,
        authentication,
        data,
        null,
        3
      );
    }
    // const url = `${configConstants.EXCERCISE_URL}index.php/example/lesson_demo?type=${typeUri}&id=${data?.lesson_id || data?.exercise_id}&token=${authentication?.jwt_token}`
    // window.open(url, "_blank")
  };

  const goToStudyGuidePage = (e, id, item_infor) => {
    e.stopPropagation();
    history.push(
      `/${authentication.role}/curriculum/study-guild/${id}?page=homepage`,
      {
        lesson_infor: item_infor,
      }
    );
  };

  const gotoStudentPage = useCallback(() => {
    if (teacher_id) {
      history.push(`/student?teacher_id=${teacher_id}`);
    } else {
      history.push(`/student`);
    }
  }, [teacher_id]);
  // console.log(examSelected)

  const checkDeadline = (time) => {
    let now = new Date();
    const timeDeadline = new Date(time);

    let distanceTime = (now - timeDeadline) / (1000 * 60);

    if (distanceTime > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="sunE-right-container">
      <Header
        title={teacher_info?.fullname}
        isBack
        clickBack={gotoStudentPage}
      />
      <div className="sunE-container-box teacher-giaotrinh-yt filter flex-column">
        <div className="gtcn-menu-gr">
          <span
            className={type === "list_home_work_new" ? " active" : ""}
            onClick={() => changeType("list_home_work_new")}
          >
            Chưa làm
          </span>
          <span
            className={type === "list_home_work_complete" ? " active" : ""}
            onClick={() => changeType("list_home_work_complete")}
          >
            Đã làm
          </span>
        </div>
        <div className="flex-1 center-flex no-item hide">
          <LazyLoad>
            <img
              src="/assets/images/student/bg_no_homework.png"
              alt="bg_no_homework"
            />
            <p>
              Bạn đã hoàn thành hết tất cả <br />
              các bài tập được giao.
            </p>
          </LazyLoad>
        </div>
        {!isEmpty(homeworkByTeacher) && homeworkByTeacher.length > 0 ? (
          <div className="student-baitap-content box-shadow scrollbar-custom flex-1">
            {homeworkByTeacher.map((item, i) => {
              return (
                <div className="sunE-giaotrinh-item flex-m rel" key={i}>
                  <div className="img" onClick={() => routeToDoHomeWork(item)}>
                    <img
                      src={
                        "/assets/images/teacher/giaobai/" +
                        item.exercise_type +
                        ".png"
                      }
                      alt="details"
                    />
                  </div>
                  <div className="content rel flex-1 flex-column">
                    <div
                      className="flex-1"
                      onClick={() => routeToDoHomeWork(item)}
                    >
                      <h2 className="title">
                        {item.level && (
                          <span className={item.level}>
                            {item.level === "normal" ? "medium" : item.level}
                          </span>
                        )}{" "}
                        {item.topic}
                      </h2>
                      <h3 className="desc">{item.exercise_name}</h3>
                      <h3
                        className="desc pointer_cursor"
                        onClick={(e) => goToStudyGuidePage(e, item.id, item)}
                      >
                        Hướng dẫn học tập
                      </h3>
                    </div>
                    <div className="lb-qh-nn">
                      {type === "list_home_work_complete" ? (
                        <Fragment>
                          <span className="qh">
                            Đã nộp:{" "}
                            {moment(item.create_submit_time).format(
                              "HH:mm - DD/MM/YYYY"
                            )}
                          </span>
                          {parseInt(item.status) !== 1 ? (
                            <span className="sc sc_waiting">Chờ chấm</span>
                          ) : (
                            <span className="sc">{item.score + " điểm"}</span>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="lb-qh-nn">
                            <span
                              className={`${
                                checkDeadline(item.deadline) ? "over " : "hn "
                              } white-space-nowrap`}
                            >
                              {checkDeadline(item.deadline)
                                ? "Quá hạn từ: "
                                : "Hạn nộp: "}{" "}
                              {moment(item.deadline).format("DD/MM/YYYY")}
                            </span>
                            {/* {
                              item.remind_before &&
                              parseInt(item.remind_before) > 0 && (
                                <span
                                  className="nn"
                                  onClick={() => setNoteRemind(item.note)}
                                >
                                  Đã nhắc nhở
                                </span>
                              )
                            } */}
                            {item?.note && (
                              <span
                                className="nn white-space-nowrap"
                                onClick={() => setNoteRemind(item.note)}
                              >
                                Xem nhắc nhở
                              </span>
                            )}
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          !isLoading && (
            <div className="__horizontal_center">
              <img src="/assets/images/student/bg_no_homework.png" alt="" />
              <div className="__text_empty">
                {type === "list_home_work_new"
                  ? "Thật tuyệt, bạn đã hoàn thành hết bài tập được giao!"
                  : "Bạn chưa hoàn thiện bài tập nào"}
              </div>
            </div>
          )
        )}
      </div>
      {noteRemind !== "" && (
        <div
          id="modal-show-remind"
          className="uk-flex-top uk-modal uk-flex uk-open"
          uk-modal=""
        >
          <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <p className="text-center">{noteRemind}</p>
            <div className="form-sunE-button">
              <button
                className="btn-line-blue"
                onClick={() => setNoteRemind("")}
              >
                Đóng
              </button>
            </div>
          </div>
        </div>
      )}
      {examSelected && (
        <DoTestExamModal
          isShow={examSelected !== null}
          classId={examSelected.class_id}
          unitId={examSelected.unit}
          isHomeWork={true}
          onClose={() => {selectExam(null)
            fetchDataGetHomeWorkByTeacher()
          }}
          mockTestId={
            examSelected?.id ||
            examSelected?.lesson_id ||
            examSelected?.exercise_id ||
            examSelected?.exam_id
          }
          type={examSelected.lesson_type || examSelected.exercise_type}
        />
      )}
      {showPopup && (
        <PopUpYesNo
          onClickYes={() =>
            parseInt(status) === 0 ? handleGoToHomework() : goToHistoryExer()
          }
          onClickNo={() => setShowPopup(false)}
          labelNo={"Không"}
          message={
            parseInt(status) === 0
              ? "Bạn đã nộp bài trước đó, bạn có chắc chắn muốn nộp lại?"
              : "Bạn đã nộp bài và giáo viên đã chấm. Bạn có muốn xem bài chữa không?"
          }
        />
      )}
    </div>
  );
}

export { HomeWorkByTeacher };
