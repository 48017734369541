import { curriculumConstants } from "../../_constants";
import { userConstants } from "../../_constants";
var initialState = {
  data: [],
  detail_class: {
    id: "",
    curriculum_data: {},
  },
  detail: {
    data_lesson: {
      unit_name: [],
    },
  },
  filters: {
    param: {
      topic: "",
      skill: [],
      level: [],
      grade_id: [],
      type: [],
      start_time: "",
      end_time: "",
    },
    data: [],
  },
  fromPage: null,
  view: "map",
  loading: false,
};

const curriculums = (state = initialState, action) => {
  switch (action.type) {
    case curriculumConstants.GET_LIST_CURRICULUM:
      return {
        ...state,
        data: action.curriculums,
      };
    case curriculumConstants.GET_LIST_CURRICULUM_EXERCISE:
      return {
        ...state,
        data: action.curriculums,
        detail: state.detail,
      };
    case curriculumConstants.GET_LIST_CURRICULUM_FAVORITE_START:
      return {
        ...state,
        loading: true,
      };
    case curriculumConstants.GET_LIST_CURRICULUM_FAVORITE:
      return {
        ...state,
        data: action.curriculums.data,
        loading: false,
      };
    case curriculumConstants.GET_DETAIL_CURRICULUM_CLASS:
      return {
        ...state,
        detail_class: action.curriculum,
      };
    case curriculumConstants.CLEAR_DETAIL_CURRICULUM_CLASS:
      return {
        ...state,
        detail_class: initialState.detail_class,
      };

    case curriculumConstants.GET_DETAIL_CURRICULUM:
      return {
        ...state,
        detail: action.curriculum,
        loading: false,
      };
    case curriculumConstants.GET_FILTER_CURRICULUM:
      return {
        ...state,
        filters: { ...state.filters, data: action.data },
      };
    case curriculumConstants.ADD_PARAM_FILTER_CURRICULUM:
      return {
        ...state,
        filters: { ...state.filters, param: action.param },
      };
    case curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM:
      return {
        ...state,
        filters: { ...state.filters, param: initialState.filters.param },
      };
    case curriculumConstants.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case curriculumConstants.RESET_DETAIL:
      return {
        ...state,
        detail: initialState.detail,
      };
    case curriculumConstants.FROM_PAGE_STATUS:
      return {
        ...state,
        fromPage: action.data,
      };
    case curriculumConstants.CLEAR_FROM_PAGE:
      return {
        ...state,
        fromPage: null,
      };
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { curriculums };
