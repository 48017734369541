import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { teacherService } from "../../../_services";
import { userConstants } from "../../../_constants";

function ListClassMessage(props) {
  let classes = props.classes;
  const authentication = useSelector((state) => state.authentication);
  const [numberMsg, setNumberMsg] = useState(0);
  useEffect(() => {
    teacherService.getInboxInfo().then((data) => {
      setNumberMsg(data.number_msg_new);
    });
  }, []);

  return (
    <div className="sunE-class-list uk-container">
      <div className="uk-grid">
        {classes.data
          .filter((item) => item.count_student > 0)
          .map((data, i) => {
            return (
              <div className=" uk-width-1-2" key={i}>
                <Link
                  to={
                    "/" +
                    authentication.role +
                    (authentication.role === userConstants.ROLE_STUDENT
                      ? "/more"
                      : "") +
                    "/message/view/" +
                    data?.id +
                    "?active=personal"
                  }
                  className="class-box box-shadow rel"
                >
                  <div className="flex-m">
                    <div className="class-box-img">
                      <img
                        className="img-cover"
                        src={classes.base_url + "/" + data.class_avatar}
                        alt=""
                      />
                    </div>
                    <div className="flex-1 class-box-desc">
                      <h2 className="title-line-blue two-line">
                        {data.class_name}
                      </h2>
                      <p className="two-line">{data.organization_name}</p>
                      <span>
                        Lớp{" "}
                        <span className={"class-" + data.type}>
                          {data.type}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="tb-mes flex-m">
                    <Link
                      to={
                        "/" +
                        authentication.role +
                        (authentication.role === userConstants.ROLE_STUDENT
                          ? "/more"
                          : "") +
                        "/message/view/" +
                        data?.id +
                        "?active=system"
                      }
                      className=""
                    >
                      <div className="box-btn mr-10 rel">
                        <img
                          src="/assets/images/tinnhan/ico_ring.png"
                          alt="ico_ring"
                          className="ico_ring-res-7"
                        />
                        {data.number_system_new > 0 && (
                          <label className="reng" />
                        )}
                      </div>
                    </Link>
                    <Link
                      to={
                        "/" +
                        authentication.role +
                        (authentication.role === userConstants.ROLE_STUDENT
                          ? "/more"
                          : "") +
                        "/message/view/" +
                        data?.id +
                        "?active=personal"
                      }
                      className=""
                    >
                      <div className="box-btn rel">
                        <img
                          src="/assets/images/tinnhan/ico_message.png"
                          alt="ico_message"
                          className="ico_message-res-7"
                        />
                        {data?.number_msg_new > 0 && <label className="reng" />}
                      </div>
                    </Link>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export { ListClassMessage };
