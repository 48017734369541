import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import _, { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { Alert } from "../../../_components/Alert";
import { teacherConstants } from "../../../_constants";
import { teacherActions } from "../../../_actions";
import { PopUpYesNo } from "../../../_components/Popup";
import LazyLoad from "react-lazyload";

function ReviewExercise() {
  const exercises = useSelector((state) => state.classes.exercises);
  const sourcePageAddExcercise = useSelector(
    (state) => state.classes.sourcePageAddExcercise
  );
  const authentication = useSelector((state) => state.authentication);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [indexExcercise, setIndexExcercise] = useState(null);
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const fromPage = params.get("page");

  const alert = useSelector((state) => state.alert);

  function handleSubmit() {
    if (validateParam()) {
      let data_exercise = exercises.data_exercise;
      for (let i = 0; i < data_exercise.length; i++) {
        if (!isEmpty(data_exercise[i].list_guide_id)) {
          let file = data_exercise[i].list_guide_id;
          let list_guide_id = [];
          for (let key = 0; key < file.length; key++) {
            if (!isEmpty(file[key].id)) {
              list_guide_id.push(file[key].id);
            }
          }
          data_exercise[i].list_guide_id = list_guide_id;
        } else {
          data_exercise[i].list_guide_id = [];
        }

        if (isEmpty(data_exercise[i].start_time)) {
          data_exercise[i].start_time = exercises.start_time;
        }
        if (isEmpty(data_exercise[i].end_time)) {
          data_exercise[i].end_time = exercises.end_time;
        }
      }
      exercises.data_exercise = data_exercise;
      dispatch(teacherActions.addExercise(exercises));
      localStorage.setItem("typeCurrExercise", "sunday");
    }
  }

  const goToChooseCurriculumScreen = () => {
    history.push(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        `/exercise/curriculum?page=${fromPage}`
    );
  };

  function removeExercise(index) {
    setIndexExcercise(null);
    let data_exercise = exercises.data_exercise;
    data_exercise.splice(index, 1);
    exercises.data_exercise = data_exercise;
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: { ...exercises },
    });
  }

  function validateParam() {
    return exercises.data_exercise.length ? true : false;
  }

  function navigateToSourcePage() {
    if (sourcePageAddExcercise === "PAGE_CURRICULUM") {
      history.push("/" + authentication.role + "/curriculum");
    } else if (sourcePageAddExcercise === "PAGE_HISTORY_ADD_EXCERCISE") {
      history.push("/" + authentication.role + `/more/exercise` + (!isEmpty(fromPage) ? `?page=${fromPage}` : ""));
    } else {
      history.push(
        "/" + authentication.role + "/class/view/" + id + "/exercise"
      );
    }
    dispatch({
      type: teacherConstants.RESET_DATA_EXERCISE,
    });
  }

  function navigateToPrevPage() {
    if (sourcePageAddExcercise === "PAGE_CURRICULUM") {
      history.push(
        "/" + authentication.role + "/class/view/" + id + `/exercise/student` + (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
      );
    } else if (sourcePageAddExcercise === "PAGE_HISTORY_ADD_EXCERCISE") {
      history.push(
        "/" + authentication.role + "/class/view/" + id + `/exercise/student` + (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
      );
    } else {
      history.push(
        "/" + authentication.role + "/class/view/" + id + "/exercise/curriculum" + (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
      );
    }
  }

  return (
    <div className="sunE-right-container">
      {alert.message &&
        alert.screen === teacherConstants.SCREEN_ADD_EXERCISE && (
          <Alert alert={alert} onComplete={navigateToSourcePage} />
        )}
      <Header
        title={"Danh sách bài đã chọn để giao"}
        isBack
        clickBack={navigateToPrevPage}
      />
      <div className="sunE-container-box filter flex-column">
        <div className="sunE-giaotrinh-resuft-filter bg-dc box-shadow scrollbar-custom flex-1">
          {exercises.data_exercise.map((data, i) => {
            return (
              <div className="sunE-giaotrinh-item flex-m rel" key={i}>
                <div className="img">
                  <Link
                    to={
                      "/" +
                      authentication.role +
                      "/class/view/" +
                      id +
                      "/exercise/detail_skill/" +
                      (i + 1) + (!isEmpty(fromPage)  ? `?page=${fromPage}` : "")
                    }
                  >
                    <img
                      src={
                        "/assets/images/teacher/giaobai/" +
                        data?.exercise_type +
                        ".png"
                      }
                      alt={data?.exercise_type}
                    />
                  </Link>
                </div>
                <div className="content rel flex-1">
                  <Link
                    to={
                      "/" +
                      authentication.role +
                      "/class/view/" +
                      id +
                      "/exercise/detail_skill/" +
                      (i + 1) + (!isEmpty(fromPage)  ? `?page=${fromPage}` : "")
                    }
                  >
                    <h2 className="title">
                      {data.detail?.level && (
                        <span className={data.detail?.level}>
                          {data.detail?.level === "normal"
                            ? "medium"
                            : data.detail?.level}
                        </span>
                      )}{" "}
                      {data.detail?.lesson_topic}
                    </h2>
                    <h3 className="desc">{data.detail?.lesson_name || data.detail?.unit_name}</h3>
                    <p>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {data?.parent[0]}
                      </span>{" "}
                      - {data.detail?.unit_name || data.detail?.exercise_name}
                    </p>
                  </Link>
                  <Link
                    to={
                        "/" +
                        authentication.role +
                        "/class/view/" +
                        id +
                        "/exercise/detail_skill/" +
                        (i + 1) + (!isEmpty(fromPage)  ? `?page=${fromPage}` : "")

                    }
                  >
                    <div
                      className={`file-support flex-m ${
                        isEmpty(data?.list_guide_id)
                          ? ""
                          : "file-support-is-active"
                      }`}
                    >
                      <div>
                        <img
                          src={`/assets/images/teacher/giaobai/${
                            isEmpty(data?.list_guide_id)
                              ? "ico_file.png"
                              : "ico_file_active.png"
                          }`}
                          alt="ico_file"
                          className="contain_image"
                          style={{
                            width: "1.625rem",
                          }}
                        />
                      </div>

                      <div className="flex-1">
                        {isEmpty(data?.list_guide_id) ? (
                          <span className="light"> Chưa có file hướng dẫn</span>
                        ) : (
                          <span
                            className="light"
                            style={{
                              color: "#0b9300",
                            }}
                          >
                            Đã có file hướng dẫn
                          </span>
                        )}
                      </div>
                    </div>
                  </Link>
                  <div className="chk-gr">
                    <span className="chk-custom-gr top-1">
                      <input
                        type="checkbox"
                        onClick={() => setIndexExcercise(i)}
                        checked={true}
                        id={i}
                      />
                      <label htmlFor={i}></label>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
          {isEmpty(exercises.data_exercise) && (
            <h3 style={{ fontSize: 18 }}>Bạn chưa chọn bài tập nào để giao.</h3>
          )}
        </div>
        <div className="form-sunE-button text-right mar-b-0">
          {/* <Link to={'/' + authentication.role + '/class/view/' + id + '/exercise/curriculum'} >
                        <button className="btn-line-blue btn-thembai">Thêm bài</button>
                    </Link> */}
          <button
            className={"btn-line-blue mr-10"}
            onClick={goToChooseCurriculumScreen}
          >
            Thêm bài
          </button>
          <button
            className={
              "btn-line-blue" + (!validateParam() ? " btn-disable" : "")
            }
            onClick={handleSubmit}
          >
            Hoàn tất
          </button>
        </div>
        {!_.isNull(indexExcercise) && (
          <PopUpYesNo
            onClickYes={() => removeExercise(indexExcercise)}
            onClickNo={() => setIndexExcercise(null)}
            message={"Bạn có chắc chắn muốn xóa bài tập này ra khỏi danh sách?"}
            labelNo={"Hủy"}
            labelYes={"Xóa"}
            width={"280px"}
          />
        )}
      </div>
    </div>
  );
}

export { ReviewExercise };
