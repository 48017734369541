import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { licenseActions } from '../../../_actions';
import { SideBar } from '../../../_components/Admin/More/License';
import { Header } from '../../../_components/Admin/Header';

function HomePageLicense() {
    const dispatch = useDispatch();
    const licenses = useSelector(state => state.licenses);
    const authentication = useSelector(state => state.authentication);

    useEffect(() => {
        dispatch(licenseActions.getCurrentLicense());
    }, []);

    // console.log(licenses)

    return (
        <div className="sunE-right-container">
            <Header title={'Nâng cấp tài khoản'} isBack/>
            <div className="flex-m sunE-content">
                <SideBar />
                <div className="flex-1 sunE-content-box">

                    {licenses.listCurentLicenses.map((license, i) => {
                        if (new Date(license.expired) < new Date()) {
                            return (
                                <div className="box-shadow box-white-content"  key={i}>
                                    <h2 className="title-bg">{license.package_name}</h2>
                                    <p className="text-light">{license.sort_desc}</p>
                                    <p className="text-center">
                                        <span className="het-han">ĐÃ HẾT HẠN</span>
                                    </p>
                                    <div className="form-sunE-button text-center">
                                        <Link to={"/" + authentication.role + "/license/add"}><button className="btn-line-blue btn-custom-hei">Gia hạn</button></Link>
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                <div className="box-shadow box-white-content" key={i}>
                                    <h2 className="title-bg">{license.package_name}</h2>
                                    <p className="text-light">{license.sort_desc}</p>
                                    <p className="text-center text-light">
                                        Ngày hết hạn: <span className="text-title-blue">{license.expired}</span>
                                    </p>
                                </div>
                            )
                        }

                    })}
                    {licenses.listCurentLicenses.length === 0 && <div className="text-center" style={{fontSize: 18}}>
                            <img src="/assets/images/no_payment.png" alt="ico_remove_blue"/><br/>
                            Bạn chưa mua khóa học nào.<br/>
                            Hãy ấn vào <strong className="__text_bold_lisence">"Mua gói mới"</strong> để trải nghiệm.
                        </div>}
                </div>
            </div>
        </div>
    );
}

export { HomePageLicense };