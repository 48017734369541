import "./Pagination.style.scss";
const Pagination = (props) => {
  let { pageSelected, dataPagination, handleClickPage } = props;

  const renderNumberPage = (item) => {
    return (
      <a href={`#content${item.number}`} key={item.id}>
        <div
          onClick={() => handleClickPage(item)}
          className="item_number_page flex-center font_news_bold"
          style={{
            backgroundColor:
              pageSelected == item.number ? "#00cc83" : "#D9D9D9",
          }}
        >
          {item.number}
        </div>
      </a>
    );
  };

  return (
    <div className="flex-center pagination_container bg_white">
      {dataPagination.map((item) => renderNumberPage(item))}
    </div>
  );
};

export default Pagination;
