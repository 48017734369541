import React from "react";
import _ from "lodash";
import { Header } from "../../../../../_components/Admin/Header";
import { assignSpecificLogic } from "./AssignSpecific.logic";
import "./AssignSpecific.style.scss";
import "../AssignExerciseSelection/AssignExerciseSelection.style.scss";
import { Redirect, useParams } from "react-router-dom";
import { TypeAssignExercise } from "../../../../../_constants/typeAssignExercise";
import CustomBarRange from "../../../../../_components/CustomBarRange/CustomBarRange";
import { convertTitleAssign } from "../../../../../_base/Validate";
import { useSelector } from "react-redux";

const AssignSpecific = () => {
  const {
    isLoading,
    exercises,
    handleSubmitAssignDemand,
    handleGoBack,
    dataAssignSpecific,
    handleChangeUnit,
    currUnit,
    cantChangeUnit,
    handleChangePercent,
    cantChangeTime,
    handleChangeTime,
    currTimeMinute,
  } = assignSpecificLogic();

  const { id, type } = useParams();
  const authentication = useSelector((state) => state.authentication);

  const renderListRangeStudy = (data) => {
    return (
      <CustomBarRange
        totalUnit={dataAssignSpecific?.total_unit}
        currUnit={currUnit}
        key={data?.stand}
        study_data_item={data}
        setMovePercent={(value) => handleChangePercent(data, value)}
      />
    );
  };

  const renderListStandardSkill = (data, index, dataParent) => {
    const lengthParent = dataParent.length;
    return (
      <div
        key={data?.skill}
        className="flex-jus-between col-100 standard_skill_item"
        style={{
          borderBottom:
            index != lengthParent - 1 ? "1px solid #c4c4c4" : "none",
        }}
      >
        <div className="col-fixed-50 fz-1 before_dot_assign pos_rel">
          {data.skill.capitalize()}
        </div>
        <div className="text-align-center col-fixed-50 fz-1">
          {convertTitleAssign(data.level)}
        </div>
      </div>
    );
  };

  if (
    ![TypeAssignExercise.DEMAND, TypeAssignExercise.CAPACITY].includes(type)
  ) {
    return (
      <Redirect
        exact={true}
        from="*"
        to={
          "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/student/selection_assign"
        }
      />
    );
  }

  return (
    <div className="sunE-right-container flex-column selection_assign assign_specific_container">
      <Header
        title={
          type == TypeAssignExercise.DEMAND
            ? "Giao bài theo yêu cầu"
            : "Giao bài theo năng lực"
        }
        isBack
        clickBack={() => handleGoBack()}
      />
      {dataAssignSpecific?.cur_unit && !isLoading ? (
        <div
          className={`content_selection ${
            type == TypeAssignExercise.CAPACITY
              ? " content_selection_capacity"
              : ""
          } flex-column-start  scrollbar-custom-2  pos_rel col-100`}
        >
          <div className="content_unit flex-jus-between col-100">
            <div className=" fz-15 font_myriad_semi_bold ">Unit đang học</div>
            <div className="flex-align btn_unit_container">
              <button
                className={
                  "btn flex-center pointer_cursor btn_prev_assign" +
                  (cantChangeUnit == "prev" ? " btn-disable" : "")
                }
                onClick={() => handleChangeUnit("prev")}
                disabled={cantChangeUnit == "prev"}
              >
                <img
                  src="/assets/images/icon/ico_left_weight.png"
                  alt="img icon left"
                  className="contain_image"
                />
              </button>

              <div className="font_myriad_bold fz-15 text-color text_unit ">
                Unit {currUnit}
              </div>

              <button
                className={
                  "btn flex-center pointer_cursor btn_next_assign" +
                  (cantChangeUnit == "next" ? " btn-disable" : "")
                }
                onClick={() => handleChangeUnit("next")}
                disabled={cantChangeUnit == "next"}
              >
                <img
                  src="/assets/images/icon/ico_right_weight.png"
                  alt="img icon right"
                  className="contain_image"
                />
              </button>
            </div>
          </div>
          {type == TypeAssignExercise.CAPACITY && (
            <div className="content_unit flex-jus-between col-100">
              <div className=" fz-15 font_myriad_semi_bold ">
                Thời lượng mong muốn
              </div>
              <div className="flex-align btn_unit_container">
                <button
                  className={
                    "btn flex-center pointer_cursor btn_prev_assign" +
                    (cantChangeTime == "prev" ? " btn-disable" : "")
                  }
                  onClick={() => handleChangeTime("prev")}
                  disabled={cantChangeTime == "prev"}
                >
                  <img
                    src="/assets/images/icon/ico_left_weight.png"
                    alt="img icon left"
                    className="contain_image"
                  />
                </button>

                <div className="font_myriad_bold fz-15 text-color text_unit ">
                  {currTimeMinute} phút
                </div>

                <button
                  className={
                    "btn flex-center pointer_cursor btn_next_assign" +
                    (cantChangeTime == "next" ? " btn-disable" : "")
                  }
                  onClick={() => handleChangeTime("next")}
                  disabled={cantChangeTime == "next"}
                >
                  <img
                    src="/assets/images/icon/ico_right_weight.png"
                    alt="img icon right"
                    className="contain_image"
                  />
                </button>
              </div>
            </div>
          )}

          <div className="content_rate col-100 flex-column">
            <div className=" fz-15 font_myriad_semi_bold ">Tỷ lệ kiến thức</div>

            <div className="fz-12-5 content_rate_desc">
              * Kéo thanh gạt để chọn tỷ lệ ôn luyện kiến thức đã học và kiến
              thức mới theo trình độ của từng kỹ năng.
            </div>

            <div
              className={`${
                Array.isArray(exercises?.students) &&
                exercises?.students.length == 1
                  ? " flex-jus-between"
                  : " flex-center"
              } flex-align-end`}
            >
              <div className="list_range_study flex-column-start">
                {dataAssignSpecific?.study_data?.map((data) =>
                  renderListRangeStudy(data)
                )}
              </div>

              {Array.isArray(exercises?.students) &&
                exercises?.students.length == 1 && (
                  <div className="list_standard_skill flex-end-column">
                    <div className="standard_skill_container flex-column box-shadow">
                      <div className="flex-jus-between col-100 pd-bottom-0-5">
                        <div className="title_standard text-align-center fz-11 color-white font_myriad_bold col-fixed-50">
                          Kỹ năng
                        </div>
                        <div className="title_standard title_standard_right text-align-center fz-11 color-white font_myriad_bold col-fixed-50">
                          Trình độ
                        </div>
                      </div>
                      <div className="flex-column col-100">
                        {Array.isArray(dataAssignSpecific?.level_skill) &&
                          dataAssignSpecific?.level_skill?.map((data, index) =>
                            renderListStandardSkill(
                              data,
                              index,
                              dataAssignSpecific?.level_skill
                            )
                          )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            flex: 1,
          }}
        ></div>
      )}

      <div className="form-sunE-button text-right mb0 btn_continue_selection">
        <button
          className={"btn-bg-blue btn-default "}
          onClick={handleSubmitAssignDemand}
        >
          Tiếp tục
        </button>
      </div>
    </div>
  );
};

export default AssignSpecific;
