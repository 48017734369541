import "./DownloadPage.style.scss";
import FooterNews from "../../_components/FooterNews/FooterNews";
import { useParams } from "react-router-dom";
import { downloadLogic } from "./DownloadPage.logic";
import { Redirect } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";

const DownloadPage = () => {
  const { type } = useParams();

  let { dataTeacher, dataStudent, dataParent, handleLinkApp } = downloadLogic();

  const renderDetailLink = (item, dataParent) => {
    return (
      <div key={item.id} className="flex-center-column col-47 mg-horizontal-1">
        <div className="text-align-center mg-bottom-1">
          <div className=" text-download ">Quét mã QR để</div>
          <div className=" font_news_bold text-download">
            tải app{" "}
            {type == "student"
              ? "học sinh"
              : type == "teacher"
              ? "giáo viên"
              : "phụ huynh"}{" "}
            ngay
          </div>
        </div>

        <LazyLoad once className="img_qr_container flex-center">
          <img
            src={`/assets/images/homeNews/icon/ico_qr_${item.type}_${dataParent.type}.png`}
            alt="Img QR"
            className="contain_image"
          />
        </LazyLoad>

        <div
          once
          className="img_store_container flex-center pointer_cursor"
          onClick={() => handleLinkApp(item.type)}
        >
          <img
            src={`/assets/images/homeNews/icon/ico_${item.type}.png`}
            alt="Img QR"
            className="contain_image"
          />
        </div>
      </div>
    );
  };

  const renderListDowload = (data) => {
    return (
      <div className="flex-center-column ">
        <LazyLoad once className="item_logo">
          <img
            src="/assets/images/logo_home.png"
            alt="Img Logo"
            className="contain_image"
          />
        </LazyLoad>
        <div className="title_download">
          <div
            className={`${
              type == "parent" ? "title_detail_1_parent" : "title_detail_1"
            } font_news_extra_bold text-align-center`}
          >
            {data.title_1}
          </div>
          <div
            className={`${
              type == "parent"
                ? " title_detail_2_parent font_news_extra_bold"
                : " title_detail_2 font_news_bold"
            }   text-align-center`}
          >
            {data.title_2}
          </div>
        </div>

        <LazyLoad
          once
          className="flex-jus-evenly img_download_container col-100"
        >
          {data.links.map((item) => renderDetailLink(item, data))}
        </LazyLoad>
      </div>
    );
  };

  if (!["teacher", "student", "parent"].includes(type)) {
    return <Redirect from="*" to="/" />;
  }

  return (
    <div className="downloadPage commondPage">
      <div className="slider flex-jus-between">
        <LazyLoad once className="list_download  flex-jus-end">
          {renderListDowload(
            type == "student"
              ? dataStudent
              : type == "teacher"
              ? dataTeacher
              : dataParent
          )}
        </LazyLoad>
        <LazyLoad once className={`col-50 slider_image slider_image_${type}`}>
          <img
            src={`/assets/images/homeNews/downloadPage/slider_${type}.png`}
            alt="Slider Img"
            className="contain_image"
          />
        </LazyLoad>
        <LazyLoad
          once
          className={`pos_abs img_side_container img_side_${type}`}
        >
          <img
            src={`/assets/images/homeNews/downloadPage/img_side_${type}.png`}
            alt="Img Side"
            className="contain_image"
          />
        </LazyLoad>
      </div>
      <FooterNews
        type="download"
        typeFee={type == "teacher" && TypeHeaderNewsItem.TEACHER}
      />
    </div>
  );
};

export default DownloadPage;
