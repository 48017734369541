import React, { useEffect } from 'react';
import Slider from "react-slick";
import { SideBar } from '../../../_components/Admin/More/License';
import { Header } from '../../../_components/Admin/Header';
import { SliderItem } from './SliderItem';
import { studentActions } from '../../../_actions';
import { useSelector, useDispatch } from 'react-redux';
import { SelectAsDiv } from '../../../_components/Select';


function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div onClick={onClick} className={className}>
            <img alt="dropdown right" src="/assets/images/icon/ico_right.png" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div onClick={onClick} className={className}>
            <img src="/assets/images/icon/ico_left.png" alt="ico_left_white" />
        </div>
    );
}

function AddLicense() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(studentActions.getAllGrade());
    }, []);

    const grades = useSelector(state => state.grades);

    return (
        <div className="sunE-right-container">
            <Header title={'Nâng cấp tài khoản'} isBack/>
            <div className="flex-m sunE-content">
                <SideBar />
                <div className="flex-1 sunE-content-box">
                    <div className="chosen-class custom-select-no-bg custom-select-w">
                        <SelectAsDiv textDefault={'Khối'} data={grades} onChangeSelect={() => {}} />
                    </div>
                    <div className="slider-custom">
                        <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-1@s uk-child-width-1-1@m">
                            <Slider {...settings}>
                                <SliderItem />
                                <SliderItem />
                                <SliderItem />
                            </Slider>
                        </ul>
                    </div>
                    <div className="form-sunE-button text-center">
                        <button className="btn-line-blue buy-now" >Mua ngay</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { AddLicense };