import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { EmptyClassBlock, ListClass } from "../../../_components/Admin/Teacher";
import { Header } from "../../../_components/Admin/Header";
import {
  configConstants,
  teacherConstants,
  userConstants,
} from "../../../_constants";
import $ from "jquery";
import { teacherService } from "../../../_services";

function HomePageClass() {
  const authentication = useSelector((state) => state.authentication);
  const [isLoading, setLoading] = useState(false);
  const [listClass, setListClass] = useState([]);
  const [baseUrl, setBaseUrl] = useState();
  const [isLoadMoreOnline, setLoadMoreOnline] = useState(true);
  const [isLoadMoreOffline, setLoadMoreOffline] = useState(true);
  const [offSetOnlineState, setOffSetOnlineState] = useState(0);
  const [offSetOfflineState, setOffSetOfflineState] = useState(0);
  const [offsetClassStudent, setOffsetClassStudent] = useState(0);
  const [listClassOnlineState, setListClassOnlineState] = useState([]);
  const [listClassOffLineState, setListClassOffLineState] = useState([]);

  useEffect(() => {
    if (isLoading) {
      $(".loading").removeClass("hide");
    } else {
      $(".loading").addClass("hide");
    }
  }, [isLoading]);

  useEffect(() => {
    if (authentication.role == userConstants.ROLE_TEACHER) {
      getListClass(configConstants.DEFAULT_LIMIT, 0, 0);
    } else {
      getListClassStudent(configConstants.DEFAULT_LIMIT, 0);
    }
  }, []);

  // Has Duplicate Data
  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
        (isLoadMoreOnline ||
      isLoadMoreOffline) && !isLoading
      ) {
      if (authentication.role == userConstants.ROLE_TEACHER) {
        getListClass(
          configConstants.DEFAULT_LIMIT,
          offSetOfflineState + configConstants.DEFAULT_LIMIT,
          offSetOnlineState + configConstants.DEFAULT_LIMIT
        );
      } else {
        if(
          (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
            isLoadMoreOnline) && !isLoading
        ) {
          getListClassStudent(
            configConstants.DEFAULT_LIMIT,
            offsetClassStudent + configConstants.DEFAULT_LIMIT
          );
        }       
      }
    }
  };

  const getListClassStudent = async (limit, offsetClassStudentParam) => {
    if (authentication.role === userConstants.ROLE_STUDENT) {
      let listClassOnline = [];
      if (
        (offsetClassStudentParam != offsetClassStudent ||
          offsetClassStudent == 0) &&
          !isLoading
          ) {
        setLoading(true);
        let resOnline = await teacherService.getListClassOnlineAsyncLimit(
          limit,
          offsetClassStudentParam
        );
        if (!hasDuplicates(resOnline.data, listClassOnlineState)) {
          setLoading(false);
          setListClassOnlineState((prev) => [...prev, ...resOnline.data]);
          setLoadMoreOnline(
            resOnline.data.length == configConstants.DEFAULT_LIMIT
          );
          setOffsetClassStudent(offsetClassStudentParam);
        }
        listClassOnline = resOnline.data || [];
        setListClass((prev) => [...prev, ...resOnline.data]);
        setBaseUrl(resOnline.base_url);
      }
    }
  };

  const getListClass = async (limit, offSetOffline, offsetOnline) => {
    try {
      setLoading(true);
      
      if (authentication.role === userConstants.ROLE_TEACHER) {
        let listClassOnline = [];
        let listClassOffline = [];
        if (
          (offsetOnline != offSetOnlineState || offSetOnlineState == 0) &&
          !isLoading
        ) {
          setLoading(true);
          let resOnline = await teacherService.getListClassOnlineAsyncLimit(
            limit,
            offsetOnline
          );
          if (!hasDuplicates(resOnline.data, listClassOnlineState)) {
            setLoading(false);
            setListClassOnlineState((prev) => [...prev, ...resOnline.data]);
            listClassOnline = resOnline.data || [];
            setLoadMoreOffline(
              resOnline.data.length == configConstants.DEFAULT_LIMIT
            );
            setOffSetOnlineState(offsetOnline);
          }
          setListClass((prev) => [...prev, ...resOnline.data]);
          setBaseUrl(resOnline.base_url);
        }
        if (
          (offsetOnline != offSetOfflineState || offSetOfflineState == 0) &&
          !isLoading
        ) {
          setLoading(true);
          let resOffline = await teacherService.getListClassOfflineAsyncLimit(
            limit,
            offSetOffline
          );
          if (!hasDuplicates(resOffline.data, listClassOffLineState)) {
            setLoading(false);
            setListClassOffLineState((prev) => [...prev, ...resOffline.data]);
            listClassOffline = resOffline.data || [];
            setLoadMoreOnline(
              resOffline.data.length == configConstants.DEFAULT_LIMIT
            );
            setOffSetOfflineState(offSetOffline);
          }
          setListClass((prev) => [...prev, ...resOffline.data]);
        }
      }
      setLoading(false);
    } catch (e) {}
  };

  let componentClass = isEmpty(listClass) ? (
    !isLoading && <EmptyClassBlock />
  ) : (
    <ListClass
      handleScroll={handleScroll}
      classes={listClass}
      base_url={baseUrl}
    />
  );
  return (
    <div className="sunE-right-container rel flex-column">
      <Header
        title={
          authentication.role === userConstants.ROLE_TEACHER
            ? "Quản lý lớp"
            : "Lớp học online của tôi"
        }
        isBack
      />
      <div
        className="sunE-content sunE-class-content flex-1"
        style={{ padding: 0 }}
      >
        {componentClass}
      </div>
      {authentication.role === userConstants.ROLE_TEACHER ? (
        <div className="form-sunE-button text-right btn-mr-custom">
          <Link to={"/" + authentication.role + "/class/add"}>
            <button className="btn-check-custom btn-line-blue bottom-30">
              Tạo lớp mới
            </button>
          </Link>
        </div>
      ) : (
        <div className="form-sunE-button text-right btn-mr-custom">
          <Link to={"/" + authentication.role + "/more/class/apply"}>
            <button className="btn-line-blue">Xin vào lớp mới</button>
          </Link>
        </div>
      )}
    </div>
  );
}

export { HomePageClass };
