import React from "react";
import _, { isEmpty } from "lodash";
import { Header } from "../../../../../_components/Admin/Header";
import "./AssignSpecificProposal.style.scss";
import "../AssignExerciseSelection/AssignExerciseSelection.style.scss";
import { Link, Redirect, useParams } from "react-router-dom";
import { TypeAssignExercise } from "../../../../../_constants/typeAssignExercise";
import { useSelector } from "react-redux";
import SkillsAssign from "../../../../../_components/SkillsAssign/SkillsAssign";
import { assignProposalLogic } from "./AssignSpecificProposal.logic";
import { PopUpYesNo } from "../../../../../_components/Popup";
import classnames from "classnames";
import { configConstants, teacherConstants } from "../../../../../_constants";
import { history } from "../../../../../_helpers";
import { Alert } from "../../../../../_components/Alert";
import { userConstants } from "../../../../../_constants";
import { useDispatch } from "react-redux";
import { cloneDeep } from "lodash";

const AssignSpecificStudents = (props) => {
  const { id, type } = useParams();
  const authentication = useSelector((state) => state.authentication);
  const alert = useSelector((state) => state.alert);
  const _dispatch = useDispatch();

  let {
    dataReduxAssign,
    isScrollable,
    checkScroll,
    dataProposal,
    dataAssignSpecific,
    setDataAssignSpecific,
    handleGoBack,
    handleOpenModalSkill,
    handleConfirmApply,
    studentSelected,
    handleSelectStudent,
    handleDeleteLesson,
    openModalSide,
    setOpenModalSide,
    handleCheckDelete,
    handleCheckEmptyExercise,
    handleAssignHomework,
  } = assignProposalLogic(props);

  const EditComponent = () => {
    return (
      <div
        className="form-sunE-button flex-align edit_container"
        onClick={handleOpenModalSkill}
      >
        <button className="btn-line-blue flex-align">
          <div className="img_edit_container  flex-align">
            <img
              loading="lazy"
              src="/assets/images/icon/ico_edit_white.png"
              alt="Icon Edit"
              className="contain_image"
            />
          </div>
          Chỉnh sửa
        </button>
      </div>
    );
  };

  const renderListLesson = (item) => {
    return (
      <div
        key={item.lesson_id}
        className="flex-align-start pos_rel lesson_item pointer_cursor"
      >
        <Link
          className="flex-align-start lesson_content"
          to={
            "/" +
            authentication.role +
            "/class/view/" +
            id +
            "/exercise/student/" +
            type +
            "/detail_lesson/" +
            item.lesson_id +
            "?user_id=" +
            studentSelected
          }
        >
          <div className="img_lesson">
            <img
              loading="lazy"
              src={`/assets/images/teacher/giaobai/${item?.lesson_type.toLowerCase()}.png`}
              alt="Img Lesson"
              className="contain_image"
            />
          </div>

          <div className="flex-column-start mg-left-1 lesson_content_text">
            <div className="flex-align">
              <div
                className="title_level color-white fz-07-5 font_myriad_bold text-align-center flex-center"
                style={{
                  backgroundColor:
                    item?.level == "easy"
                      ? "#84C241"
                      : item?.level == "normal"
                      ? "#F8AF36"
                      : "  #EB5757",
                }}
              >
                <span
                  className="color-white fz-07-5 font_myriad_bold text-align-center"
                  style={{
                    top: "8%",
                  }}
                >
                  {item.level == "normal" ? "Medium" : item.level.capitalize()}
                </span>
              </div>
              <div className="fz-11-25 title_lesson font_myriad_bold">
                {item.topic || item.lesson_topic}
              </div>
            </div>

            <div className="flex-align fz-11  lesson_name">
              {item.lesson_name}
            </div>
            <div className="flex-align fz-09 font_myriad_light">
              {item.curriculum_name}
            </div>

            <div className="flex-align pd-top-0-5-pc">
              <div className="flex-align mg-right-0-75">
                <img
                  loading="lazy"
                  src={`/assets/images/teacher/giaobai/${
                    isEmpty(item.list_guide_id)
                      ? "ico_file.png"
                      : "ico_file_active.png"
                  }`}
                  alt="ico_file"
                  className="contain_image"
                  style={{
                    width: "1.15rem",
                  }}
                />
              </div>

              {isEmpty(item.list_guide_id) ? (
                <div
                  className="fz-09"
                  style={{
                    color: "#f16522",
                  }}
                >
                  Chưa có file hướng dẫn
                </div>
              ) : (
                <div
                  className="light"
                  style={{
                    color: "#0b9300",
                  }}
                >
                  Đã có file hướng dẫn
                </div>
              )}
            </div>
          </div>
        </Link>
        <div
          className="pos_abs ico_delete_lesson"
          onClick={() => handleCheckDelete(item)}
        >
          <img
            loading="lazy"
            src="/assets/images/icon/ico_delete_green.png"
            alt="Icon Delete"
            className="contain_image"
          />
        </div>
      </div>
    );
  };

  const renderListProposal = (data) => {
    return (
      <div key={data.user_id} className="box-shadow proposal_item col-100">
        <div
          className="proposal_header flex-align flex-jus-between pointer_cursor"
          onClick={() => handleSelectStudent(data)}
        >
          <div className="flex-align">
            <div className="img_avt_container  ">
              <img
                loading="lazy"
                src={`${configConstants.BASE_URL}${data.avatar}`}
                alt="Img Avt"
                className="box-shadow"
              />
            </div>

            <div className="flex-column-start mg-left-1">
              <div className="font_myriad_bold fz-11-25 mg-bottom-0-5">
                {data.fullname} ({data.minute_finish} phút)
              </div>
              <div className="fz-1 line_height_normal">{data.email}</div>
            </div>
          </div>

          <div className="icon  icon_dropdown cursor_pointer">
            <svg
              width="23"
              height="14"
              viewBox="0 0 26 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classnames({
                rotate_180: studentSelected == data.user_id,
              })}
              style={{ transition: "all 0.3s" }}
            >
              <path
                d="M13.2809 15.704L0.507935 2.93097L2.74695 0.692017L13.2611 11.206L23.7389 0.539978L25.9979 2.75897L13.2809 15.704Z"
                fill="#4f4f4f"
              />
            </svg>
          </div>
        </div>
        {studentSelected == data.user_id && (
          <>
            <div className="flex-column-start">
              {data?.exercise_suggest?.map((item) => renderListLesson(item))}
            </div>

            <div
              className="form-sunE-button flex-center add_lesson_container"
              onClick={() =>
                history.push(
                  "/" +
                    authentication.role +
                    "/class/view/" +
                    id +
                    "/exercise/curriculum?assign=" +
                    type +
                    "&user_id=" +
                    data.user_id
                )
              }
            >
              <div className="flex-align btn_add_lesson ">
                <div className="img_add_container flex-align">
                  <img
                    loading="lazy"
                    src="/assets/images/icon/ico_add_lesson.png"
                    alt="Icon Edit"
                    className="contain_image"
                  />
                </div>
                <div className="fz-11-25 font_myriad_bold color-white flex-align mg-left-0-5">
                  Thêm bài tập
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="pos_rel flex-column sunE-right-container selection_assign skills_assign_container assign_specific_container">
      <Header
        title={
          type == TypeAssignExercise.DEMAND
            ? "Giao bài theo yêu cầu"
            : "Giao bài theo năng lực"
        }
        isBack
        component={type == TypeAssignExercise.DEMAND && <EditComponent />}
        clickBack={() => handleGoBack()}
      />
      <div
        className={`content_selection flex-column-start  scrollbar-custom-purple  pos_rel col-100`}
      >
        {dataProposal && dataProposal?.map((data) => renderListProposal(data))}
      </div>

      <div
        className={" form-sunE-button text-right mb0 btn_continue_selection"}
      >
        <button
          className={
            "btn-bg-blue btn-default " +
            (handleCheckEmptyExercise() ? "  btn-disable" : "")
          }
          onClick={handleAssignHomework}
          disabled={handleCheckEmptyExercise()}
        >
          Hoàn tất
        </button>
      </div>

      {(openModalSide == "modalSkills" || openModalSide == "modalApply") && (
        <div
          id="modal-center"
          className="flex-center-column uk-flex-top uk-modal modal_not_scroll uk-flex uk-open commondPage"
          uk-modal=""
          style={{
            overflow: "hidden",
          }}
        >
          <div className="pos_rel modal_skills_container box-shadow">
            <div
              ref={checkScroll}
              className={`${
                isScrollable ? "flex-align-start" : "flex-align"
              } scrollbar-custom-2`}
              style={{
                overflow: "auto",
                height: "90%",
                paddingRight: "5%",
              }}
            >
              <SkillsAssign
                dataSkills={dataAssignSpecific}
                setDataSkills={setDataAssignSpecific}
                typeModal="center"
              />
            </div>

            <div className="form-sunE-button flex-center btn_action_container">
              <button
                className="btn-line-blue flex-align btn_action flex-center"
                onClick={() => {
                  setDataAssignSpecific(cloneDeep(dataReduxAssign));
                  setOpenModalSide("");
                }}
              >
                Hủy
              </button>

              <button
                className={
                  "btn-line-blue flex-align btn_action  flex-center" +
                  (dataAssignSpecific?.lesson_list_propose?.every(
                    (item) => item.sum <= 0
                  )
                    ? " btn-disable"
                    : "")
                }
                onClick={() => setOpenModalSide("modalApply")}
                disabled={dataAssignSpecific?.lesson_list_propose?.every(
                  (item) => item.sum <= 0
                )}
              >
                Áp dụng
              </button>
            </div>
          </div>
        </div>
      )}

      {openModalSide == "modalApply" && (
        <PopUpYesNo
          onClickYes={() => handleConfirmApply()}
          onClickNo={() => setOpenModalSide("modalSkills")}
          labelNo={"Hủy"}
          labelYes={"Đồng ý"}
          message={
            "Hệ thống sẽ đề xuất danh sách bài tập mới, những thay đổi trước đó của bạn sẽ không được giữ lại."
          }
        />
      )}

      {openModalSide == "modalDelete" && (
        <PopUpYesNo
          onClickYes={() => handleDeleteLesson()}
          onClickNo={() => setOpenModalSide("")}
          labelNo={"Hủy"}
          labelYes={"Xóa"}
          message={"Bạn có chắc chắn muốn xóa bài tập này ra khỏi danh sách?"}
        />
      )}

      {alert.message &&
        alert.screen === teacherConstants.SCREEN_ASSIGN_HOME_WORK && (
          <Alert
            alert={alert}
            onComplete={() => {
              history.push(
                "/" + authentication.role + "/class/view/" + id + "/exercise"
              );
              _dispatch({
                type: teacherConstants.RESET_DATA_EXERCISE,
              });
              _dispatch({
                type: teacherConstants.CLEAR_DATA_EXERCISES,
              });
            }}
          />
        )}
    </div>
  );
};

export default AssignSpecificStudents;
