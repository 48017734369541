import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "./../../../_actions";
import { teacherService } from "./../../../_services";
import { PopUpRemoveStudentInClass } from "../../Popup";
import { Alert } from "./../../../_components/Alert";
import {
  teacherConstants,
  configConstants,
  userConstants,
} from "././../../../_constants";
import Pagination from "react-js-pagination";
import { useParams } from "react-router-dom";
import { alertActions } from "../../../_actions/alerts";

function ListStudent(props) {
  const { id } = useParams();

  const [pagination] = useState({
    limit: configConstants.DEFAULT_LIMIT,
    offset: 0,
  });
  const [isLoadMore, setLoadMore] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const refsScroll = useRef(null);

  const alert = useSelector((state) => state.alert);
  const authentication = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const [dataStudent, setDataStudent] = useState({
    id: "",
    name: "",
    member_id: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  let { students, class_detail } = props;
  // console.log(class_detail);

  useEffect(() => {
    if (refsScroll && refsScroll.current) {
      refsScroll.current.addEventListener("scroll", handleScroll);
      return () =>
        refsScroll.current.removeEventListener("scroll", handleScroll);
    }
  }, [refsScroll, currentPage, isLoadMore]);

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
      isLoadMore &&
      !isLoading
    ) {
      // console.log('Scroll to bottom load more data');
      handlePageChange(currentPage + 1, false);
    }
  };

  useEffect(() => {
    if (
      alert.message &&
      alert.screen === teacherConstants.SCREEN_LIST_STUDENT
    ) {
      setCurrentPage(1);
    }
  }, [alert]);

  useEffect(() => {
    return () => {
      dispatch({
        type: teacherConstants.GET_STUDENT_OFF_CLASS,
        students: {
          data: [],
          base_url: "",
        },
      });
    };
  }, []);

  function showPopUpRemove(data) {
    setShowPopup(true);
    setDataStudent(data);
  }

  function removeStudent(id) {
    setShowPopup(false);
    teacherActions
      .removeStudent({
        id: props.class_id,
        students: id,
      })
      .then(
        (res) => {
          dispatch(
            alertActions.success({
              message: "Xoá học sinh thành công",
              screen: teacherConstants.SCREEN_LIST_STUDENT,
            })
          );
          let list = students.data.filter((item) => item.id !== id);
          dispatch({
            type: teacherConstants.GET_STUDENT_OFF_CLASS,
            students: {
              data: list,
              base_url: students.base_url,
            },
          });
        },
        (error) => {
          dispatch(
            alertActions.error({
              message: error.toString(),
              screen: teacherConstants.SCREEN_LIST_STUDENT,
            })
          );
        }
      );
  }

  // console.log(students.data);

  function removeStudentOffline(class_member_id) {
    setShowPopup(false);
    teacherActions.removeStudentOffline(class_member_id).then(
      (res) => {
        dispatch(
          alertActions.success({
            message: "Xoá học sinh thành công",
            screen: teacherConstants.SCREEN_LIST_STUDENT,
          })
        );
        let list = students.data.filter(
          (item) => item.member_id !== class_member_id
        );
        dispatch({
          type: teacherConstants.GET_STUDENT_OFF_CLASS,
          students: {
            data: list,
            base_url: students.base_url,
          },
        });
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_LIST_STUDENT,
          })
        );
      }
    );
  }

  function handlePageChange(pageNumber, isLoading) {
    if (currentPage !== pageNumber) {
      setLoading(true);
      teacherService
        .getStudentOfClass(
          id,
          pagination.limit,
          pagination.limit * (pageNumber - 1),
          isLoading
        )
        .then((res) => {
          setLoading(false);
          setLoadMore(res.data?.length === configConstants.DEFAULT_LIMIT);
          dispatch({
            type: teacherConstants.GET_STUDENT_OFF_CLASS,
            students: {
              data: [...students.data, ...res.data],
              base_url: students.base_url,
            },
          });
          setCurrentPage(pageNumber);
        });
    }
  }

  return (
    <Fragment>
      {alert.message &&
        alert.screen === teacherConstants.SCREEN_LIST_STUDENT && (
          <Alert alert={alert} />
        )}
      {showPopup && (
        <PopUpRemoveStudentInClass
          onClickYes={() =>
            class_detail.type == "offline"
              ? removeStudentOffline(dataStudent.member_id)
              : removeStudent(dataStudent.id)
          }
          onClickNo={() => setShowPopup(false)}
          message={dataStudent.name}
        />
      )}
      <div className="class_student_list">
        <h2 className="bold sunE-title-medium">Danh sách lớp</h2>
        <div className="custom-list-student-2">
          <div
            className="class_student_list_box scrollbar-custom flex-column"
            ref={refsScroll}
          >
            <div className="flex-1">
              {students.data.map((data, i) => {
                return (
                  <div className="list-student" key={data?.id}>
                    <div className="item-student flex-m align-item-center">
                      <span className="stt">{i + 1}</span>
                      <div className="item-student-img">
                        <img
                          src={students.base_url + "/" + data.avatar}
                          alt="avatar"
                        />
                      </div>
                      <div className="flex-1 item-student-name">
                        <p className="one-line">
                          {data.fullname ? data.fullname : data.username}
                        </p>
                      </div>
                      {authentication.role === userConstants.ROLE_TEACHER && (
                        <div
                          className="btn-remove-student"
                          onClick={() =>
                            showPopUpRemove({
                              id: data.id,
                              name: data.fullname,
                              member_id: data.member_id,
                            })
                          }
                        >
                          <img
                            src="/assets/images/teacher/ico_remove_blue.png"
                            alt="ico_remove_blue"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* {
                        students.total_student > configConstants.DEFAULT_LIMIT_STUDENT &&
                        <div className="text-right">
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={configConstants.DEFAULT_LIMIT_STUDENT}
                                totalItemsCount={students.total_student ? students.total_student : 0}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange.bind(this)}
                            />
                        </div>
                    } */}
        </div>
      </div>
    </Fragment>
  );
}

export { ListStudent };
