import { BoxTitlte } from "../_components/BoxTitle/BoxTitle";
import { handleCheckEven } from "./Validate";

// List detail with image success
const renderListDetail = (item, numberLine, typeFontSize) => {
  return (
    <div
      key={item.id}
      className={`${
        numberLine == 1 ? "flex-align" : "flex-all-start"
      } detail_item text-align-justify`}
      style={{
        marginBottom: "1.4rem",
      }}
    >
      <img
        loading="lazy"
        src="/assets/images/icon/ico_tick.png"
        alt="Icon Tick"
        style={{
          marginRight: "0.8rem",
          marginTop:
            typeFontSize == "smaller"
              ? "0.35rem"
              : typeFontSize == "tiny"
              ? "0.32rem"
              : numberLine != 1
              ? "0.7rem"
              : "",
        }}
        className={`icon_tick_success ${
          numberLine != 1 && " icon_tick_success_over"
        }`}
      />
      <div
        style={{
          fontSize:
            typeFontSize == "smaller"
              ? "1.375rem"
              : typeFontSize == "tiny"
              ? "1.25rem"
              : "1.7rem",
        }}
        className="fz-12-mobile name_detail"
      >
        {item?.name}
      </div>
    </div>
  );
};

const renderListDot = (item) => {
  return (
    <div className="text-align-justify  mg-bottom-0-9 bg_white" key={item.id}>
      <div className="text-align-justify fz-12-5 item_dot font_news_bold fz-1-mobile ">
        {item.name}
      </div>
      <div className="line_height_18-6-6 fz-1-mobile fz-12-5 text-align-justify ">
        {item?.content}
      </div>
      {item?.content_1 && (
        <div className="mg-bottom-0-9  mg-top-0-8 line_height_18-6-6 fz-1-mobile fz-12-5 text-align-justify ">
          {item?.content_1}
        </div>
      )}
      {item?.lists_1?.map((item) => renderListContent1(item))}
    </div>
  );
};

const renderListContent1 = (item) => {
  return (
    <div
      key={item.id}
      className={`${
        item?.lists_1 ? "flex-column" : "flex-align"
      } mg-bottom-0-9 bg_white line_height_18-6-6 ${
        item?.link && " flex-wrap"
      }`}
    >
      {item.content && (
        <span
          className={`fz-12-5 fz-1-mobile text-align-justify  mg-right-0-5 ${
            item.link && " white_space_pre"
          }`}
        >
          {item.content}

          {item.content_1_bold && (
            <span className="fz-12-5 fz-1-mobile font_news_bold line_height_18-6">
              {item.content_1_bold}{" "}
            </span>
          )}
          {item.content_1_regular && (
            <span className="fz-12-5 fz-1-mobile line_height_18-6">
              {item.content_1_regular}
            </span>
          )}
        </span>
      )}{" "}
      {item.content_bold && (
        <span
          className={`fz-12-5 fz-1-mobile text-align-justify font_news_bold mg-right-0-5 ${
            item.link && " white_space_pre"
          }`}
        >
          {item.content_bold}
        </span>
      )}{" "}
      {item.content_note && (
        <span
          className={`fz-12-5 fz-1-mobile color-red text-align-justify font_news_bold mg-right-0-5 ${
            item.link && " white_space_pre"
          }`}
        >
          {item.content_note}{" "}
          <span className="fz-12-5 fz-1-mobile">{item.content_note_1}</span>
          <span className="fz-12-5 fz-1-mobile font_news_bold line_height_18-6 ">
            {item.content_note_bold}
          </span>
        </span>
      )}{" "}
      {item?.link && (
        <div className="flex-align">
          <a
            href="https://sundayenglish.com/"
            className="color-primary-green fz-11-mobile fz-12-5 "
          >
            {item?.link}
          </a>
        </div>
      )}
      {item?.lists_1 && (
        <div className="flex-column pd-top-0-75-pc">
          {item?.lists_1?.map((item) => renderListContent1(item))}
        </div>
      )}
    </div>
  );
};

const renderListContent2 = (item) => {
  return (
    <div
      key={item.id}
      className="text-align-justify flex-align mg-bottom-0-9 bg_white"
    >
      <span className="line_height_18-6-6 fz-1-mobile fz-12-5 mg-left-0-5 text-align-justify ">
        {item.content}
      </span>
    </div>
  );
};

const renderListContent3 = (item) => {
  return (
    <div key={item.id} className="text-align-justify  mg-bottom-0-9 bg_white">
      <div className="text-align-justify  flex-align font_news_bold mg-bottom-0-9 fz-1-mobile fz-12-5">
        {item.title}
      </div>
      <ul className="list_style">
        {item?.data?.map((item) => renderListContentDefault(item))}
      </ul>
    </div>
  );
};

const renderListContentDefault = (item) => {
  return (
    <li
      key={item.id}
      className="text-align-justify pd-left-05-pc pos_rel mg-left-0-5  bg_white mg-bottom-0-9 flex-column fz-1-mobile list_style fz-12-5 font_news"
    >
      <div className="line_height_18-6-6 before_dot  pd-left-05-pc fz-1-mobile fz-12-5 font_news text-align-justify ">
        {item.content}
      </div>
      {item?.lists_1?.map((item) => renderListContent1(item))}
    </li>
  );
};

const renderListEleSide = (item) => {
  return (
    <div className="mg-bottom-0-9 bg_white" key={item.id}>
      <div className="text-align-justify  flex-align font_news_bold fz-1-mobile fz-12-5 mg-bottom-1">
        {item.name}
      </div>
      {item?.lists_1?.map((item) => renderListContent1(item))}
      {item?.lists_2?.map((item) => renderListContent3(item))}
    </div>
  );
};

const renderListPage1 = (data) => {
  return (
    <div key={data.id} className="text-align-justify pd_8per bg_white">
      <div
        className={`fz-12-5 fz-11-mobile color-red font_news_bold ${
          data.id != 5 && "pd-top-1 mg-bottom-1"
        } ${data.id == 4 && " pd-top-1-pc"}`}
      >
        {data.title}
      </div>

      <div className="flex-column">
        {data?.content_1 && (
          <div className="line_height_18-6-6 mg-bottom-0-9 fz-1-mobile fz-12-5 text-align-justify ">
            {data?.content_1}
          </div>
        )}
        {data?.lists_1?.map((item) => renderListContent1(item))}
        {data?.content_2 && (
          <div className="line_height_18-6-6 mg-bottom-0-9 fz-1-mobile fz-12-5 text-align-justify ">
            {data?.content_2}
          </div>
        )}
        {data?.lists_2?.map((item) => renderListContent1(item))}
        {data?.content_3 && (
          <div className="line_height_18-6-6 mg-bottom-0-9 fz-1-mobile fz-12-5 text-align-justify ">
            {data?.content_3}
          </div>
        )}
        <div className="list_item_dot mg-bottom-0-9">
          {data?.list_dot?.map((item) => renderListDot(item))}
        </div>
      </div>
    </div>
  );
};

const renderListPage2 = (data) => {
  return (
    <div key={data.id} className="bg_white text-align-justify ">
      <div
        className={`fz-12-5 fz-11-mobile color-red font_news_bold pd-top-1 mg-bottom-1`}
      >
        {data.title}
      </div>

      <div className="flex-column">
        {data?.content_1 && (
          <div className="line_height_18-6-6 mg-bottom-0-9 fz-1-mobile fz-12-5 text-align-justify ">
            {data?.content_1}
          </div>
        )}
        {data?.lists_1?.map((item) => renderListContent2(item))}
        {data?.lists_ele_side_1?.map((item) => renderListEleSide(item))}
        {data?.content_2 && (
          <div className="line_height_18-6-6 mg-bottom-0-9 fz-1-mobile fz-12-5 text-align-justify ">
            {data?.content_2}
          </div>
        )}
      </div>
    </div>
  );
};

// Content Payment
const renderContentPolicyPayment = (item) => {
  return (
    <div key={item.id} className=" text-align-justify mg-bottom-0-9 bg_white">
      <div className="text-align-justify  flex-align font_news mg-bottom-0-9 fz-1-mobile fz-12-5">
        {item.title}
      </div>
      <div className="flex-column-start col-100 text-align-justify flex-align font_news fz-1-mobile fz-12-5">
        {item?.data.map((item) => renderContentDefaultPolicyPayment(item))}
      </div>
    </div>
  );
};

const renderContentDefaultPolicyPayment = (item) => {
  return (
    <div key={item.id} className="flex-column-start col-100">
      <div className="mg-bottom-0-5 fz-12-5 fz-1-mobile font_news_bold">
        {item.title}
      </div>
      <ul className="list_style text-align-justify">
        {item?.list_1?.map((item) => renderListContentDotPayment(item))}
      </ul>
    </div>
  );
};

const renderListContentDotPayment = (item) => {
  return (
    <li
      key={item.id}
      className="col-100 pd-left-05-pc  pos_rel bg_white mg-bottom-0-5 flex-column fz-1-mobile list_style fz-12-5 font_news"
    >
      <div className="line_height_18-6-6  pd-left-05-pc fz-1-mobile fz-12-5 font_news text-align-justify ">
        {item?.content}
      </div>
      <div className="color-red font_news_bold line_height_18-6-6  pd-left-05-pc fz-1-mobile fz-12-5 font_news text-align-justify ">
        {item.content_note}{" "}
        <span className="fz-12-5 fz-1-mobile">{item.content_note_1}</span>
      </div>
    </li>
  );
};

//

const renderContentPolicyPayment2 = (item) => {
  return (
    <div
      key={item.id}
      className=" text-align-justify mg-bottom-0-9 bg_white col-100"
    >
      <div className="font_news_bold color-red  flex-align font_news mg-bottom-0-9 fz-11-mobile fz-12-5">
        {item.title}
      </div>
      <div className="flex-column-start col-100 text-align-justify flex-align font_news fz-1-mobile fz-12-5">
        {item?.data.map((item) => renderListContentDotPayment(item))}
      </div>
    </div>
  );
};

//==== CONTENT COURSES =====
const renderItemListDetailCourse = (item, courseID) => {
  return (
    <div key={item.id} className="flex-all-start detail_item pd-bottom-0-6">
      <img
        loading="lazy"
        src={`/assets/images/icon/${
          courseID == 1 ? "ico_tick_red" : "ico_tick"
        }.png`}
        alt="Icon Tick"
        style={{
          marginRight: "0.8rem",
          marginTop: "0.5rem",
        }}
        className="icon_tick_success"
      />
      <div>
        <span className="fz-12-5 fz-1-mobile name_detail">{item?.content}</span>{" "}
        <span className="fz-12-5 fz-1-mobile name_detail font_news_bold">
          {item?.content_1}
        </span>{" "}
        <span className="fz-12-5 fz-1-mobile name_detail">
          {item?.content_2 || null}
        </span>{" "}
        <span className="fz-12-5 fz-1-mobile name_detail font_news_bold">
          {item?.tail_content}
        </span>{" "}
        <span className="fz-12-5 fz-1-mobile name_detail">
          {item?.content_3}
        </span>{" "}
      </div>
    </div>
  );
};

const renderListDetailCourse = (item, courseID) => {
  return (
    <div
      key={item.id}
      className={`pos_rel border-1 detail_item_course line_height_18-6-6 text-align-justify detail_item_${item.id}`}
      style={{
        borderRadius: item.id <= 2 ? "5rem" : "2.5rem",
        paddingLeft: item.id != 1 ? (item.id == 2 ? "4vw" : "1.8vw") : "2.8vw",
        paddingRight: item.id != 1 ? (item.id == 2 ? "4vw" : "1.8vw") : "2.8vw",
      }}
    >
      <span className="fz-12-5">{item?.content}</span>
      <span className="fz-12-5 font_news_bold">{item?.tail_content}</span>
      {item?.list_detail?.map((item) =>
        renderItemListDetailCourse(item, courseID)
      )}

      {courseID == 1 && <BoxTitlte title={item?.title} id={item.id} />}
      {item.id == 2 && (
        <div className={`pos_abs zIndex1 img_bonus_${courseID}`}>
          <img
            loading="lazy"
            src={`/assets/images/coursesNews/${
              courseID == 1 ? "img_student_1" : "img_item_2"
            }.png`}
            alt="Img Goal"
            className="contain_image"
          />
        </div>
      )}
      {item.id == 4 && (
        <div className={`pos_abs zIndex1 img_order_${courseID}`}>
          <img
            loading="lazy"
            src={`/assets/images/coursesNews/img_item_4.png`}
            alt="Img Order"
            className="contain_image"
          />
        </div>
      )}
      {item.id == 3 && courseID == 1 && (
        <div className={`pos_abs zIndex1 img_book`}>
          <img
            loading="lazy"
            src={`/assets/images/coursesNews/img_item_3.png`}
            alt="Img Book"
            className="contain_image"
          />
        </div>
      )}
    </div>
  );
};

const renderCourseItem = (data) => {
  return (
    <div key={data.id} id={data.type} className="flex-column col-45 ">
      <div
        className={`flex-center col-100 pd-bottom-3 course_title_${data.id}`}
      >
        <div
          className="col-100 flex-center img_border_courses"
          style={{
            background: `url(
              /assets/images/coursesNews/border_${data.id}.png
            ) no-repeat top center /contain`,
          }}
        >
          <div className="font_news_bold color-white course_title_name">
            {data.name}
          </div>
        </div>
      </div>

      <div
        className={`list_boxes flex-column ${
          data.id == 1 ? " list_boxes_1" : " list_boxes_2"
        }`}
      >
        {data.data.map((item) => renderListDetailCourse(item, data.id))}
      </div>
    </div>
  );
};

// Courses Mobile
const renderListDetailCourseMobile = (item, courseID) => {
  return (
    <div
      key={item.id}
      className={`pos_rel border-1 detail_item_course_mobile ${
        item.id == 1 && "flex-align"
      } col-100-mobile line_height_14 text-align-justify detail_item_${
        item.id
      }`}
      style={{
        borderRadius: "1.2rem",
        paddingLeft: item.id != 1 ? (item.id == 2 ? "10vw" : "5.8vw") : "5.8vw",
        paddingRight:
          item.id != 1 ? (item.id == 2 ? "10vw" : "5.8vw") : "5.8vw",
      }}
    >
      {item.id == 1 && (
        <div className="img_item_1">
          <img
            loading="lazy"
            src={`/assets/images/coursesNews/img_student_${courseID}.png`}
            alt="Img Student"
            className="contain_image"
          />
        </div>
      )}
      <span>
        <span className="fz-1-mobile">{item?.content}</span>
        <span className="fz-1-mobile font_news_bold">{item?.tail_content}</span>
        {item?.list_detail?.map((item) =>
          renderItemListDetailCourse(item, courseID)
        )}
      </span>

      <div
        className={`pos_abs ${
          handleCheckEven(item.id) ? "box_title_even" : "box_title_odd"
        }`}
      >
        <BoxTitlte title={item?.title} id={item.id} />
      </div>

      <div className="img_item">
        {item.id != 1 && (
          <div className={`pos_abs zIndex1 img_item_${item.id}`}>
            <img
              loading="lazy"
              src={`/assets/images/coursesNews/img_item_${item.id}.png`}
              alt="Img Goal"
              className="contain_image"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export {
  renderListContent1,
  renderListDetail,
  renderListDot,
  renderListPage1,
  renderListPage2,
  renderListContent3,
  renderContentPolicyPayment,
  renderContentPolicyPayment2,
  renderListDetailCourse,
  renderCourseItem,
  renderListDetailCourseMobile,
};
