import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "./../../../_components/Admin/Header";
import { useHistory } from "react-router-dom";
import { userConstants } from "./../../../_constants";
import moment from "moment";
import { isEmpty } from "lodash";
import styled from "styled-components";
import $ from "jquery";
import LazyLoad from "react-lazyload";

const ProfileImage = styled.img`
  object-fit: cover;
`;

function HomePageProfile() {
  const history = useHistory();

  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(teacherActions.getProfile(authentication.id));
  }, []);

  const dataProfile = useSelector((state) => state.profile.user_info.data);
  const baseUrl = useSelector((state) => state.profile.base_url);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [dataProfile]);

  const dateHere = String(dataProfile.birthday).split(" ");
  const days = String(dateHere[0]).split("-");
  const formatDays = days[2] + "/" + days[1] + "/" + days[0];

  function editProfile() {
    return (
      <div
        className={
          authentication.role === userConstants.ROLE_STUDENT
            ? "mr-10 rel edit-profile-student"
            : "mr-10"
        }
      >
        <Link to={"/" + authentication.role + "/more/profile/edit"}>
          <button className="btn-line-blue">Chỉnh sửa</button>
        </Link>
        {authentication.role === userConstants.ROLE_STUDENT && (
          <img
            src="/assets/images/student/ico_edit_profile.png"
            alt="ico_edit_profile"
            className="ico_edit_profile"
          />
        )}
      </div>
    );
  }

  // console.log(dataProfile);
  return (
    <div className="sunE-container flex bg-main-color">
      <div className="sunE-right-container flex-column">
        <Header
          title={"Hồ sơ"}
          component={editProfile()}
          isBack
          clickBack={() => history.push("/" + authentication.role + "/more")}
        />
        {!isLoading && (
          <div className="sunE-container-box detail flex-1 rel">
            <div className="rel text-center">
              {authentication.role === userConstants.ROLE_STUDENT && (
                <img
                  src="/assets/images/hoso/bg_hoso_detail_student.png"
                  alt="bg_hoso_detail_student"
                />
              )}
              {authentication.role === userConstants.ROLE_TEACHER && (
                <img
                  src="/assets/images/hoso/bg_hoso_detail.png"
                  alt="bg_hoso_detail"
                />
              )}
            </div>
            <div
              className={
                "box-detail-ab" +
                (authentication.role === userConstants.ROLE_STUDENT
                  ? " student-ab"
                  : "")
              }
            >
              <div
                className={
                  "box-detail-hs rel box-shadow" +
                  (authentication.role === userConstants.ROLE_STUDENT ? "" : "")
                }
              >
                <LazyLoad once className="avt-ab">
                  <ProfileImage
                    src={
                      dataProfile.avatar
                        ? dataProfile.avatar
                        : "/assets/images/hoso/default_avt.png"
                    }
                    alt="default_avt"
                  />
                </LazyLoad>
                <h2 className="name">{dataProfile.fullname}</h2>
                <div className="detail-info">
                  {/* <div className="text-center">{dataProfile.email}</div>
                <br /> */}
                  <div className="flex-m">
                    <div className="mr-10 w25">
                      <img
                        src="/assets/images/hoso/ico_gender.png"
                        alt="ico_gender"
                      />
                    </div>
                    <span className="info flex-1">
                      {dataProfile.gender === "female" ? "Nữ" : "Nam"}
                    </span>
                  </div>
                  <div className="flex-m">
                    <div className="mr-10 w25">
                      <img
                        src="/assets/images/hoso/ico_birthday.png"
                        alt="ico_birthday"
                      />
                    </div>
                    <span className="info flex-1">{formatDays}</span>
                  </div>
                  <div className="flex-m">
                    <div className="mr-10 w25">
                      <img
                        src="/assets/images/hoso/ico_phone.png"
                        alt="ico_phone"
                      />
                    </div>
                    <span className="info flex-1">{dataProfile.phone}</span>
                  </div>
                  <div className="flex-m">
                    <div className="mr-10 w25">
                      <img
                        src="/assets/images/hoso/ico_email.png"
                        alt="ico_email"
                      />
                    </div>
                    <span className="info flex-1">{dataProfile.email}</span>
                  </div>
                  {authentication.role === userConstants.ROLE_STUDENT && (
                    <div>
                      <div className="flex-m">
                        <div className="mr-10 w25">
                          <img
                            src="/assets/images/student/ico_class.png"
                            alt="ico_class"
                          />
                        </div>
                        <span className="info flex-1">
                          {dataProfile.grade_name}
                        </span>
                      </div>
                      <div className="flex-m">
                        <div className="mr-10 w25">
                          <img
                            src="/assets/images/student/ico_school.png"
                            alt="ico_school"
                          />
                        </div>
                        <span className="info flex-1">
                          {dataProfile.school}
                        </span>
                      </div>
                    </div>
                  )}
                  {authentication.role === userConstants.ROLE_TEACHER && (
                    <div className="flex-m">
                      <div className="mr-10 w25">
                        <img
                          src="/assets/images/hoso/ico_shcool.png"
                          alt="ico_shcool"
                        />
                      </div>
                      <span className="info flex-1">{dataProfile.school}</span>
                    </div>
                  )}
                  <div className="ltk flex-m">
                    <div className="flex-1">
                      <span className="info">Tài khoản:</span>
                    </div>
                    <div className="flex-1 text-center">
                      <p className="bold blue-main">Free</p>
                    </div>
                    <div className="flex-1 text-right">
                      {authentication.role !== userConstants.ROLE_TEACHER && (
                        <span className="btn-nc">
                          <Link to={"/" + authentication.role + "/license"}>
                            Nâng cấp
                          </Link>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* {authentication.role === userConstants.ROLE_TEACHER && (
              <div className="info-sum flex-m">
                <div className="flex-1 text-center">
                  <h3 className="bold blue-main">{dataProfile.class_number}</h3>
                  <span className="info">Lớp</span>
                </div>
                <div className="flex-1 text-center">
                  <h3 className="bold blue-main">
                    {dataProfile.student_number}
                  </h3>
                  <span className="info">Học sinh</span>
                </div>
                <div className="flex-1 text-center">
                  <h3 className="bold blue-main">
                    {dataProfile.curriculum_number}
                  </h3>
                  <span className="info">Giáo trình</span>
                </div>
              </div>
            )} */}
              {authentication.role === userConstants.ROLE_STUDENT && (
                <div className="info-parent">
                  <div
                    className="ph-add-gr flex-m"
                    style={{ alignItems: "center" }}
                  >
                    {isEmpty(dataProfile.list_parent) && (
                      <span style={{ fontSize: 18, fontWeight: "bold" }}>
                        Phụ huynh{" "}
                      </span>
                    )}
                    {isEmpty(dataProfile.list_parent) && (
                      <Link
                        to={"/" + authentication.role + "/more/link-account"}
                      >
                        <div className="btn-add-ph flex-m">
                          <img
                            src="/assets/images/student/ico_add_ph.png"
                            alt="ico_add_ph"
                          />
                          <span>Thêm liên kết</span>
                        </div>
                      </Link>
                    )}
                    <div className="flex-1"></div>
                  </div>
                  {!isEmpty(dataProfile.list_parent) && (
                    <div className="parent-info flex-m">
                      <div className="avatar">
                        <img
                          src={
                            dataProfile.list_parent[0].avatar
                              ? baseUrl + dataProfile.list_parent[0].avatar
                              : "/assets/images/hoso/default_avt.png"
                          }
                          alt="ico_add_ph"
                        />
                      </div>
                      <div className="flex-1">
                        <p>{dataProfile.list_parent[0].fullname}</p>
                        <span>
                          Đã liên kết từ{" "}
                          {moment(
                            dataProfile.list_parent[0].time_request
                          ).format("DD/MM/YYYY")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export { HomePageProfile };
