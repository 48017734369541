import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { useParams } from "react-router-dom";
import { PickDay } from "../../../_components/Calendar/PickDay";
import moment from "moment";
import _ from "lodash";
import { teacherConstants } from "../../../_constants";
import { useHistory } from "react-router-dom";
import { Alert } from "../../../_components/Alert";
import { Header } from "../../../_components/Admin/Header";
import { isEmpty, findIndex } from "lodash";
import { teacherService } from "../../../_services";
import { validateNumber } from "../../../_base/Validate";
import { SelectDate } from "../../../_components/Calendar";

function AddStudentExercise() {
  const history = useHistory();
  const alert = useSelector((state) => state.alert);

  const dispatch = useDispatch();
  const { id, exercise_id } = useParams();
  const classes = useSelector((state) => state.classes);
  const authentication = useSelector((state) => state.authentication);
  const exercises = useSelector((state) => state.classes.exercises);
  const edit_exercises = useSelector((state) => state.classes.edit_exercises);
  const [isLoading, setLoading] = useState(false);
  const sourcePageAddExcercise = useSelector(
    (state) => state.classes.sourcePageAddExcercise
  );
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const fromPage = params.get("page");

  // const [isSuccessDate, setIsSuccessDate] = useState(true)
  const [isScrollable, setIsScrollable] = useState(false);
  const checkScroll = useRef(null);

  useEffect(() => {
    const checkScrollAbility = () => {
      if (checkScroll.current) {
        const div = checkScroll.current;
        if (div.scrollHeight > div.clientHeight) {
          setIsScrollable(true);
        } else {
          setIsScrollable(false);
        }
      }
    };

    const handleResize = () => {
      checkScrollAbility();
    };

    window.addEventListener("resize", handleResize);

    setTimeout(checkScrollAbility, 500);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // dispatch(teacherActions.getStudentOfClass(id, 999));
    setLoading(true);
    teacherService.getStudentOfClass(id, 999, 0).then((students) => {
      if (students) {
        dispatch({
          type: teacherConstants.GET_STUDENT_OFF_CLASS,
          students,
        });
      }
      setLoading(false);
    });
  }, []);

  const [listStudentIdAll, setListStudentIdAll] = useState(
    exercises.students ? exercises.students : []
  );
  const [selectAll, setSelectAll] = useState(
    listStudentIdAll.length > 0 &&
      listStudentIdAll.length === classes.students.data.length
      ? true
      : false
  );
  const [inputs, setInputs] = useState({
    start_time: !isEmpty(edit_exercises)
      ? moment(edit_exercises.exercise_info.start_time).format("YYYY-MM-DD")
      : !isEmpty(exercises?.start_time)
      ? moment(exercises?.start_time).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
    end_time: !isEmpty(edit_exercises)
      ? moment(edit_exercises.exercise_info.end_time).format("YYYY-MM-DD")
      : !isEmpty(exercises?.end_time)
      ? moment(exercises?.end_time).format("YYYY-MM-DD")
      : moment().add(2, "days").format("YYYY-MM-DD"),
    note: !isEmpty(edit_exercises)
      ? edit_exercises.exercise_info.note || ""
      : !isEmpty(exercises?.note)
      ? exercises?.note
      : "",
    remind_before: !isEmpty(edit_exercises)
      ? edit_exercises.exercise_info.remind_before || 1
      : !isEmpty(exercises?.remind_before)
      ? exercises.remind_before
      : 1,
    before_start_time: !isEmpty(edit_exercises)
      ? parseInt(edit_exercises.exercise_info.before_start_time) === 1
      : parseInt(exercises.before_start_time) === 1,
  });

  // console.log('edit_exercises', edit_exercises,inputs)
  const [remind, setRemind] = useState(
    edit_exercises &&
      parseInt(edit_exercises.exercise_info?.remind_before || 0) !== 0
      ? true
      : exercises?.note
      ? true
      : false
  );

  function handleChangeDate(date) {
    if (date.start_time) {
      if (
        moment(date.start_time).toDate() <=
        moment(inputs.end_time, "DD-MM-YYYY").toDate()
      ) {
        setInputs((inputs) => ({
          ...inputs,
          start_time:
            moment(date.start_time).format("DD-MM-YYYY") || inputs.start_time,
        }));
      }
    } else if (date.end_time) {
      if (
        moment(date.end_time).toDate() >=
        moment(inputs.start_time, "DD-MM-YYYY").toDate()
      ) {
        setInputs((inputs) => ({
          ...inputs,
          end_time:
            moment(date.end_time).format("DD-MM-YYYY") || inputs.end_time,
        }));
      }
    }
  }

  function onChangeSelectAll() {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setListStudentIdAll(classes.students.data.map((obj) => obj.id));
    } else {
      setListStudentIdAll([]);
      let ListUserDidPick = [];
      if (!isEmpty(edit_exercises)) {
        ListUserDidPick = edit_exercises.list_student.map((obj) => obj.user_id);
      }
      setListStudentIdAll(ListUserDidPick);
      if (ListUserDidPick.length === classes.students.data.length) {
        setSelectAll(true);
      }
    }
  }

  function hanldeChecked(id) {
    if (
      isEmpty(edit_exercises) ||
      findIndex(edit_exercises.list_student, { user_id: id }) === -1
    ) {
      if (selectAll) {
        setSelectAll(false);
      }
      if (!listStudentIdAll.includes(id)) {
        listStudentIdAll.push(id);
      } else {
        const index = listStudentIdAll.indexOf(id);
        if (index > -1) {
          listStudentIdAll.splice(index, 1);
        }
      }
      setListStudentIdAll([...listStudentIdAll]);
      if (listStudentIdAll.length === classes.students.data.length) {
        setSelectAll(true);
      }
    }
  }

  function handleChange(e) {
    const { name } = e.target;
    if (e.target.value === "") {
      setInputs((inputs) => ({ ...inputs, [name]: e.target.value }));
    } else {
      let value = "";
      if (_.isEqual(name, "note")) {
        value = e.target.value;
      } else {
        value = parseInt(e.target.value.replace(/\D/, ""));
      }
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  }

  function handleOnBlur(e) {
    const { name } = e.target;
    if (e.target.value === "") {
      setInputs((inputs) => ({ ...inputs, [name]: 0 }));
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validateParam()) {
      let data = {
        ...inputs,
        students: listStudentIdAll,
        before_start_time: inputs.before_start_time ? 1 : 0,
        data_exercise: exercises.data_exercise,
        data_lessons_selected: exercises.data_lessons_selected,
        class_id: id,
        exercise_id,
        files: exercises.files,
        histories: exercises.histories,
      };
      if (!exercise_id) {
        console.log(data);
        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data,
        });
        dispatch({
          type: teacherConstants.SAVE_DATE_ADD_STUDENT_EXERCISE,
          data: {
            start_time: inputs.start_time,
            end_time: inputs.end_time,
          },
        });
        if (!_.isEmpty(exercises.data_exercise)) {
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              `/exercise/review?${!isEmpty(fromPage) ? `page=${fromPage}` : ""}`
          );
        } else {
          localStorage.removeItem("type_assign");
          fromPage == "curriculum";
          history.push(
            "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/student/selection_assign"
          );
        }
      } else {
        dispatch(teacherActions.udpateExercise(data));
      }
    }
  }

  function reFormatDate(time) {
    let Date = time.substr(0, 2);
    let Month = time.substr(3, 2);
    let Year = time.substr(6, 4);
    return Year + "-" + Month + "-" + Date;
  }
  var start_time = new Date(reFormatDate(inputs.start_time));
  var end_time = new Date(reFormatDate(inputs.end_time));
  const diffTime = Math.abs(inputs.end_time - inputs.start_time);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  function validateParam() {
    if (
      _.isEmpty(listStudentIdAll) ||
      (remind && !inputs.note) ||
      (remind && parseInt(diffDays) < parseInt(inputs.remind_before)) ||
      new Date(inputs.start_time) > new Date(inputs.end_time) ||
      (new Date(inputs.end_time) - new Date(inputs.start_time)) /
        (1000 * 60 * 60 * 24) <
        parseInt(inputs.remind_before)
    ) {
      return false;
    }
    return true;
  }

  function changeBeforeStart() {
    if (
      isEmpty(edit_exercises) ||
      !edit_exercises.exercise_info.before_start_time
    ) {
      setInputs({ ...inputs, before_start_time: !inputs.before_start_time });
    }
  }

  function changeRemind() {
    if (isEmpty(edit_exercises) || !edit_exercises.exercise_info.remind) {
      if (remind) {
        setInputs({ ...inputs, remind_before: 0, note: "" });
      }
      setRemind(!remind);
    }
  }

  function navigateToSourcePage() {
    if (sourcePageAddExcercise === "PAGE_CURRICULUM") {
      history.push("/" + authentication.role + "/curriculum");
    } else if (sourcePageAddExcercise === "PAGE_HISTORY_ADD_EXCERCISE") {
      history.push(
        "/" +
          authentication.role +
          `/more/exercise` +
          (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
      );
    } else {
      history.push(
        "/" + authentication.role + "/class/view/" + id + "/exercise"
      );
    }
    dispatch({
      type: teacherConstants.CLEAR_DATA_EXERCISES,
    });
  }

  if (isLoading) return null;

  const handleChangeNumber = (e) => {
    setInputs((inputs) => ({
      ...inputs,
      [e.target.name]: validateNumber(e.target.value),
    }));
  };

  return (
    <div className="sunE-right-container add_student_container ">
      <Header
        title={"Giao bài"}
        isBack
        clickBack={() => navigateToSourcePage()}
      />
      {alert.message &&
        alert.screen === teacherConstants.SCREEN_ADD_STUDENT_EXERCISE && (
          <Alert
            alert={alert}
            onComplete={() =>
              history.push(
                "/" +
                  authentication.role +
                  "/class/view/" +
                  id +
                  "/exercise/" +
                  exercise_id
              )
            }
          />
        )}
      <div className="flex-m">
        <div className="flex-3 pr-15">
          <div className="class_student_list dk_class">
            <div className="flex-m">
              <div className="flex-1">
                <h2 className="bold sunE-title-medium line-h-44 f20">
                  Chọn học viên
                </h2>
              </div>
              <div
                className={`select-all checbox-right ${
                  isScrollable ? "change_point_checkbox_add_exercise" : ""
                }`}
              >
                <span>Chọn tất cả</span>
                <span className="chk-custom-gr wh20 check-select-student">
                  <input
                    type="checkbox"
                    name="all"
                    id="all"
                    checked={selectAll ? true : false}
                    onChange={onChangeSelectAll}
                  />
                  <label htmlFor="all"></label>
                </span>
              </div>
            </div>
            <div
              className="box-shadow custom-list-student class_student_list_box scrollbar-custom h-160"
              ref={checkScroll}
            >
              {!isEmpty(classes.students) &&
                !isEmpty(classes.students.data) &&
                classes.students.data.map((data, i) => {
                  return (
                    <div className="list-student" key={i}>
                      <div className="item-student flex-m">
                        <span className="stt">{i + 1}</span>
                        <div className="item-student-img">
                          <img
                            src={classes.students.base_url + "/" + data.avatar}
                            alt="avatar"
                          />
                        </div>
                        <div className="flex-1 item-student-name flex-align">
                          <p className="two-line">
                            {data.fullname || data.username}
                          </p>
                        </div>
                        <div
                          className="btn-check-gr"
                          style={{
                            paddingTop: 0,
                          }}
                        >
                          <span className="chk-custom-gr wh20 check-select-student">
                            <input
                              type="checkbox"
                              name="pnv"
                              defaultValue="pnv"
                              checked={
                                listStudentIdAll.includes(data.id)
                                  ? true
                                  : false
                              }
                              onChange={() => hanldeChecked(data.id)}
                              id={data.id}
                            />
                            <label htmlFor={data.id}></label>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="flex-2 pl-15 flex-column">
          <div className="flex-1">
            <div className="flex-m class-slect-time mgh">
              <div className="flex-1 mg-right-1">
                <div className=" text-align-center sunE-input-group slect-time">
                  <label>Ngày bắt đầu</label>
                  <div
                    className={
                      "select-gr select-time-center text-align-center" +
                      (inputs.start_time > inputs.end_time ? " err" : "")
                    }
                  >
                    {/* <PickDay
                      time={inputs.start_time}
                      name_time={"start_time"}
                      handleChangeDate={handleChangeDate}
                    /> */}
                    <SelectDate
                      showTimeSelect={false}
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy"
                      selected={
                        inputs?.start_time !== ""
                          ? new Date(inputs?.start_time)
                          : inputs?.start_time
                      }
                      onChange={(start_time) => {
                        new Date(start_time) <= new Date(inputs.end_time) &&
                          new Date(start_time) >=
                            moment(new Date()).startOf("day") &&
                          setInputs((inputs) => ({
                            ...inputs,
                            start_time:
                              moment(start_time).format("YYYY-MM-DD") ||
                              inputs.start_time,
                          }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-1">
                <div className="text-align-center sunE-input-group slect-time">
                  <label>Ngày kết thúc</label>
                  <div
                    className={
                      "select-gr select-time-center text-align-center" +
                      (inputs.start_time > inputs.end_time ? " err" : "")
                    }
                  >
                    {/* <PickDay
                      time={inputs.end_time}
                      name_time={"end_time"}
                      handleChangeDate={handleChangeDate}
                    /> */}

                    <SelectDate
                      showTimeSelect={false}
                      timeIntervals={15}
                      dateFormat="dd/MM/yyyy"
                      selected={
                        inputs?.end_time !== ""
                          ? new Date(inputs.end_time)
                          : inputs.end_time
                      }
                      onChange={(end_time) => {
                        new Date(inputs.start_time) <= new Date(end_time) &&
                          setInputs((inputs) => ({
                            ...inputs,
                            end_time:
                              moment(end_time).format("YYYY-MM-DD") ||
                              inputs.end_time,
                          }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="class-on-off">
              <div className="check-box-gr">
                <span className="chk-custom-gr wh20 check-assigned ">
                  <input
                    type="checkbox"
                    name="offline"
                    defaultValue="offline"
                    id="offline"
                    onChange={() => changeRemind()}
                    checked={remind ? true : false}
                  />
                  <label htmlFor="offline">Nhắc nhở chung</label>
                </span>
              </div>

              {remind && (
                <div className="check-box-gr">
                  <div className="nhacnho-content">
                    <textarea
                      name="note"
                      id="message"
                      rows="3"
                      onChange={handleChange}
                      placeholder="Nhập nhắc nhở"
                      defaultValue={inputs.note}
                    ></textarea>
                    <div className="datlichnhac-input-gr">
                      <span className="left">Nhắc trước</span>
                      <input
                        type="text"
                        name="remind_before"
                        onChange={handleChangeNumber}
                        className="dln-mins day"
                        onBlur={handleOnBlur}
                        value={inputs.remind_before}
                      />
                      <span className="right">Ngày hết hạn</span>
                    </div>
                    {(new Date(inputs.end_time) - new Date(inputs.start_time)) /
                      (1000 * 60 * 60 * 24) <
                      parseInt(inputs.remind_before) && (
                      <div className="flex-m err-nhacnho err">
                        <div>
                          <img
                            src="/assets/images/icon/warning.png"
                            alt="warning"
                            className="contain_image"
                            style={{
                              width: "2.2rem",
                            }}
                          />
                        </div>
                        <div className="flex-1 pl-15 pr-15">
                          <p style={{ textAlign: "justify" }}>
                            Số ngày nhắc trước không được quá tổng thời gian kể
                            từ ngày bắt đầu giao bài cho đến ngày hết hạn.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="check-box-gr flex-m">
                <span className="chk-custom-gr wh20 check-assigned">
                  <input
                    type="checkbox"
                    onChange={() => changeBeforeStart()}
                    id="beforestart"
                    checked={inputs.before_start_time ? true : false}
                  />
                  <label htmlFor="beforestart"></label>
                </span>
                <label className="flex-1">
                  Cho phép học viên làm trước ngày bắt đầu
                </label>
              </div>
            </div>
          </div>
          <div className="form-sunE-button text-right mb0">
            <button
              className={
                "btn-bg-blue btn-default" +
                (!validateParam() ? " btn-disable" : "")
              }
              onClick={handleSubmit}
            >
              Tiếp tục
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export { AddStudentExercise };
