import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { teacherConstants } from "./../../../_constants";
import { teacherService } from "../../../_services";
import { Header } from "../../../_components/Admin/Header";
import { SelectAsDiv } from "../../../_components/Select";
import { PopUpAddCommentRollUp } from "../../../_components/Popup";
import {
  PopUpSelectObjectRollUp,
  PopUpYesNo,
} from "../../../_components/Popup";
import { teacherActions } from "../../../_actions";
import { Alert } from "./../../../_components/Alert";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { forEach } from "lodash";
import _ from "lodash";

function AddRollUp() {
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  const authentication = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const { id, rollUpId } = useParams();

  // console.log(rollUpId);
  
  let classes = useSelector((state) => state.classes);
  const [numberAbsence, setNumberAbsence] = useState([]);
  const [inputs, setInputs] = useState({
    date: moment(classes.add_roll_up.date).format("DD/MM/YYYY HH:mm"),
    send_content: "student",
    send_to_parent: true,
    class_id: id,
    list_student: [],
  });
  const [isSendedBox, checkSendBox] = useState(false);
  const [students, setStudents] = useState({
    data: [],
  });
  const [avatarStudents, setAvatarStudents] = useState([]);

  const [listStudentData, setListStudentData] = useState([]);
  const [valueCommentAll, setValueCommentAll] = useState("");
  const [listItemSended, setListSended] = useState([]);
  const [isSHowConfirmEdit, showConfirmEdit] = useState(false);
  const [{ commentReplaceAll, isConfirmReplaceAll }, confirmReplaceCommentAll] =
    useState({});
  useEffect(() => {
    teacherService.getStudentOfClass(id, 999).then((students) => {
      if (students) {
        setAvatarStudents((prev) => {
          let list = [...prev];
          students.data.map((student) => {
            list.push(student.avatar);
          });

          return list;
        });
      }
      // console.log(students);
    });
  }, []);

  // console.log(avatarStudents);

  useEffect(() => {
    if (rollUpId) {
      dispatch(
        teacherActions.getRollUpById(rollUpId, (res) => {
          if (res && res.data) {
            // console.log(res);
            let data = {
              date: res.data.date_roll_up.replaceAll("-", "/"),
              send_content: res.data.send_content,
              send_to_parent: parseInt(res.data.send_to_parent) === 1,
              class_id: res.data.class_id,
              list_student: res.data.data_detail,
            };
            let list = [];
            res.data.data_detail.forEach((item, index) => {
              // console.log(item);
              if (
                item.status === "absence_not_allowed" ||
                item.status === "absence_allowed"
              ) {
                list.push(item.id);
              }
            });
            let listMessSended = res.data.data_detail.filter(
              (item) => item.send_to_parent === 1
            );
            setListSended(listMessSended);
            setNumberAbsence(list);
            setListStudentData(res.data.data_detail);
            setStudents({
              base_url: res?.base_url,
              data: res.data.data_detail,
              avatar: avatarStudents,
            });
            setInputs(data);
            checkSendBox(parseInt(res.data.send_to_parent) === 1);
          }
        })
      );
    } else {
      teacherService.getStudentOfClass(id, 999).then((students) => {
        setStudents(students);
      });
    }
  }, [rollUpId, avatarStudents]);

  function hanldeChecked(data) {
    var index = _.findIndex(listStudentData, { id: data.id });
    if (index === -1) {
      let data_student = {
        id: data.id,
        fullname: data.fullname,
        comment: data.comment,
        status: data.status ? data.status : "intime",
        send_to_parent: 1,
      };
      listStudentData.push(data_student);
      if (students.data.length === 1) {
        setInputs({
          ...inputs,
          send_to_parent: true,
        });
      }
    } else {
      listStudentData[index].send_to_parent =
        parseInt(data.send_to_parent || 0) === 1 ? 0 : 1;
      if (students.data.length === 1) {
        setInputs({
          ...inputs,
          send_to_parent: false,
        });
      }
    }

    let count_students_checked = listStudentData.filter(
      (item) => parseInt(item.send_to_parent) === 1
    ).length;

    if (count_students_checked === students.data.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }

    setListStudentData([...listStudentData]);
  }

  function handleSubmit() {
    if (validateParam()) {
      let list_student = listStudentData;
      students.data.forEach((student) => {
        var index = _.findIndex(listStudentData, {
          id: student.id || student.member_id,
        });
        if (_.isEqual(index, -1)) {
          list_student.push({
            id: student.id || student.member_id,
            fullname: student.fullname,
            comment: "",
            status: "intime",
            send_to_parent: 0,
          });
        }
      });
      if (rollUpId) {
        dispatch(
          teacherActions.updateRollUp({
            list_student: _.isEmpty(list_student)
              ? [{ send_to_parent: 0 }]
              : list_student,
            send_to_parent: selectAll ? 1 : 0,
            total_student: students.data.length || 0,
            number_absence: numberAbsence.length,
            class_id: id,
            roll_up_id: rollUpId,
          })
        );
        showConfirmEdit(false);
      } else {
        dispatch(
          teacherActions.createRollUp({
            ...inputs,
            list_student: _.isEmpty(list_student)
              ? [{ send_to_parent: 0 }]
              : list_student,
            send_to_parent: selectAll ? 1 : 0,
            total_student: students.data.length || 0,
            number_absence: numberAbsence.length,
          })
        );
      }
    }
  }

  function addComponenComment() {
    return (
      <div
        className="form-sunE-button mr-10"
        onClick={() => openPopUpComment(0, "Nhận xét chung", valueCommentAll)}
      >
        <button className="btn-line-blue">Nhận xét chung</button>
      </div>
    );
  }

  function changeStatus(key, status, id) {
    students.data[key].status = status;
    setStudents({ ...students });
    var index = _.findIndex(listStudentData, { id });
    if (index !== -1) {
      listStudentData[index].status = status;
      setListStudentData([...listStudentData]);
    } else {
      let data = students.data[key];
      let data_student = {
        id: data.id || data.member_id,
        fullname: data.fullname,
        comment: data.comment,
        status: status,
        send_to_parent: 0,
      };
      listStudentData.push(data_student);
    }
    if (status === "absence_not_allowed" || status === "absence_allowed") {
      if (!numberAbsence.includes(students.data[key].id)) {
        setNumberAbsence([...numberAbsence, students.data[key].id]);
      }
    } else {
      if (numberAbsence.includes(students.data[key].id)) {
        let list = numberAbsence.filter(
          (item) =>
            item != students.data[key].id &&
            item != students.data[key].member_id
        );
        setNumberAbsence([...list]);
      }
    }
  }

  const confirmReplaceAllComment = (comment) => {
    // let isExistCmtPersonal =
    //   listStudentData.filter((item) => !_.isEmpty(item.comment)).length > 0;
    // if (isExistCmtPersonal) {
    //   confirmReplaceCommentAll({
    //     commentReplaceAll: comment,
    //     isConfirmReplaceAll: false,
    //   });
    // } else {
    //   commentAll(comment);
    // }
    commentAll(comment);
  };

  // console.log(students);

  function commentAll(comment, isSkip) {
    comment = comment.trim();
    confirmReplaceCommentAll({});
    if (dataPopupComment.id === 0) {
      setValueCommentAll(comment);
      let list_student = [];
      students.data.map((data) => {
        let dataSelect = listStudentData.find(
          (item) => item.id === data.id || item.id === data.member_id
        );
        if (dataSelect) {
          if (
            _.isEmpty(dataSelect.comment) ||
            !isSkip ||
            dataSelect.isCommentNormal
          ) {
            dataSelect.comment = comment;
            dataSelect.isCommentNormal = true;
          }
          // console.log(dataSelect);
          list_student.push(dataSelect);
        } else {
          let data_student = {
            id: data.id || data.member_id,
            fullname: data.fullname,
            comment: comment,
            status: data.status ? data.status : "intime",
            send_to_parent: 0,
            isCommentNormal: true,
          };
          list_student.push(data_student);
        }
        return dataSelect;
      });
      setListStudentData(list_student);
    } else {
      var indexStudents = _.findIndex(students.data, {
        id: dataPopupComment.id,
      });
      if (indexStudents !== -1) {
        students.data[indexStudents].comment = comment;
        setStudents({ ...students });
      }
      indexStudents = _.findIndex(listStudentData, { id: dataPopupComment.id });
      if (indexStudents !== -1) {
        listStudentData[indexStudents].comment = comment;
        listStudentData[indexStudents].isCommentNormal = false;
        setListStudentData([...listStudentData]);
      } else {
        indexStudents = _.findIndex(students.data, { id: dataPopupComment.id });
        let data = students.data[indexStudents];
        let data_student = {
          id: data.id || data.member_id,
          fullname: data.fullname,
          comment: comment,
          status: data.status ? data.status : "intime",
          send_to_parent: 0,
          isCommentNormal: false,
        };
        listStudentData.push(data_student);
      }
    }
  }

  function validateParam() {
    return listStudentData.length > 0 ? true : false;
    // return true;
  }

  const [dataPopupComment, setDataPopupComment] = useState({
    id: "",
    title: "",
  });
  const [showPopupAddComment, setShowPopupAddComment] = useState(false);
  const [showPopupSelectObject, setShowPopupSelectObject] = useState(false);

  function openPopUpComment(id, title, value) {
    setDataPopupComment({
      ...dataPopupComment,
      id,
      title,
      value,
    });
    setShowPopupAddComment(true);
  }

  const [selectAll, setSelectAll] = useState(false);

  function onChangeSelectAll() {
    setSelectAll(!selectAll);
    if (!selectAll) {
      let tempListStudent = [];
      forEach(students.data, function (data) {
        let indexOfSended = _.findIndex(listItemSended, { id: data.id });
        if (indexOfSended === -1) {
          let dataSelect =
            listStudentData.filter((item) => item.id === data.id)[0] || data;
          let data_student = {
            id: dataSelect.id,
            fullname: dataSelect.fullname,
            comment: dataSelect.comment,
            status: dataSelect.status ? dataSelect.status : "intime",
            send_to_parent: 1,
          };
          tempListStudent.push(data_student);
        } else {
          tempListStudent.push(listItemSended[indexOfSended]);
        }
      });
      setListStudentData(tempListStudent);
    } else {
      let tempListStudent = [];
      listStudentData.forEach((item) => {
        if (_.findIndex(listItemSended, { id: item.id }) !== -1) {
          item.send_to_parent = 0;
          tempListStudent.push(item);
        }
      });
      setListStudentData(tempListStudent);
    }
  }

  const onSaveNote = (comment, id) => {
    var indexStudents = _.findIndex(students.data, { id });
    if (indexStudents !== -1) {
      students.data[indexStudents].comment = comment;
      setStudents({ ...students });
    }
    indexStudents = _.findIndex(listStudentData, { id: id });
    if (indexStudents !== -1) {
      listStudentData[indexStudents].isCommentNormal = false;
      listStudentData[indexStudents].comment = comment;
      setListStudentData([...listStudentData]);
    } else {
      indexStudents = _.findIndex(students.data, { id: id });
      let data = students.data[indexStudents];
      let data_student = {
        id: data.id || data.member_id,
        fullname: data.fullname,
        comment: comment,
        status: data.status ? data.status : "intime",
        send_to_parent: 0,
        isCommentNormal: false,
      };
      listStudentData.push(data_student);
    }
  };

  // console.log(students);

  return (
    <div className="sunE-right-container flex-column">
      <Header
        title={
          id
            ? inputs.date
            : moment(classes.add_roll_up.date).format("DD/MM/YYYY")
        }
        component={addComponenComment()}
        isBack
      />
      {showPopupAddComment && (
        <PopUpAddCommentRollUp
          value={dataPopupComment.value}
          title={dataPopupComment.title}
          onClickNo={() => setShowPopupAddComment(false)}
          onClickYes={(comment) => confirmReplaceAllComment(comment)}
        />
      )}
      {showPopupSelectObject && (
        <PopUpSelectObjectRollUp
          onClickNo={() => setShowPopupSelectObject(false)}
          onClickYes={(send_content) => setInputs({ ...inputs, send_content })}
          title="Gửi điểm danh"
        />
      )}
      {alert.message &&
        alert.screen === teacherConstants.SCREEN_ADD_ROLL_UP && (
          <Alert
            alert={alert}
            onComplete={() =>
              history.push(
                "/" + authentication.role + "/class/view/" + id + "/roll_up/"
              )
            }
          />
        )}
      {isSHowConfirmEdit && (
        <PopUpYesNo
          onClickYes={() => handleSubmit()}
          onClickNo={() => showConfirmEdit(false)}
          labelNo={"Không"}
          message={["Bạn có chắc chắn muốn Lưu thông tin đã chỉnh sửa không?"]}
        />
      )}
      {isConfirmReplaceAll && (
        <PopUpYesNo
          onClickYes={() => commentAll(commentReplaceAll, true)}
          onClickNo={() => commentAll(commentReplaceAll, false)}
          labelNo={"Không"}
          message={["Bạn có muốn giữ lại những nhận xét riêng trước đó?"]}
        />
      )}
      <div className="sunE-container-box diemdanh offline flex-1">
        <div className="diemdanh-container">
          <div className="flex-m">
            <div
              style={{ marginLeft: 10 }}
              className="flex-1 medium-title checbox-right tpm-r"
            >
              Sĩ số:{" "}
              {students?.data?.length -
                numberAbsence.length +
                "/" +
                students?.data?.length}
            </div>

            {/* {!isSendedBox ? <h2 className="medium-title checbox-right tpm-r">
                            Gửi tất cả phụ huynh
                            <span className="chk-custom-gr wh20">
                                <input type="checkbox" id="all" onChange={() => onChangeSelectAll()} checked={selectAll ? true : false} />
                                <label htmlFor="all" />
                            </span>
                        </h2> : <span className="flex-m __checked_send_exam">
                            <img src="/assets/images/icon/ico_check_calendar.png"/>
                            Đã gửi Phụ Huynh
                        </span>} */}
          </div>
          <div className="list-giaobai diemdanh-list-hs scrollbar-custom">
            {students.data.map((data, i) => {
              let dataSelect =
                listStudentData.find(
                  (item) =>
                    _.isEqual(item.id, data.user_id) ||
                    _.isEqual(item.id, data.member_id)
                ) || data;
              return (
                <div className="item-student flex-m box-shadow rel" key={i}>
                  <div className="item-student-img">
                    <img
                      src={
                        students.base_url +
                        "/" +
                        (rollUpId ? students.avatar[i] : data.avatar)
                      }
                      alt="avatar"
                    />
                  </div>
                  <div className="flex-1 item-student-name pl-15">
                    <h2 className="semibold">{dataSelect.fullname}</h2>
                    {/* <span className="nx">Nhận xét: {dataSelect?.comment || ''}</span> */}
                    <input
                      className="__input_note_rollup"
                      placeholder="Viết nhận xét..."
                      value={dataSelect?.comment || ""}
                      onChange={(e) =>
                        onSaveNote(e.target.value, dataSelect.id)
                      }
                      // onKeyDown={(e) => handleKeyEnterInput(e, dataSelect.id)}
                      onBlur={(e) => onSaveNote(e.target.value, dataSelect.id)}
                    />
                    <div className="flex-m nx-right-gr">
                      {/* <div className="pr-15" onClick={() => openPopUpComment(dataSelect.id, dataSelect.fullname, dataSelect.comment)}>
                                                <span className="btn-line-blue btn-send-now">Nhận xét</span>
                                            </div> */}
                      <div className="flex-1 diemdanh-cutom-select ">
                        <SelectAsDiv
                          position={
                            students.data.length === i + 1
                              ? "__top"
                              : "__bottom"
                          }
                          keySelected={
                            dataSelect?.status ? dataSelect.status : "intime"
                          }
                          keyName="status"
                          textDefault={
                            teacherConstants.TEXT_LIST_ROLL_UP_STATUS[0].status
                          }
                          data={teacherConstants.TEXT_LIST_ROLL_UP_STATUS}
                          onChangeSelect={(status) =>
                            changeStatus(i, status, dataSelect.id)
                          }
                          hzLine
                        />
                      </div>
                    </div>
                  </div>
                  <div className="chk-flex ab-top-custom">
                    {/* {_.findIndex(listItemSended, { id: dataSelect.id }) !== -1 ?
                                            <span className="flex-m __checked_send_exam_children">
                                                <img src="/assets/images/icon/ico_check_calendar.png"/>
                                                Đã gửi Phụ Huynh
                                            </span>
                                            :
                                            <span className="chk-custom-gr wh20">
                                                <input type="checkbox" id={i} onChange={() => hanldeChecked(dataSelect)} checked={parseInt(dataSelect.send_to_parent) === 1} />
                                                <label htmlFor={i} />
                                            </span>
                                        } */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="text-right">
        <button
          className={"btn-line-blue" + (!validateParam() ? " btn-disable" : "")}
          onClick={() => (!rollUpId ? handleSubmit() : showConfirmEdit(true))}
        >
          {rollUpId ? "Lưu" : "Lưu"}
        </button>
      </div>
    </div>
  );
}

export { AddRollUp };
