import React from "react";
import { alertConstants } from "./../../_constants";
import { AlertError, AlertSuccess } from "./";

function Alert(props) {
  const { customMessage } = props;

  const onComplete = props.onComplete ? props.onComplete : () => {};
  const notShowComplete = props.notShowComplete ? props.notShowComplete : false;
  const notShowError = props.notShowError ? props.notShowError : false;
  const isHideIcon = props.isHideIcon ? props.isHideIcon : false;
  
  return props.alert.type === alertConstants.SUCCESS ? (
    <AlertSuccess
      message={customMessage || props.alert.message}
      onComplete={onComplete}
      notShowComplete={notShowComplete}
    />
  ) : (
    <AlertError
      message={customMessage || props.alert.message}
      notShowError={notShowError}
      imgErorr={!isHideIcon}
    />
  );
}

export { Alert };
