import { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "../../_helpers";
import { LinkApp } from "../../_constants/linkDownloadApp";
import { getMobileOperatingSystem } from "../../_base/Validate";

export const homePageLogic = (props) => {
  const authentication = useSelector((state) => state.authentication);

  const [dataBanner1, setDataBanner1] = useState([
    {
      id: 1,
      name: "Giáo trình bám sát nội dung SGK",
    },
    {
      id: 2,
      name: "Tiết kiệm thời gian soạn bài",
    },
    {
      id: 3,
      name: "Chữa bài viết bằng AI",
    },
    {
      id: 4,
      name: "Dễ dàng nâng cao trình độ học sinh",
    },
  ]);

  const dataBanner2 = [
    {
      id: 1,
      name: "Tiết kiệm chi phí",
    },
    {
      id: 2,
      name: "Tiết kiệm thời gian",
    },
    {
      id: 3,
      name: "Giao bài theo đúng học lực của con",
    },
    {
      id: 4,
      name: "Quản lý thời gian và kết quả học của con",
    },
  ];

  const handleClickNavigate = (type) => {
    if (type) {
      history.push(`/${type}`);
      window.scrollTo({ top: 0, behavior: "instant" });
      // console.log(type);
    }
  };

  const handleNavigateCurriculum = () => {
    if (authentication.isLogin) {
      history.push(`/${authentication.role}/curriculum`);
    } else {
      history.push(`/login`);
    }
  };

  // Home Page Student
  const [dataContent, setDataContent] = useState([
    {
      id: 1,
      content: "Phát triển toàn diện 4 kỹ năng: Nghe - Nói - Đọc - Viết",
    },
    {
      id: 2,
      content: "Nhớ từ nhanh, không cần học thuộc lòng",
    },
    {
      id: 3,
      content: "Lấp lỗ hổng kiến thức với gia sư ảo thông thái",
    },
    {
      id: 4,
      content:
        "Tiến bộ nhanh nhờ phương pháp cá nhân hoá giáo trình đào tạo theo học lực của từng học sinh",
    },
    {
      id: 5,
      content:
        "Hàng ngàn bài luyện tập với trên 50 dạng bài kèm lời giải chi tiết được Thầy cô sử dụng trong các bài kiểm tra trên lớp",
    },

    // {
    //   id: 6,
    //   content: "Thi thử",
    //   sideText: " MIỄN PHÍ",
    //   tailText: ", rèn luyện kỹ năng làm bài và nâng cao điểm số",
    // },
    {
      id: 6,
      content:
        "Luyện kỹ năng nói chuẩn bản xứ với công nghệ trí tuệ nhân tạo AI tiên tiến",
    },
    {
      id: 7,
      content:
        "Phát triển kỹ năng viết theo chủ đề với sự hỗ trợ của AI và Thầy cô",
    },
    {
      id: 8,
      content: "Ôn bài cũ, học trước bài mới mọi lúc mọi nơi",
    },
    {
      id: 9,
      content: "Tự tin giao tiếp tiếng Anh và đạt điểm cao trong các kỳ thi",
    },
  ]);

  // Hash to id link
  useEffect(() => {
    let hash = window.location?.hash;
    if (hash) {
      var ids = hash?.split("#");
      for (var i = 1; i < ids.length; i++) {
        var element = document.getElementById(ids[i]);
        // console.log(element);
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }
    }
  }, [window.location.hash]);

  // Hamdle Navigate Curriculum Mobile
  const handleNavigateCurriculumMobile = () => {
    const osMobile = getMobileOperatingSystem();
    if (osMobile == "Android") {
      window.open(LinkApp.GG_PLAY, "_blank");
    } else if (osMobile == "iOS") {
      window.open(LinkApp.APP_STORE, "_blank");
    } else {
      if (authentication.isLogin) {
        history.push(`/${authentication.role}/curriculum`);
      } else {
        history.push(`/login`);
      }
    }
  };

  return {
    dataBanner1,
    dataBanner2,
    dataContent,
    handleClickNavigate,
    handleNavigateCurriculum,
    handleNavigateCurriculumMobile,
  };
};
