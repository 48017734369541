import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useLocation } from "react-router-dom";
import { Header } from "../../../../../_components/Admin/Header";
import { teacherConstants } from "../../../../../_constants";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { SelectDate } from "../../../../../_components/Calendar";
import { detailSkillAssignLogic } from "./DetailSkillAssign.logic";

function DetailSkillAssign() {
  const exercises = useSelector((state) => state.classes.exercises);

  let {
    skill_key,
    isEdit,
    inputs,
    originListFileId,
    deletedItem,
    stateReceived,
    getPrevTime,
    originAttachFile,
    skill,
    listFileId,
    listFileIdCopy,
    setIsEdit,
    setInputs,
    handleBack,
    validateParam,
    handleSubmit,
    handleChangeDate,
    removeFile,
    dispatch,
  } = detailSkillAssignLogic();

  const history = useHistory();

  const authentication = useSelector((state) => state.authentication);
  const { id, type, lesson_id } = useParams();

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const userID = params.get("user_id");

  return (
    <div className="sunE-right-container flex-column">
      <Header title={"Chi tiết bài"} isBack clickBack={handleBack} />
      <div className="sunE-container-box giaobai-detail canhan flex-1">
        <div className="sunE-giaobai">
          <div className="box-shadow box-info-giaobai">
            <h2 className="title">
              {skill?.detail?.lesson_name || skill?.lesson_name}
            </h2>
            <h3 className="desc">
              {skill?.detail?.lesson_topic || skill?.topic}
            </h3>
            <p className="text-black semibold text_detail">
              {skill?.curriculum_name} &gt;&gt; {skill?.unit_name} &gt;&gt;{" "}
              {skill?.exercise_type?.capitalize() ||
                skill?.lesson_type?.capitalize()}{" "}
            </p>
          </div>
        </div>
        <h2 className="title-semi">Thời gian làm bài</h2>
        <div className="flex-m class-slect-time mb0 class_slect_time_margin flex-start ">
          <div className="pr-25">
            <div className=" text-align-center sunE-input-group re slect-time">
              <label>Ngày bắt đầu</label>
              <div
                className={
                  "select-gr select-time-center text-align-center" +
                  (inputs.start_time > inputs.end_time ? " err" : "")
                }
              >
                <SelectDate
                  showTimeSelect={false}
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy"
                  selected={
                    inputs?.start_time
                      ? new Date(inputs?.start_time)
                      : new Date()
                  }
                  onChange={(start_time) => {
                    if (
                      new Date(start_time) <= new Date(inputs.end_time) &&
                      new Date(start_time) >= moment(new Date()).startOf("day")
                    ) {
                      setIsEdit(true);
                      setInputs((inputs) => ({
                        ...inputs,
                        start_time:
                          moment(start_time).format("YYYY-MM-DD") ||
                          inputs.start_time,
                      }));
                      dispatch({
                        type: teacherConstants.ADD_TIME_TO_CHOOSE_FILE_SCREEN,
                        data: {
                          start_time: moment(start_time).format("YYYY-MM-DD"),
                          end_time: inputs.end_time,
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="">
            <div className=" text-align-center sunE-input-group re slect-time">
              <label>Ngày kết thúc</label>
              <div
                className={
                  "select-gr  select-time-center text-align-center" +
                  (inputs.start_time > inputs.end_time ? " err" : "")
                }
              >
                <SelectDate
                  showTimeSelect={false}
                  timeIntervals={15}
                  dateFormat="dd/MM/yyyy"
                  selected={
                    inputs?.end_time ? new Date(inputs.end_time) : new Date()
                  }
                  onChange={(end_time) => {
                    if (new Date(inputs.start_time) <= new Date(end_time)) {
                      setIsEdit(true);
                      setInputs((inputs) => ({
                        ...inputs,
                        end_time:
                          moment(end_time).format("YYYY-MM-DD") ||
                          inputs.end_time,
                      }));
                      dispatch({
                        type: teacherConstants.ADD_TIME_TO_CHOOSE_FILE_SCREEN,
                        data: {
                          start_time: inputs.start_time,
                          end_time: moment(end_time).format("YYYY-MM-DD"),
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-m mb-10">
          <div className="flex-1">
            <h2 className="title-semi">File Hướng dẫn</h2>
          </div>
          <Link
            to={
              "/" +
              authentication.role +
              "/class/view/" +
              id +
              "/exercise/list_file_support/" +
              skill_key +
              "?type=" +
              type +
              "&lesson_id=" +
              lesson_id +
              "&user_id=" +
              userID
            }
          >
            <div className="flex-1 text-right">
              <h2 className="title-semi blue-main underline">Thêm hướng dẫn</h2>
            </div>
          </Link>
        </div>
        <div className="file-support-list scrollbar-custom">
          {(listFileId || []).map((data, i) => {
            return (
              <div className="flex-m mb-10" key={i}>
                <div className="flex-1">
                  <div className="file-support flex-m black">
                    <div>
                      <img
                        src="/assets/images/teacher/giaobai/ico_file.png"
                        alt="ico_file"
                      />
                    </div>
                    <div className="flex-1">
                      <span className="light">{data.title}</span>
                    </div>
                  </div>
                </div>
                <div className="w-remove">
                  <img
                    src="/assets/images/teacher/ico_remove_blue.png"
                    alt="ico_remove_blue"
                    className="btn-remove"
                    onClick={() => removeFile(i)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="form-sunE-button text-right">
        <button
          className={
            "btn-line-blue" +
            ((!validateParam() && !isEdit) || !isEdit ? " btn-disable" : "")
          }
          onClick={handleSubmit}
          disabled={!isEdit}
        >
          Lưu
        </button>
      </div>
    </div>
  );
}

export default DetailSkillAssign;
