import "./ScrollFixed.style.scss";
import { scrollFixedLogic } from "./ScrollFixed.logic";

const ScrollFixed = () => {
  let { handleScrollTop } = scrollFixedLogic();

  return (
    <div className="box_fixed">
      <div onClick={handleScrollTop}>
        <img
          src="/assets/images/homeNews/icon/ico_scroll_up.png"
          alt="Icon Scroll Up"
          className="icon_scale mg-bottom-0-5 icon_scroll_fixed"
        />
      </div>
      {/* <div>
        <img
          src="/assets/images/homeNews/icon/ico_box_chat.png"
          alt="Icon Box Chat"
          className="icon_scale icon_scroll_fixed"
        />
      </div> */}
    </div>
  );
};
export default ScrollFixed;
