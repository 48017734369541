import { history } from "../../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiCaller } from "../../../../../_helpers";
import { TypeAssignExercise } from "../../../../../_constants/typeAssignExercise";
import { teacherService } from "../../../../../_services";
import { teacherConstants } from "../../../../../_constants";
import { floatObjectToArray } from "../../../../../_base/Validate";

export const assignSpecificLogic = (props) => {
  const { id, type } = useParams();

  const _dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const exercises = useSelector((state) => state.classes.exercises);

  const [dataAssignSpecific, setDataAssignSpecific] = useState(
    useSelector((state) => state.classes.setting_assign)
  );
  const [isLoading, setLoading] = useState(false);

  const [currUnit, setCurrUnit] = useState(
    parseInt(dataAssignSpecific?.cur_unit) || 1
  );

  const [totalUnit, setTotalUnit] = useState(
    dataAssignSpecific?.total_unit || 12
  );

  const [currTimeMinute, setCurrTimeMinute] = useState(
    dataAssignSpecific?.expected_time || 30
  );

  const [cantChangeUnit, setCantChangeUnit] = useState(
    currUnit == 1 ? "prev" : currUnit == totalUnit ? "next" : ""
  );

  // Capacity
  const [cantChangeTime, setCantChangeTime] = useState(
    currTimeMinute == 10 ? "prev" : currTimeMinute == 180 ? "next" : ""
  );

  const getDataSetting = async () => {
    setLoading(true);
    const res = await teacherService.getDataAssignSetting(
      id,
      JSON.stringify(exercises.students)
    );
    if (res?.status) {
      _dispatch({
        type: teacherConstants.GET_DATA_SETTING_ASSIGN,
        setting: res.data,
      });

      const data = res.data;

      data["expected_time"] = currTimeMinute;

      setLoading(false);
      setDataAssignSpecific(data);
      setCurrUnit(parseInt(data.cur_unit));
      setTotalUnit(parseInt(data.total_unit));
      setCantChangeUnit(
        parseInt(data.cur_unit) == 1
          ? "prev"
          : parseInt(data.cur_unit) == parseInt(data.total_unit)
          ? "next"
          : ""
      );
    }
  };

  useEffect(() => {
    dataAssignSpecific.length <= 0 && getDataSetting();
  }, []);

  // Get Data Proposal
  const getDataProposalCapacity = async () => {
    var body = new FormData();

    body.append("list_student", JSON.stringify(exercises.students));
    body.append("id_class", id.toString());
    body.append("unit_learning_id", dataAssignSpecific.cur_unit.toString());
    body.append(
      "setting_student",
      JSON.stringify(dataAssignSpecific.study_data)
    );
    body.append(
      "desired_time",
      JSON.stringify(dataAssignSpecific.expected_time)
    );

    const res = await teacherService.getDataAssignProposal("capacity", body);

    return res.data;
  };

  // Handle Go Back
  const handleGoBack = () => {
    _dispatch({
      type: teacherConstants.CLEAR_ONLY_DATA_EXERCISES,
    });
    localStorage.setItem("typeCurrExercise", "sunday");
    history.push(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        "/exercise/student/selection_assign"
    );
  };

  // Handle Submit Assign Demand
  const handleSubmitAssignDemand = async () => {
    _dispatch({
      type: teacherConstants.GET_DATA_SETTING_ASSIGN,
      setting: dataAssignSpecific,
    });

    if (type == TypeAssignExercise.DEMAND) {
      history.push(
        "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/student/" +
          type +
          "/skills"
      );
    } else if (type == TypeAssignExercise.CAPACITY) {
      const responseData = await getDataProposalCapacity();

      if (responseData) {
        responseData.map(
          (item) =>
            (item.exercise_suggest = floatObjectToArray(item.exercise_suggest))
        );

        _dispatch({
          type: teacherConstants.GET_DATA_ASSIGN_SPECIFIC,
          proposal: responseData.map((item) => ({
            ...item,
            exercise_suggest: item.exercise_suggest.map((exercise) => ({
              ...exercise,
              user_id: item.user_id,
              list_guide_id: [],
            })),
          })),
        });

        const dataExercise = responseData.reduce(function (accumulator, obj) {
          return accumulator.concat(
            obj.exercise_suggest.map((exercise) => ({
              ...exercise,
              user_id: obj.user_id,
              list_guide_id: [],
            }))
          );
        }, []);

        _dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: {
            ...exercises,
            data_exercise: dataExercise,
          },
        });
      }

      history.push(
        "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/student/" +
          type +
          "/list_proposals"
      );
    }
  };

  // Handle Change Unit
  const handleChangeUnit = (type) => {
    switch (type) {
      case "prev":
        if (currUnit > 1) {
          setCurrUnit(currUnit - 1);
          setDataAssignSpecific((data) => ({
            ...data,
            cur_unit: currUnit - 1,
          }));
        }
        if (currUnit - 1 <= 1) {
          setCantChangeUnit("prev");
        } else {
          setCantChangeUnit("");
        }
        break;
      case "next":
        if (currUnit < totalUnit) {
          setCurrUnit(currUnit + 1);
          if (currUnit + 1 == totalUnit) {
            setDataAssignSpecific((data) => ({
              ...data,
              cur_unit: currUnit + 1,
              study_data: dataAssignSpecific.study_data.map((ele) => {
                return {
                  ...ele,
                  practice_percent: 100,
                  estudy_percent: 100,
                };
              }),
            }));
          } else {
            setDataAssignSpecific((data) => ({
              ...data,
              cur_unit: currUnit + 1,
            }));
          }
        }
        if (currUnit + 1 >= totalUnit) {
          setCantChangeUnit("next");
        } else {
          setCantChangeUnit("");
        }
        break;
      default:
    }
  };

  // Handle Change Time
  const handleChangeTime = (type) => {
    switch (type) {
      case "prev":
        if (currTimeMinute > 10) {
          setCurrTimeMinute(currTimeMinute - 10);
          setDataAssignSpecific((data) => ({
            ...data,
            expected_time: currTimeMinute - 10,
          }));
        }
        if (currTimeMinute - 10 <= 10) {
          setCantChangeTime("prev");
        } else {
          setCantChangeTime("");
        }
        break;
      case "next":
        if (currTimeMinute < 180) {
          setCurrTimeMinute(currTimeMinute + 10);
          setDataAssignSpecific((data) => ({
            ...data,
            expected_time: currTimeMinute + 10,
          }));
        }
        if (currTimeMinute + 10 >= 180) {
          setCantChangeTime("next");
        } else {
          setCantChangeTime("");
        }
        break;
      default:
    }
  };

  // Handle Change Percent
  const handleChangePercent = (data, value) => {
    if (value <= 100 && value >= 0) {
      let newStudyData = dataAssignSpecific.study_data;

      newStudyData.forEach((item) => {
        if (item.stand == data.stand) {
          item.practice_percent = value;
          item.study_percent = 100 - value;
        }
      });
      setDataAssignSpecific((data) => ({ ...data, study_data: newStudyData }));
    }
  };

  return {
    isLoading,
    exercises,
    cantChangeTime,
    currUnit,
    cantChangeUnit,
    dataAssignSpecific,
    currTimeMinute,
    handleSubmitAssignDemand,
    setDataAssignSpecific,
    handleChangeUnit,
    handleChangePercent,
    handleGoBack,
    handleChangeTime,
  };
};
