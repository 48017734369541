import React from "react";

function AlertError(props) {
  let { changePasswordError, imgErorr, errorHelpClassName } = props;
  if (props.notShowError) {
    return null;
  } else {
    return (
      <div className="center-flex">
        <div className={`error-help flex-m ${errorHelpClassName}`}>
          {/* {imgErorr && (
            <div className="">
              <img src="/assets/images/ico_danger.png" className="error-img" />
            </div>
          )} */}
          <div className="flex-1">
            {changePasswordError ? (
              <p className="bold" style={{ fontSize: "18px" }}>
                Mật khẩu bạn nhập không trùng khớp.
              </p>
            ) : (
              <p className="bold" style={{ fontSize: "18px" }}>
                {props.message}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export { AlertError };
