import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherService } from "../../../_services";
import { userActions } from "../../../_actions";
import { Link, useHistory } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { userConstants } from "./../../../_constants";
import { isEmpty } from "lodash";
import _ from "lodash";
import { SelectAsDiv } from "../../../_components/Select";
import { PopUpYesNo } from "../../../_components/Popup";
import { deleteAccount } from "../../../_services/user";
import { alertActions } from "../../../_actions";
import styled from "styled-components";

const SelectLanguage = styled(SelectAsDiv)`
  min-width: 7rem;
  img {
    top: 6px;
    position: absolute;
  }
`;

function HomePageSetting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);
  const cachedDeviceId = localStorage.getItem("device_id_" + authentication.id);
  const [deviceId, setDeviceId] = useState("");
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);

  const [settingDetail, setSettingDetail] = useState({
    app_info: [],
    user_setting: [],
  });

  useEffect(() => {
    teacherService.getSettingDetail(cachedDeviceId).then((data) => {
      setSettingDetail(data.data_setting);
      setDeviceId(data.user_device_id);
      console.log(data.data_setting);
    });
  }, []);

  //Setting of teacher
  const new_feature = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "general" && data.key === "new_feature"
  );
  const new_promotion = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "general" && data.key === "new_promotion"
  );
  const new_msg = _.filter(
    settingDetail.user_setting,
    (data) =>
      (data.group_alias === "general" || data.group_alias === "notify") &&
      data.key === "new_msg"
  );
  const sound = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "general" && data.key === "sound"
  );
  const lang = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "general" && data.key === "lang"
  );
  const student_request = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "class" && data.key === "student_request"
  );
  const student_approved = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "class" && data.key === "student_approved"
  );
  const student_complete_exercise = _.filter(
    settingDetail.user_setting,
    (data) =>
      data.group_alias === "class" && data.key === "student_complete_exercise"
  );
  const student_expired = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "class" && data.key === "student_expired"
  );
  const number_exercise_pending = _.filter(
    settingDetail.user_setting,
    (data) =>
      data.group_alias === "specialize" &&
      data.key === "number_exercise_pending"
  );
  const new_curriculum = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "curriculum" && data.key === "new_curriculum"
  );

  //Setting of student
  const new_exercise = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "notify" && data.key === "new_exercise"
  );
  //Báo khi bài tập được chấm, chữa
  const teacher_mark_exercise = _.filter(
    settingDetail.user_setting,
    (data) =>
      data.group_alias === "notify" && data.key === "teacher_mark_exercise"
  );
  //Nhắc nhở học tập
  const remind_learning = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "notify" && data.key === "remind_learning"
  );
  //Báo cáo học tập mới
  const new_report = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "notify" && data.key === "new_report"
  );
  //Khi gv cập nhật giáo trình
  const teacher_edit_curriculum = _.filter(
    settingDetail.user_setting,
    (data) =>
      data.group_alias === "notify" && data.key === "teacher_edit_curriculum"
  );
  // Báo tin nhắn mới
  const new_message = _.filter(
    settingDetail.user_setting,
    (data) => data.group_alias === "notify" && data.key === "new_msg"
  );

  // console.log(new_message);

  const [sttSound, setSttSound] = useState(false);
  const statusSound =
    !isEmpty(sound[0]) && sound[0].value === "1" ? true : false;

  const [sttPromotion, setSttPromotion] = useState(false);
  const statusPromotion =
    !isEmpty(new_promotion[0]) && new_promotion[0].value === "1" ? true : false;

  const [sttMessage, setSttMessage] = useState(false);
  const statusMessage =
    !isEmpty(new_msg[0]) && new_msg[0].value === "1" ? true : false;

  const [sttLang, setSttLang] = useState("vi");
  const statusLang = !isEmpty(lang[0]) && lang[0].value === "vi" ? "vi" : "en";

  const [sttStudentRequest, setSttStudentRequest] = useState(false);
  const statusStudentRequest =
    !isEmpty(student_request[0]) && student_request[0].value === "1"
      ? true
      : false;

  const [sttStudentApproved, setSttStudentApproved] = useState(false);
  const statusStudentApproved =
    !isEmpty(student_approved[0]) && student_approved[0].value === "1"
      ? true
      : false;

  const [sttStudentComplete, setSttStudentComplete] = useState(false);
  const statusStudentComplete =
    !isEmpty(student_complete_exercise[0]) &&
    student_complete_exercise[0].value === "1"
      ? true
      : false;

  const [sttStudentExpired, setSttStudentExpired] = useState(false);
  const statusStudentExpired =
    !isEmpty(student_expired[0]) && student_expired[0].value === "1"
      ? true
      : false;

  const [sttNumberExercise, setSttNumberExercise] = useState(false);
  const statusNumberExercise =
    !isEmpty(number_exercise_pending[0]) &&
    number_exercise_pending[0].value === "1"
      ? true
      : false;

  const [sttFeature, setSttFeature] = useState(false);
  const statusFeature =
    !isEmpty(new_feature[0]) && new_feature[0].value === "1" ? true : false;

  const [sttCurriculum, setSttCurriculum] = useState(false);
  const statusCurriculum =
    !isEmpty(new_curriculum[0]) && new_curriculum[0].value === "1"
      ? true
      : false;

  //Setting student private
  const [sttNewExercise, setSttNewExercise] = useState(false);
  const statusNewExercise =
    !isEmpty(new_exercise[0]) && new_exercise[0].value === "1" ? true : false;

  const [sttTeacherMarkExercise, setSttTeacherMarkExercise] = useState(false);
  const statusTeacherMarkExercise =
    !isEmpty(teacher_mark_exercise[0]) && teacher_mark_exercise[0].value === "1"
      ? true
      : false;

  const [sttRemindLearning, setSttRemindLearning] = useState(false);
  const statusRemindLearning =
    !isEmpty(remind_learning[0]) && remind_learning[0].value === "1"
      ? true
      : false;

  const [sttNewReport, setSttNewReport] = useState(false);
  const statusNewReport =
    !isEmpty(new_report[0]) && new_report[0].value === "1" ? true : false;

  const [sttTeacherEditCurriculum, setSttTeacherEditCurriculum] =
    useState(false);
  const statusTeacherEditCurriculum =
    !isEmpty(teacher_edit_curriculum[0]) &&
    teacher_edit_curriculum[0].value === "1"
      ? true
      : false;

  const [sttNewMessage, setSttNewMessage] = useState(false);
  const statusNewMessage =
    !isEmpty(new_message[0]) && new_message[0].value === "1" ? true : false;

  // console.log(sttNewMessage);
  // console.log(new_message[0]);

  useEffect(() => {
    setSttSound(statusSound);
    //Teacher
    setSttPromotion(statusPromotion);
    setSttMessage(statusMessage);
    setSttStudentRequest(statusStudentRequest);
    setSttStudentApproved(statusStudentApproved);
    setSttStudentComplete(statusStudentComplete);
    setSttStudentExpired(statusStudentExpired);
    setSttNumberExercise(statusNumberExercise);
    setSttFeature(statusFeature);
    setSttCurriculum(statusCurriculum);
    setSttLang(statusLang);

    // Student
    setSttNewExercise(statusNewExercise);
    setSttTeacherMarkExercise(statusTeacherMarkExercise);
    setSttRemindLearning(statusRemindLearning);
    setSttNewReport(statusNewReport);
    setSttTeacherEditCurriculum(statusTeacherEditCurriculum);
    setSttNewMessage(statusNewMessage);
  }, [
    statusSound,
    statusFeature,
    statusCurriculum,
    statusPromotion,
    statusMessage,
    statusLang,
    statusStudentRequest,
    statusStudentApproved,
    statusStudentComplete,
    statusStudentExpired,
    statusNumberExercise,
    statusNewExercise,
    statusTeacherMarkExercise,
    statusRemindLearning,
    statusNewReport,
    statusTeacherEditCurriculum,
    statusNewMessage,
  ]);

  function handleChange(e) {
    const isChecked = e.target.checked;
    if (e.target.name === "sound") {
      setSttSound(isChecked);
      let data = [
        {
          setting_template_id:
            authentication.role === userConstants.ROLE_TEACHER ? "18" : "1",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "new_msg") {
      setSttMessage(isChecked);
      let data = [
        {
          setting_template_id:
            authentication.role === userConstants.ROLE_TEACHER ? "11" : "8",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "new_feature") {
      setSttFeature(isChecked);
      let data = [
        {
          setting_template_id: "9",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "new_curriculum") {
      setSttCurriculum(isChecked);
      let data = [
        {
          setting_template_id: "17",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "new_promotion") {
      setSttPromotion(isChecked);
      let data = [
        {
          setting_template_id: "10",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "student_request") {
      setSttStudentRequest(isChecked);
      let data = [
        {
          setting_template_id: "12",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "student_approved") {
      setSttStudentApproved(isChecked);
      let data = [
        {
          setting_template_id: "13",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "student_complete_exercise") {
      setSttStudentComplete(isChecked);
      let data = [
        {
          setting_template_id: "14",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "student_expired") {
      setSttStudentExpired(isChecked);
      let data = [
        {
          setting_template_id: "15",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "number_exercise_pending") {
      setSttNumberExercise(isChecked);
      let data = [
        {
          setting_template_id: "16",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "new_exercise") {
      setSttNewExercise(isChecked);
      let data = [
        {
          setting_template_id: "3",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "teacher_mark_exercise") {
      setSttTeacherMarkExercise(isChecked);
      let data = [
        {
          setting_template_id: "4",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "remind_learning") {
      setSttRemindLearning(isChecked);
      let data = [
        {
          setting_template_id: "5",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "new_report") {
      setSttNewReport(isChecked);
      let data = [
        {
          setting_template_id: "6",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    } else if (e.target.name === "teacher_edit_curriculum") {
      setSttTeacherEditCurriculum(isChecked);
      let data = [
        {
          setting_template_id: "7",
          value: Number(isChecked).toString(),
        },
      ];
      dispatch(userActions.saveUserSetting(data, deviceId));
    }
    //  else if (e.target.name === "new_msg") {
    //   setSttNewMessage(isChecked);
    //   let data = [
    //     {
    //       setting_template_id: "8",
    //       value: Number(isChecked).toString(),
    //     },
    //   ];
    //   dispatch(userActions.saveUserSetting(data, deviceId));
    // }
  }

  function handleChangeOption(type) {
    let data = [
      {
        setting_template_id:
          authentication.role === userConstants.ROLE_TEACHER ? "19" : "2",
        value: type,
      },
    ];
    dispatch(userActions.saveUserSetting(data, deviceId));
    setSttLang(type);
  }

  function handleChangeStudentExpired(e) {
    let data = [
      {
        setting_template_id: "15",
        sub_value: e.target.value,
      },
    ];
    dispatch(userActions.saveUserSetting(data, deviceId));
  }
  function handleChangeExercisePending(e) {
    let data = [
      {
        setting_template_id: "16",
        sub_value: e.target.value,
      },
    ];
    dispatch(userActions.saveUserSetting(data, deviceId));
  }

  // Delete Account User
  const deleteAccountUser = async () => {
    try {
      const res = await deleteAccount();
      if (res?.data?.status) {
        setIsOpenModalDelete(false);
        setIsOpenModalConfirm(true);
      } else {
        dispatch(
          alertActions.error({
            message: res?.data?.message,
          })
        );
      }
    } catch (e) {
      dispatch(
        alertActions.error({
          message: e.toString(),
        })
      );
    }
  };

  return (
    <div className="sunE-container flex bg-main-img-teacher">
      <div className="sunE-right-container flex-column scrollbar-custom">
        <Header
          title="Cài Đặt"
          isBack
          clickBack={() => history.push("/" + authentication.role + "/more")}
        />
        <div className="sunE-container-box setting flex-1">
          <div className="flex-m">
            <div className="flex-1 pr-10">
              <div className="box-shadow box-setting-custom">
                <h2 className="setting-title-blue">Cài đặt chung</h2>
                <div className="flex-m setting-on-off-box">
                  <div className="flex-1">
                    <p>Hiệu ứng âm thanh</p>
                  </div>
                  {sound[0] && (
                    <div
                      className="center-flex w80"
                      style={{ marginLeft: "2rem" }}
                    >
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={sound[0] ? sound[0].key : ""}
                            onChange={handleChange}
                            checked={sttSound}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {lang[0] && (
                  <div className="flex-m setting-on-off-box">
                    <div className="flex-1">
                      <p>Ngôn ngữ</p>
                    </div>
                    <div className="center-flex w80">
                      <SelectLanguage
                        disabled
                        keySelected={sttLang}
                        keyName="name"
                        textDefault={""}
                        data={userConstants.LANG_CONFIG}
                        onChangeSelect={(type) => handleChangeOption(type)}
                        className="select-language"
                      />
                      {/* <select className="select-language">
                                            <option>Tiếng Việt</option>
                                            <option>English</option>
                                        </select> */}
                    </div>
                  </div>
                )}
                <div className="flex-m setting-on-off-box">
                  <div className="flex-1">
                    <p>Đổi mật khẩu</p>
                  </div>
                  <Link
                    to={
                      "/" + authentication.role + "/more/setting/reset-password"
                    }
                    className="mr-10"
                  >
                    <div className="center-flex">
                      <img
                        src="/assets/images/setting/ico_dropright_blue.png"
                        alt="ico_dropright_blue"
                        className="btn"
                      />
                    </div>
                  </Link>
                </div>
                {/*<div className="flex-m setting-on-off-box">
                                    <div className="flex-1">
                                        <p>Liên kết tài khoản</p>
                                    </div>
                                    <Link to={'/' + authentication.role + '/more/link-account'} className="mr-10">
                                        <div className="center-flex">
                                            <img src="/assets/images/setting/ico_dropright_blue.png" alt="ico_dropright_blue" className="btn" />
                                        </div>
                                    </Link>
                                </div>*/}
              </div>
              {authentication.role === userConstants.ROLE_TEACHER && (
                <div className="box-shadow box-setting-custom">
                  <h2 className="setting-title-blue">Thông báo chung</h2>
                  {new_feature[0] && (
                    <div className="flex-m setting-on-off-box">
                      <div className="flex-1">
                        <p>Cập nhật sản phẩm mới, tính năng mới</p>
                      </div>
                      <div
                        className="center-flex"
                        style={{ marginLeft: "2rem" }}
                      >
                        <div className="toggle-button-cover">
                          <div className="button r btn-on-off active">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={new_feature[0] ? new_feature[0].key : ""}
                              onChange={handleChange}
                              checked={sttFeature}
                            />
                            <div className="knobs" />
                            <div className="layer" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {new_promotion[0] && (
                    <div className="flex-m setting-on-off-box">
                      <div className="flex-1">
                        <p>Các chương trình khuyến mãi</p>
                      </div>
                      <div
                        className="center-flex"
                        style={{ marginLeft: "2rem" }}
                      >
                        <div className="toggle-button-cover">
                          <div className="button r btn-on-off active">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={
                                new_promotion[0] ? new_promotion[0].key : ""
                              }
                              onChange={handleChange}
                              checked={sttPromotion}
                            />
                            <div className="knobs" />
                            <div className="layer" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {new_msg[0] && (
                    <div className="flex-m setting-on-off-box mt-17">
                      <div className="flex-1">
                        <p>Khi có tin nhắn mới</p>
                      </div>
                      <div
                        className="center-flex"
                        style={{ marginLeft: "2rem" }}
                      >
                        <div className="toggle-button-cover">
                          <div className="button r btn-on-off active">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={new_msg[0] ? new_msg[0].key : ""}
                              onChange={handleChange}
                              checked={sttMessage}
                            />
                            <div className="knobs" />
                            <div className="layer" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="box-shadow box-setting-custom">
                <h2 className="setting-title-blue">Giới thiệu</h2>
                <div className="flex-m setting-on-off-box">
                  <div className="flex-1">
                    <p>Về Sunday English</p>
                  </div>
                  <Link
                    to={"/" + authentication.role + "/more/setting/about"}
                    className="mr-10"
                  >
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <img
                        src="/assets/images/setting/ico_dropright_blue.png"
                        alt="ico_dropright_blue"
                        className="btn"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex-m setting-on-off-box">
                  <div className="flex-1">
                    <p>Chính sách và điều khoản sử dụng</p>
                  </div>
                  <Link
                    to={
                      "/" +
                      authentication.role +
                      "/more/setting/policy-and-term"
                    }
                    className="mr-10"
                  >
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <img
                        src="/assets/images/setting/ico_dropright_blue.png"
                        alt="ico_dropright_blue"
                        className="btn"
                      />
                    </div>
                  </Link>
                </div>
                <div className="flex-m setting-on-off-box">
                  <div className="flex-1">
                    <p>Liên hệ</p>
                  </div>
                  <Link
                    to={"/" + authentication.role + "/more/setting/contact"}
                    className="mr-10"
                  >
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <img
                        src="/assets/images/setting/ico_dropright_blue.png"
                        alt="ico_dropright_blue"
                        className="btn"
                      />
                    </div>
                  </Link>
                </div>

                <div className="flex-m setting-on-off-box">
                  <div className="flex-1">
                    <p>Xóa tài khoản</p>
                  </div>
                  <div
                    className="mr-10"
                    onClick={() => {
                      setIsOpenModalDelete(true);
                    }}
                  >
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <img
                        src="/assets/images/setting/ico_dropright_blue.png"
                        alt="ico_dropright_blue"
                        className="btn"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="flex-m setting-on-off-box">
                  <div className="flex-1">
                    <p>Hỗ trợ</p>
                  </div>
                  <Link
                    to={"/" + authentication.role + "/more/setting/support"}
                    className="mr-10"
                  >
                    <div className="center-flex">
                      <img
                        src="/assets/images/setting/ico_dropright_blue.png"
                        alt="ico_dropright_blue"
                        className="btn"
                      />
                    </div>
                  </Link>
                </div> */}
                {/* <div className="flex-m setting-on-off-box">
                  <div className="flex-1">
                    <p>Phiên bản</p>
                  </div>
                  <div className="center-flex">
                    <span className="btn f-13">
                      {settingDetail ? settingDetail.app_info.version_name : ""}
                    </span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="flex-1 pl-10">
              <div className="box-shadow box-setting-custom">
                {authentication.role === userConstants.ROLE_TEACHER && (
                  <h2 className="setting-title-blue">Thông báo Lớp học</h2>
                )}
                {authentication.role === userConstants.ROLE_STUDENT && (
                  <h2 className="setting-title-blue">Thông báo</h2>
                )}
                {/*{new_msg[0] && <div className="flex-m setting-on-off-box">
                                    <div className="flex-1">
                                        <p>Khi có tin nhắn mới</p>
                                    </div>
                                    <div className="center-flex">
                                        <div className="toggle-button-cover">
                                            <div className="button r btn-on-off active">
                                                <input type="checkbox" className="checkbox" name={new_msg[0] ? new_msg[0].key : ''} onChange={handleChange} checked={sttMessage} />
                                                <div className="knobs" />
                                                <div className="layer" />
                                            </div>
                                        </div>
                                    </div>
                                </div>}*/}
                {new_exercise[0] && (
                  <div className="flex-m setting-on-off-box">
                    <div className="flex-1">
                      <p>Báo bài tập mới được giao</p>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={new_exercise[0] ? new_exercise[0].key : ""}
                            onChange={handleChange}
                            checked={sttNewExercise}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {teacher_mark_exercise[0] && (
                  <div className="flex-m setting-on-off-box mt-17">
                    <div className="flex-1">
                      <p>Báo bài tập được chấm, chữa</p>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={
                              teacher_mark_exercise[0]
                                ? teacher_mark_exercise[0].key
                                : ""
                            }
                            onChange={handleChange}
                            checked={sttTeacherMarkExercise}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {remind_learning[0] && (
                  <div className="flex-m setting-on-off-box mt-17">
                    <div className="flex-1">
                      <p>Nhắc nhở học tập</p>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={
                              remind_learning[0] ? remind_learning[0].key : ""
                            }
                            onChange={handleChange}
                            checked={sttRemindLearning}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {new_report[0] && (
                  <div className="flex-m setting-on-off-box mt-17">
                    <div className="flex-1">
                      <p>Báo cáo học tập mới</p>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={new_report[0] ? new_report[0].key : ""}
                            onChange={handleChange}
                            checked={sttNewReport}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {teacher_edit_curriculum[0] && (
                  <div className="flex-m setting-on-off-box mt-17">
                    <div className="flex-1">
                      <p>Khi giáo viên cập nhật giáo trình</p>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={
                              teacher_edit_curriculum[0]
                                ? teacher_edit_curriculum[0].key
                                : ""
                            }
                            onChange={handleChange}
                            checked={sttTeacherEditCurriculum}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {authentication.role === userConstants.ROLE_STUDENT &&
                  new_msg[0] && (
                    <div className="flex-m setting-on-off-box mt-17">
                      <div className="flex-1">
                        <p>Báo tin nhắn mới</p>
                      </div>
                      <div
                        className="center-flex"
                        style={{ marginLeft: "2rem" }}
                      >
                        <div className="toggle-button-cover">
                          <div className="button r btn-on-off active">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={new_msg[0] ? new_msg[0].key : ""}
                              onChange={handleChange}
                              checked={sttMessage}
                            />
                            <div className="knobs" />
                            <div className="layer" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {student_request[0] && (
                  <div className="flex-m setting-on-off-box">
                    <div className="flex-1">
                      <p>Khi có học sinh xin tham gia vào lớp</p>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={
                              student_request[0] ? student_request[0].key : ""
                            }
                            onChange={handleChange}
                            checked={sttStudentRequest}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {student_approved[0] && (
                  <div className="flex-m setting-on-off-box">
                    <div className="flex-1">
                      <p>Khi có học sinh mới được thêm vào lớp</p>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={
                              student_approved[0] ? student_approved[0].key : ""
                            }
                            onChange={handleChange}
                            checked={sttStudentApproved}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {student_complete_exercise[0] && (
                  <div className="flex-m setting-on-off-box">
                    <div className="flex-1">
                      <p>
                        Khi học sinh đã hoàn thành được hết bài tập được giao
                        trong lớp
                      </p>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={
                              student_complete_exercise[0]
                                ? student_complete_exercise[0].key
                                : ""
                            }
                            onChange={handleChange}
                            checked={sttStudentComplete}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {student_expired[0] && (
                  <div className="flex-m setting-on-off-box">
                    <div
                      className="flex-1 flex-m"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div>
                        <input
                          type="number"
                          name="limitStudent"
                          onChange={handleChangeStudentExpired}
                          defaultValue={
                            student_expired[0]
                              ? student_expired[0].sub_value
                              : ""
                          }
                          className={sttStudentExpired ? "active" : ""}
                          min="0"
                        />
                      </div>
                      <div className="flex-1 center-flex-hz">
                        <p className="ph10">
                          Học sinh để quá hạn bài tập trên 1 bài
                        </p>
                      </div>
                    </div>
                    <div className="center-flex" style={{ marginLeft: "2rem" }}>
                      <div className="toggle-button-cover">
                        <div className="button r btn-on-off active">
                          <input
                            type="checkbox"
                            className="checkbox"
                            name={
                              student_expired[0] ? student_expired[0].key : ""
                            }
                            onChange={handleChange}
                            checked={sttStudentExpired}
                          />
                          <div className="knobs" />
                          <div className="layer" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {authentication.role === userConstants.ROLE_TEACHER && (
                <div className="box-shadow box-setting-custom">
                  <h2 className="setting-title-blue">Thông báo chuyên môn</h2>
                  {number_exercise_pending[0] && (
                    <div className="flex-m setting-on-off-box">
                      <div
                        className="flex-1 flex-m"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          <input
                            type="number"
                            name="limitStudent"
                            onChange={handleChangeExercisePending}
                            defaultValue={
                              number_exercise_pending[0]
                                ? number_exercise_pending[0].sub_value
                                : ""
                            }
                            className={sttNumberExercise ? "active" : ""}
                            min="0"
                          />
                        </div>
                        <div className="flex-1 center-flex-hz">
                          <p className="ph10">Bài cần chấm</p>
                        </div>
                      </div>
                      <div
                        className="center-flex"
                        style={{ marginLeft: "2rem" }}
                      >
                        <div className="toggle-button-cover">
                          <div className="button r btn-on-off active">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={
                                number_exercise_pending[0]
                                  ? number_exercise_pending[0].key
                                  : ""
                              }
                              onChange={handleChange}
                              checked={sttNumberExercise}
                            />
                            <div className="knobs" />
                            <div className="layer" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {authentication.role === userConstants.ROLE_TEACHER && (
                <div className="box-shadow box-setting-custom">
                  <h2 className="setting-title-blue">Thông báo Giáo trình</h2>
                  {new_curriculum[0] && (
                    <div className="flex-m setting-on-off-box">
                      <div className="flex-1">
                        <p>Khi có giáo trình mới</p>
                      </div>
                      <div
                        className="center-flex"
                        style={{ marginLeft: "2rem" }}
                      >
                        <div className="toggle-button-cover">
                          <div className="button r btn-on-off active">
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={
                                new_curriculum[0] ? new_curriculum[0].key : ""
                              }
                              onChange={handleChange}
                              checked={sttCurriculum}
                            />
                            <div className="knobs" />
                            <div className="layer" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpenModalDelete && (
        <PopUpYesNo
          onClickYes={() => deleteAccountUser()}
          onClickNo={() => setIsOpenModalDelete(false)}
          labelYes="Xóa"
          labelNo="Hủy"
          message="Mọi dữ liệu và các gói học tập bạn đã mua sẽ bị mất, bạn có chắc chắn muốn xóa tài khoản?"
        />
      )}

      {isOpenModalConfirm && (
        <PopUpYesNo
          hideButtonNo={true}
          onClickYes={() => {
            dispatch({
              type: userConstants.LOGOUT,
            });
            // localStorage.clear();
            localStorage.removeItem("authentication");
            localStorage.removeItem("access_token");
            localStorage.removeItem("info_header_user");
            localStorage.removeItem("date_selected");
            window.location.href = "/login";

            setIsOpenModalConfirm(false);
          }}
          labelYes="Đồng ý"
          message="Bạn đã xóa tài khoản thành công!"
        />
      )}
    </div>
  );
}

export { HomePageSetting };
