import { apiCaller } from './../_helpers';
import { alertActions } from './';
import { licenseConstants } from './../_constants';
import { configConstants } from './../_constants';

export const licenseActions = {
    getCurrentLicense,
    addCodeLicense,
    getHistoryLicense,
};

function getCurrentLicense() {
    return (dispatch) => {
        return apiCaller('/license/license_owned', 'GET', {}, null, true,configConstants.API_URL_SELICENSE).then(
            (licenses) => {
                dispatch({
                    type: licenseConstants.GET_CURRENT_LICENSE,
                    listCurentLicenses: licenses.data
                });
            }, error => {
                dispatch(alertActions.error({ 'message': error.toString(), 'screen': licenseConstants.SCREEN_GET_CURREN_LICENSE }));
            }
        );
    };
}


function addCodeLicense(data) {
    return (dispatch) => {
        return apiCaller('/apilicense_management/api/license/active_license_code', 'POST', data, null, true,configConstants.API_URL_SELICENSE).then(
            () => {
                dispatch(alertActions.success({ 'message': licenseConstants.MESSAGE_LICENSE_ADD_CODE_COMPLETE, 'screen': licenseConstants.SCREEN_LICENSE_ADD_CODE }));
            },
            error => {
                dispatch(alertActions.error({ 'message': error.toString(), 'screen': licenseConstants.SCREEN_LICENSE_ADD_CODE }));
            }
        );
    };
}

function getHistoryLicense(student_id) {
    return (dispatch) => {
        return apiCaller('/order/list_order?student_id=' + student_id, 'GET', {}, null, true,configConstants.API_URL_SELICENSE).then(
            (licenses) => {
                dispatch({
                    type: licenseConstants.GET_HISTORY_LICENSE,
                    listHistoryLicenses: licenses.data
                });
            }, error => {
                dispatch(alertActions.error({ 'message': error.toString(), 'screen': licenseConstants.SCREEN_GET_HISTORY_LICENSE }));
            }
        );
    };
}

