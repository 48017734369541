import { history } from "../../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { TypeAssignExercise } from "../../../../../_constants/typeAssignExercise";
import { teacherService } from "../../../../../_services";
import { teacherConstants } from "../../../../../_constants";
import { floatObjectToArray } from "../../../../../_base/Validate";
import { useLocation } from "react-router-dom";
import { alertActions } from "../../../../../_actions";
import { cloneDeep } from "lodash";

export const assignProposalLogic = (props) => {
  const _location = useLocation();
  const { id, type } = useParams();
  const _dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const exercises = useSelector((state) => state.classes.exercises);

  const dateAddStudentExercise = useSelector(
    (state) => state.classes.dateAddStudentExercise
  );

  const userIDSelected = _location?.state?.userIDSelected;

  const dataAssignProposal = useSelector(
    (state) => state.classes.data_assign_proposal
  );

  dataAssignProposal.map(
    (item) =>
      (item.exercise_suggest = floatObjectToArray(item.exercise_suggest))
  );

  const [dataProposal, setDataProposal] = useState(dataAssignProposal);

  const dataReduxAssign = useSelector((state) => state.classes.setting_assign);

  const [dataAssignSpecific, setDataAssignSpecific] = useState(
    cloneDeep(dataReduxAssign)
  );

  // Selected Student
  const [studentSelected, setStudentSelected] = useState(userIDSelected);

  // Lesson Selected
  const [lessonSelected, setLessonSelected] = useState();

  // Get Data Proposal
  const getDataProposal = async () => {
    var body = new FormData();

    body.append("list_student", JSON.stringify(exercises.students));
    body.append("id_class", id.toString());
    body.append("unit_learning_id", dataAssignSpecific.cur_unit.toString());

    type == TypeAssignExercise.DEMAND
      ? body.append(
          "setting_student",
          JSON.stringify(
            dataAssignSpecific.study_data.map(
              ({
                practice_percent: practice_per,
                study_percent: study_per,
                ...rest
              }) => ({
                practice_per,
                study_per,
                ...rest,
              })
            )
          )
        )
      : body.append(
          "setting_student",
          JSON.stringify(dataAssignSpecific.study_data)
        );

    type == TypeAssignExercise.DEMAND
      ? body.append(
          "ingredient",
          JSON.stringify(dataAssignSpecific?.lesson_list_propose)
        )
      : body.append(
          "desired_time",
          JSON.stringify(dataAssignSpecific.expected_time)
        );

    const res = await teacherService.getDataAssignProposal(
      type == TypeAssignExercise.DEMAND ? "request" : "capacity",
      body
    );

    return res.data;
  };

  // Handle Open Modal Skill
  const handleOpenModalSkill = () => {
    setOpenModalSide("modalSkills");
  };

  // Handle Go Back
  const handleGoBack = () => {
    if (type == TypeAssignExercise.DEMAND) {
      history.push(
        "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/student/" +
          type +
          "/skills"
      );
    } else if (type == TypeAssignExercise.CAPACITY) {
      history.push(
        "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/student/" +
          type
      );
    }
  };

  // Variable Open Popup
  const [openModalSide, setOpenModalSide] = useState("");

  // Handle Confirm Apply
  const handleConfirmApply = async () => {
    setOpenModalSide("");
    const responseData = await getDataProposal();

    if (responseData) {
      _dispatch({
        type: teacherConstants.GET_DATA_SETTING_ASSIGN,
        setting: dataAssignSpecific,
      });
      _dispatch({
        type: teacherConstants.GET_DATA_ASSIGN_SPECIFIC,
        proposal: responseData,
      });
      setDataProposal(responseData);
    }
  };

  // Handle Select Student
  const handleSelectStudent = (data) => {
    data.user_id == studentSelected
      ? setStudentSelected("")
      : setStudentSelected(data.user_id);
  };

  // Handle Check Delete
  const handleCheckDelete = (item) => {
    setLessonSelected(item);
    setOpenModalSide("modalDelete");
  };

  //  Handle Delete Lesson
  const handleDeleteLesson = () => {
    setOpenModalSide("");
    const studentLessonDelete = dataProposal.filter(
      (item) => item.user_id == studentSelected
    );

    const [objStudentLessonDelete] = studentLessonDelete;

    const newObjStudentLess = objStudentLessonDelete.exercise_suggest.filter(
      (item) => item.lesson_id != lessonSelected.lesson_id
    );

    objStudentLessonDelete["exercise_suggest"] = newObjStudentLess;

    const newDataProposal = dataProposal;
    newDataProposal.forEach((item, index, dataOriginal) => {
      if (item.user_id == objStudentLessonDelete.user_id) {
        dataOriginal[index] = objStudentLessonDelete;
      }
    });

    setDataProposal(newDataProposal);

    // const dataExercise = newDataProposal.reduce(function (accumulator, obj) {
    //   return accumulator.concat(obj.exercise_suggest);
    // }, []);

    const dataExercise = exercises.data_exercise;

    const newDataExercise = dataExercise.filter(
      (item_filter) => item_filter.lesson_id != lessonSelected.lesson_id
    );

    const dataLessonSelected = exercises.data_lessons_selected;

    const newDataLessonSelected = dataLessonSelected.filter(
      (item_filter) => item_filter?.lesson_id != lessonSelected?.lesson_id
    );

    _dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: {
        ...exercises,
        start_time: dateAddStudentExercise.start_time,
        end_time: dateAddStudentExercise.end_time,
        data_exercise: newDataExercise,
        data_lessons_selected: newDataLessonSelected,
      },
    });

    _dispatch({
      type: teacherConstants.GET_DATA_ASSIGN_SPECIFIC,
      proposal: newDataProposal,
    });
  };

  // Handle check empty exercise
  const handleCheckEmptyExercise = () => {
    return dataAssignProposal?.every(
      (item) => item.exercise_suggest.length <= 0
    );
  };

  // Handle Submit Assign
  const handleAssignHomework = async () => {
    const exercise_per_hs = dataProposal.map((data) => ({
      user_id: data?.user_id,
      exercise_suggest: data?.exercise_suggest.map((item) => ({
        exercise_id: item?.lesson_id,
        exercise_type: item?.lesson_type,
        start_time: dateAddStudentExercise?.start_time || item.start_time,
        end_time: dateAddStudentExercise?.end_time || item.end_time,
        curriculum_id: item?.curriculum_id,
        unit_id: item?.unit_id,
        list_guide_id: JSON.stringify(item.list_guide_id),
      })),
    }));

    const bodyAssign = {
      class_id: id.toString(),
      students: exercises.students,
      start_time: dateAddStudentExercise?.start_time,
      end_time: dateAddStudentExercise?.end_time,
      remind_before: exercises.remind_before,
      note: exercises.note,
      before_start_time: exercises.before_start_time,
      exercise_per_hs: exercise_per_hs,
    };

    const res = await teacherService.postAssignSpecific(bodyAssign);

    if (res.status) {
      _dispatch(
        alertActions.success({
          message: res.msg.toString(),
          screen: teacherConstants.SCREEN_ASSIGN_HOME_WORK,
        })
      );
    } else {
      _dispatch(
        alertActions.error({
          message: res.msg.toString(),
          screen: teacherConstants.SCREEN_ASSIGN_HOME_WORK,
        })
      );
    }
  };

  const [isScrollable, setIsScrollable] = useState(false);
  const checkScroll = useRef(null);

  useEffect(() => {
    const checkScrollAbility = () => {
      if (checkScroll.current) {
        const div = checkScroll.current;
        if (div.scrollHeight > div.clientHeight) {
          setIsScrollable(true);
        } else {
          setIsScrollable(false);
        }
      }
    };

    window.addEventListener("resize", checkScrollAbility);

    setTimeout(checkScrollAbility, 500);

    return () => {
      window.removeEventListener("resize", checkScrollAbility);
    };
  }, []);

  return {
    dataReduxAssign,
    isScrollable,
    checkScroll,
    dataProposal,
    dataAssignSpecific,
    setDataAssignSpecific,
    handleGoBack,
    handleOpenModalSkill,
    handleConfirmApply,
    studentSelected,
    handleSelectStudent,
    handleDeleteLesson,
    openModalSide,
    setOpenModalSide,
    handleCheckDelete,
    handleCheckEmptyExercise,
    handleAssignHomework,
  };
};
