import React, { useEffect, useState } from "react";
import './styles.scss'

export const DropDownCustom = (props) => {

    const { listOptions, itemSelected, onChangeValue, classItem} = props;

    const [isExpand, setExpand] = useState(false);
    let wrapperRef = null;

	useEffect(() => {
		document.addEventListener('mousedown', clickOutside);
		return () => {
			document.removeEventListener('mousedown', clickOutside);
		}
	}, [])

	function clickOutside(event) {
		if (wrapperRef && !wrapperRef.contains(event.target)) {
            setExpand(false)
        }
	}

	const setWrapperRefFind = (node) => {
		if (!wrapperRef) {
			wrapperRef = node
		}
	}

    const onChange = (item) => {
        onChangeValue && onChangeValue(item)
        setExpand(false)
    }
    
    return (
        <div className="c-dropdown-custom" ref={(e) => setWrapperRefFind(e)}>
            <div className={"item-selected" + (classItem ? (" " + classItem) : "")} onClick={() => setExpand(!isExpand)}>
                {props.children ? props.children : (
                    <div className="item-default">
                        <img className={isExpand ? "" : "reverse"} src="/assets/images/luyenthi/ico-expand.png"/>
                        <span>{itemSelected && itemSelected.title}</span>
                    </div>
                )}
            </div>
            {isExpand && <div className="list-options scrollbar-custom">
                {listOptions && listOptions.map(item => (
                    <div className="option" key={item.value} onClick={() => onChange(item)}>
                        {item.title}
                    </div>
                ))}
            </div>}
        </div>
    )
}
