import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertConstants, userConstants } from "./../../_constants";

import { HeaderCloud } from "../../_components/Header";
import { alertActions, userActions } from "../../_actions";
import { Alert } from "../../_components/Alert";
import { LinkBack } from "../../_components/Link";
import { isEmail } from "../../_helpers/validateEmail";
import InputText from "../../_components/Auth/InputText";
import { Link } from "react-router-dom";

function ForgotPasswordPage() {
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  useEffect(() => {
    if (alert.type === alertConstants.SUCCESS) {
      setResetSuccess(true);
    }
  }, [alert]);

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (validateParam()) {
      dispatch(userActions.forgotPassword(email));
    }
  }

  function validateParam() {
    return isEmail(email);
  }

  const changeEmail = (value) => {
    if (
      alert.message &&
      alert.screen === userConstants.SCREEN_FORGOT_PASSWORD
    ) {
      dispatch(alertActions.clear());
    }
    if (emailError) {
      if (isEmail(value)) {
        setEmailError("");
      }
    }
    setEmail(value.trim());
  };

  const onBlurEmail = () => {
    if (email && !emailError && !isEmail(email)) {
      setEmailError("Định dạng email không đúng");
    }
  };

  const renderEmailIcon = () => {
    return (
      <svg
        width="31"
        height="29"
        viewBox="0 0 31 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.2412 11.4491L25.2341 7.79102V6.91003C25.2157 6.04501 24.8553 5.22259 24.2317 4.6228C23.6081 4.02302 22.7721 3.69481 21.907 3.71008H19.647L15.604 0.756104C15.4999 0.680115 15.3745 0.63916 15.2456 0.63916C15.1167 0.63916 14.9913 0.680115 14.8872 0.756104L10.844 3.71008H8.58521C7.71995 3.69454 6.88357 4.02262 6.25977 4.62244C5.63596 5.22225 5.27547 6.04483 5.25708 6.91003V7.79102L0.25 11.4491C0.173 11.5054 0.110312 11.5789 0.0668945 11.6638C0.0234769 11.7487 0.000539448 11.8426 0 11.938C0.00129897 11.9716 0.00551907 12.0051 0.012207 12.0381C0.00662848 12.0655 0.00241492 12.0932 0 12.1211V27.255C0.0812238 27.478 0.092936 27.7204 0.0332031 27.9501C0.0739633 28.0702 0.151258 28.1745 0.254395 28.2484C0.357531 28.3223 0.481275 28.3621 0.608154 28.3621H29.8811C30.008 28.3617 30.1318 28.3217 30.2349 28.2477C30.338 28.1736 30.4152 28.0693 30.4561 27.9491C30.3962 27.7199 30.4074 27.4778 30.488 27.255V12.1211C30.4861 12.0936 30.4825 12.0661 30.4771 12.0391C30.4841 12.0035 30.4877 11.9673 30.488 11.931C30.4865 11.8371 30.4635 11.7448 30.4207 11.6613C30.3778 11.5777 30.3166 11.5052 30.2412 11.4491ZM25.2341 9.297L28.8691 11.953L25.364 14.6531L25.2351 14.7461L25.2341 9.297ZM15.2461 1.99707L17.5852 3.70703H12.905L15.2461 1.99707ZM6.47412 6.9071C6.49214 6.3643 6.72452 5.85073 7.12036 5.47888C7.5162 5.10703 8.04331 4.90719 8.58618 4.9231H21.9082C22.4509 4.90746 22.9777 5.10743 23.3733 5.47925C23.7689 5.85107 24.001 6.36448 24.019 6.9071V15.625L19.2332 19.1011C19.1506 19.118 19.0726 19.152 19.0042 19.201L15.2842 22.011L11.3892 19.1941L6.4751 15.6261L6.47412 6.9071ZM5.25806 9.29504V14.629L1.63501 11.942L5.25806 9.29504ZM1.21899 13.313L9.99805 19.687L1.21899 26.061V13.313ZM2.39014 27.144L11.0452 20.4441L14.9351 23.2581C15.0405 23.3343 15.1677 23.3747 15.2979 23.3734C15.428 23.3722 15.5542 23.3293 15.6582 23.2511L19.3672 20.451L28.0901 27.145L2.39014 27.144ZM29.2742 26.0601L20.4951 19.686L29.2742 13.312V26.0601Z"
          fill="#00B9B7"
        />
      </svg>
    );
  };

  return (
    <div className="bg-register rel">
      <HeaderCloud />
      <div className="sunE-form-container login-form-container">
        {!resetSuccess ? (
          <>
            <div className="sunE-custom-form login-form-container_content responsive_small_screen_margin_login">
              <h2 className="title text-center">ĐẶT LẠI MẬT KHẨU</h2>
              <h3
                className="text-center subtitle"
                style={{ marginBottom: "44px", fontSize: "18px" }}
              >
                Bạn vui lòng điền Email sử dụng khi tạo tài khoản để lấy lại mật
                khẩu
              </h3>
              {alert.message &&
                alert.screen === userConstants.SCREEN_FORGOT_PASSWORD && (
                  <Alert alert={alert} />
                )}
              <form
                className="sunE-login-app"
                onSubmit={handleSubmit}
                noValidate={true}
              >
                <InputText
                  value={email}
                  setValue={changeEmail}
                  type="text"
                  name="email"
                  placeholder="Nhập email"
                  required={true}
                  renderLabelIcon={renderEmailIcon}
                  errorText={emailError}
                  setErrorText={setEmailError}
                  onBlur={onBlurEmail}
                ></InputText>
                <div className="form-sunE-button" style={{ marginTop: "46px" }}>
                  <button
                    style={{
                      width: "210px",
                      height: "50px",
                      borderRadius: "25px",
                      fontSize: "18px",
                    }}
                    className={
                      "btn-line-blue" + (!validateParam() ? " btn-disable" : "")
                    }
                  >
                    Gửi yêu cầu
                  </button>
                </div>
              </form>
            </div>
            <div className="sunE-bottom-form text-center">
              <LinkBack />
            </div>
          </>
        ) : (
          <>
            <div className="sunE-custom-form login-form-container_content">
              <h2
                className="title text-center"
                style={{ marginBottom: "56px" }}
              >
                ĐẶT LẠI MẬT KHẨU
              </h2>
              <div
                className="text-center subtitle"
                style={{ marginBottom: "36px", fontSize: "18px" }}
              >
                <svg
                  width={85}
                  height={56}
                  viewBox="0 0 85 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M55.0131 53.616H8.74109C7.00334 53.611 5.33821 52.9185 4.10944 51.6897C2.88066 50.4609 2.18812 48.7956 2.18311 47.0579V8.64896C2.18812 6.91121 2.88066 5.24614 4.10944 4.01736C5.33821 2.78859 7.00334 2.09611 8.74109 2.09109H64.5491C66.2869 2.09611 67.952 2.78859 69.1808 4.01736C70.4095 5.24614 71.1021 6.91121 71.1071 8.64896V19.7229"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.54395 7.44295L36.144 23.6031L63.744 7.576"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M75.727 36.334H66.068C61.8198 36.334 58.376 39.7778 58.376 44.026V46.308C58.376 50.5562 61.8198 54 66.068 54H75.727C79.9752 54 83.419 50.5562 83.419 46.308V44.026C83.419 39.7778 79.9752 36.334 75.727 36.334Z"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M70.8972 23.988C73.1266 23.988 75.2647 24.8736 76.8412 26.4501C78.4176 28.0265 79.3032 30.1646 79.3032 32.394V36.288H62.4912V32.394C62.4912 30.1646 63.3768 28.0265 64.9533 26.4501C66.5297 24.8736 68.6678 23.988 70.8972 23.988V23.988Z"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M70.873 47.828V42.257"
                    stroke="#231F20"
                    strokeWidth={3}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <p className="text-center" style={{ fontSize: "18px" }}>
                  Đường dẫn đặt lại mật khẩu đã được gửi đến địa chỉ email{" "}
                  <span style={{ color: "#00B9B7", fontSize: "18px" }}>
                    {email}
                  </span>
                  . Bạn vui lòng kiểm tra hộp thư và làm theo hướng dẫn nhé!
                </p>
              </div>
            </div>
            <div className="form-sunE-button" style={{ marginTop: "105px" }}>
              <Link to="/login">
                <button
                  style={{
                    width: "210px",
                    height: "50px",
                    borderRadius: "25px",
                    fontSize: "18px",
                  }}
                  className={"btn-line-blue"}
                >
                  Đồng ý
                </button>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export { ForgotPasswordPage };
