import React, { useEffect, useState, Fragment } from "react";
import "./style.css";

function PopUpEditHomeWork(props) {
  let {
    onClickNo,
    onClickYes,
    width,
    currentDataErr,
    currentDataFix,
    cachesList,
    type,
  } = props;

  console.log(currentDataFix);

  let [dataError, setDataError] = useState({ ...currentDataErr });

  let [dataFix, setDataFix] = useState({ ...currentDataFix });

  function handleSubmit() {
    if (validateParam()) {
      dataFix.ai = false;
      dataFix.idErr = dataError.start + "_" + dataError.end;

      let startFix = 0;
      let endFix = 0;

      let startError = 0;
      let endError = 0;
      // let cachesList = cachesList;
      if (type == "update") {
        if (type == "update") {
          if (currentDataFix?.type == 1) {
            dataError.start = dataError.start - currentDataFix.fixed.length;
            dataError.end = dataError.end - currentDataFix.fixed.length;
            dataError.id = dataError.start + "_" + dataError.end;
            setDataError({ ...dataError });
          }
        }
        cachesList =
          cachesList.substring(0, currentDataFix?.start) +
          cachesList.substring(currentDataFix?.end, cachesList?.length);
      }
      if (!dataFix.fixed.toString()) {
        dataFix.start = dataError.start;
        dataFix.end = dataError.start;
        dataFix.id = dataError.start + "_" + dataError.start;
        dataFix.idErr = dataError.start + "_" + dataError.end;
        dataError.id = dataError.start + "_" + dataError.end;
        cachesList =
          cachesList.substring(0, dataError.start) +
          cachesList.substring(dataError.start, dataError.end) +
          cachesList.substring(dataError.end, dataError.length);
      } else {
        if (dataFix.type) {
          startFix = dataError.start;
          endFix = dataError.start + dataFix.fixed.length;
          startError = dataError.start + dataFix.fixed.length;
          endError = dataError.end + dataFix.fixed.length;
          cachesList =
            cachesList.substring(0, dataError.start) +
            dataFix.fixed +
            cachesList.substring(dataError.start, dataError.length);
        } else {
          startFix = dataError.end;
          endFix = dataError.end + dataFix.fixed.length;
          startError = dataError.start;
          endError = dataError.end;
          cachesList =
            cachesList.substring(0, dataError.end) +
            dataFix.fixed +
            cachesList.substring(dataError.end, dataError.length);
        }
        dataFix.start = startFix;
        dataFix.end = endFix;
        dataFix.id = startFix + "_" + endFix;
        dataFix.idErr = startError + "_" + endError;

        dataError.id = startError + "_" + endError;
        dataError.start = startError;
        dataError.end = endError;
      }

      dataError.ai = false;
      dataError.type = dataFix.type;

      let addNumberToId = parseInt(
        dataFix.fixed.length - currentDataFix.fixed.length
      );
      onClickYes({
        dataError,
        dataFix,
        cachesList,
        type,
        addNumberToId,
      });
    }
  }

  function back() {
    let addNumberToId = parseInt(0 - currentDataFix.fixed.length);
    cachesList =
      cachesList.substring(0, currentDataFix.start) +
      cachesList.substring(currentDataFix.end, cachesList.length);
    onClickYes({
      dataError,
      dataFix,
      cachesList,
      type: "delete",
      addNumberToId,
    });
  }

  function validateParam() {
    return dataError.isHide || dataFix.fixed ? true : false;
  }

  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
        style={{ width: width }}
      >
        <h2 className="title-sp-gray">Từ chọn</h2>
        <p className="text-edit">{dataError?.text}</p>
        <div className="flex-m mb-10">
          <div className="mr-10">
            <p className="title-sp-gray">Xóa từ đã chọn</p>
          </div>
          <div className="flex-1">
            <span className="chk-custom-gr wh20">
              <input
                type="checkbox"
                id="delete"
                checked={dataError.isHide ? true : false}
                onChange={() =>
                  setDataError({ ...dataError, isHide: !dataError.isHide })
                }
              />
              <label htmlFor="delete"></label>
            </span>
          </div>
        </div>
        <div className="input-text-gr">
          <p className="title-sp-gray">Thêm</p>
          <input
            type="text"
            defaultValue={dataFix.fixed}
            onChange={(e) =>
              setDataFix({ ...dataFix, fixed: e.target.value + `&nbsp;` })
            }
            placeholder="Thêm từ của bạn"
          />
        </div>
        <div className="flex-m radio-gr-add-content">
          <div className="flex-1 flex-m">
            <input
              type="radio"
              checked={dataFix.type ? true : false}
              onChange={() => setDataFix({ ...dataFix, type: 1 })}
              id="before"
              name="add_content"
            />
            <label htmlFor="before" className="title-sp-gray flex-1">
              Thêm
              <br />
              phía trước
            </label>
          </div>
          <div className="flex-1 flex-m">
            <input
              type="radio"
              checked={!dataFix.type ? true : false}
              onChange={() => setDataFix({ ...dataFix, type: 0 })}
              id="after"
              name="add_content"
            />
            <label htmlFor="after" className="title-sp-gray flex-1">
              Thêm
              <br />
              phía sau
            </label>
          </div>
        </div>
        <div className="input-text-gr mb-20">
          <p className="title-sp-gray">Giải thích</p>
          <input
            type="text"
            defaultValue={dataFix.explain}
            onChange={(e) =>
              setDataFix({ ...dataFix, explain: e.target.value })
            }
            placeholder="Giải thích của bạn"
          />
        </div>
        <div className="flex-m form-sunE-button1">
          {type === "add" ? (
            <button
              className="btn-default width-100-percent mr-10"
              onClick={onClickNo}
            >
              Đóng
            </button>
          ) : (
            <button
              className="btn-default width-100-percent mr-10"
              onClick={() => back()}
            >
              Hoàn tác
            </button>
          )}
          <button
            className={
              "btn-line-blue width-100-percent wd-c" +
              (!validateParam() ? " btn-disable" : "")
            }
            onClick={handleSubmit}
          >
            {type === "add" ? "Lưu" : "Cập nhật"}
          </button>
        </div>
      </div>
    </div>
  );
}

export { PopUpEditHomeWork };
