import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { Header } from "./../../../_components/Admin/Header";
import { useParams } from "react-router-dom";
import { CalendaSchedule } from "../../../_components/Calendar";
import moment from "moment";
import { EmptyScheduleToday } from "../../../_components/Admin/More/Schedule";
import { isEmpty, findIndex } from "lodash";
import { scheduleConstants, teacherConstants } from "../../../_constants";

const formatSecontToMinutes = (second) => {
  let minute = parseInt(second / 60);
  let seconds = second % 60;
  if (minute > 0) {
    return `${minute} phút ${seconds} giây`;
  }
  return `${seconds} giây`;
};

function ViewScheduleStudent() {
  const { id, student_id } = useParams();
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.classes.reports);
  // const logLearnings = useSelector(state => state.classes.reports.log_learning);
  const logLearningsByDate = useSelector(
    (state) => state.classes.reports.learning_by_date
  );

  const detailClass = useSelector((state) => state.classes)
  const class_id = detailClass?.data[0]?.id

  // const [inputs, setInputs] = useState({
  //     from_time: moment().format('yyyy-MM-DD')
  // });

  // let { from_time } = inputs;
  // let key_from_time = findIndex(reports.log_learning.data.recent_activity, { time: moment(from_time).format('yyyy-MM-DD') });
  // let recent_activity = []
  // if (key_from_time !== -1) {
  //     recent_activity = reports.log_learning.data.recent_activity[key_from_time];
  // }

  const upperFirstCase = useCallback(
    (actionName) => {
      if (!actionName) {
        return "";
      }
      return actionName.charAt(0).toUpperCase() + actionName.slice(1);
    },
    [logLearningsByDate]
  );

  useEffect(() => {
    dispatch(
      teacherActions.getReportByStudentId(
        id,
        student_id,
        moment(moment().format("yyyy-MM-DD"))
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
        moment(moment().format("yyyy-MM-DD"))
          .endOf("isoWeek")
          .format("YYYY-MM-DD")
      )
    );
    // dispatch(teacherActions.getLogLearningByStudentId(student_id, id, moment().format('yyyy-MM-DD')));
    dispatch(
      teacherActions.getLogLearningByDate(
        moment().format("yyyy-MM-DD"),
        student_id
      )
    );
  }, []);

  function changeDateCalendaSchedule(date) {
    let temp_time =
      date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day;
    // setInputs({
    //     from_time: temp_time,
    // })
    // dispatch(teacherActions.getLogLearningByStudentId(student_id, id, temp_time));
    dispatch(teacherActions.getLogLearningByDate(temp_time, student_id));
  }
  return (
    <div className="sunE-right-container">
      <Header title={"Nhật ký học tập"} isBack />
      <div className="flex-m sunE-content bcht flex-1">
        <div className="flex-column align-item-center">
          <div className="student-info">
            <div className="avt-i responsive_viewScheduleStudent_avatar" style={{width: "96px", height: "96px"}}>
              <img
                src={
                  reports.students.base_url +
                  reports.students.student_data.avatar
                }
                alt="student"
              />
            </div>
            <h2 className="name bold responsive_viewScheduleStudent_name">
              {reports.students.student_data.fullname}
            </h2>
            <div className="email-gr center-flex responsive_viewScheduleStudent_email">
              <div className="flex-m max-w-hidden">
                <div>
                  <img
                    src="/assets/images/chambai/ico_email.png"
                    alt="ico_email"
                  />
                </div>
                <p className="flex-1 one-line">
                  {reports.students.student_data.email}
                </p>
              </div>
            </div>
          </div>
          <div className="__calendar_log">
            <CalendaSchedule
              student_id={student_id}
              role={"student"}
              changeDateCalendaSchedule={(date) =>
                changeDateCalendaSchedule(date)
              }
              collapse
              showMonth
              class_id={class_id}
            />
          </div>
        </div>
        <div className="flex-1" style={{width: "100%"}}>
          <div className="his-hd view_height_scroll_assessment_log_learning">
            {isEmpty(logLearningsByDate.data) ? (
              <div className="flex-1 sunE-plan">
                <EmptyScheduleToday isBCHT ViewScheduleStudentDetail/>
              </div>
            ) : (
              <div className="list-schedule scrollbar-custom nkht-op">
                {logLearningsByDate.data.map((data, i) => {
                  return (
                    <div className="flex-m schedule-item" key={i}>
                      <div className="time-h" style={{width: "20%"}}>
                        <p>{moment(data.time).format("HH:mm")}</p>
                      </div>
                      <div className="flex-1 schedule-i" style={{width: "80%", paddingLeft: "20px"}}>
                        <p className="one-line-break-word bold">{data.lesson_name}</p>
                        <p className="one-line-break-word">{data?.curriculum_name}</p>
                        <p className="one-line-break-word">
                          {data.unit_name + " - " + upperFirstCase(data.skill)}
                        </p>
                        <p className="one-line-break-word">
                          Thời gian học:{" "}
                          {formatSecontToMinutes(parseInt(data.duration))}
                        </p>
                        <p className="scor font-family-bold">
                          {data.status == 1 || data.score > 0 ? `${parseFloat(parseFloat(data.score).toFixed(1))} điểm` : null}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { ViewScheduleStudent };
