import "./AdvisementForm.style.scss";
import { advisementLogic } from "./AdvisementForm.logic";
import InputText from "../Auth/InputText";
import ButtonNews from "../Button/ButtonNews";
import InputSelectNews from "../Input/InputSelect";
import { userConstants } from "../../_constants";
import ModalSuccessMsg from "../Modal/ModalSuccessMsg/ModalSuccessMsg";
import LazyLoad from "react-lazyload";

const AdvisementForm = (props) => {
  let {
    dataTimeAdvisement,
    timeAdvisement,
    gradeSelected,
    dataGrades,
    changeGradeSelected,
    changeTimeAdvisement,
    nameUser,
    setNameUser,
    changeNameUser,
    nameUserError,
    setNameUserError,
    phone,
    phoneWarning,
    setPhoneWarning,
    changePhone,
    onBlurField,
    addressTeach,
    setAddressTeach,
    changeAddressTeach,
    handleRegistAdvisement,
    timeAdvisementError,
    setTimeAdvisementError,
    addressTeachError,
    setAddressTeachError,
    gradeError,
    setGradeError,
    isSuccessRegist,
    handleCloseModal,
  } = advisementLogic(props);

  return (
    <div className=" advisement_form pos_rel flex-center-column">
      {/* Img Banner */}

      <div className="content_advisement_container pos_rel">
        <div
          className="content_advisement flex-align-start-column"
          style={{
            background: `url(
              /assets/images/homeNews/content/bg_advisement.png
            ) no-repeat top center /cover`,
          }}
        >
          <div>
            {props.type == userConstants.ROLE_TEACHER ? (
              <img
                src="/assets/images/homeNews/content/teacher/title_advisement_teacher.png"
                alt="Titlte Advisement"
                className="contain_image title_header_teacher"
              />
            ) : (
              <>
                <img
                  src="/assets/images/homeNews/content/title_advisement.png"
                  alt="Titlte Advisement"
                  className="contain_image title_header display_none_mobile"
                />

                <div className="hide display_flex_mobile title_header_mobile flex-column-mobile">
                  <img
                    src="/assets/images/homeNews/content/title_header_1_mobile.png"
                    alt="Titlte Advisement"
                    className="contain_image title_header_1 "
                  />
                  <img
                    src="/assets/images/homeNews/content/title_header_2_mobile.png"
                    alt="Titlte Advisement"
                    className="contain_image title_header_2 "
                  />
                </div>
              </>
            )}
          </div>
          <div
            className="display_flex flex-center-column-mobile"
            style={{
              justifyContent: "space-between",
            }}
          >
            <div className="content_left_advisement">
              <div className="header_advisement">
                <div className="detail_header">
                  {props.type == userConstants.ROLE_TEACHER
                    ? "Thầy cô hãy để lại thông tin chính xác để được hỗ trợ sớm nhất có thể nhé!"
                    : "Ba mẹ hãy để lại thông tin chính xác để được hỗ trợ sớm nhất có thể nhé!"}
                </div>
              </div>
              <form
                className="form_input_advisement"
                noValidate={true}
                onSubmit={handleRegistAdvisement}
              >
                <InputText
                  value={nameUser}
                  setValue={changeNameUser}
                  type="text"
                  name="name"
                  placeholder={props.placeholder}
                  required={true}
                  // renderLabelIcon={() => renderAuthIcon("name")}
                  errorText={nameUserError}
                  setErrorText={setNameUserError}
                  onFocus={() => setNameUser(nameUser?.trim())}
                  onBlur={() => {
                    onBlurField("name");
                  }}
                  errorAbsolute={true}
                />
                <InputText
                  value={phone}
                  setValue={changePhone}
                  type="text"
                  name="phone"
                  placeholder="Số điện thoại"
                  required={true}
                  // renderLabelIcon={() => renderAuthIcon("phone")}
                  errorText={phoneWarning}
                  setErrorText={setPhoneWarning}
                  onBlur={() => {
                    onBlurField("phone");
                  }}
                  errorAbsolute={true}
                />

                {props.type == userConstants.ROLE_TEACHER ? (
                  <InputText
                    value={addressTeach}
                    setValue={changeAddressTeach}
                    type="text"
                    name="address"
                    placeholder="Nơi công tác"
                    required={true}
                    // renderLabelIcon={() => renderAuthIcon("phone")}
                    errorText={addressTeachError}
                    setErrorText={setAddressTeachError}
                    onFocus={() => setAddressTeach(addressTeach?.trim())}
                    onBlur={() => {
                      onBlurField("address");
                    }}
                    errorAbsolute={true}
                  />
                ) : (
                  <InputSelectNews
                    value={gradeSelected}
                    setValue={changeGradeSelected}
                    type="text"
                    name="phone"
                    placeholder="Lớp của con"
                    required={true}
                    errorText={gradeError}
                    setErrorText={setGradeError}
                    options={dataGrades.map((item) => {
                      return {
                        value: item.value,
                        title: item.title,
                      };
                    })}
                    errorAbsolute={true}
                  />
                )}

                <InputSelectNews
                  value={timeAdvisement}
                  setValue={changeTimeAdvisement}
                  type="text"
                  name="phone"
                  placeholder="Thời gian nhận tư vấn"
                  required={true}
                  errorText={timeAdvisementError}
                  setErrorText={setTimeAdvisementError}
                  options={dataTimeAdvisement.map((item) => {
                    return {
                      value: item.value,
                      title: item.title,
                    };
                  })}
                  errorAbsolute={true}
                />
                <div className="flex-center btn_container">
                  <ButtonNews
                    width="18rem"
                    height="4rem"
                    onClick={(e) => handleRegistAdvisement(e)}
                    border={"1px solid #fff"}
                    backgroundColor={"#EB5757"}
                    boxShadow={"1px 1px 5px white, 0 0 1px white"}
                    maxWidth={"19.5vw"}
                    maxHeight={"5.5vw"}
                  >
                    <div className="text_btn fz-1-mobile btn_text font_news_bold color-white">
                      ĐĂNG KÝ
                    </div>
                  </ButtonNews>
                </div>
              </form>
            </div>

            <div className="flex-align-end img_side_advisement">
              <img
                src="/assets/images/homeNews/content/img_advisement_side.png"
                alt="Img Side Advisement"
                className="contain_image"
              />
            </div>
          </div>
        </div>

        {isSuccessRegist && (
          <div className="pos_abs modal_success_abs_container">
            <div className=" modal_success_abs">
              <ModalSuccessMsg
                isVisible={isSuccessRegist}
                onClickClose={handleCloseModal}
                titleMsg="ĐĂNG KÝ THÀNH CÔNG!"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvisementForm;
