import { customBarLogic } from "./CustomBarRange.logic";
import { convertTitleAssign } from "../../_base/Validate";
import "./CustomBarRange.style.scss";

const CustomBarRange = (props) => {
  let { study_data_item, setMovePercent, currUnit, totalUnit } = props;
  let { handleTimeDrag } = customBarLogic(props);

  const curPercentage = (study_data_item?.practice_percent / 100) * 100;

  return (
    <div className="flex-column-start bar_range">
      <div className="color-title-assign fz-14 font_myriad_bold pd-bottom-0-7">
        Trình độ {convertTitleAssign(study_data_item?.stand)}
      </div>
      <div
        className="bar__progress col-100 mg-bottom-0-7"
        style={{
          background: `linear-gradient(to right, #F8AF36 ${curPercentage}%, #E9DECD 0)`,
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span
          className="bar__progress__knob"
          style={{ left: `${curPercentage - 4}%` }}
        />
      </div>

      <div className="flex-jus-between">
        <div className="font_style_italic fz-11-25">
          Ôn luyện {`(${study_data_item?.practice_percent}%)`}
        </div>

        <div className="font_style_italic fz-11-25">
          Kiến thức mới{" "}
          {`(${100 - parseInt(study_data_item?.practice_percent)}%)`}
        </div>
      </div>
    </div>
  );
};

export default CustomBarRange;
