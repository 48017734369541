import { apiCaller } from "./../_helpers";
import {
  studentConstants,
  curriculumConstants,
  teacherConstants,
  popupConstants,
  configConstants,
} from "./../_constants";
import { alertActions } from "./";

export const studentActions = {
  getAllGrade,
  getExerciseByTeacherId,
  getExerciseByTeacherIdV2,
  getExerciseByCurriculumId,
  getDetailCurriculumById,
  getDataMapCurriculum,
  getDataMapByClassId,
  getDataGridCurriculum,
  getListCurriculum,
  getLessonCurriculum,
  getListClass,
  addParent,
  checkParent,
  applyForClass,
  checkClass,
  getAssessmentsOnline,
  getLogLearningByDate,
  getListLogLearning,
  updateDefaultCurriculum,
};

function getAllGrade(data) {
  return (dispatch) => {
    return apiCaller("/api_login/grade", "GET", data, null, false).then(
      (grade) => {
        dispatch({
          type: studentConstants.GET_ALL_GRADE,
          grade: grade.list_grade,
        });
      }
    );
  };
}

function getExerciseByTeacherId(teacher_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/homework_by_teacher_homepage?teacher_id=" + teacher_id,
      "GET",
      {},
      null,
      false
    ).then((exercises) => {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises,
      });
    });
  };
}

function getExerciseByTeacherIdV2(teacher_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/homework_by_teacher?teacher_id=" + teacher_id,
      "GET",
      {},
      null,
      false
    ).then((exercises) => {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises,
      });
    });
  };
}

function getExerciseByCurriculumId(curriculum_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/exercise_by_curriculum?curriculum_id=" + curriculum_id,
      "GET",
      {},
      null,
      false
    ).then((exercises) => {
      dispatch({
        type: studentConstants.GET_LIST_EXERCISE_STUDENT,
        exercises,
      });
    });
  };
}

function getDetailCurriculumById(id) {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/map_unit?curriculum_id=" + id,
      "GET",
      {},
      null,
      true
    ).then((curriculum) => {
      dispatch({
        type: curriculumConstants.GET_DETAIL_CURRICULUM,
        curriculum,
      });
    });
  };
}

function getDataMapCurriculum(id) {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/map_unit?curriculum_id=" + id,
      "GET",
      {},
      null,
      true
    ).then((data_map) => {
      dispatch({
        type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
        data_map,
      });
    });
  };
}

function getDataMapByClassId(id) {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/map_unit?class_id=" + id,
      "GET",
      {},
      null,
      true
    ).then((data_map) => {
      dispatch({
        type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
        data_map,
      });
    });
  };
}

function getDataGridCurriculum(id, isLoading) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/class_curriculum?curriculum_id=" + id,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      isLoading
    ).then((data_grid) => {
      dispatch({
        type: curriculumConstants.GET_DATA_GRID_CURRICULUM_STUDENT,
        data_grid,
      });
    });
  };
}

function getListCurriculum() {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/courses_name_follow_class_v2",
      "GET",
      {},
      null,
      true
    ).then((data_grid) => {
      dispatch({
        type: curriculumConstants.GET_LIST_CURRICULUM_STUDENT,
        data_grid,
      });
    });
  };
}

function getLessonCurriculum(unit_id, isLoading) {
  return (dispatch) => {
    return apiCaller(
      "/student/api_student_curriculum/lessons_by_skill?unit_id=" + unit_id,
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      isLoading ?? true
    ).then((lessons) => {
      dispatch({
        type: curriculumConstants.GET_LESSON_CURRICULUM_STUDENT,
        lessons,
      });
    });
  };
}

function getListClass() {
  return (dispatch) => {
    return apiCaller("/api_class/my_classes", "GET", {}, null, true).then(
      (classes) => {
        dispatch({
          type: teacherConstants.GET_LIST_CLASS,
          classes: classes,
        });
      }
    );
  };
}

function checkParent(data) {
  return (dispatch) => {
    return apiCaller(
      "/api_user/code?user_code=" + data.user_code,
      "GET",
      {},
      null,
      false
    ).then(
      (addStudent) => {
        addStudent.user_data.user_code = data.user_code;
        dispatch({
          type: popupConstants.SHOW_FORM_POPUP,
          data: {
            showFormAddStudent: true,
          },
        });
        dispatch(alertActions.clear());
        dispatch({
          type: teacherConstants.ADD_STUDENT,
          data: addStudent,
        });
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: studentConstants.SCREEN_ADD_PARENT,
          })
        );
      }
    );
  };
}

function addParent(data) {
  return (dispatch) => {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
    return apiCaller(
      "/api_relationship/send_request",
      "POST",
      data,
      null,
      false
    ).then(
      (res) => {
        if (
          res.code === null ||
          res.code === "MTY0OTc4MTc5NA==" ||
          res.msg ===
            "Bạn đã tạo link liên kết với tài khoản này trước đó. Vui lòng đợi phụ huynh xác nhận."
        ) {
          dispatch(
            alertActions.error({
              message:
                "Bạn đã gửi yêu cầu liên kết với tài khoản này trước đó. Vui lòng đợi phụ huynh xác nhận.",
              screen: studentConstants.SCREEN_ADD_PARENT,
            })
          );
        } else {
          dispatch(
            alertActions.success({
              message: studentConstants.ADD_PARENT_COMPLETE,
              screen: studentConstants.SCREEN_ADD_PARENT,
            })
          );
        }
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: studentConstants.SCREEN_ADD_PARENT,
          })
        );
      }
    );
  };
}

function applyForClass(data) {
  return (dispatch) => {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
    return apiCaller(
      "/api_class/class_request",
      "POST",
      data,
      null,
      false
    ).then(
      (data) => {
        dispatch(
          alertActions.success({
            message: data.msg,
            screen: studentConstants.SCREEN_APPLY_CLASS,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: studentConstants.SCREEN_APPLY_CLASS,
          })
        );
      }
    );
  };
}

function checkClass(data) {
  return (dispatch) => {
    return apiCaller(
      "/api_class/code?class_code=" + data.class_code,
      "GET",
      {},
      null,
      false
    ).then(
      (applyClass) => {
        applyClass.class_data.class_code = data.class_code;
        dispatch({
          type: popupConstants.SHOW_FORM_POPUP,
          data: {
            showFormApplyClass: true,
          },
        });
        dispatch(alertActions.clear());
        dispatch({
          type: teacherConstants.GET_DETAIL_CLASS,
          class: applyClass,
        });
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: studentConstants.SCREEN_APPLY_CLASS,
          })
        );
      }
    );
  };
}

function getAssessmentsOnline(student_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/student_assessment?student_id=" + student_id,
      "GET",
      {},
      null,
      true
    ).then((assessments) => {
      if (assessments.data_assessment) {
        dispatch({
          type: studentConstants.GET_ASSESSMENT_ONLINE,
          assessments,
        });
      }
    });
  };
}

function getLogLearningByDate(date, id) {
  return (dispatch) => {
    return apiCaller(
      `/api_student/log_learning_by_date?date=${date}&student_id=${id}`,
      "GET",
      {},
      null,
      true
    ).then((learnings) => {
      dispatch({
        type: studentConstants.GET_LOG_LEARNING_BY_DATE,
        learnings,
      });
    });
  };
}

function getListLogLearning(from_time, student_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_student/student_log_learning?from_time=" +
        from_time +
        "&student_id=" +
        student_id,
      "GET",
      {},
      null,
      true
    ).then((learnings) => {
      dispatch({
        type: studentConstants.GET_LIST_LOG_LEARNING,
        learnings,
      });
    });
  };
}

function updateDefaultCurriculum(curriculumId) {
  let data = {
    curriculum_id: curriculumId,
  };
  return apiCaller(
    "/api_student/save_default_curriculum",
    "POST",
    data,
    null,
    false
  ).then(
    (res) => {
      return res;
    },
    (error) => {
      return false;
    }
  );
}
