import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { isEmpty } from "lodash";
import {
  EmptyClassBlock,
  ListClassMessage,
} from "../../../_components/Admin/Teacher";
import $ from "jquery";

function HomePageMessages() {
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.classes);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [classes]);

  useEffect(() => {
    setLoading(true);
    dispatch(teacherActions.getListClass());
    dispatch(teacherActions.getListClassOffline());
  }, []);

  // console.log(classes);

  let componentClass =
    isEmpty(classes.data) && isEmpty(classes.dataOffline) ? (
      <EmptyClassBlock
        messagEmpty={
          "Bạn chưa có lớp học nào. Hãy tạo lớp mới và thêm học sinh để tạo cuộc trò chuyện"
        }
      />
    ) : (classes.data.every((data) => data.count_student <= 0) ? (
      <EmptyClassBlock
        messagEmpty={
          "Lớp học online chưa có thành viên. Bạn vui lòng thêm học sinh vào lớp để tạo cuộc trò chuyện!"
        }
      />
    ) : (
      <ListClassMessage classes={classes} />
    ));

  return (
    <div className="sunE-container flex flex">
      <div className="sunE-right-container rel">
        <Header title={"Tin nhắn"} />
        <div className="sunE-content sunE-class-content tinnhan">
          {!isLoading && componentClass}
        </div>
      </div>
    </div>
  );
}

export { HomePageMessages };
