import "./DeleteAccountSp.style.scss";
import { HeaderNews } from "../../../_components/Header";
import FooterNews from "../../../_components/FooterNews/FooterNews";
import ScrollFixed from "../../../_components/ScrollFixed/ScrollFixed";
import { deleteAccountLogic } from "./DeleteAccountSp.logic";
import { Fragment } from "react";

const DeleteAccountSp = (props) => {
  let { dataStepsDelete } = deleteAccountLogic(props);

  const renderListSteps = (step, type) => {
    return (
      <Fragment key={step.id}>
        <div className={`flex-center-column delete_step_item `}>
          <div className="delete_step_item_title col-100 text-align-center">
            <span className="font_news_bold item_title_text">
              Bước {step.id}:{" "}
            </span>{" "}
            {step?.title}
          </div>
          <div className="flex-center img_step_delete  box-shadow">
            <img
              src={
                "/assets/images/supportPage/deleteAccount/" +
                step?.srcImg +
                ".jpg"
              }
              alt="Step Img"
              className="contain_image"
            />
          </div>
          <div className={`text-align-center delete_step_detail`}>
            {step?.detail}
          </div>

          {step.id == 2 && (
            <div
              className={`display_none_mobile flex-center-column icon_fast_arrow_vertical `}
            >
              <img
                src="/assets/images/icon/ico_fast_arrow.png"
                alt="Icon Fast Arrow"
                className="contain_image"
              />
            </div>
          )}
          {step.id != 4 && (
            <div
              className={`flex-center-column hide display_flex_mobile icon_fast_arrow_vertical`}
            >
              <img
                src="/assets/images/icon/ico_fast_arrow.png"
                alt="Icon Fast Arrow"
                className="contain_image"
              />
            </div>
          )}
        </div>

        {(step.id == 1 || step.id == 3) && (
          <div
            className={`display_none_mobile flex-center-column icon_fast_arrow_horizontal icon_fast_arrow_${step.id} `}
          >
            <img
              src="/assets/images/icon/ico_fast_arrow.png"
              alt="Icon Fast Arrow"
              className="contain_image"
            />
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <div className="commondPage deleteAccountPage">
      <HeaderNews type={"delete_account"} />

      <div className="content flex-column">
        <h1 className="delete_accound_title text_uppercase font_news_bold color-red">
          Hướng dẫn xóa Tài khoản
        </h1>

        <div className="content_steps flex-center flex-wrap flex-center-column-mobile">
          {dataStepsDelete.slice(0, 2)?.map((step) => renderListSteps(step))}
          <div className="flex-center content_step_after flex-center-column-mobile">
            {dataStepsDelete
              .slice(2, 4)
              ?.map((step) => renderListSteps(step, "after"))}
          </div>
        </div>
      </div>

      <ScrollFixed />
      <FooterNews />
    </div>
  );
};

export default DeleteAccountSp;
