import React, { useEffect, useState } from "react";
import { PopUpFilter } from "../../../_components/Popup";
import { Header } from "../../../_components/Admin/Header";
import { teacherActions } from "../../../_actions";
import {
  popupConstants,
  curriculumConstants,
  userConstants,
} from "../../../_constants";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import { studentService, teacherService } from "../../../_services";
import LazyLoad from "react-lazyload";
import { isEmpty } from "lodash";

function ViewCurriculumSunEnglish({
  choose_list_curriculum,
  handleSelectedCurriculum,
}) {
  const { type } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const paramFilter = useSelector((state) => state.curriculums.filters.param);
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const fromPage = params.get("page");

  const handleClickCourse = (e, course) => {
    e.preventDefault();
    handleSelectedCurriculum(course);
  };

  useEffect(() => {
    // dispatch(teacherActions.getCurriculum(type));
    // return () => {
    //     dispatch({
    //         type: curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM
    //     });
    // }
    setLoading(true);
    if (choose_list_curriculum) {
      studentService.loadCurriculumRootLessness().then((curriculums) => {
        if (curriculums) {
          dispatch({
            type: curriculumConstants.GET_LIST_CURRICULUM,
            curriculums: curriculums.data,
          });
        }
        setLoading(false);
      }).catch((error) => {
        dispatch({type: userConstants.OFF_CHOOSE_LIST_CURRICULUM})
        dispatch({type: userConstants.RESET_ALL_PLACEMENT_TEST}) 
    });
    } else {
      teacherService.getCurriculum(type).then((curriculums) => {
        if (curriculums) {
          dispatch({
            type: curriculumConstants.GET_LIST_CURRICULUM,
            curriculums: curriculums.courses,
          });
        }
        setLoading(false);
      });
    }
  }, []);

  const curriculums = useSelector((state) => state.curriculums);
  const Popup = useSelector((state) => state.Popup);
  const authentication = useSelector((state) => state.authentication);

  function setshowFilter() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFilterCurriculum: true,
      },
    });
  }

  function setcloseFilter() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  function componentFilter() {
    return (
      <div
        className="form-sunE-button btn-filter rel"
        onClick={() => setshowFilter()}
      >
        <img
          src={
            _.isEmpty(paramFilter.skill) &&
            _.isEmpty(paramFilter.type) &&
            _.isEmpty(paramFilter.grade_id)
              ? `/assets/images/teacher/icon_not_yet_filter.png`
              : `/assets/images/teacher/ico_filter.png`
          }
          className="ml--5"
          alt="ico_add"
        />
        <button className="btn-line-blue">Lọc</button>
      </div>
    );
  }

  return (
    <div className="sunE-right-container">
      <>
        {Popup.showFilterCurriculum && (
          <PopUpFilter
            filter_curriculum={true}
            onClickNo={() => setcloseFilter()}
            width={420}
            fromPage={fromPage}
          />
        )}
        {choose_list_curriculum ? (
          <Header title={"Chọn giáo trình"} />
        ) : (
          <Header
            title={"Giáo trình Sunday English"}
            component={componentFilter()}
            clickBack={() =>
              history.push("/" + authentication.role + "/curriculum") + (!isEmpty(fromPage)  ? `?page=${fromPage}` : "")
            }
            isBack
          />
        )}
      </>
      {!isLoading && (
        <div className="sunE-container-box giaotrinh canhan">
          <div className="flex-m">
            <div className="flex-1 sunE-giaotrinh-list pr-25 scrollbar-custom gt-sunE-hei">
              {curriculums.data.map((data, i) => {
                // console.log(data)
                return (
                  <>
                    {choose_list_curriculum ? (
                      <a
                        href="#"
                        onClick={(e) => handleClickCourse(e, data)}
                        key={i}
                      >
                        <div className="box-giaotrinh-gr rel box-shadow">
                          <div className="thumb">
                            <img
                              src="/assets/images/giaotrinh/ico_gt.png"
                              alt="ico_gt_sun"
                            />
                          </div>
                          <div className="box-select-info width-100-percent">
                            <p className="one-line text-left">
                              {choose_list_curriculum
                                ? data.curriculum_name
                                : data.name}
                            </p>
                          </div>
                        </div>
                      </a>
                    ) : (
                      <Link
                        to={
                          "/" +
                          authentication.role +
                          "/curriculum/view/detail/" +
                          data.id  + (!isEmpty(fromPage)  ? `?page=${fromPage}` : "")
                        }
                        key={i}
                      >
                        <div className="box-giaotrinh-gr rel box-shadow">
                          <div className="thumb">
                            <img
                              src="/assets/images/giaotrinh/ico_gt.png"
                              alt="ico_gt_sun"
                            />
                          </div>
                          <div className="box-select-info width-100-percent">
                            <p className="one-line text-left">{data.name}</p>
                          </div>
                        </div>
                      </Link>
                    )}
                  </>
                );
              })}
              {curriculums.data.length === 0 && (
                <div
                  className="__text_empty_tutorial_guide"
                  style={{ fontWeight: "normal" }}
                >
                  Không tìm thấy kết quả phù hợp.
                </div>
              )}
            </div>
            <div className="flex-1 pl-25">
              <LazyLoad className="text-center">
                <img
                  src={
                    choose_list_curriculum
                      ? `/assets/images/rootlessnessCourse/list_courses_student.png`
                      : `/assets/images/giaotrinh/bg_gt.png`
                  }
                  alt="bg_gt"
                />
              </LazyLoad>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { ViewCurriculumSunEnglish };
