import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import {
  configConstants,
  teacherConstants,
  popupConstants,
} from "../../../_constants";
import { isEmpty, map, filter } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ChartDoughnut } from "./../../../_components/Chart";
import { PopUpRemind } from "./../../../_components/Popup";
import { Alert } from "./../../../_components/Alert";
import OverviewChartReport from "./OverviewChartReport";
import styled from "styled-components";
import $ from "jquery";

const ChuaChamText = styled.div`
  color: #00a69c;
  font-family: "Myriadpro-Bold";
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
`;

function ViewExercise() {
  const history = useHistory();

  const exercises = useSelector(
    (state) => state.classes.exercises.histories.detail
  );
  const classes = useSelector((state) => state.classes);
  const authentication = useSelector((state) => state.authentication);
  const Popup = useSelector((state) => state.Popup);
  const { id, exercise_id } = useParams();
  const dispatch = useDispatch();

  const [view, setView] = useState("completed");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [classes, exercises]);

  useEffect(() => {
    setLoading(true);
    dispatch(teacherActions.getDetailExercise(id, exercise_id));
    dispatch(teacherActions.getStudentOfClass(id, 999));
  }, []);

  const [listStudentIdAll, setListStudentIdAll] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  function onChangeSelectAll() {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setListStudentIdAll(
        exercises.data.incomplete
          .filter((obj) => !parseInt(obj.remind))
          .map((obj) => obj.id)
      );
    } else {
      setListStudentIdAll([]);
    }
  }

  function hanldeChecked(id) {
    if (selectAll) {
      setSelectAll(false);
    }
    if (!listStudentIdAll.includes(id)) {
      listStudentIdAll.push(id);
    } else {
      const index = listStudentIdAll.indexOf(id);
      if (index > -1) {
        listStudentIdAll.splice(index, 1);
      }
    }
    setListStudentIdAll([...listStudentIdAll]);
    if (listStudentIdAll.length === exercises.data.incomplete.length) {
      setSelectAll(true);
    }
  }
  function redirectPageEdit() {
    let currentListStudent = filter(exercises.list_student, function (o) {
      return o.status_assign;
    });
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: {
        ...classes.exercises,
        students: map(currentListStudent, "user_id"),
      },
    });

    dispatch({
      type: teacherConstants.EDIT_EXERCISE,
      exercises: {
        ...exercises,
        list_student: currentListStudent,
      },
    });

    history.push(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        "/exercise/" +
        exercise_id +
        "/edit"
    );
  }

  function addComponentSetting() {
    return (
      <div
        className="form-sunE-button btn-setting rel"
        onClick={() => redirectPageEdit()}
      >
        <img
          src="/assets/images/teacher/giaobai/ico_setting.png"
          alt="ico_setting"
        />
        <button className="btn-line-blue">Chỉnh sửa</button>
      </div>
    );
  }

  let report_data = {};
  if (!isEmpty(exercises) && !isEmpty(exercises.exercise_info)) {
    let total_overview_score =
      exercises.exercise_info.poor +
      exercises.exercise_info.average +
      exercises.exercise_info.good +
      exercises.exercise_info.excellent;
    report_data = {
      datasets: [
        {
          // data: [exercises.exercise_info.excellent, exercises.exercise_info.good, exercises.exercise_info.average, exercises.exercise_info.poor],
          data: [
            exercises.exercise_info.excellent,
            exercises.exercise_info.good,
            exercises.exercise_info.average,
            exercises.exercise_info.poor,
          ],
          backgroundColor: ["#00AEEF", "#00CC7E", "#FBB040", "#BE1E2D"],
        },
      ],
      borderWidth: 16,
      labels: [
        "Giỏi: " +
          exercises.exercise_info.excellent +
          " (" +
          Number.parseFloat(
            (exercises.exercise_info.excellent / total_overview_score) * 100
          ).toFixed(1) +
          "%)",
        "Khá: " +
          exercises.exercise_info.good +
          " (" +
          Number.parseFloat(
            (exercises.exercise_info.good / total_overview_score) * 100
          ).toFixed(1) +
          "%)",
        "Trung Bình: " +
          exercises.exercise_info.average +
          " (" +
          Number.parseFloat(
            (exercises.exercise_info.average / total_overview_score) * 100
          ).toFixed(1) +
          "%)",
        "Yếu: " +
          exercises.exercise_info.poor +
          " (" +
          Number.parseFloat(
            (exercises.exercise_info.poor / total_overview_score) * 100
          ).toFixed(1) +
          "%)",
      ],
    };
  }

  function remindStudent() {
    if (validateParam()) {
      dispatch({
        type: popupConstants.SHOW_FORM_POPUP,
        data: {
          showFormRemind: true,
        },
      });
    }
  }

  function validateParam() {
    return listStudentIdAll.length ? true : false;
  }

  let isEmptyDataInComplete =
    !isEmpty(exercises) && isEmpty(exercises.data.incomplete);

  let isRemindedAll =
    exercises.data.incomplete.filter((data) => parseInt(data.remind)).length ===
    exercises.data.incomplete.length;

  return (
    <div className="sunE-right-container">
      {Popup.showFormRemind && (
        <PopUpRemind
          class_id={id}
          exercise_id={exercise_id}
          listStudentIdAll={listStudentIdAll}
          width={340}
        />
      )}
      <Header
        title={"Bài đã giao"}
        isBack
        // component={addComponentSetting()}
        clickBack={() =>
          history.push(
            "/" + authentication.role + "/class/view/" + id + "/exercise/"
          )
        }
      />
      {!isLoading && (
        <div className="sunE-container-box giaobai-ganday">
          <div className="flex-m">
            {!isEmpty(exercises) && !isEmpty(exercises.exercise_info) && (
              <Fragment>
                <div className="flex-2 sunE-giaobai pr-15">
                  <div className="box-shadow box-info-giaobai semibold">
                    <h2 className="title">
                      {exercises.exercise_info.exercise_name}
                    </h2>
                    <h3 className="desc">
                      {exercises.exercise_info.exercise_topic}
                    </h3>
                    <p>
                      Thời gian làm:
                      <br />
                      {moment(exercises.exercise_info.start_time).format(
                        "DD/MM/YYYY"
                      )}{" "}
                      -{" "}
                      {moment(exercises.exercise_info.end_time).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                  {!isEmpty(exercises) &&
                    exercises.data.completed.length > 0 && (
                      <OverviewChartReport
                        report_data={report_data}
                        exercises={exercises}
                      />
                    )}
                </div>
              </Fragment>
            )}
            <div className="flex-3 pl-15">
              <div className="flex-m menu-gr">
                <div className="flex-1 pr-10">
                  <button
                    className={
                      "btn-line-blue flex-1" +
                      (view === "completed" ? " active" : "")
                    }
                    onClick={() => setView("completed")}
                  >
                    Đã làm
                  </button>
                </div>
                <div className="flex-1 pl-10">
                  <button
                    className={
                      "btn-line-blue flex-1" +
                      (view === "incomplete" ? " active" : "")
                    }
                    onClick={() => setView("incomplete")}
                  >
                    Chưa làm
                  </button>
                </div>
              </div>
              {view === "completed" && (
                <div className="list-giaobai scrollbar-custom">
                  {!isEmpty(exercises) &&
                    exercises.data.completed.map((data, i) => {
                      return (
                        <div className="item-student flex-m box-shadow" key={i}>
                          <div className="item-student-img">
                            <img
                              src={configConstants.BASE_URL + data.to_avatar}
                              alt="student"
                            />
                          </div>
                          <div className="flex-1 item-student-name pl-15">
                            <h2 className="semibold one-line">
                              {data.to_fullname
                                ? data.to_fullname
                                : data.to_username}
                            </h2>
                            <div className="flex-m">
                              <div>
                                <img
                                  src="/assets/images/teacher/giaobai/ico_clock.png"
                                  alt="ico_clock"
                                />
                              </div>
                              <span className="flex-1">
                                Nộp vào{" "}
                                {moment(data.create_submit_time)?.format(
                                  "HH:mm:ss DD/MM/yyyy"
                                )}
                              </span>
                            </div>
                          </div>
                          {data?.status === "1" ? (
                            <div className="score">
                              <span>{data.score}</span>
                            </div>
                          ) : (
                            <ChuaChamText>Chưa chấm</ChuaChamText>
                          )}
                        </div>
                      );
                    })}
                  {!isEmpty(exercises) &&
                    exercises.data.completed.length === 0 && (
                      <div className="__text_empty_tutorial_guide">
                        Chưa có học sinh nào hoàn thành bài tập này.
                      </div>
                    )}
                </div>
              )}
              {view === "incomplete" && (
                <Fragment>
                  <div className="list-giaobai chualam scrollbar-custom">
                    {!isEmptyDataInComplete && (
                      <div className="flex-m">
                        <div className="flex-1"></div>
                        <div className="select-all-k mg-top-0 flex-m">
                          <label className="flex-1 mr-10">Chọn tất cả</label>
                          <span className="chk-custom-gr wh20">
                            <input
                              type="checkbox"
                              name="all"
                              id="all"
                              checked={selectAll ? true : false}
                              onChange={onChangeSelectAll}
                              disabled={isRemindedAll}
                            />
                            <label htmlFor="all"></label>
                          </span>
                        </div>
                      </div>
                    )}
                    {!isEmpty(exercises) &&
                      exercises.data.incomplete.map((data, i) => {
                        return (
                          <div
                            className="item-student flex-m box-shadow"
                            key={i}
                          >
                            <div className="item-student-img">
                              <img
                                src={configConstants.BASE_URL + data.to_avatar}
                                alt="student"
                              />
                            </div>
                            <div className="flex-1 item-student-name pl-15">
                              <h2 className="semibold one-line">
                                {data.to_fullname
                                  ? data.to_fullname
                                  : data.to_username}
                              </h2>
                            </div>
                            <div className="chk-flex">
                              {!parseInt(data.remind) ? (
                                <span className="chk-custom-gr wh20">
                                  <input
                                    type="checkbox"
                                    id={i}
                                    checked={
                                      listStudentIdAll.includes(data.id)
                                        ? true
                                        : false
                                    }
                                    onChange={() => hanldeChecked(data.id)}
                                  />
                                  <label htmlFor={i}></label>
                                </span>
                              ) : (
                                <img
                                  src="/assets/images/teacher/giaobai/Nhac_nho.png"
                                  alt="Nhac_nho"
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    {isEmptyDataInComplete && (
                      <div className="__text_empty_tutorial_guide">
                        Tất cả học sinh đã hoàn thành bài tập.
                      </div>
                    )}
                  </div>
                  {!isEmptyDataInComplete && (
                    <div className="flex-m gr-all-nn">
                      <div className="flex-1"></div>
                      <div className="text-right">
                        <button
                          className={
                            "btn-line-blue" +
                            (!validateParam() || isRemindedAll
                              ? " btn-disable"
                              : "")
                          }
                          onClick={() => remindStudent()}
                        >
                          Nhắc nhở{" "}
                        </button>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { ViewExercise };
