import React from "react";
import "./styles.scss";

export const CheckBoxCustom = (props) => {
  const { label, status, onChange } = props;

  const renderBox = () => {
    switch (status) {
      case "checked":
        return <img src="/assets/images/icon/checkbox_active.png" />;
      case "fail":
        return <img src="/assets/images/icon/checkbox_danger.png" />;
      default:
        return <img src="/assets/images/icon/checkbox_none.png" />;
    }
  };

  const renderColor = () => {
    switch (status) {
      case "checked":
        return "#009444";
      case "fail":
        return "#BE1E2D";
      default:
        return null;
    }
  };

  return (
    <div className="item-checkbox" onClick={onChange}>
      {renderBox()}
      <label
        style={{ color: renderColor(), cursor: "pointer" }}
        dangerouslySetInnerHTML={{ __html: label }}
      ></label>
    </div>
  );
};

export const renderStatusCheckBox = (
  isMatch,
  isTrueAnswer,
  isReview = false
) => {
  let status = "unchecked";
  if (isReview) {
    if (isTrueAnswer) {
      status = "checked";
    } else if (isMatch) {
      status = "fail";
    }
  } else {
    status = isMatch ? "checked" : "unchecked";
  }
  return status;
};
